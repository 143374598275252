import { createAction } from 'common/utils'
import {
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_FAILED,
  SET_LOCATIONS,
  ADD_NEW_LOCATION,
  SUBMIT_NEW_LOCATION,
  SET_LOCATION_GEOJSON,
  SET_CURRENT_GEOJSON,
  DELETE_CURRENT_GEOJSON,
  SUBMIT_EDIT_LOCATION,
  EDIT_LOCATION,
  DELETE_LOCATION,
} from './types'

export const fetchLocations = payload => createAction(FETCH_LOCATIONS)
export const fetchLocationsFailed = payload =>
  createAction(FETCH_LOCATIONS_FAILED)

export const setLocations = payload => createAction(SET_LOCATIONS, payload)

export const submitNewLocation = payload =>
  createAction(SUBMIT_NEW_LOCATION, payload)
export const addNewLocation = payload => createAction(ADD_NEW_LOCATION, payload)

export const submitEditLocation = payload =>
  createAction(SUBMIT_EDIT_LOCATION, payload)
export const updateLocation = payload => createAction(EDIT_LOCATION, payload)
export const deleteLocation = payload => createAction(DELETE_LOCATION, payload)

export const setGeoJSON = payload => createAction(SET_LOCATION_GEOJSON, payload)
export const setCurrentUploadedGeoJSON = payload =>
  createAction(SET_CURRENT_GEOJSON, payload)
export const deleteGeoJSON = payload =>
  createAction(DELETE_CURRENT_GEOJSON, payload)
