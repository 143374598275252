import { DateUtils } from 'react-day-picker'
import dateFnsFormat from 'date-fns/format'
import dateFnsParse from 'date-fns/parse'

import enLocale from 'date-fns/locale/en'
import frLocale from 'date-fns/locale/fr'

const DEFAULT_LOCALE = 'en'

const MONTHS = {
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'Semptember',
    'October',
    'November',
    'December'
  ],
  fr: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ]
}

const WEEKDAYS_LONG = {
  en: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ],
  fr: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
}

const WEEKDAYS_SHORT = {
  en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  fr: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
}

const FIRST_DAY = {
  en: 0,
  fr: 1
}

const formatToLocale = {
  en: enLocale,
  fr: frLocale
}

const formatDay = (d, locale = DEFAULT_LOCALE) => {
  return `${WEEKDAYS_LONG[locale][d.getDay()]}, ${d.getDate()} ${
    MONTHS[locale][d.getMonth()]
  } ${d.getFullYear()}`
}

const formatMonthTitle = (d, locale = DEFAULT_LOCALE) => {
  return `${MONTHS[locale][d.getMonth()]} ${d.getFullYear()}`
}

const formatWeekdayShort = (i, locale = DEFAULT_LOCALE) => {
  return WEEKDAYS_SHORT[locale][i]
}

const formatWeekdayLong = (i, locale = DEFAULT_LOCALE) => {
  return WEEKDAYS_SHORT[locale][i]
}

const getFirstDayOfWeek = (locale = DEFAULT_LOCALE) => {
  return FIRST_DAY[locale]
}

export const FORMAT = 'YYYY/MM/DD'

export const parseDate = (
  str,
  format = FORMAT,
  locale = DEFAULT_LOCALE,
  date
) => {
  const parsed = dateFnsParse(date, format, locale)

  if (DateUtils.isDate(parsed)) {
    return parsed
  }

  return undefined
}

export const formatDate = (date, format = FORMAT, locale = DEFAULT_LOCALE) => {
  return dateFnsFormat(date, format, { locale: formatToLocale[locale] })
}

export const localeUtils = {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek
}
