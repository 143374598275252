import React from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'

import { MessageCount, Combo, ComboFirst, ComboLast } from 'common/components'
import { TextCell } from 'common/components/table/cells'
import CancelConfirmButtons from './cancelConfirmButtons'
import DeleteReportButton from './deleteReportButton'
import CarryForwardButton from './carryForwardButton'

import { NEW_PRODUCT_STATUS, FIELDS_CHANGES } from '../constants'
import { PUBLISHED_STATUS, OFF_SEASON } from 'core/product/config/constants'

import css from './style.module.scss'

const ProgressAndReviewCell = (
  { value: values, value: { key, status, user, productType }, notes, review, userId },
  onRestoreSorted
) => {
  const showReviewFlag = status === PUBLISHED_STATUS && notes.length > 0
  const showDeleteButton = FIELDS_CHANGES.delete[status]
  const showCarryForwardButton =
    FIELDS_CHANGES.carryForward[status] &&
    user === userId &&
    productType !== OFF_SEASON
  if (status.toLowerCase() === NEW_PRODUCT_STATUS) {
    return (
      <CancelConfirmButtons values={values} restoreSorted={onRestoreSorted} />
    )
  }

  return (
    <div className={css.container}>
      {showReviewFlag && (
        <Combo middle>
          <ComboFirst>
            <MessageCount
              count={notes.length}
              id={key}
              priority={review.priority}
            />
          </ComboFirst>
          <ComboLast>
            <TextCell noPadding>
              {format(review.deadline, 'MM/DD/YYYY HH:mm')}
            </TextCell>
          </ComboLast>
        </Combo>
      )}
      <div className={css.buttons}>
        {showDeleteButton && (
          <DeleteReportButton
            reportKey={key}
            isDeleted={status === 'deleted'}
          />
        )}
        {showCarryForwardButton && <CarryForwardButton reportKey={key} />}
      </div>
    </div>
  )
}

ProgressAndReviewCell.propTypes = {
  value: PropTypes.object.isRequired
}

export default ProgressAndReviewCell
