import Immutable from 'seamless-immutable'

/**
 * Move an item in an ordered list up or down
 *
 * @param {Array} list: the list containing the items
 * @param {number} index: the index of the item in list
 * @param {number} direction: direction to move (-1 toward front, 1 toward back)
 */
export function moveItem(list, index, direction) {
  if (direction !== -1 && direction !== 1)
    throw new Error('invalid direction, must be -1 or 1')

  if (!list[index]) throw new Error('No item at given index')

  const nextIndex = index + direction
  if (nextIndex < 0) return list // we are attempting to move the first item up
  if (nextIndex >= list.length) return list // we are attempting to move the last item down

  const updatedList = Immutable.asMutable(list)
  updatedList[nextIndex] = Immutable.merge(list[index], { index: nextIndex })
  updatedList[index] = Immutable.merge(list[nextIndex], { index })

  return Immutable(updatedList)
}

/**
 * Delete an item in an ordered list
 *
 * @param {Array} list: the list containing the items
 * @param {number} index: the index of the item in list
 */
export function deleteItem(list, index) {
  const deleted = list[index]
  if (!deleted) throw new Error('No item at given index')

  const updatedList = Immutable.asMutable(list)
  updatedList.splice(index, 1)

  return Immutable(reIndexList(updatedList))
}

/**
 * Adds or updates an item in an ordered list
 *
 * @param {Array} list: the list containing the items
 * @param {number} index: the index of the item to update
 * @param {object} item: the object to add/update in the list
 */
export function putItem(list, index, item) {
  if (!item) throw new Error('No item provided')

  let newItem = item
  if (index !== item.index) {
    newItem = Immutable.merge(item, { index })
  }

  const updatedList = Immutable.asMutable(list)
  updatedList[index] = newItem

  return Immutable(updatedList)
}

/**
 * Update the index param of every object in a list to match
 * the given order
 *
 * @param {Array} list: the list containing the items
 * @param {number} index: the index of the item to update
 * @param {object} item: the object to add/update in the list
 */
export function reIndexList(list) {
  let count = 0
  const updatedList = Immutable.asMutable(list, { deep: true })
  updatedList.forEach(item => (item.index = count++))

  return Immutable(updatedList)
}
