import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Immutable from 'seamless-immutable'

import { Link } from 'react-router-dom'
import { Checkbox } from 'common/components/form/'
import ManagedType from 'common/data/managedTypes'

import { submitAvalancheProblems } from 'core/avalancheProblems/actions'
import { putItem } from 'common/helpers/orderedList'

import css from './style.module.scss'

class TypeCell extends React.Component {
  static propTypes = {
    workspace: PropTypes.number.isRequired,
    workspaceAvalancheProblems: PropTypes.object.isRequired,
    avalancheProblem: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    handleToggle: PropTypes.func.isRequired
  }

  render() {
    const {
      workspaceAvalancheProblems,
      avalancheProblem,
      path,
      handleToggle
    } = this.props
    const disabled =
      workspaceAvalancheProblems.publicSum > 2 && !avalancheProblem.public

    return (
      <ManagedType type="avalancheproblemtypes">
        {({ data }) => {
          const to = `${path}/${avalancheProblem.index}`
          const problem = data.find(
            entry => entry.value === avalancheProblem.problemTypeId
          )
          const display = problem ? problem.label : ''
          return (
            <div className={css.typeCellContainer}>
              <Checkbox
                id={to}
                noLabel={true}
                disabled={disabled}
                onChange={handleToggle}
                value={avalancheProblem.public}
              />
              <Link className={css.typeLink} to={to}>
                {display}
              </Link>
            </div>
          )
        }}
      </ManagedType>
    )
  }
}

const mapDispatchToProps = (
  dispatch,
  { workspace, avalancheProblem, workspaceAvalancheProblems }
) => ({
  handleToggle: () => {
    const updatedAvalancheProblems = putItem(
      workspaceAvalancheProblems.problemList,
      avalancheProblem.index,
      Immutable.merge(avalancheProblem, { public: !avalancheProblem.public })
    )

    dispatch(
      submitAvalancheProblems({
        listPageSubmit: true,
        workspace,
        isEditing: true,
        problemList: updatedAvalancheProblems
      })
    )
  }
})

export default connect(
  null,
  mapDispatchToProps
)(TypeCell)
