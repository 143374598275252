import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { get, findIndex, isEqual } from 'lodash'

import { onDropdownChangeAction, addNewReport } from 'core/products/actions'

import { Table } from 'common/components'
import { classList } from 'common/utils'

import {
  DropdownFilter,
  DateFilters,
  DateFilterMethod,
  filterDropdowns,
  filterLocations
} from 'common/components/table/filters'
import { getUserLang } from 'core/users/selectors'

import { ResetFilterSort } from './buttons'

import {
  NewProductHeaderCell,
  ProductHeaderCell,
  LocationHeaderCell,
  ValidFromHeaderCell,
  ValidTillHeaderCell,
  ForecasterHeaderCell,
  StatusHeaderCell,
  QuickStartAndReviewHeaderCell
} from './headerCells'
import LastTimeSaved from 'core/product/components/lastTimeSaved'

import {
  ProductTitleCell,
  LocationCell,
  ForecasterCell,
  ProductTypeCell,
  DropdownCell,
  DateCell,
  StatusCell,
  ProgressAndReviewCell
} from './columnCells'

import { QUICK_START_STATUS_FILTER } from './constants'

import css from './style.module.scss'

const CELL_WIDTH_WIDE = 135
const CELL_WIDTH_NARROW = 105

const DEFAULT_SORT = {
  id: 'validFrom',
  desc: true
}

const DEFAULT_FILTER = {
  id: 'validFrom',
  value: 'today'
}

const NEW_PRODUCT_SORT = {
  id: 'id',
  desc: false
}

const QUICKSTART_FILTERS = userId => [
  {
    id: 'user',
    value: userId
  },
  {
    id: 'validFrom',
    value: 'today'
  },
  {
    id: 'status',
    value: 'quickstart'
  }
]

const REVIEW_FILTERS = [
  {
    id: 'status',
    value: 'published'
  },
  {
    id: 'nbReviewsAndProgress',
    value: 0
  }
]

class ProductTable extends Component {
  static propTypes = {
    products: PropTypes.array.isRequired,
    locations: PropTypes.object.isRequired,
    locationNames: PropTypes.object.isRequired,
    productTypes: PropTypes.array.isRequired,
    reportStatuses: PropTypes.array.isRequired,
    dropdownsOptions: PropTypes.shape({
      locationsOptions: PropTypes.array.isRequired,
      forecasterOptions: PropTypes.array.isRequired
    }).isRequired,
    userId: PropTypes.string.isRequired,
    className: PropTypes.string,
    notes: PropTypes.object.isRequired,
    reviews: PropTypes.object.isRequired
  }

  static defaultProps = {
    userId: ''
  }

  state = {
    key: '0',
    sorted: [DEFAULT_SORT],
    previousSorted: [],
    filtered: [DEFAULT_FILTER],
    previousFiltered: []
  }

  componentDidMount() {
    this.setState({
      key: this.props.products.map(product => product.key).join('')
    })
  }

  onAddNewReportClicked = () => {
    this.setState(state => {
      this.props.addNewReport()
      return {
        key: this.props.products.map(product => product.key).join(''),
        previousSorted: state.sorted,
        sorted: [NEW_PRODUCT_SORT],
        filtered: [],
        previousFiltered: state.filtered
      }
    })
  }

  onResetSortedAndFiltered = () => {
    this.setState({
      key: this.props.products.map(product => product.key).join(''),
      sorted: [DEFAULT_SORT],
      filtered: [DEFAULT_FILTER]
    })
  }

  onRestoreSorted = () => {
    this.setState({
      key: this.props.products.map(product => product.key).join(''),
      sorted: this.state.previousSorted,
      previousSorted: this.state.sorted
    })
  }

  onSortedChange = sorted => {
    this.setState({
      key: this.props.products.map(product => product.key).join(''),
      previousSorted: this.state.sorted,
      sorted
    })
  }

  onRestoreFiltered = () => {
    this.setState({
      key: this.props.products.map(product => product.key).join(''),
      filtered: this.state.previousFiltered,
      previousFiltered: this.state.filtered
    })
  }

  onFilteredChange = filtered => {
    this.setState({
      key: this.props.products.map(product => product.key).join(''),
      previousFiltered: this.state.filtered,
      filtered
    })
  }

  onQuickStartClicked = () => {
    this.onFilteredChange(QUICKSTART_FILTERS(this.props.userId))
  }

  onReviewFilterClicked = () => {
    this.onFilteredChange(REVIEW_FILTERS)
  }

  filterLocations = () => {
    return filterLocations(this.props.locationNames)
  }

  sortLocations = (a, b) => {
    const {
      dropdownsOptions: { locationsOptions }
    } = this.props
    const ids = locationsOptions.map(location => location.value)

    if (!ids.includes(a)) return -1
    if (!ids.includes(b)) return 1

    const getLocation = id =>
      locationsOptions.find(location => location.value === id).label

    return getLocation(a).localeCompare(getLocation(b))
  }

  sortForecasters = (a, b) => {
    const {
      dropdownsOptions: { forecasterOptions }
    } = this.props
    const ids = forecasterOptions.map(forecaster => forecaster.value)

    if (!ids.includes(a)) return -1
    if (!ids.includes(b)) return 1

    const getForecaster = id =>
      forecasterOptions.find(forecaster => forecaster.value === id).label

    return getForecaster(a).localeCompare(getForecaster(b))
  }

  sortStatuses = (a, b) => {
    const { reportStatuses } = this.props
    const ids = reportStatuses.map(status => status.id)

    if (!ids.includes(a)) return -1
    if (!ids.includes(b)) return 1

    const getStatus = id =>
      reportStatuses.find(status => status.id === id).display

    return getStatus(a).localeCompare(getStatus(b))
  }

  renderDropdownFilter = options => {
    const filterOptions = options.slice()
    filterOptions.unshift({ value: '', label: this.props.t('all') })

    return DropdownFilter(filterOptions)
  }

  renderProductFilter = () => {
    return this.renderDropdownFilter(
      this.props.productTypes.map(type => ({
        value: type.id,
        label: type.display
      }))
    )
  }

  renderForecastFilter = () => {
    return this.renderDropdownFilter(
      this.props.dropdownsOptions.forecasterOptions
    )
  }

  renderStatusFilter = () => {
    //  Add 'Not Published' Status filter option
    const reportStatuses = [
      {
        ...QUICK_START_STATUS_FILTER,
        display: this.props.t(QUICK_START_STATUS_FILTER.display)
      },
      ...this.props.reportStatuses
    ]
    return this.renderDropdownFilter(
      reportStatuses.map(status => ({
        value: status.id,
        label: status.display
      }))
    )
  }

  renderProductTitleCell = () => {
    const { locations, userId } = this.props
    return ProductTitleCell({ locations, userId })
  }

  renderDropdownCell = (options, accessor) => {
    return DropdownCell(options, this.props.onDropdownChange, accessor)
  }

  renderProductTypeCell = () => {
    const { onDropdownChange, productTypes } = this.props
    return ProductTypeCell(
      productTypes.map(type => ({
        value: type.id,
        label: type.display
      })),
      onDropdownChange
    )
  }

  renderLocationCell = () => {
    const { onDropdownChange, dropdownsOptions, locations } = this.props
    return LocationCell(
      locations,
      dropdownsOptions.locationsOptions,
      onDropdownChange
    )
  }

  onForecasterDropdownChange = (data, e, accessor) => {
    let confirm = true
    if (data.row.status !== 'pending')
      confirm = window.confirm(this.props.t('confirmForecasterChange')) === true

    if (confirm) this.props.onDropdownChange(data, e, accessor)
  }

  renderForecasterCell = () => {
    const {
      dropdownsOptions: { forecasterOptions }
    } = this.props
    return ForecasterCell(forecasterOptions, this.onForecasterDropdownChange)
  }

  renderProgressAndReviewCell = rowData => {
    const onClick = () => {
      this.onRestoreSorted()
      this.onRestoreFiltered()
    }

    return ProgressAndReviewCell(
      {
        ...rowData,
        notes: this.props.notes[rowData.row.id] || [],
        review: this.props.reviews[rowData.row.id] || [],
        userId: this.props.userId
      },
      onClick
    )
  }

  renderDateCell = accessor => {
    return DateCell(accessor, this.props.userId)
  }

  render() {
    const { products, className, reportStatuses, date, language } = this.props
    const { sorted, filtered } = this.state

    const filterProduct = filterDropdowns('productType')
    const filterForecast = filterDropdowns('user')
    const filterStatus = filterDropdowns('status')

    const classes = classList(css.ReactTable, className || '')

    const noFilters = filtered.length === 0
    const isCompoundFiltering = filtered.length > 1
    const isOnlyDefaultFilter =
      filtered.length === 1 && isEqual(filtered[0], DEFAULT_FILTER)

    const isSorting = sorted.length > 0
    const isNotDefaultSort = findIndex(sorted, DEFAULT_SORT) < 0
    const isNotNewProductSort = findIndex(sorted, NEW_PRODUCT_SORT) < 0

    const shouldShowReset =
      isNotNewProductSort &&
      (noFilters ||
        isCompoundFiltering ||
        !isOnlyDefaultFilter ||
        (isSorting && isNotDefaultSort))

    return (
      <div>
        <ResetFilterSort
          shouldShow={shouldShowReset}
          handleOnClick={this.onResetSortedAndFiltered}
        />
        {/* When the cancel button for a new product is clicked, the sorted
         * and filtered attributes on the Table state get set to null. In
         * order to force those to be updated from the props we've added a
         * key that will cause the component to unmount and remount.
         * This is potentially something to look at fixing more elegantly
         * in the future.
         */}
        <Table
          key={this.state.key}
          data={products}
          pageSizeOptions={[10, 20, 25, 50, 100]}
          defaultPageSize={100}
          showPageJump={false}
          sorted={this.state.sorted}
          onSortedChange={this.onSortedChange}
          filtered={this.state.filtered}
          onFilteredChange={this.onFilteredChange}
          sortable={isNotNewProductSort}
          filterable={isNotNewProductSort}
          className={classes}
          columns={[
            {
              columns: [{ accessor: 'id', show: false }]
            },
            {
              columns: [
                {
                  accessor: 'key',
                  filterable: true,
                  sortable: false,
                  Filter: () => (
                    <NewProductHeaderCell
                      onClick={this.onAddNewReportClicked}
                    />
                  ),
                  Cell: this.renderProductTitleCell(),
                  minWidth: CELL_WIDTH_WIDE
                }
              ]
            },
            {
              columns: [
                {
                  id: 'productType',
                  accessor: product => get(product, 'productType'),
                  Filter: this.renderProductFilter(),
                  filterMethod: filterProduct,
                  Header: ProductHeaderCell,
                  Cell: this.renderProductTypeCell(),
                  minWidth: CELL_WIDTH_NARROW
                }
              ]
            },
            {
              columns: [
                {
                  id: 'location',
                  accessor: product => `${get(product, 'location')}`,
                  filterMethod: this.filterLocations(),
                  sortMethod: this.sortLocations,
                  Header: LocationHeaderCell,
                  Cell: this.renderLocationCell(),
                  minWidth: CELL_WIDTH_NARROW
                }
              ]
            },
            {
              columns: [
                {
                  id: 'validFrom',
                  accessor: product => get(product, 'date.validFrom'),
                  Filter: DateFilters,
                  filterMethod: DateFilterMethod,
                  Header: ValidFromHeaderCell,
                  Cell: this.renderDateCell('validFrom'),
                  className: 'publishDate',
                  minWidth: CELL_WIDTH_WIDE
                }
              ]
            },
            {
              columns: [
                {
                  id: 'validTill',
                  accessor: product => get(product, 'date.validTill'),
                  Filter: DateFilters,
                  filterMethod: DateFilterMethod,
                  Header: ValidTillHeaderCell,
                  Cell: this.renderDateCell('validTill'),
                  className: 'publishDate',
                  minWidth: CELL_WIDTH_WIDE
                }
              ]
            },
            {
              columns: [
                {
                  id: 'user',
                  accessor: product => get(product, 'user'),
                  Filter: this.renderForecastFilter(),
                  filterMethod: filterForecast,
                  sortMethod: this.sortForecasters,
                  Header: ForecasterHeaderCell,
                  headerClassName: css.forecasterHeader,
                  Cell: this.renderForecasterCell(),
                  className: css.forecasterColumnCell,
                  minWidth: CELL_WIDTH_WIDE
                }
              ]
            },
            {
              columns: [
                {
                  id: 'status',
                  accessor: product => get(product, 'status'),
                  Filter: this.renderStatusFilter(),
                  filterMethod: filterStatus,
                  sortMethod: this.sortStatuses,
                  Header: StatusHeaderCell,
                  headerClassName: css.statusHeader,
                  Cell: rowData => (
                    <StatusCell {...rowData} statusTypes={reportStatuses} />
                  ),
                  minWidth: CELL_WIDTH_NARROW
                }
              ]
            },
            {
              columns: [
                {
                  id: 'nbReviewsAndProgress',
                  accessor: product => product,
                  sortable: false,
                  filterable: true,
                  // Hides search field that appears behind buttons
                  Filter: () => {
                    if (isNotNewProductSort) {
                      return (
                        <QuickStartAndReviewHeaderCell
                          onQuickStartClick={this.onQuickStartClicked}
                          onReviewFilterClick={this.onReviewFilterClicked}
                        />
                      )
                    }
                    return null
                  },
                  filterMethod: (filter, row) => {
                    if (row.id === -1) return true
                    const length =
                      this.props.notes[row.id] &&
                      this.props.notes[row.id].length
                    return length > filter.value
                  },
                  Header: () => (
                    <LastTimeSaved date={date} language={language} />
                  ),
                  Cell: this.renderProgressAndReviewCell,
                  minWidth: CELL_WIDTH_WIDE
                }
              ]
            }
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  date: state.product.lastTimeSaved,
  language: getUserLang(state)
})

const mapDispatchToProps = {
  onDropdownChange: onDropdownChangeAction,
  addNewReport
}

ProductTable = withTranslation('common')(ProductTable)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductTable)
