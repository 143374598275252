import { createActions, handleActions } from 'redux-actions'
import { put, call, select, takeEvery } from 'redux-saga/effects'
import {
  FETCH_ACTIONS,
  delegateReducerForFetch,
  statusReducerFactory
} from 'common/helpers/store'
import * as utils from 'dot-prop-immutable'
import Status from 'common/helpers/Status'
import * as api from 'common/api'
import { getUserLang } from 'core/users/selectors'

// Types
const PREFIX = 'avid/managedTypes/'
export const READ = PREFIX + 'READ'
export const SET = PREFIX + 'SET'

// Defaults
export const DEFAULT = {
  status: new Status(),
  data: []
}

// Actions
export const actions = createActions({
  [READ]: FETCH_ACTIONS,
  [SET]: undefined
}).avid.managedTypes

export const read = actions.read.requested

// Reducer
const typeReducer = handleActions(
  new Map([
    [READ, statusReducerFactory()],
    [
      SET,
      (state, action) => {
        const { data, language } = action.payload

        return utils.set(state, 'data', data.map(setDisplay, { language }))
      }
    ]
  ]),
  DEFAULT
)

export default handleActions(
  new Map([
    [READ, delegateReducerForFetch(delegateToTypeReducer)],
    [SET, delegateToTypeReducer]
  ]),
  new Map()
)

// Getter
export function get({ managedTypes }, type, defaultValue) {
  return managedTypes.has(type) ? managedTypes.get(type) : defaultValue
}

// Side effects
function* readType(action) {
  const { type } = action.payload

  try {
    yield put(actions.read.pending({ type }))

    const language = yield select(getUserLang)
    const data = yield call(api.get, type)

    yield put(actions.set({ type, data, language }))
    yield put(actions.read.fulfilled({ type }))
  } catch (error) {
    console.log(error)
    window.alert(error.message)
    yield put(actions.read.rejected({ type, error }))
  }
}

function callOnce(saga) {
  let previousCalls = []

  return function*(...args) {
    const call = args[args.length - 1]
    const hasBeenCalled = previousCalls.some(prev => Object.is(prev, call))
    if (hasBeenCalled) return

    previousCalls.push(call)
    yield* saga(...args)
  }
}

export function* sagas() {
  return yield takeEvery(read, callOnce(readType))
}

// Utils
function setDisplay(type) {
  return {
    value: type.id,
    label: type.values.filter(value => value.includes(this.language))[0][1]
  }
}

function delegateToTypeReducer(state, action) {
  const { type } = action.payload

  return new Map(state.set(type, typeReducer(state.get(type), action)))
}
