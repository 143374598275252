import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { moveItem } from 'common/helpers/orderedList'

import { MoveKeys, DeleteButton } from 'common/components'
import { AspectElevation, HazardChart } from 'common/components/form'
import TypeCell from '../typeCell'

import {
  submitAvalancheProblems,
  deleteAvalancheProblem
} from 'core/avalancheProblems/actions'

import css from './style.module.scss'

class AvalancheProblemsListItem extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    workspaceAvalancheProblems: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    workspace: PropTypes.number.isRequired,
    problem: PropTypes.object.isRequired
  }

  handleMoveClick = (value, problem) => {
    const { onUpdateAvalancheProblem } = this.props
    onUpdateAvalancheProblem(value, problem)
  }

  handleDeleteClick = problem => {
    const { t, confirmDelete } = this.props
    if (window.confirm(t('confirmDelete')) === true) confirmDelete(problem)
  }

  render() {
    const {
      key,
      path,
      problem,
      workspace,
      workspaceAvalancheProblems
    } = this.props

    return (
      <div className={css.problem} key={key}>
        <div className={css.typeAndKeys}>
          <TypeCell
            workspace={workspace}
            workspaceAvalancheProblems={workspaceAvalancheProblems}
            avalancheProblem={problem}
            path={path}
          />
          <div className={css.keys}>
            <MoveKeys
              start={problem.index === 0}
              end={
                problem.index ===
                workspaceAvalancheProblems.problemList.length - 1
              }
              onClick={value => this.handleMoveClick(value, problem)}
            />
            <DeleteButton
              className={css.deleteContainer}
              onClick={() => this.handleDeleteClick(problem)}
            />
          </div>
        </div>
        <div className={css.aspectAndHazard}>
          <div className={css.aspectElevation}>
            <AspectElevation value={problem.aspectElevation} disabled={true} />
          </div>
          <div className={css.hazard}>
            <HazardChart value={problem.hazard} disabled={true} />
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (
  dispatch,
  { workspace, workspaceAvalancheProblems }
) => ({
  confirmDelete(avalancheProblem) {
    dispatch(
      deleteAvalancheProblem({
        workspace,
        index: avalancheProblem.index
      })
    )
  },
  onUpdateAvalancheProblem(value, avalancheProblem) {
    const updatedAvalancheProblems = moveItem(
      workspaceAvalancheProblems.problemList,
      avalancheProblem.index,
      value
    )

    dispatch(
      submitAvalancheProblems({
        workspace,
        isEditing: true,
        problemList: updatedAvalancheProblems
      })
    )
  }
})

AvalancheProblemsListItem = connect(
  null,
  mapDispatchToProps
)(AvalancheProblemsListItem)
AvalancheProblemsListItem = withTranslation([
  'avalancheProblems',
  'likelihood'
])(AvalancheProblemsListItem)

export default AvalancheProblemsListItem
