import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { getFormValues, isValid, isDirty } from 'redux-form'
import { WEAK_LAYER_FORM_NAME } from 'core/product/config/constants'
import format from 'date-fns/format'
import { getUserLang } from 'core/users/selectors'
import {
  getCurrentProductLocation,
  getCurrentProductKey
} from 'core/product/selectors'

import * as api from 'common/api'

import {
  setWeakLayers,
  addWeakLayer,
  updateWeakLayer,
  failFetchingWeakLayers,
  addWeakLayerComment
} from './actions'

import {
  FETCH_WEAK_LAYERS,
  CREATE_WEAK_LAYER,
  SUBMIT_WEAK_LAYER,
  DELETE_WEAK_LAYER,
  SUBMIT_WEAK_LAYER_COMMENT
} from './types'

import { getWeakLayerComments } from './selectors'

function* onFetchWeakLayers() {
  yield takeLatest(FETCH_WEAK_LAYERS, fetchWeakLayers)
}

/*
  GET /weaklayers

  [
    {
        "id": 1,
        "name": "Non-Persistent",
        "location": 0,
        "burialDate": "2018-08-22",
        "status": "active",
        "grainType": 0,
        "depth": {
            "from": 0,
            "to": 99
        },
        "thickness": {
            "from": 0,
            "to": 0
        },
        "comments": [],
        "sticky": true
    }
  ]
*/
function* fetchWeakLayers() {
  const locationId = yield select(getCurrentProductLocation)
  const endpoint = `/weaklayers/locations/${locationId}`
  try {
    const weakLayers = yield call(api.get, endpoint)
    yield put(setWeakLayers(weakLayers))
  } catch (err) {
    console.log(err)
    //window.alert(err.message)
    yield put(failFetchingWeakLayers(err))
  }
}

function* onCreateWeakLayer() {
  yield takeLatest(CREATE_WEAK_LAYER, createWeakLayer)
}

/*
  POST /v1/locations/:{locationId}/weaklayer
*/
function* createWeakLayer({ payload }) {
  const reportKey = yield select(getCurrentProductKey)
  const { locationId, redirect } = payload
  const endpoint = `/locations/${locationId}/weaklayer`

  try {
    const newWeakLayer = yield call(api.post, endpoint)
    yield put(addWeakLayer({ ...newWeakLayer, new: true }))

    const url = `/avid/products/edit/${reportKey}/weakLayers/${newWeakLayer.id}`
    redirect(url)
  } catch (err) {
    console.log(err)
    window.alert(err.message)
  }
}

function* onSubmitWeakLayer() {
  yield takeLatest(SUBMIT_WEAK_LAYER, putWeakLayer)
}

/*
  PUT /weaklayers/{weakLayerId}

  {
    "name": "Weak Layer 1",
    "location": 1,
    "burialDate": "2018-10-30+09:00:00",
    "status": "active",
    "grainType": 1,
    "depth": {
      "from": 0,
      "to": 10
    },
    "thickness": {
      "from": 0,
      "to": 10
    },
    "comments": [],
    "sticky": false
  }
*/
function* putWeakLayer({ payload }) {
  const reportKey = yield select(getCurrentProductKey)
  const { weakLayerId, redirect } = payload

  const dirty = yield select(state => isDirty(WEAK_LAYER_FORM_NAME)(state))
  if (!dirty) return

  const valid = yield select(state => isValid(WEAK_LAYER_FORM_NAME)(state))
  if (!valid) return

  const values = yield select(state =>
    getFormValues(WEAK_LAYER_FORM_NAME)(state)
  )
  const comments = yield select(state =>
    getWeakLayerComments(state, { weakLayerId })
  )

  let isEditing = true
  if (values.new) {
    isEditing = false
    delete values.new
  }

  const locale = yield select(state => getUserLang(state))
  const burialDate = format(values.burialDate, 'YYYY-MM-DD', { locale })
  const weakLayerUpdated = Object.assign(
    {},
    values,
    { burialDate },
    { comments }
  )

  try {
    yield call(api.put, `/weaklayers/${weakLayerId}`, weakLayerUpdated)
    yield put(updateWeakLayer({ weakLayerUpdated }))

    if (!isEditing) {
      redirect(`/avid/products/edit/${reportKey}/weakLayers`)
    }
  } catch (err) {
    console.log(err)
    window.alert(err.message)
  }
}

function* onDeleteWeakLayer() {
  yield takeLatest(DELETE_WEAK_LAYER, deleteWeakLayer)
}

/*
  DELETE /weaklayers/{weakLayerId}
*/
function* deleteWeakLayer({ payload }) {
  try {
    yield call(api.del, `/weaklayers/${payload}`)
  } catch (err) {
    console.log(err)
    window.alert(err.message)
  }
}

/*
  POST /weaklayer/{weakLayerId}/comments

  {
    "weakLayerId": 25,
    "content": "test",
    "date": "2018-05-24T21:18:41.234Z",
    "author": "5aaea2848bd5067ff577d24d"
  }
*/
function* submitWeakLayerComment({ payload }) {
  const { weakLayerId, comment } = payload

  try {
    const newWeakLayerComment = yield call(
      api.post,
      `weaklayers/${weakLayerId}/comments`,
      comment
    )
    yield put(addWeakLayerComment({ weakLayerId, newWeakLayerComment }))
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

function* onSubmitWeakLayerComment() {
  yield takeLatest(SUBMIT_WEAK_LAYER_COMMENT, submitWeakLayerComment)
}

export default function* rootSaga() {
  yield all([
    onFetchWeakLayers(),
    onCreateWeakLayer(),
    onSubmitWeakLayer(),
    onDeleteWeakLayer(),
    onSubmitWeakLayerComment()
  ])
}
