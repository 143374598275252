import { createAction } from 'common/utils'

import {
  FETCH_SEASON,
  SET_SEASON,
  FAIL_FETCH_SEASON,
  SUBMIT_SEASON
} from './types'

export const fetchSeason = payload => createAction(FETCH_SEASON, payload)
export const setSeason = payload => createAction(SET_SEASON, payload)
export const failFetchSeason = payload =>
  createAction(FAIL_FETCH_SEASON, payload)
export const submitSeason = payload => createAction(SUBMIT_SEASON, payload)
