import { put, call, takeLatest, all } from 'redux-saga/effects'
import addDays from 'date-fns/add_days'
import isBefore from 'date-fns/is_before'
import isSameDay from 'date-fns/is_same_day'

import * as api from 'common/api'
import {
  FETCH_PRODUCTS,
  ON_DROPDOWN_CHANGE,
  ON_DATE_CHANGE,
  SUBMIT_NEW_REPORT,
  DELETE_REPORT,
  CARRY_FORWARD_REPORT_DATA
} from './types'
import {
  setProducts,
  fetchProductsFailed,
  updateProduct,
  deleteProduct,
  confirmNewReport as confirmNewReportReducer,
  setProductForecasters
  // setNote
} from './actions'
import { onDateChange as changeDate } from 'core/products/actions'

function* onFetchProducts() {
  yield takeLatest(FETCH_PRODUCTS, fetchProducts)
}

/*
  GET /reports

  To properly hydrate, requires:
  GET /locations
  GET /users
  [
    {
      "id": 1,
      "key": "59ea6d0b3978f00820b624ad",
      "productType": "forecast",
      "status": "draft",
      "user": "5aa15f5f0fcaa4541b1563ce",
      "location": 8,
      "date": {
        "reviewBy:" "2018-05-25T17:00:00.000Z"
        "edited": "2018-05-25T21:00:00.000Z",
        "validFrom": "2018-05-25T17:00:00.000Z",
        "validTill": "2018-05-26T17:00:00.000Z"
      },
      "version": 1
    }
  ]
*/
function* fetchProducts() {
  try {
    const products = yield call(api.get, 'reports')
    yield put(setProducts(products))
    const productForecasters = yield call(api.get, 'users')
    yield put(setProductForecasters(productForecasters))
  } catch (err) {
    console.error(err)
    window.alert(err.message)
    yield put(fetchProductsFailed(err))
  }
}

function* onSubmitNewReport() {
  yield takeLatest(SUBMIT_NEW_REPORT, submitNewReport)
}

/*
  POST /reports

  {
      "productType": "forecast",
      "status": "draft",
      "user": "5aa15f5f0fcaa4541b1563ce",
      "location": 8,
      "date": {
        "reviewBy:" "2018-05-25T17:00:00.000Z"
        "validFrom": "2018-05-25T17:00:00.000Z",
        "validTill": "2018-05-26T17:00:00.000Z"
      },
      "version": 1
    }
*/
function* submitNewReport({ payload }) {
  const {
    product,
    product: { key: tempKey }
  } = payload
  delete product.key

  try {
    const newReport = yield call(api.post, 'reports', product)
    newReport.tempKey = tempKey
    yield put(confirmNewReportReducer(newReport))
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

function* onDropdownChange() {
  yield takeLatest(ON_DROPDOWN_CHANGE, dropdownChange)
}

/*
  PUT /reports/:reportKey

  {
    ...report,
    "user": "59ea8287c30a38053ab57988",
  }
*/
function* dropdownChange({ payload }) {
  if (payload.row.original.key === -1) {
    return
  }

  const {
    row: {
      original: {
        id,
        key,
        productType,
        status,
        user,
        location,
        date,
        nbReviews,
        version
      }
    },
    accessor,
    value
  } = payload

  const product = {
    id,
    key,
    productType,
    status,
    user,
    location,
    date,
    nbReviews,
    version
  }

  const accessorsValueIsNumber = ['location']
  if (accessorsValueIsNumber.indexOf(accessor) > -1) {
    product[accessor] = parseInt(value, 10)
  } else {
    product[accessor] = value
  }

  try {
    const updatedReport = yield call(api.put, `reports/${key}`, product)
    yield put(updateProduct(updatedReport))
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

function* onDateChange() {
  yield takeLatest(ON_DATE_CHANGE, dateChange)
}

/*
  PUT /reports/:reportKey

  {
    ...report,
    "date": {
      "validFrom": "2018-05-25T11:00:00.000Z",
      "validTill": "2018-05-27T17:00:00.000Z",
      "review": "2018-05-25T22:00:00.000Z"
    }
  }
*/
function* dateChange({ payload }) {
  const {
    row,
    row: {
      original: {
        key,
        date: { validTill }
      }
    },
    accessor,
    value
  } = payload

  if (key === -1) {
    if (accessor === 'validFrom') {
      const validFrom = value
      const isBeforeValidFrom =
        isBefore(validTill, validFrom) || isSameDay(validFrom, validTill)
      if (isBeforeValidFrom) {
        yield put(changeDate(row, addDays(validFrom, 1), 'validTill'))
      }
    }
    return
  }

  const {
    row: {
      original: {
        id,
        productType,
        status,
        user,
        location,
        date: { validFrom, review },
        version
      }
    }
  } = payload

  const product = {
    id,
    key,
    productType,
    status,
    user,
    location,
    date: {
      validFrom,
      validTill,
      review
    },
    version
  }

  product.date[accessor] = value

  try {
    const updatedReport = yield call(api.put, `reports/${key}`, product)
    yield put(updateProduct(updatedReport))
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

function* onDeleteReport() {
  yield takeLatest(DELETE_REPORT, deleteReport)
}

/*
  DEL /reports/:reportKey
*/
function* deleteReport({ payload }) {
  const { reportKey } = payload
  try {
    yield call(api.del, `reports/${reportKey}`)
    yield put(deleteProduct(reportKey))
    yield put({ type: FETCH_PRODUCTS })
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

function* onCarryForwardReportData() {
  yield takeLatest(CARRY_FORWARD_REPORT_DATA, carryForwardReportData)
}

/*
  PUT /reports/:reportKey/initialize
*/
function* carryForwardReportData({ payload }) {
  const { reportKey } = payload
  try {
    yield call(api.put, `/reports/${reportKey}/initialize`)
    yield put({ type: FETCH_PRODUCTS })
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

export default function* rootSaga() {
  yield all([
    onFetchProducts(),
    onSubmitNewReport(),
    onDropdownChange(),
    onDateChange(),
    onDeleteReport(),
    onCarryForwardReportData()
  ])
}
