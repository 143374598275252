import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { initialHazardValue } from 'core/avalancheProblems/selectors'

import { WorkspaceMain } from 'common/components'
import DefinedType from 'common/data/definedTypes'
import ManagedType from 'common/data/managedTypes'
import {
  Label,
  InfoTip,
  AutoSave,
  TextField,
  HazardChart,
  AspectElevation,
  MultiLanguageInput
} from 'common/components/form'
import { Dropdown } from 'common/components'
import {
  DestructiveSizeInfoTable,
  AvalancheLikelihoodInfoTable,
  AvalancheProblemTypeInfoTable,
  AvalancheSensitivityInfoTable,
  AvalancheDistributionInfoTable
} from 'common/components/form/infoTip/templates'

import {
  integer,
  required,
  lessThan,
  lessThan500,
  greaterThan,
  requiredAndNotBlank
} from 'common/helpers/validation'
import selectOptionStructure from 'common/helpers/selectOptionStructure'

import css from './style.module.scss'

const typicalSizeToHazard = {
  one: 1,
  one_half: 1.5,
  two: 2,
  two_half: 2.5,
  three: 3,
  three_half: 3.5,
  four: 4,
  four_half: 4.5,
  five: 5
}

const hazardLikelihoodMatrix = {
  widespread: {
    unreactive: 'unlikely',
    stubborn: 'possible',
    reactive: 'verylikely',
    touchy: 'certain'
  },
  specific: {
    unreactive: 'unlikely',
    stubborn: 'possible',
    reactive: 'likely',
    touchy: 'verylikely'
  },
  isolated: {
    unreactive: 'unlikely',
    stubborn: 'unlikely',
    reactive: 'possible',
    touchy: 'likely'
  }
}

const likelihoods = [
  'unlikely',
  'possible_unlikely',
  'possible',
  'likely_possible',
  'likely',
  'verylikely_likely',
  'verylikely',
  'certain_verylikely',
  'certain'
]

const DropdownWithBlankOption = ({
  field,
  update,
  blur,
  options,
  autoFocus = false,
  disableBlankOption
}) => {
  const data = [
    {
      value: 0,
      label: ' ',
      disabled: disableBlankOption
    }
  ].concat(options)

  return (
    <Dropdown
      name={field.name}
      autoFocus={autoFocus}
      options={data}
      value={field.value}
      onChange={event => update(field.name, event.target.value)}
      onBlur={() => blur(field.name)}
    />
  )
}

const AvalancheProblemForm = ({
  isEditing,
  relatedWeakLayers
}) => {
  const { t } = useTranslation(['avalancheProblems', 'validation'])
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    handleSubmit
  } = useFormikContext()

  if (!values) return null

  function update(field, value) {
    setFieldValue(field, value)
  }

  function handleBlur(field) {
    setFieldTouched(field)
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const validateFrom = useCallback((value) => {
    if (!value) return undefined
    if (value < 0) return 'mustBePositiveInteger'

    return lessThan(value, values, 'typicalDepth') || lessThan500(value) || integer(value)
  }, [values])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const validateTo = useCallback((value) => {
    if (!value) return undefined
    if (value < 0) return 'mustBePositiveInteger'

    return greaterThan(value, values, 'typicalDepth') || lessThan500(value) || integer(value)
  }, [values])

  function calculateNewLikelihood(newCentroidLikelihood) {
    const newCentroidLikelihoodIndex = likelihoods.indexOf(
      newCentroidLikelihood
    )

    const newLikelihoodFromIndex = newCentroidLikelihoodIndex - 1
    const newLikelihoodToIndex = newCentroidLikelihoodIndex + 1

    return {
      from:
        likelihoods[newLikelihoodFromIndex > -1 ? newLikelihoodFromIndex : 0],
      to: likelihoods[newLikelihoodToIndex < 9 ? newLikelihoodToIndex : 8]
    }
  }

  function calculateNewSize() {
    const hazard = JSON.parse(values.hazard)
    const sizeFrom = hazard.centroid.size - 0.5
    const sizeTo = hazard.centroid.size + 0.5

    return {
      from: sizeFrom > 1 ? sizeFrom : 1,
      to: sizeTo < 5 ? sizeTo : 5
    }
  }

  function updateHazard(field, value) {
    let newHazard

    if (field === 'typicalSize') {
      const centroid = typicalSizeToHazard[value]

      const hazard = JSON.parse(values.hazard)
      const sizeFrom = centroid - 0.5
      const sizeTo = centroid + 0.5

      newHazard = {
        ...hazard,
        size: {
          from: sizeFrom > 1 ? sizeFrom : 1,
          to: sizeTo < 5 ? sizeTo : 5
        },
        centroid: {
          ...hazard.centroid,
          size: centroid
        }
      }
    } else {
      const otherValue =
        values[field === 'distribution' ? 'sensitivity' : 'distribution']

      if (otherValue && otherValue !== '0' && value !== '0') {
        const newCentroidLikelihood =
          hazardLikelihoodMatrix[field === 'distribution' ? value : otherValue][
          field === 'distribution' ? otherValue : value
          ]
        const likelihood = calculateNewLikelihood(newCentroidLikelihood)
        const size = calculateNewSize()

        newHazard = {
          size,
          likelihood,
          centroid: {
            ...JSON.parse(values.hazard).centroid,
            likelihood: newCentroidLikelihood
          }
        }
      }
    }

    if (newHazard) {
      setFieldValue('hazard', JSON.stringify(newHazard))
    }
  }

  return (
    <WorkspaceMain hasSiblingAside className={css.workspace}>
      <form onSubmit={handleSubmit} className={isSubmitting ? css.disabled : ''}>
        <fieldset disabled={isSubmitting}>
          {isEditing && <AutoSave debounceMs={1500} />}
          <div className={css.avalancheProblemForm}>
            <div className={css.problemType}>
              <Label htmlFor="problem" required>
                {t('avalancheProblemType')}
              </Label>
              <InfoTip offset={65}>
                <AvalancheProblemTypeInfoTable />
              </InfoTip>
              <ManagedType type="avalancheproblemtypes">
                {({ data, status }) => {
                  return (
                    <>
                      <Field
                        name="problemTypeId"
                        validate={requiredAndNotBlank}
                        update={update}
                        blur={handleBlur}
                        autoFocus={true}
                        options={data}
                        disableBlankOption={isEditing}
                        component={DropdownWithBlankOption}
                      />
                      {errors.problemTypeId && touched.problemTypeId && (
                        <div>{t(`validation:${errors.problemTypeId}`)}</div>
                      )}
                    </>
                  )
                }}
              </ManagedType>
            </div>
            <div className={css.distribution}>
              <Label htmlFor="distribution" required>
                {t('distribution')}
              </Label>
              <InfoTip offset={70} defaultPos={{ height: 250 }}>
                <AvalancheDistributionInfoTable />
              </InfoTip>
              <DefinedType type="distributions">
                {({ data, status }) => {
                  return (
                    <>
                      <Field
                        name="distribution"
                        validate={requiredAndNotBlank}
                        update={(field, value) => {
                          updateHazard(field, value)
                          update(field, value)
                        }}
                        blur={handleBlur}
                        options={data.map(selectOptionStructure)}
                        disableBlankOption={isEditing}
                        component={DropdownWithBlankOption}
                      />
                      {errors.distribution && touched.distribution && (
                        <div>{t(`validation:${errors.distribution}`)}</div>
                      )}
                    </>
                  )
                }}
              </DefinedType>
            </div>
            <div className={css.sensitivity}>
              <Label htmlFor="sensitivity" required>
                {t('sensitivity')}
              </Label>
              <InfoTip offset={70} defaultPos={{ height: 400 }}>
                <AvalancheSensitivityInfoTable />
              </InfoTip>
              <DefinedType type="sensitivities">
                {({ data, status }) => {
                  return (
                    <>
                      <Field
                        name="sensitivity"
                        validate={requiredAndNotBlank}
                        update={(field, value) => {
                          updateHazard(field, value)
                          update(field, value)
                        }}
                        blur={handleBlur}
                        options={data.map(selectOptionStructure)}
                        disableBlankOption={isEditing}
                        component={DropdownWithBlankOption}
                      />
                      {errors.sensitivity && touched.sensitivity && (
                        <div>{t(`validation:${errors.sensitivity}`)}</div>
                      )}
                    </>
                  )
                }}
              </DefinedType>
            </div>
            <div className={css.weakLayer}>
              <Label htmlFor="weakLayer" required>
                {t('weakLayer')}
              </Label>
              <Field name="weakLayerId" validate={required}>
                {({ field }) => (
                  <Dropdown
                    name={field.name}
                    options={relatedWeakLayers}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              </Field>
              {errors.weakLayerId && touched.weakLayerId && (
                <div>{t(`validation:${errors.weakLayerId}`)}</div>
              )}
            </div>
            <div className={css.typicalSize}>
              <Label htmlFor="typicalSize" required>
                {t('typicalSize')}
              </Label>
              <DefinedType type="typicalsizes">
                {({ data, status }) => {
                  return (
                    <>
                      <Field
                        name="typicalSize"
                        validate={requiredAndNotBlank}
                        update={(field, value) => {
                          updateHazard(field, value)
                          update(field, value)
                        }}
                        blur={handleBlur}
                        options={data.map(selectOptionStructure)}
                        disableBlankOption={isEditing}
                        component={DropdownWithBlankOption}
                      />
                      {errors.typicalSize && touched.typicalSize && (
                        <div>{t(`validation:${errors.typicalSize}`)}</div>
                      )}
                    </>
                  )
                }}
              </DefinedType>
            </div>
            <div className={css.typicalDepth}>
              <Label htmlFor="typicalDepth">{t('typicalDepth')}</Label>
              <div className={css.inline}>
                <div className={css.input}>
                  <Field name="typicalDepth.from" validate={validateFrom}>
                    {({ field, meta }) => (
                      <TextField
                        type="number"
                        step="1"
                        min={0}
                        input={field}
                        meta={meta}
                      />
                    )}
                  </Field>
                  <span className={css.unit}>cm</span>
                </div>
                <span className={css.to}>{t('to')}</span>
                <div className={css.input}>
                  <Field name="typicalDepth.to" validate={validateTo}>
                    {({ field, meta }) => (
                      <TextField
                        type="number"
                        step="1"
                        min={0}
                        input={field}
                        meta={meta}
                      />
                    )}
                  </Field>
                  <span className={css.unit}>cm</span>
                </div>
              </div>
            </div>
            <div className={css.aspectElevation}>
              <Label htmlFor="aspectElevation" required>
                {t('aspectElevation')}
              </Label>
              <Field
                name="aspectElevation"
                validate={validateAspectElevation}
              >
                {({ field }) => (
                  <AspectElevationField field={field} setFieldValue={setFieldValue} isSubmitting={isSubmitting} />
                )}
              </Field>
              {errors.aspectElevation && touched.aspectElevation && (
                <div>{t(`validation:${errors.aspectElevation}`)}</div>
              )}
            </div>
            <div className={css.hazardChart}>
              <Label htmlFor="hazardAssessment" required>
                {t('hazardAssessment')}
              </Label>
              <Field name="hazard">
                {({ field }) => (
                  <div className={css.hazardChartWrapper}>
                    <button
                      type={'button'}
                      className={css.hazardReset}
                      onClick={() =>
                        setFieldValue(field.name, initialHazardValue)
                      }
                    >
                      {t('reset')}
                    </button>
                    <HazardChartField field={field} setFieldValue={setFieldValue} isSubmitting={isSubmitting} />
                    <div className={css.hazardInfo}>
                      <InfoTip
                        label={t('likelihood')}
                        offset={30}
                        defaultPos={{ y: 20, height: 145 }}
                      >
                        <AvalancheLikelihoodInfoTable />
                      </InfoTip>
                      <InfoTip
                        label={t('size')}
                        offset={30}
                        defaultPos={{ y: 20, height: 315 }}
                      >
                        <DestructiveSizeInfoTable />
                      </InfoTip>
                    </div>
                  </div>
                )}
              </Field>
            </div>
            <div className={css.avalancheProblemComment}>
              <Label htmlFor="comment">{t('comments')}</Label>
              <Field name="comment">
                {({ field }) => (
                  <MultiLanguageInputField field={field} t={t} isSubmitting={isSubmitting} setFieldValue={setFieldValue} />
                )}
              </Field>
            </div>
          </div>
        </fieldset>
      </form>
    </WorkspaceMain>
  )
}

AvalancheProblemForm.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  relatedWeakLayers: PropTypes.array.isRequired
}

export default AvalancheProblemForm

function AspectElevationField({ field, setFieldValue, isSubmitting }) {
  const value = useMemo(() => JSON.parse(field.value), [field.value])
  const onChange = useCallback(value => {
    setFieldValue(field.name, JSON.stringify(value))
  }, [field.name, setFieldValue])
  return (
    <AspectElevation
      className={css.avalancheProblemRose}
      parentDOMId={'avalancheSingle'}
      value={value}
      onChange={onChange}
      disabled={isSubmitting}
    />
  )
}

function MultiLanguageInputField({ field, isSubmitting, t, setFieldValue }) {
  const value = useMemo(() => JSON.parse(field.value), [field.value])
  const onChange = useCallback(value => {
    setFieldValue(field.name, JSON.stringify(value))
  }, [field.name, setFieldValue])
  return (
    <MultiLanguageInput
      value={value}
      onChange={onChange}
      placeholder={t('comment')}
      disabled={
        isSubmitting
      }
    />
  )
}

function HazardChartField({ field, setFieldValue, isSubmitting }) {
  const value = useMemo(() => JSON.parse(field.value), [field.value])
  const onChange = useCallback(value => {
    setFieldValue(field.name, JSON.stringify(value))
  }, [field.name, setFieldValue])
  return (
    <HazardChart
      value={value}
      onChange={onChange
      }
      disabled={isSubmitting}
    />
  )
}

function validateAspectElevation(value) {
  return JSON.parse(value).length === 0 ? 'required' : undefined
}