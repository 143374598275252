import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { getUnvalidatedNotes } from 'core/product/components/notes/selectors'
import { toggleCompleted, register } from './store'
import { get } from './selectors'

class ComponentContainer extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    toggleCompleted: PropTypes.func,
    register: PropTypes.func,
    completed: PropTypes.bool,
    hasNotes: PropTypes.bool
  }
  static defaultProps = {
    completed: false
  }
  componentDidMount() {
    this.props.register({
      type: this.props.type
    })
  }
  handleToggleCompleted = () => {
    this.props.toggleCompleted({
      type: this.props.type
    })
  }
  render() {
    return this.props.children({
      completed: this.props.completed,
      hasNotes: this.props.hasNotes,
      toggleCompleted: this.handleToggleCompleted
    })
  }
}

export default connect(
  createSelector(
    get,
    hasNotes,
    (component, hasNotes) => ({
      ...component,
      hasNotes
    })
  ),
  { toggleCompleted, register }
)(ComponentContainer)

// Getters
function hasNotes(state, { type }) {
  return getUnvalidatedNotes(state).includes(type)
}
