import { put, call, takeLatest, all, select } from 'redux-saga/effects'
import subDays from 'date-fns/sub_days'
import { change } from 'redux-form'
import * as api from 'common/api'
import { formatDate } from 'common/helpers/formatDate'
import { PUBLISHED_STATUS, SUMMARY_FORM_NAME } from './config/constants'
import {
  GET_CURRENT_PRODUCT,
  GET_YESTERDAYS_PRODUCT,
  GET_WORKSPACE_CONTENT,
  SET_WORKSPACE_CONTENT,
  PUBLISH_PRODUCT,
  CREATE_NEW_PRODUCT_VERSION,
  SET_LAST_TIME_SAVED
} from './types'
import {
  setCurrentProduct,
  setCurrentProductStatus,
  getYesterdaysProduct,
  getYesterdaysProductFail,
  setYesterdaysProduct,
  setWorkspaceContent,
  fetchProductsFailed
} from './actions'
import { getCurrentProduct, getCurrentProductKey } from './selectors'
import { getUserId } from 'core/users/selectors'

function* onFetchCurrentProduct() {
  yield takeLatest(GET_CURRENT_PRODUCT, fetchCurrentProduct)
}

/*
  GET /reports/:reportKey

  {
    "productType": "forecast",
    "status": "draft",
    "forecaster": "5aa15f5f0fcaa4541b1563ce",
    "location": 1,
    "progress": 5,
    "date": {
      "validFrom": "2018-05-25T17:00:00.000Z",
      "review": "2018-05-25T22:00:00.000Z",
      "edited": "2018-05-25T21:00:00.000Z",
      "validTill": "2018-05-26T17:00:00.000Z"
    },
    "nbReviews": 6,
    "id": "ce17a28d-8d06-4efd-a86d-469fe8c490a6",
    "key": "8a8bbbe2-ef1a-4301-a9b2-50db829ef37a"
  }
*/
function* fetchCurrentProduct({ payload }) {
  let reportKey = payload
  if (!reportKey)
    reportKey = yield select(getCurrentProductKey)

  try {
    const currentProduct = yield call(api.get, `reports/${reportKey}`)
    if (!currentProduct) return

    yield put(setCurrentProduct(currentProduct))

    const {
      location,
      date: { validFrom }
    } = currentProduct
    yield put(getYesterdaysProduct({ location, date: validFrom }))
  } catch (err) {
    console.error(err)
    window.alert(err.message)
    yield put(fetchProductsFailed(err))
  }
}

function* onUpdateProductStatus() {
  yield takeLatest(SET_LAST_TIME_SAVED, updateProductStatus)
}

/*
  GET /reports/:reportKey/status

  "draft"
*/
function* updateProductStatus() {
  const reportKey = yield select(getCurrentProductKey)
  if (!reportKey) return

  try {
    const status = yield call(api.get, `reports/${reportKey}/status`)
    yield put(setCurrentProductStatus(status))
  } catch (err) {
    window.alert(err.message)
    console.error(err)
  }
}

function* onPublishProduct() {
  yield takeLatest(PUBLISH_PRODUCT, publish)
}

function* publish() {
  const currentProduct = yield select(getCurrentProduct)
  yield updateProduct(currentProduct.key)
  yield fetchCurrentProduct(currentProduct.key)
}

/*
  PUT /reports/:reportKey/status

  "published"
*/
function* updateProduct(reportKey) {
  try {
    const report = yield call(
      api.put,
      `reports/${reportKey}/status`,
      PUBLISHED_STATUS
    )
    yield put(setCurrentProductStatus(report.status))
  } catch (err) {
    window.alert(err.message)
    console.error(err)
  }
}

function* onCreateNewProductVersion() {
  yield takeLatest(CREATE_NEW_PRODUCT_VERSION, createNewProductVersion)
}

function* createNewProductVersion({ payload }) {
  const { component } = payload
  const reportKey = yield select(getCurrentProductKey)
  const user = yield select(getUserId)
  try {
    const newProductKey = yield call(
      api.put,
      `reports/${reportKey}/newversion`,
      user
    )
    if (newProductKey) {
      window.location.replace(`/avid/products/edit/${newProductKey}/${component}`)
    }
  } catch (err) {
    window.alert(err.message)
    console.error(err)
  }
}

function* onFetchYesterdaysProduct() {
  yield takeLatest(GET_YESTERDAYS_PRODUCT, fetchYesterdaysProduct)
}

/*
  GET /reports/:reportKey?location=:locationId&date=:yesterdaysDate

  {
    "productType": "forecast",
    "status": "draft",
    "forecaster": "5aa15f5f0fcaa4541b1563ce",
    "location": 1,
    "progress": 5,
    "date": {
      "validFrom": "2018-05-25T17:00:00.000Z",
      "review": "2018-05-25T22:00:00.000Z",
      "edited": "2018-05-25T21:00:00.000Z",
      "validTill": "2018-05-26T17:00:00.000Z"
    },
    "nbReviews": 6,
    "id": "ce17a28d-8d06-4efd-a86d-469fe8c490a6",
    "key": "8a8bbbe2-ef1a-4301-a9b2-50db829ef37a"
  }
*/
function* fetchYesterdaysProduct({ payload }) {
  const { location, date } = payload
  const yesterday = subDays(new Date(date), 1)
  const formattedDate = formatDate(yesterday)
  // TODO: This will likely need to be extended w/ productType param in future
  const endpoint = `/reports?location=${location}&date=${formattedDate}`

  try {
    const yesterdaysProduct = yield call(api.get, endpoint)
    if (yesterdaysProduct) {
      yield put(setYesterdaysProduct(yesterdaysProduct.key))
    } else {
      yield put(getYesterdaysProductFail())
    }
  } catch (err) {
    console.log(err)
    window.alert(err.message)
    yield put(getYesterdaysProductFail())
  }
}

function* onFetchWorkspaceContent() {
  yield takeLatest(GET_WORKSPACE_CONTENT, fetchWorkspaceContentForCarryForward)
}

/*
  TODO: As carry-forward needs to be integrated across different components (namely, Avalanche Problems),
  this approach will need to be made a bit more flexible. This currently assumes integration with
  redux-form; which will likely NOT be the approach for Avalanche Problems (carry entire list vs. carry form.)

  GET /summaries?reportId=:reportId&type=:componentType

  {
    "productType": "forecast",
    "status": "draft",
    "forecaster": "5aa15f5f0fcaa4541b1563ce",
    "location": 1,
    "progress": 5,
    "date": {
      "validFrom": "2018-05-25T17:00:00.000Z",
      "review": "2018-05-25T22:00:00.000Z",
      "edited": "2018-05-25T21:00:00.000Z",
      "validTill": "2018-05-26T17:00:00.000Z"
    },
    "nbReviews": 6,
    "id": 18
  }
*/
function* fetchWorkspaceContentForCarryForward({ payload }) {
  const { component, yesterdaysId, workspace, workspaceRequested } = payload
  const endpoint = `/summaries?type=${component}&reportId=${yesterdaysId}`
  try {
    const content = yield call(api.get, endpoint)
    yield put(
      setWorkspaceContent({
        component,
        workspace,
        content: content[workspaceRequested]
      })
    )
  } catch (err) {
    console.log(err)
    window.alert(err.message)
  }
}

function* onSetWorkspaceContent() {
  yield takeLatest(SET_WORKSPACE_CONTENT, updateWorkspaceContentForCarryForward)
}

function* updateWorkspaceContentForCarryForward({ payload }) {
  const { component, workspace, content } = payload

  try {
    yield put(
      change(
        `${component}Day${workspace}${SUMMARY_FORM_NAME}`,
        'text',
        content.text
      )
    )
  } catch (err) {
    console.log(err)
    window.alert(err.message)
  }
}

export default function* rootSaga() {
  yield all([
    onFetchCurrentProduct(),
    onFetchYesterdaysProduct(),
    onFetchWorkspaceContent(),
    onSetWorkspaceContent(),
    onUpdateProductStatus(),
    onPublishProduct(),
    onCreateNewProductVersion()
  ])
}
