import { createSelector } from 'reselect'

const tagsByLanguageAndComponentSelector = (state, { language, component }) => {
  if (!component || !state.tags.tagsByComponent[component]) return []

  if (!language) language = 'en'

  return state.tags.tagsByComponent[component].map(tag => {
    const [tagForLanguage] = tag.values.filter(value =>
      value.includes(language)
    )
    return {
      id: tag.id,
      display: tagForLanguage[1]
    }
  })
}

// createSelector is for meomization
export const filterTags = createSelector(
  tagsByLanguageAndComponentSelector,
  tags => tags
)
