import { createAction } from 'common/utils'

import {
  FETCH_AVALANCHE_PROBLEMS,
  FAIL_FETCHING_AVALANCHE_PROBLEMS,
  SET_AVALANCHE_PROBLEMS,
  DELETE_AVALANCHE_PROBLEM,
  UPDATE_AVALANCHE_PROBLEMS,
  SUBMIT_AVALANCHE_PROBLEMS
} from './types'

export const fetchAvalancheProblems = payload =>
  createAction(FETCH_AVALANCHE_PROBLEMS, payload)
export const failFetchingAvalancheProblems = payload =>
  createAction(FAIL_FETCHING_AVALANCHE_PROBLEMS, payload)
export const setAvalancheProblems = payload =>
  createAction(SET_AVALANCHE_PROBLEMS, payload)
export const deleteAvalancheProblem = payload =>
  createAction(DELETE_AVALANCHE_PROBLEM, payload)
export const updateAvalancheProblems = payload =>
  createAction(UPDATE_AVALANCHE_PROBLEMS, payload)
export const submitAvalancheProblems = payload =>
  createAction(SUBMIT_AVALANCHE_PROBLEMS, payload)
