import { takeLatest, select, all, call, put } from 'redux-saga/effects'
import { isPristine, getFormValues } from 'redux-form'

import * as api from 'common/api'
import {
  SUBMIT_REVIEW,
  FETCH_REVIEW,
  FETCH_ALL_REVIEWS
} from './types'
import { setReview, setAllReviews } from './actions'
import { getCurrentProductId, getCurrentProductKey } from 'core/product/selectors'
import { WHITEBOARD_FORM_NAME } from 'core/product/config/constants'

function* onFetchReview() {
  yield takeLatest(FETCH_REVIEW, fetchReview)
}

function* fetchReview() {
  const reportId = yield select(getCurrentProductKey)

  try {
    const review = yield call(api.get, `reviews/${reportId}`)
    review.deadline = new Date(review.deadline)
    yield put(setReview(review))
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

function* onFetchAllReviews() {
  yield takeLatest(FETCH_ALL_REVIEWS, fetchAllReviews)
}

function* fetchAllReviews() {
  try {
    const reviews = yield call(api.get, 'reviews')
    if (!reviews) return

    const mappedReviews = reviews.map(review => ({
      ...review,
      deadline: new Date(review.deadline)
    }))
    yield put(setAllReviews(mappedReviews))
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

function* onSubmitReview() {
  yield takeLatest(SUBMIT_REVIEW, submitReview)
}

function* submitReview() {
  const reportId = yield select(getCurrentProductId)
  const clean = yield select(state => isPristine(WHITEBOARD_FORM_NAME)(state))
  if (clean) return

  const values = yield select(state =>
    getFormValues(WHITEBOARD_FORM_NAME)(state)
  )
  if (!values) return

  const review = {
    reportId,
    priority: values.priority,
    deadline: values.deadline
  }

  try {
    yield call(api.put, `reviews/${reportId}`, review)
    yield put(setReview(review))
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

export default function* rootSaga() {
  yield all([
    onSubmitReview(),
    onFetchReview(),
    onFetchAllReviews()
  ])
}
