import {
  createInitialState as initialState,
  reducers
} from 'core/summariesReducer'
import { createReducer } from 'common/utils'
import * as types from './types'

export default createReducer(
  initialState('observation'),
  reducers('observation', types)
)
