import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { carryForwardReport } from 'core/products/actions'

import ContentDuplicateIcon from 'mdi-react/ContentDuplicateIcon'
import css from './style.module.scss'

const CarryForwardButton = ({ onClick }) => (
  <div className={css.carryforward} onClick={onClick}>
    <ContentDuplicateIcon />
  </div>
)

CarryForwardButton.propTypes = {
  reportKey: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick() {
    if (window.confirm(ownProps.t('confirmCarryForward')) === true) {
      dispatch(carryForwardReport({ reportKey: ownProps.reportKey }))
    }
  }
})

export default compose(
  withTranslation('products'),
  connect(
    null,
    mapDispatchToProps
  )
)(CarryForwardButton)
