import React, { Component, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { select, event } from 'd3-selection'
import { axisBottom, axisLeft } from 'd3-axis'
import { scaleLinear } from 'd3-scale'
import { brush } from 'd3-brush'
import { drag } from 'd3-drag'
import isEqual from 'lodash/isEqual'
import { withTranslation } from 'react-i18next'

// https://bl.ocks.org/mbostock/6232537

const SIZES = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]
const TO_VALUES = new Map([
  ['certain', 5],
  ['certain_verylikely', 4.5],
  ['verylikely', 4],
  ['verylikely_likely', 3.5],
  ['likely', 3],
  ['likely_possible', 2.5],
  ['possible', 2],
  ['possible_unlikely', 1.5],
  ['unlikely', 1]
])
const FROM_VALUES = new Map(Array.from(TO_VALUES).map(tuple => tuple.reverse()))
const LIKELIHOOD = Array.from(TO_VALUES.keys())

class HazardChart extends PureComponent {
  static propTypes = {
    value: PropTypes.shape({
      size: PropTypes.shape({
        from: PropTypes.oneOf(SIZES).isRequired,
        to: PropTypes.oneOf(SIZES).isRequired
      }).isRequired,
      likelihood: PropTypes.shape({
        from: PropTypes.oneOf(LIKELIHOOD).isRequired,
        to: PropTypes.oneOf(LIKELIHOOD).isRequired
      }).isRequired,
      centroid: PropTypes.shape({
        size: PropTypes.oneOf(SIZES).isRequired,
        likelihood: PropTypes.oneOf(LIKELIHOOD).isRequired
      }).isRequired
    }),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    t: PropTypes.func.isRequired
  }
  static defaultProps = {
    value: null,
    onChange() { },
    disabled: false
  }
  state = {
    centroid: null,
    brush: null
  }
  constructor(props) {
    super(props)

    if (props.value) {
      const { size, likelihood, centroid } = props.value

      this.state = {
        centroid: this.createCentroid(centroid),
        brush: this.createBrush(size, likelihood)
      }
    }
  }
  get value() {
    const { brush, centroid } = this.state
    const [c0, c1, c] = [...brush, centroid].map(invert, this)

    return {
      size: {
        from: c0[0],
        to: c1[0]
      },
      likelihood: {
        from: FROM_VALUES.get(c1[1]),
        to: FROM_VALUES.get(c0[1])
      },
      centroid: {
        size: c[0],
        likelihood: FROM_VALUES.get(c[1])
      }
    }
  }
  createCentroid({ size, likelihood }) {
    return convert([size, TO_VALUES.get(likelihood)])
  }
  createBrush(size, likelihood) {
    return [
      [size.from, TO_VALUES.get(likelihood.to)],
      [size.to, TO_VALUES.get(likelihood.from)]
    ].map(convert, this)
  }
  componentDidUpdate(previous) {
    if (isEqual(previous.value, this.props.value)) {
      return
    }

    const { value } = this.props

    this.setState(() => {
      if (!value) {
        return {
          centroid: null,
          brush: null
        }
      }

      const { size, likelihood } = value
      const centroid = this.createCentroid(value.centroid)
      const brush = this.createBrush(size, likelihood)

      if (
        isEqual(centroid, this.state.centroid) &&
        isEqual(brush, this.state.brush)
      ) {
        return null
      }

      return {
        centroid,
        brush
      }
    })
  }
  drawXAxis = g => {
    select(g)
      .call(g =>
        g
          .attr('transform', `translate(0,${HEIGHT - MARGIN.bottom})`)
          .call(axisBottom(X).ticks(5))
      )
      .call(g =>
        g
          .selectAll('.tick line')
          .clone()
          .attr('y2', -HEIGHT + MARGIN.top + MARGIN.bottom)
          .attr('stroke-opacity', 0.1)
          .clone()
          .attr('transform', `translate(${(X(2) - X(1)) / 2},0)`)
          .attr('stroke-opacity', 0.05)
      )
  }
  drawYAxis = g => {
    const { t } = this.props

    select(g)
      .call(g =>
        g.attr('transform', `translate(${MARGIN.left},0)`).call(
          axisLeft(Y)
            .ticks(5)
            .tickFormat(index => t(`likelihood:${LIKELIHOOD_TEXTS[index - 1]}`))
        )
      )
      .call(g =>
        g
          .selectAll('.tick line')
          .clone()
          .attr('x2', WIDTH - MARGIN.left - MARGIN.right)
          .attr('stroke-opacity', 0.1)
          .clone()
          .attr('transform', `translate(0,${(Y(2) - Y(1)) / 2})`)
          .attr('stroke-opacity', 0.05)
      )
  }
  handleCentroidDrag = ([x, y]) => {
    const [[x0, y0], [x1, y1]] = this.state.brush

    this.setState({
      centroid: [Math.min(x1, Math.max(x0, x)), Math.min(y1, Math.max(y0, y))]
    })
  }
  handleCentroidEndDrag = ([x, y]) => {
    const [[x0, y0], [x1, y1]] = this.state.brush
    const centroid = [
      Math.min(x1, Math.max(x0, x)),
      Math.min(y1, Math.max(y0, y))
    ]

    this.setState({ centroid: snap(centroid) }, this.sendChange)
  }
  handleBrushStart = ([[x0, y0], [x1, y1]]) => {
    const [x, y] = this.state.centroid || []
    const dx = x1 - x0
    const dy = y1 - y0

    this.rx = dx === 0 || typeof x !== 'number' ? 0.5 : (x - x0) / dx
    this.ry = dy === 0 || typeof y !== 'number' ? 0.5 : (y - y0) / dy
  }
  handleBrush = ([[x0, y0], [x1, y1]]) => {
    // Move centroid only if the whole rectangle is moving
    if (
      this.state.brush[0][0] !== x0 &&
      this.state.brush[0][1] !== y0 &&
      this.state.brush[1][0] !== x1 &&
      this.state.brush[1][1] !== y1
    ) {
      const dx = x1 - x0
      const dy = y1 - y0

      this.setState({
        centroid: [x0 + this.rx * dx, y0 + this.ry * dy]
      })
    }
  }
  handleBrushEnd = selection => {
    const snapped = selection.map(snap, this).map(invert, this)

    // x's are equals, not good!
    if (snapped[0][0] === snapped[1][0]) {
      if (snapped[1][0] === 5) {
        snapped[0][0] = 4.5
      } else {
        snapped[1][0] = snapped[0][0] + 0.5
      }
    }

    // y's are equals, not good!
    if (snapped[0][1] === snapped[1][1]) {
      if (snapped[0][1] === 5) {
        snapped[1][1] = 4.5
      } else {
        snapped[0][1] = snapped[1][1] + 0.5
      }
    }

    this.setState({
      brush: snapped.map(convert, this)
    })
  }
  handleRectangleMoveEnd = () => {
    this.setState(
      ({ centroid }) => ({ centroid: snap(centroid) }),
      this.sendChange
    )
  }
  sendChange = () => {
    this.props.onChange(this.value)
  }
  render() {
    const { t } = this.props

    return (
      <svg viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
        <g ref={this.drawXAxis} />
        <g ref={this.drawYAxis} />

        {this.props.disabled ? (
          <rect
            stroke="none"
            fill="rgb(119, 119, 119)"
            fillOpacity="0.3"
            cursor="auto"
            width={this.state.brush[1][0] - this.state.brush[0][0]}
            height={this.state.brush[1][1] - this.state.brush[0][1]}
            x={this.state.brush[0][0]}
            y={this.state.brush[0][1]}
          />
        ) : (
            <Rectangle
              value={this.state.brush}
              onBrushStart={this.handleBrushStart}
              onBrush={this.handleBrush}
              onBrushEnd={this.handleBrushEnd}
              onMoveEnd={this.handleRectangleMoveEnd}
            />
          )}

        {this.props.disabled ? (
          <Centroid value={this.state.centroid} cursor="auto" />
        ) : (
            <Centroid
              value={this.state.centroid}
              onDrag={this.handleCentroidDrag}
              onDragEnd={this.handleCentroidEndDrag}
              cursor="move"
            />
          )}

        <g fontSize="10" textAnchor="middle">
          <text
            x={(WIDTH - MARGIN.left - MARGIN.right) / 2 + MARGIN.left}
            y={HEIGHT - TEXT_OFFSET}
          >
            {t('hazardSizeLabel')}
          </text>
          <text
            x={TEXT_OFFSET}
            y={(HEIGHT - MARGIN.top - MARGIN.bottom) / 2 + MARGIN.top}
            transform={`rotate(90 5 ${HEIGHT / 2})`}
          >
            {t('hazardLikelihoodLabel')}
          </text>
        </g>
      </svg>
    )
  }
}

class Centroid extends Component {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.number),
    onDrag: PropTypes.func,
    onDragEnd: PropTypes.func
  }

  static defaultProps = {
    onDrag: () => { },
    onDragEnd: () => { }
  }

  handleDrag = () => {
    const { x, y } = event

    this.props.onDrag([x, y])
  }
  handleEndDrag = () => {
    const { x, y } = event

    this.props.onDragEnd([x, y])
  }
  setup = g => {
    this.centroid = select(g)

    this.centroid.call(
      drag()
        .on('drag', this.handleDrag)
        .on('end', this.handleEndDrag)
    )
  }
  shouldComponentUpdate({ value }) {
    return !isEqual(value, this.props.value)
  }
  render() {
    const { value } = this.props
    return (
      <g ref={this.setup} transform="translate(-4,-4)">
        {value && (
          <rect
            stroke="blue"
            fill="transparent"
            width="8"
            height="8"
            x={value[0]}
            y={value[1]}
          />
        )}
      </g>
    )
  }
}

class Rectangle extends Component {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    onBrushStart: PropTypes.func.isRequired,
    onBrush: PropTypes.func.isRequired,
    onBrushEnd: PropTypes.func.isRequired,
    onMoveEnd: PropTypes.func.isRequired
  }
  handleBrushStart = () => {
    if (!event.sourceEvent || !event.selection) {
      return
    }

    this.props.onBrushStart(event.selection)
  }
  handleBrush = () => {
    if (!event.sourceEvent || !event.selection) {
      return
    }

    this.props.onBrush(event.selection)
  }
  handleBrushEnd = () => {
    if (!event.sourceEvent || !event.selection) {
      return
    }

    this.props.onBrushEnd(event.selection)
  }
  draw = g => {
    this.brush = brush().extent(EXTENT)
    this.group = select(g).call(this.brush)

    const { value } = this.props

    if (value) {
      this.brush.move(this.group, value)
    }

    this.brush
      .on('start', this.handleBrushStart)
      .on('brush', this.handleBrush)
      .on('end', this.handleBrushEnd)
  }
  shouldComponentUpdate({ value }) {
    return value !== this.props.value
  }
  componentDidUpdate() {
    const { value } = this.props
    let display = 'none'

    if (Array.isArray(value)) {
      display = null
      this.group
        .transition()
        .call(this.brush.move, value)
        .on('end', this.props.onMoveEnd)
    }

    this.group.select(':not(.overlay)').style('display', display)
  }
  render() {
    return <g ref={this.draw} />
  }
}

export default withTranslation(['avalancheProblems', 'likelihood'])(HazardChart)

// Constants
const TEXT_OFFSET = 5
const WIDTH = 375
const HEIGHT = 300
const MARGIN = {
  top: 25,
  right: 25,
  bottom: 35,
  left: 100
}
const LIKELIHOOD_TEXTS = [
  'unlikely',
  'possible',
  'likely',
  'verylikely',
  'almostcertain'
]
const EXTENT = [
  [MARGIN.left, MARGIN.top],
  [WIDTH - MARGIN.right, HEIGHT - MARGIN.bottom]
]
const X = scaleLinear()
  .domain([1, 5])
  .range([MARGIN.left, WIDTH - MARGIN.right])
const Y = scaleLinear()
  .domain([5, 1])
  .range([MARGIN.top, HEIGHT - MARGIN.bottom])

// Utils
function snap([x, y]) {
  return [
    X(Math.round(X.invert(x) * 2) / 2),
    Y(Math.round(Y.invert(y) * 2) / 2)
  ]
}
function convert([x, y]) {
  return [X(x), Y(y)]
}
function invert([x, y]) {
  return [X.invert(x), Y.invert(y)]
}
