import { createAction } from 'common/utils'

import {
  SET_USER,
  SET_USERS,
  GET_USER,
  SUBMIT_EDIT_USER,
  UPDATE_USER,
  UPDATE_CURRENT_USER,
  LOGOUT_USER,
  FETCH_USERS
} from './types'

// TODO: Currently no action creators for success / failure w/ user getting
export const setUser = payload => createAction(SET_USER, payload)

// TODO: Currently no action creators for success / failure w/ users getting
export const setUsers = payload => createAction(SET_USERS, payload)

export const fetchUser = payload => createAction(GET_USER, payload)

// TODO: Currently no action creators for success / failure w/ user updating
export const submitEditUser = payload => createAction(SUBMIT_EDIT_USER, payload)
export const updateUser = payload => createAction(UPDATE_USER, payload)
export const updateCurrentUser = payload =>
  createAction(UPDATE_CURRENT_USER, payload)

export const logoutUser = () => createAction(LOGOUT_USER)

export const fetchUsers = () => createAction(FETCH_USERS)
