import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ProductsDashboard from './list'
import Product from 'core/product'

const Products = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={ProductsDashboard} />
    <Route path={`${match.path}/edit/:id`} component={Product} />
  </Switch>
)

export default Products
