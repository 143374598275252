import React from 'react'
import PropTypes from 'prop-types'

import { DropdownCell } from 'common/components/table/cells'

const DropdownColumnCell = (
  options,
  onDropdownChange,
  columnAccessor
) => rowData => {
  return (
    <DropdownCell
      rowData={rowData}
      columnAccessor={columnAccessor}
      options={options}
      onDropdownChange={onDropdownChange}
    />
  )
}

DropdownColumnCell.propTypes = {
  options: PropTypes.array.isRequired,
  onDropdownChange: PropTypes.func.isRequired,
  columnAccessor: PropTypes.string.isRequired
}

export default DropdownColumnCell
