import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { AvalancheProblemsList, AvalancheProblemsSingle } from './components'

const AvalancheProblems = ({ match, workspaces, defaultDays }) => (
  <Switch>
    <Route
      exact
      path={match.path}
      render={props =>
        React.createElement(AvalancheProblemsList, {
          ...props,
          workspaces,
          defaultDays
        })
      }
    />
    <Route
      path={`/avid/products/edit/:id/workspaces/:workspace/avalancheProblems/:index`}
      component={AvalancheProblemsSingle}
    />
  </Switch>
)

export default AvalancheProblems
