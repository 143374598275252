import React from 'react'
import PropTypes from 'prop-types'
import CloseBoxIcon from 'mdi-react/CloseBoxIcon'

import css from './style.module.scss'

const ResetFilterSort = ({ shouldShow, handleOnClick }) => (
  <div className={css.iconContainer} onClick={handleOnClick}>
    {shouldShow && <CloseBoxIcon />}
  </div>
)

ResetFilterSort.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  shouldShow: PropTypes.bool.isRequired
}

export default ResetFilterSort
