import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import {
  Nav,
  SidebarPageContainer,
  SidebarContainer as Sidebar,
  LastTimeSavedHeader
} from 'common/components'

import { fetchUser, submitEditUser } from './actions'

import {
  AccountManagement,
  Logout,
  CreateAccount,
  UserForm
} from './components'

import css from './style.module.scss'

class User extends React.Component {
  static propTypes = {
    fetchUser: PropTypes.func.isRequired,
    submitUser: PropTypes.func.isRequired,
    id: PropTypes.string
  }

  componentDidMount() {
    document.title = `AvID - ${this.props.t('profile')}`
    this.props.fetchUser()
  }
  componentDidUpdate() {
    document.title = `AvID - ${this.props.t('profile')}`
  }
  componentWillUnmount() {
    this.props.submitUser()
  }

  render() {
    const { id } = this.props
    return (
      <div>
        {id && <Nav />}
        <main>
          <LastTimeSavedHeader />
          <SidebarPageContainer sidebarAlignment="right">
            <Sidebar className={css.userFormContainer}>
              <UserForm onSubmit={this.props.submitUser} />
              <div>
                <AccountManagement id={id} />
                {!id && <CreateAccount />}
                <Logout />
              </div>
            </Sidebar>
          </SidebarPageContainer>
        </main>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  id: state.users.currentUser.id
})

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUser()),
  submitUser: () => dispatch(submitEditUser())
})

export default compose(
  withTranslation('common'),
  connect(mapStateToProps, mapDispatchToProps)
)(User)
