import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../../auth'

import { Link } from 'common/components'

import css from './style.module.scss'

const ADMIN_URL =
  'https://manage.auth0.com/dashboard/us/avid-avalanchecanada/users'

const AccountManagement = ({ id }) => {
  const { t } = useTranslation('user')
  const { logout } = useAuth()

  return (
    <div className={css.container}>
      {id && (
        <div className={css.updatePassword}>
          <p className={css.linkSpoof} onClick={logout}>
            {t('user:updatePassword')}
          </p>
          <p className={css.instructions}>{t('user:resetInstructions')}</p>
        </div>
      )}
      <Link to={ADMIN_URL}>{t('user:admin')}</Link>
      <p className={css.instructions}>{t('user:adminInstructions')}</p>
    </div>
  )
}

export default AccountManagement
