import React, { Component } from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames/bind'
import css from './style.module.scss'
const cx = classNames.bind(css)

/*
 * A selectable option: to be used as a child of <OptionSet />
 *
 * value prop is optional (if not specified, the <OptionSet /> with map the
 * option's index to it's value prop)
 *
 */
class Option extends Component {
  static propTypes = {
    children: PropTypes.node,
    selected: PropTypes.bool,
    selectedClassName: PropTypes.string,
    onClick: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }

  handleClick = () => {
    const { value, onClick } = this.props
    onClick(value)
  }

  render() {
    const { selectedClassName, selected, children } = this.props

    return (
      <button
        type="button"
        className={cx('button', {
          selected: !selectedClassName && selected,
          [selectedClassName]: selected,
          unselected: !selected
        })}
        onClick={this.handleClick}
      >
        {children}
      </button>
    )
  }
}

export default Option
