import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Immutable from 'seamless-immutable'
import { moveItem } from 'common/helpers/orderedList'

import { WorkspaceAside } from 'common/components'
import { QueryStatements, StatementBuilder } from 'core/statements'

import {
  updateTerrainAndTravelAdvice,
  deleteTerrainAndTravelAdvice
} from 'common/data/terrainAndTravelAdvice/actions'
import { filterStatements } from 'common/data/statements/selectors'
import { filterTags } from 'common/data/tags/selectors'
import { getTerrainAndTravelAdviceForWorkspace } from 'common/data/terrainAndTravelAdvice/selectors'
import { getUserLang } from 'core/users/selectors'

import css from './style.module.scss'

const STATEMENT_LIMIT = 8

class TerrainAndTravelAdvice extends Component {
  static propTypes = {
    statements: QueryStatements.propTypes.statements,
    selected: QueryStatements.propTypes.statements,
    onSelectStatement: PropTypes.func.isRequired
  }

  static defaultProps = {
    statements: [],
    selected: []
  }

  handleDelete = statement => {
    const { t, confirmDelete } = this.props

    if (window.confirm(t('confirmDelete')) === true) confirmDelete(statement)
  }

  handleMove = (statement, dir) => {
    const { onMoveStatement, selected } = this.props

    onMoveStatement(selected, statement, dir)
  }

  handleSelect = statement => {
    const { t, onSelectStatement, selected } = this.props

    if (selected.length < STATEMENT_LIMIT) {
      onSelectStatement(selected, statement)
    } else {
      window.alert(t('maximumReachedTaT'))
    }
  }

  render() {
    const { t, statements, selected } = this.props

    return (
      <WorkspaceAside className={css.terrainAndTravelAside}>
        <p className={css.terrainAndTravel}>{t('terrainAndTravel')}</p>
        <StatementBuilder
          limit={STATEMENT_LIMIT}
          orderedStatements={selected}
          onMove={this.handleMove}
          onDelete={this.handleDelete}
          queryStatements={statements}
          onSelect={this.handleSelect}
        />
      </WorkspaceAside>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const language = getUserLang(state)

  const statements = filterStatements(state, {
    language,
    component: 'terrainandtraveladvice'
  })

  const tags = filterTags(state, {
    language,
    component: 'terrainandtraveladvice'
  })

  const statementsWithTags = statements.map(statement => {
    return Object.assign({}, statement, {
      tags: tags.length
        ? statement.tags.map(id => tags.find(r => r.id === id))
        : []
    })
  })

  const mutableTerrainAndTravelAdvice = Immutable.asMutable(
    getTerrainAndTravelAdviceForWorkspace(state, ownProps.workspace),
    { deep: true }
  )

  const terrainAndTravelAdviceStatements =
    statementsWithTags.length > 0
      ? mutableTerrainAndTravelAdvice.map(terrainAndTravel => ({
          ...statementsWithTags.find(
            statement => statement.id === terrainAndTravel.statementId
          ),
          index: terrainAndTravel.index
        }))
      : []

  return {
    statements: Immutable.asMutable(statementsWithTags, { deep: true }),
    selected: Immutable.asMutable(terrainAndTravelAdviceStatements, {
      deep: true
    })
  }
}

const mapDispatchToProps = (dispatch, { workspace }) => ({
  onSelectStatement: (selected, statement) => {
    if (!selected.find(selected => selected.id === statement.id)) {
      statement.index = selected.length
      selected.push(statement)
    }

    dispatch(
      updateTerrainAndTravelAdvice({
        workspace,
        statements: selected
      })
    )
  },
  confirmDelete: statement =>
    dispatch(
      deleteTerrainAndTravelAdvice({
        workspace,
        index: statement.index
      })
    ),
  onMoveStatement: (selected, statement, dir) => {
    const updatedTerrainAndTravelAdvice = moveItem(
      selected,
      statement.index,
      dir
    )

    dispatch(
      updateTerrainAndTravelAdvice({
        workspace,
        statements: updatedTerrainAndTravelAdvice
      })
    )
  }
})

TerrainAndTravelAdvice = connect(
  mapStateToProps,
  mapDispatchToProps
)(TerrainAndTravelAdvice)
TerrainAndTravelAdvice = withTranslation('statements')(TerrainAndTravelAdvice)

export default TerrainAndTravelAdvice
