import Immutable from 'seamless-immutable'
import { convertToRaw, ContentState } from 'draft-js'

import { createReducer } from 'common/utils'
import * as types from './types'

export const initialFactors = [
  ['rainfall', 'undefined'],
  ['snowfall', 'undefined'],
  ['recentactivity', 'undefined'],
  ['warming', 'undefined'],
  ['signs', 'undefined'],
  ['windloading', 'undefined'],
  ['persistentproblem', 'undefined']
]

export const factorsReducer = (acc, [factor, value]) => {
  acc[factor] = value
  return acc
}

const WORKSPACES = [1, 2, 3, 4]

const INITIAL_STATE = Immutable({
  isFetching: false,
  criticalFactorsByDays: WORKSPACES.reduce((acc, day, index) => {
    acc[index + 1] = {
      dangerRating: 'norating',
      factors: initialFactors.reduce(factorsReducer, {}),
      comments: []
    }
    return acc
  }, {})
})

const reducers = {
  [types.FETCH_CRITICAL_FACTORS]: fetchCriticalFactors,
  [types.FAIL_FETCHING_CRITICAL_FACTORS]: failFetchingCriticalFactors,
  [types.SET_CRITICAL_FACTORS]: setCriticalFactors,
  [types.UPDATE_CRITICAL_FACTORS]: updateCriticalFactors
}

function fetchCriticalFactors(state) {
  return Immutable.set(state, 'isFetching', true)
}

function failFetchingCriticalFactors(state) {
  return Immutable.set(state, 'isFetching', false)
}

function setCriticalFactors(state, payload) {
  return Immutable.merge(state, {
    isFetching: false,
    criticalFactorsByDays: payload.reduce((acc, criticalfactor, index) => {
      acc[criticalfactor.workspace] = {
        dangerRating: criticalfactor.dangerRating,
        factors: criticalfactor.factors.reduce(factorsReducer, {}),
        /*  The comments textboxes should be initialized with an empty DraftJS
         *  ContentState object. This ensures accurate object comparisons when
         *  checking if the text has changed value.
         */
        comments: criticalfactor.comments.map(lang => lang[1]
          ? lang
          : [lang[0], convertToRaw(ContentState.createFromText(''))]
        )
      }
      return acc
    }, {})
  })
}

function updateCriticalFactors(state, criticalFactors) {
  return Immutable.setIn(
    state,
    ['criticalFactorsByDays', `${criticalFactors.workspace}`],
    {
      dangerRating: criticalFactors.dangerRating,
      factors: criticalFactors.factors,
      comments: criticalFactors.comments
    }
  )
}

export default createReducer(INITIAL_STATE, reducers)
