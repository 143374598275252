import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { read, get, DEFAULT } from './store'

class Container extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['graintypes', 'tags', 'avalancheproblemtypes'])
      .isRequired,
    children: PropTypes.func.isRequired
  }
  get params() {
    return {
      type: this.props.type
    }
  }
  componentDidMount() {
    this.props.read(this.params)
  }
  render() {
    return this.props.children(this.props.data)
  }
}

export default connect(
  createStructuredSelector({
    data(state, { type }) {
      return get(state, type, DEFAULT)
    }
  }),
  { read }
)(Container)
