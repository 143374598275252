import { createSelector } from 'reselect'

export const reviewMessagingSelector = state => state.reviewMessaging
export const getReview = createSelector(
  reviewMessagingSelector,
  state => state.review
)

export const getAllReviews = createSelector(
  reviewMessagingSelector,
  state =>
    state.allReviews.reduce(
      (reviews, review) => ({
        ...reviews,
        [review.reportId]: review
      }),
      {}
    )
)
