import React from 'react'
import PropTypes from 'prop-types'
import { classList } from 'common/utils/'
import Styles from './style.module.scss'

const GridItem = props => {
  const { col } = props
  const columns = col.split('/')
  const percent = (columns[0] / columns[1]) * 100

  const classes = classList(Styles.gridItem, props.className || '')

  return (
    <div className={classes} style={{ width: `${percent}%`, ...props.style }}>
      {props.children}
    </div>
  )
}

GridItem.propTypes = {
  /**
   * A string with the calculation for how wide the column should be:
   * ie: 1/4 will be 25%, 4/8 will make a 50% column
   */
  col: PropTypes.string.isRequired,
  /**
   * If this is set, the width will be 66% on tablet (521px - 768px)
   */
  tablet2of3: PropTypes.bool,
  /**
   * If this is set, the width will be 100% on mobile (520px or less)
   */
  mobile1of1: PropTypes.bool,
  /**
   * If this is set, the width will be 50% on mobile (520px or less)
   */
  mobile1of2: PropTypes.bool,
  /**
   * A style object that inlines the style to the containing element
   */
  style: PropTypes.object
}

export default GridItem
