import { createSelector } from 'reselect'
import { get } from 'lodash'

import { getLocations } from 'core/locations/selectors'
import { sortInAlphabeticalOrder } from 'common/helpers/sort'

export const productsSelector = state => state.products
const productSelector = (state, props) => {
  const productId = get(props, 'match.params.id')

  if (productId) {
    return state.products.productsByKey[productId]
  }

  return null
}

const productsByKeySelector = createSelector(
  productsSelector,
  products => get(products, 'productsByKey')
)

const forecastersSelector = createSelector(
  productsSelector,
  products => {
    const forecasters = get(products, 'productForecasters')
    if (!forecasters) return []

    return forecasters.filter(
      forecaster => forecaster.name.length > 0
    )
  }
)

export const getProductsArray = createSelector(
  productsByKeySelector,
  products => Object.values(products)
)

export const getLocationNames = createSelector(
  getLocations,
  locations =>
    Object.values(locations).reduce((locationsById, location) => {
      locationsById[location.feature.id] = location.feature.properties.name
      return locationsById
    }, {})
)

const getActiveLocations = createSelector(
  getLocations,
  locations =>
    Object.values(locations).filter(
      ({ feature }) => feature.properties.status !== 'deleted'
    )
)

const getLocationsOptions = createSelector(
  getActiveLocations,
  locations =>
    Object.values(locations)
      .map(({ feature }) => ({
        label: feature.properties.name,
        value: `${feature.id}`
      }))
      .sort(sortInAlphabeticalOrder)
)

const getForecasterOptions = createSelector(
  forecastersSelector,
  forecasters =>
    Object.values(forecasters)
      .map(({ id, name }) => ({
        label: name,
        value: id
      }))
      .sort(sortInAlphabeticalOrder)
)

export const getDropdownsOptions = createSelector(
  [getLocationsOptions, getForecasterOptions],
  (locationsOptions, forecasterOptions) => ({
    locationsOptions,
    forecasterOptions
  })
)

export const getProductName = createSelector(
  productSelector,
  product => get(product, 'id')
)
