export const FETCH_LOCATIONS = 'FETCH_LOCATIONS'
export const FETCH_LOCATIONS_FAILED = 'FETCH_LOCATIONS_FAILED'
export const SET_LOCATIONS = 'SET_LOCATIONS'
export const SUBMIT_NEW_LOCATION = 'SUBMIT_NEW_LOCATION'
export const ADD_NEW_LOCATION = 'ADD_NEW_LOCATION'
export const SUBMIT_EDIT_LOCATION = 'SUBMIT_EDIT_LOCATION'
export const EDIT_LOCATION = 'EDIT_LOCATION'
export const DELETE_LOCATION = 'DELETE_LOCATION'
export const SUBMIT_WEATHER_STATION = 'SUBMIT_WEATHER_STATION'
export const ADD_WEATHER_STATION = 'ADD_WEATHER_STATION'
export const UPDATE_WEATHER_STATION = 'UPDATE_WEATHER_STATION'
export const DELETE_WEATHER_STATION = 'DELETE_WEATHER_STATION'
export const SET_LOCATION_GEOJSON = 'SET_LOCATION_GEOJSON'
export const SET_CURRENT_GEOJSON = 'SET_CURRENT_GEOJSON'
export const DELETE_CURRENT_GEOJSON = 'DELETE_CURRENT_GEOJSON'
