import React from 'react'
import PropTypes from 'prop-types'

import css from './style.module.scss'

const Footer = ({ children }) => <div className={css.footer}>{children}</div>

Footer.propTypes = {
  children: PropTypes.node.isRequired
}

export default Footer
