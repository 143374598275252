import { createReducer } from 'common/utils'
import * as types from './types'

import Immutable from 'seamless-immutable'
import { deleteItem } from 'common/helpers/orderedList'

const FULFILLED = 'FULFILLED'
const REJECTED = 'REJECTED'
const PENDING = 'PENDING'

const INITIAL_STATE = Immutable({
  status: null,
  terrainAndTravelAdviceByDays: {
    1: [],
    2: [],
    3: [],
    4: []
  }
})

const reducers = {
  [types.FETCH_TERRAIN_AND_TRAVEL_ADVICE]: fetchTerrainAndTravelAdvice,
  [types.FAIL_FETCHING_TERRAIN_AND_TRAVEL_ADVICE]: failFetchingTerrainAndTravelAdvice,
  [types.SET_TERRAIN_AND_TRAVEL_ADVICE]: setTerrainAndTravelAdvice,
  [types.UPDATE_TERRAIN_AND_TRAVEL_ADVICE]: updateTerrainAndTravelAdvice,
  [types.DELETE_TERRAIN_AND_TRAVEL_ADVICE]: deleteTerrainAndTravelAdvice
}

function fetchTerrainAndTravelAdvice(state) {
  return Immutable.set(state, 'status', PENDING)
}

function failFetchingTerrainAndTravelAdvice(state) {
  return Immutable.set(state, 'status', REJECTED)
}

function setTerrainAndTravelAdvice(state, payload) {
  return Immutable.merge(state, {
    status: FULFILLED,
    terrainAndTravelAdviceByDays: payload.reduce((acc, advice) => {
      acc[advice.workspace] = advice.statements
      return acc
    }, {})
  }, { deep: true })
}

function updateTerrainAndTravelAdvice(state, { workspace, statements }) {
  return Immutable.setIn(
    state,
    ['terrainAndTravelAdviceByDays', `${workspace}`],
    statements.map(statement => ({
      statementId: statement.id,
      index: statement.index
    }))
  )
}

function deleteTerrainAndTravelAdvice(state, { workspace, index }) {
  const updatedTerrainAndTravelAdvice = deleteItem(
    state.terrainAndTravelAdviceByDays[workspace],
    index
  )

  return Immutable.setIn(
    state,
    ['terrainAndTravelAdviceByDays', `${workspace}`],
    updatedTerrainAndTravelAdvice
  )
}

export default createReducer(INITIAL_STATE, reducers)
