// TODO: Move that to another folder. So wrong location

import { createAction, handleActions } from 'redux-actions'
import * as immutable from 'dot-prop-immutable'

// Types
const PREFIX = 'avid/component'
export const REGISTER = PREFIX + '/REGISTER'
export const TOGGLE_COMPLETED = PREFIX + '/completed/TOGGLE'

// Reducer
export default handleActions(
  {
    [REGISTER](state, action) {
      const { type } = action.payload

      return immutable.set(state, `${type}.completed`, false)
    },
    [TOGGLE_COMPLETED](state, action) {
      const { type } = action.payload

      return immutable.toggle(state, `${type}.completed`)
    }
  },
  {}
)

// Actions
export const register = createAction(REGISTER)
export const toggleCompleted = createAction(TOGGLE_COMPLETED)
