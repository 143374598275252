import { createAction } from 'common/utils'

import {
  FETCH_WHITEBOARD,
  FAIL_FETCHING_WHITEBOARD,
  SET_WHITEBOARD,
  SUBMIT_WHITEBOARD
} from './types'

export const fetchWhiteboard = payload =>
  createAction(FETCH_WHITEBOARD, payload)
export const failFetchingWhiteboard = payload =>
  createAction(FAIL_FETCHING_WHITEBOARD, payload)
export const setWhiteboard = payload => createAction(SET_WHITEBOARD, payload)
export const submitWhiteboard = payload =>
  createAction(SUBMIT_WHITEBOARD, payload)
