import { all, call, put, takeLatest, select } from 'redux-saga/effects'

import * as api from 'common/api'

import { setConfidence } from './actions'
import { getCurrentProductKey } from 'core/product/selectors'

import {
  FETCH_CONFIDENCE,
  SUBMIT_CONFIDENCE,
  SUBMIT_ALL_CONFIDENCE_WORKSPACES
} from './types'

function* onFetchConfidence() {
  yield takeLatest(FETCH_CONFIDENCE, fetchConfidence)
}

function* fetchConfidence() {
  const reportKey = yield select(getCurrentProductKey)
  if (!reportKey) return

  try {
    const confidence = yield call(api.get, `/confidence/${reportKey}`)
    yield put(setConfidence(confidence))
  } catch (err) {
    console.error(err)
    //window.alert(err.message)
  }
}

function* onSubmitConfidence() {
  yield takeLatest(SUBMIT_CONFIDENCE, submitConfidence)
}

function* submitConfidence({ payload }) {
  const { workspace } = payload
  const reportKey = yield select(getCurrentProductKey)
  if (!reportKey || !workspace) return

  try {
    yield call(api.put, `/confidence/${reportKey}/${workspace}`, payload)
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

function* onSubmitAllConfidenceWorkspaces() {
  yield takeLatest(
    SUBMIT_ALL_CONFIDENCE_WORKSPACES,
    submitAllConfidenceWorkspaces
  )
}

function* submitAllConfidenceWorkspaces({ payload }) {
  const confidenceByDays = yield select(
    state => state.confidence.confidenceByDays
  )

  yield submitConfidence(payload, 1, confidenceByDays[1])
  yield submitConfidence(payload, 2, confidenceByDays[2])
  yield submitConfidence(payload, 3, confidenceByDays[3])
  yield submitConfidence(payload, 4, confidenceByDays[4])
}

export default function* rootSaga() {
  yield all([
    onFetchConfidence(),
    onSubmitConfidence(),
    onSubmitAllConfidenceWorkspaces()
  ])
}
