import {
  OFFSEASON_WEATHER,
  OFFSEASON_MESSAGE,
  COMMUNICATIONS,
  REVIEW_MESSAGING,
  PUBLISH
} from './components'

export const OFFSEASON_COMPONENTS = [
  OFFSEASON_WEATHER,
  OFFSEASON_MESSAGE,
  COMMUNICATIONS,
  REVIEW_MESSAGING,
  PUBLISH
]

export const OFFSEASON_NAVIGATION = OFFSEASON_COMPONENTS.map(
  component => component.route
)
