import { createAction } from 'common/utils'

import {
  FETCH_ADVISORIES,
  FAIL_FETCH_ADVISORIES,
  SET_ADVISORIES,
  DELETE_ADVISORY,
  UPDATE_ADVISORIES
} from './types'

export const fetchAdvisories = payload =>
  createAction(FETCH_ADVISORIES, payload)
export const failFetchAdvisories = payload =>
  createAction(FAIL_FETCH_ADVISORIES, payload)
export const setAdvisories = payload => createAction(SET_ADVISORIES, payload)
export const updateAdvisories = payload =>
  createAction(UPDATE_ADVISORIES, payload)
export const deleteAdvisory = payload => createAction(DELETE_ADVISORY, payload)
