import Immutable from 'seamless-immutable'

import { createReducer } from 'common/utils'
import * as types from './types'

const VISIBLE_WORKSPACES = [1, 2, 3, 4]

const INITIAL_STATE = Immutable({
  days: {
    ...VISIBLE_WORKSPACES.reduce((visibleDaysState, visibleDay) => {
      visibleDaysState[`day${visibleDay}`] = {
        isActive: false,
        isInteractable: false
      }
      return visibleDaysState
    }, {})
  },
  hasInteracted: false,
  lastVisibleDays: null
})

const reducers = {
  [types.TOGGLE_DAY]: toggleDay,
  [types.SET_WORKSPACES]: setWorkspaces
}

function toggleDay(state, payload) {
  const setInteraction = Immutable.set(state, 'hasInteracted', true)
  const day = `day${payload}`
  return Immutable.setIn(
    setInteraction,
    ['days', day, 'isActive'],
    !state.days[day].isActive
  )
}

function mapStateDays(days) {
  return Object.keys(days)
    .map(key => {
      return days[key].isActive === true
        ? parseInt(key.replace('day', ''), 10)
        : null
    })
    .filter(Boolean)
}

function setWorkspaces(state, payload) {
  const { toShow, defaultDays } = payload

  const visibleDays = mapStateDays(state.days)

  const activeDays = !state.hasInteracted
    ? defaultDays
    : visibleDays.length === 0
    ? mapStateDays(state.lastVisibleDays)
    : visibleDays

  let i = 1
  const newDays = Object.keys(state.days).reduce((acc, workspace) => {
    if (i <= toShow) {
      acc[workspace] = {
        isActive: activeDays.includes(i),
        isInteractable: true
      }
      i += 1
      return acc
    }

    acc[workspace] = {
      ...state[workspace],
      isInteractable: false
    }
    return acc
  }, {})

  return Immutable.merge(state, {
    days: newDays,
    lastVisibleDays: visibleDays.length ? state.days : state.lastVisibleDays
  })
}

export default createReducer(INITIAL_STATE, reducers)
