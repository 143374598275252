import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import isValid from 'date-fns/is_valid'
import getMonth from 'date-fns/get_month'
import getDate from 'date-fns/get_date'
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import DayPicker from 'react-day-picker/DayPicker'
import { getModifiersForDay } from 'react-day-picker/lib/src/ModifiersUtils'
import { ESC } from 'react-day-picker/lib/src/keys'

import { localeUtils } from 'common/helpers/reactDayPicker'
import { getUserLang } from 'core/users/selectors'

import './style.module.scss'

export const HIDE_TIMEOUT = 100

function getStateFromProps(props) {
  let month

  if (props.value) {
    if (isValid(props.value)) {
      // even though it says month it's a Date object WTF React day picker?
      month = props.value
    }
  } else {
    month =
      props.dayPickerProps.initialMonth ||
      props.dayPickerProps.month ||
      new Date()
  }
  return {
    value: format(props.value, props.formatstring),
    month
  }
}

export class DayPickerInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = getStateFromProps(props)
    this.state.showOverlay = false
  }

  componentDidUpdate(prevProps) {
    const { dayPickerProps, value } = prevProps
    const { dayPickerProps: nextDayPickerProps, value: nextValue } = this.props

    const hasDifferentValue = nextValue !== value

    const willUpdateDayPickerMonth =
      nextDayPickerProps.month && dayPickerProps.month

    const newMonthProp = nextDayPickerProps.month && !dayPickerProps.month

    const shouldDisplayAnotherMonth =
      newMonthProp ||
      (willUpdateDayPickerMonth &&
        (nextDayPickerProps.month.getFullYear() !==
          dayPickerProps.month.getFullYear() ||
          nextDayPickerProps.month.getMonth() !==
            dayPickerProps.month.getMonth()))

    if (hasDifferentValue && !shouldDisplayAnotherMonth) {
      this.setState(getStateFromProps(this.props))
    } else if (shouldDisplayAnotherMonth) {
      this.setState({ month: nextDayPickerProps.month })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.clickTimeout)
    clearTimeout(this.hideTimeout)
    clearTimeout(this.blurTimeout)
  }

  input = null
  daypicker = null
  clickedInside = false
  clickTimeout = null
  hideTimeout = null

  /**
   * Show the Day Picker overlay.
   *
   * @memberof DayPickerInput
   */
  showDayPicker() {
    this.setState({
      showOverlay: true
    })
  }

  /**
   * Hide the Day Picker overlay
   *
   * @memberof DayPickerInput
   */
  hideDayPicker() {
    this.setState({
      showOverlay: false
    })
  }

  hideAfterDayClick = () => {
    if (!this.props.hideOnDayClick) {
      return
    }
    this.hideTimeout = setTimeout(
      () => this.hideDayPicker(),
      HIDE_TIMEOUT // give a timeout to show the clicked day
    )
  }

  handleContainerMouseDown = () => {
    this.clickedInside = true
    // The input's onBlur method is called from a queue right after onMouseDown event.
    // setTimeout adds another callback in the queue, but is called later than onBlur event
    this.clickTimeout = setTimeout(() => {
      this.clickedInside = false
    }, 0)
  }

  handleClick = e => {
    this.showDayPicker()
    if (this.props.onClick) {
      e.persist()
      this.props.onClick(e)
    }
  }

  handleFocus = e => {
    this.showDayPicker()
    if (this.props.onFocus) {
      e.persist()
      this.props.onFocus(e)
    }
  }

  handleBlur = e => {
    this.setState({
      showOverlay: this.clickedInside
    })

    // Force input's focus if blur event was caused by clicking inside the overlay
    if (this.clickedInside) {
      this.blurTimeout = setTimeout(() => this.input.focus(), 0)
    }

    if (this.props.onBlur) {
      e.persist()
      this.props.onBlur(e)
    }
  }

  handleChange = e => {
    const { value } = e.target
    const { dayPickerProps, onDayChange, onChange } = this.props

    if (onChange) {
      e.persist()
      onChange(e)
    }

    if (value.trim() === '') {
      this.setState({ value })
      if (this.props.onDayChange) {
        this.props.onDayChange(undefined, {})
      }
      return
    }
    if (!isValid(value)) {
      this.setState({ value })
      return
    }

    this.setState({ month: getMonth(value), value }, () => {
      if (!onDayChange) {
        return
      }
      const modifiersObj = {
        disabled: dayPickerProps.disabledDays,
        selected: dayPickerProps.selectedDays,
        ...dayPickerProps.modifiers
      }
      const modifiers = getModifiersForDay(getDate(value), modifiersObj).reduce(
        (obj, modifier) => {
          const newObj = { ...obj }
          newObj[modifier] = true
          return newObj
        },
        {}
      )
      this.props.onDayChange(parse(value), modifiers)
    })
  }

  handleOnKeyUp = e => {
    this.setState({
      showOverlay: e.keyCode !== ESC
    })
    if (this.props.onKeyUp) {
      e.persist()
      this.props.onKeyUp(e)
    }
  }

  handleDayClick = (day, modifiers, e) => {
    if (this.props.dayPickerProps.onDayClick) {
      this.props.dayPickerProps.onDayClick(day, modifiers, e)
    }
    if (modifiers.disabled) {
      // Do nothing if the day is disabled
      return
    }
    if (modifiers.selected && this.props.clickUnselectsDay) {
      // Unselect the day
      this.setState({ value: '' }, this.hideAfterDayClick)
      if (this.props.onDayChange) {
        this.props.onDayChange(undefined, modifiers)
      }
      return
    }
    this.setState(
      {
        value: format(day, this.props.formatstring),
        month: day
      },
      () => {
        if (this.props.onDayChange) {
          this.props.onDayChange(day, modifiers)
        }
        this.hideAfterDayClick()
      }
    )
  }

  renderOverlay() {
    let selectedDay
    if (this.state.value) {
      if (this.state.value && isValid(parse(this.state.value))) {
        selectedDay = getDate(parse(this.state.value))
      }
    }
    return (
      <div className={this.props.classNames.overlayWrapper}>
        <div className={this.props.classNames.overlay}>
          <DayPicker
            locale={this.props.locale}
            ref={el => (this.daypicker = el)}
            fixedWeeks
            {...this.props.dayPickerProps}
            month={this.state.month}
            selectedDays={[selectedDay]}
            onDayClick={this.handleDayClick}
            localeUtils={localeUtils}
          />
        </div>
      </div>
    )
  }

  render() {
    const inputProps = { ...this.props }
    delete inputProps.component
    delete inputProps.dayPickerProps
    delete inputProps.formatstring
    delete inputProps.clickUnselectsDay
    delete inputProps.hideOnDayClick
    delete inputProps.onDayChange
    delete inputProps.classNames
    delete inputProps.dispatch
    return (
      <div
        className={this.props.classNames.container}
        onMouseDown={this.handleContainerMouseDown}
      >
        {React.createElement(this.props.component, {
          ref: el => (this.input = el),
          ...inputProps,
          hide: `${inputProps.hide}`,
          value: inputProps.hide ? '--' : this.state.value,
          onChange: this.handleChange,
          onFocus: this.handleFocus,
          onBlur: this.handleBlur,
          onKeyUp: this.handleOnKeyUp,
          onClick: this.handleClick
        })}
        {this.state.showOverlay && this.renderOverlay()}
      </div>
    )
  }
}

DayPickerInput.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),

  formatstring: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),

  dayPickerProps: PropTypes.object,
  hideOnDayClick: PropTypes.bool,
  clickUnselectsDay: PropTypes.bool,
  component: PropTypes.any,

  classNames: PropTypes.shape({
    container: PropTypes.string,
    overlayWrapper: PropTypes.string,
    overlay: PropTypes.string.isRequired
  }),

  onDayChange: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  hide: PropTypes.bool
}

DayPickerInput.defaultProps = {
  dayPickerProps: {},
  value: '',
  formatstring: 'YYYY/MM/DD',
  hide: false,
  hideOnDayClick: true,
  clickUnselectsDay: false,
  component: 'input',
  classNames: {
    container: 'DayPickerInput',
    overlayWrapper: 'DayPickerInput-OverlayWrapper',
    overlay: 'DayPickerInput-Overlay'
  },
  locale: 'en'
}

const mapStateToProps = state => ({
  locale: getUserLang(state)
})

export const UnconnectedDayPickerInput = DayPickerInput

export default connect(mapStateToProps)(DayPickerInput)
