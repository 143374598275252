import Immutable from 'seamless-immutable'

import { createReducer } from 'common/utils'
import * as types from './types'

const INITIAL_STATE = Immutable({
  currentProduct: {},
  yesterdaysProduct: null,
  lastTimeSaved: null,
  readyToPublish: false
})

const reducers = {
  [types.SET_CURRENT_PRODUCT]: setCurrentProduct,
  [types.SET_CURRENT_PRODUCT_STATUS]: setCurrentProductStatus,
  [types.SET_YESTERDAYS_PRODUCT]: setYesterdaysProduct,
  [types.SET_LAST_TIME_SAVED]: setLastTimeSaved,
  [types.PUBLISH_READY]: setPublishReady
}

function setCurrentProduct(state, payload) {
  return Immutable.merge(state, { currentProduct: payload })
}

function setCurrentProductStatus(state, payload) {
  return Immutable.setIn(state, ['currentProduct', 'status'], payload)
}

function setYesterdaysProduct(state, payload) {
  return Immutable.set(state, 'yesterdaysProduct', payload)
}

function setLastTimeSaved(state) {
  return Immutable.set(state, 'lastTimeSaved', new Date())
}

function setPublishReady(state, payload) {
  return Immutable.set(state, 'readyToPublish', payload)
}

export default createReducer(INITIAL_STATE, reducers)
