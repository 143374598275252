import React from 'react'
import PropTypes from 'prop-types'
import { classList } from 'common/utils'
import InputWrapper from '../inputWrapper'
import UploadIcon from 'mdi-react/UploadIcon'

import css from './style.module.scss'

// Note: this is a "stylable" file input component that tries to work with
// reduxForm but it is not a controlled input like the others. HTMLInputElement
// does not accept a `value` attribute for inputs of `type="file"`, so although
// we allow redux form to maintain the state of the input, we rely on the DOM's
// state as well.

// In regards to styling, browsers don't make it easy to style a file input. The
// typical workaround is to render an invisible <input type="file"> element and
// hook it up to a label via the `for` attribute. The browser's behaviour is to
// trigger a click on the associated input when the label is clicked, thus
// causing the file selection dialog to open.

class FileInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      files: []
    }
  }

  handleFileChange = () => {
    const files = this.inputRef && this.inputRef.files
    if (files) {
      this.setState({
        files: files
      })
    }
  }

  render() {
    const files = this.inputRef && this.inputRef.files
    const {
      meta,
      label,
      showFilename,
      accept,
      multiple,
      disabled,
      input: { value, ...inputForm } = {}
    } = this.props

    return (
      <InputWrapper {...meta}>
        <label
          className={classList(css.fileInput, disabled && css.disabledInput)}
          htmlFor={inputForm.name}
        >
          <UploadIcon className={css.icon} />
          {label}
        </label>

        <input
          ref={input => {
            this.inputRef = input
          }}
          id={inputForm.name}
          type="file"
          disabled={disabled}
          accept={accept}
          multiple={multiple}
          onChange={this.handleFileChange}
          {...inputForm}
        />

        {showFilename && this.state.files.length > 0 ? (
          <span className={css.filename}>{files[0].name}</span>
        ) : null}
      </InputWrapper>
    )
  }
}

FileInput.propTypes = {
  label: PropTypes.string.isRequired,
  showFilename: PropTypes.bool,
  accept: PropTypes.string,
  multiple: PropTypes.bool
}

FileInput.defaultProps = {
  showFilename: false,
  multiple: false
}

export default FileInput
