import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import FormatBoldIcon from 'mdi-react/FormatBoldIcon'
import FormatItalicIcon from 'mdi-react/FormatItalicIcon'
import FormatUnderlineIcon from 'mdi-react/FormatUnderlineIcon'
import FormatListBulletedIcon from 'mdi-react/FormatListBulletedIcon'
import LinkVariantIcon from 'mdi-react/LinkVariantIcon'

import css from './style.module.scss'

const RichTextButtons = ({
  disableButtons,
  allowPreview,
  onBoldClick,
  onItalicClick,
  onUnderlineClick,
  onBulletClick,
  onLinkClick,
  onPreviewClick
}) => {
  const { t } = useTranslation('products')
  return (
    <div className={css.styleButtons} onMouseDown={e => e.preventDefault()}>
      <button type="button" disabled={disableButtons} onMouseDown={onBoldClick}>
        <FormatBoldIcon />
      </button>
      <button
        type="button"
        disabled={disableButtons}
        onMouseDown={onItalicClick}
      >
        <FormatItalicIcon />
      </button>
      <button
        type="button"
        disabled={disableButtons}
        onMouseDown={onUnderlineClick}
      >
        <FormatUnderlineIcon />
      </button>
      <button
        type="button"
        disabled={disableButtons}
        onMouseDown={onBulletClick}
      >
        <FormatListBulletedIcon />
      </button>
      <button type="button" disabled={disableButtons} onMouseDown={onLinkClick}>
        <LinkVariantIcon />
      </button>
      {allowPreview && (
        <div className={css.preview}>
          <button type="button" onMouseDown={onPreviewClick}>
            <span>{t('preview')}</span>
          </button>
        </div>
      )}
    </div>
  )
}

RichTextButtons.propTypes = {
  disableButtons: PropTypes.bool,
  allowPreview: PropTypes.bool,
  onItalicClick: PropTypes.func,
  onBoldClick: PropTypes.func,
  onUnderlineClick: PropTypes.func,
  onBulletClick: PropTypes.func,
  onLinkClick: PropTypes.func,
  onPreviewClick: PropTypes.func
}

RichTextButtons.defaultProps = {
  allowPreview: true
}

export default RichTextButtons
