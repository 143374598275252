import React from 'react'
import { connect } from 'react-redux'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from 'react-day-picker/moment'

import { FORMAT } from 'common/helpers/reactDayPicker'
import { getUserLang } from 'core/users/selectors'

import { classList } from 'common/utils'

import InputWrapper from '../inputWrapper'

import css from './style.module.scss'

import 'moment/locale/fr'

const DayPicker = props => {
  const classes = classList(css.dayPicker, css.test)

  return (
    <div className={classes}>
      <InputWrapper {...props.meta}>
        <DayPickerInput
          formatDate={formatDate}
          parseDate={parseDate}
          format={FORMAT}
          placeholder=""
          inputProps={{
            ...props.input,
            disabled: props.disabled,
            autoComplete: 'off'
          }}
          dayPickerProps={{
            locale: props.locale,
            localeUtils: MomentLocaleUtils,
            ...props.dayPickerProps
          }}
          value={props.input.value}
        />
      </InputWrapper>
    </div>
  )
}

const mapStateToProps = state => ({
  locale: getUserLang(state)
})

export default connect(mapStateToProps)(DayPicker)
