import React, { Component } from 'react'
import PropTypes from 'prop-types'
import getHours from 'date-fns/get_hours'
import getMinutes from 'date-fns/get_minutes'

import { frankensteinDateAndTime } from 'common/helpers/frankensteinDateAndTime'

import { TimePicker } from 'common/components/form'
import { DayPickerInput as DatePicker } from 'common/components'

import css from './style.module.scss'

const DEFAULT_DATE = new Date()
const DEFAULT_HOUR = process.env.REACT_APP_AVID_PUBLISH_TIME || 17
const DEFAULT_MIN = 0

class DateAndTimePicker extends Component {
  constructor(props) {
    super(props)

    const { date, hour, min } = props

    this.state = {
      date,
      hour,
      min,
      dateAndTime: null
    }
  }

  componentDidMount() {
    if (this.props.value) {
      this.initializeFromValue()
    } else {
      this.handleSetState(this.props)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.initializeFromValue(this.props.value)
    }
  }

  initializeFromValue = (value = this.props.value) => {
    const hour = getHours(value)
    const min = getMinutes(value)

    this.handleSetState({ hour, min, date: value })
  }

  handleSetState = ({
    date = this.state.date,
    hour = this.state.hour,
    min = this.state.min,
    shouldDispatch = false
  }) => {
    this.setState(() => {
      const dateAndTime = frankensteinDateAndTime({ date, hour, min })

      if (shouldDispatch) {
        this.props.handleOnChange(dateAndTime)
      }

      return { date, hour, min, dateAndTime }
    })
  }

  handleDateChange = date => {
    this.handleSetState({ date, shouldDispatch: true })
  }

  handleTimeChange = (e, unit) => {
    const { value } = e.target
    this.handleSetState({ [unit]: value, shouldDispatch: true })
  }

  render() {
    const { datePickerDisabled, timePickerDisabled } = this.props

    return (
      <div className={css.container}>
        <div className={css.date}>
          <DatePicker
            onDayChange={this.handleDateChange}
            value={this.state.date}
            disabled={datePickerDisabled}
          />
        </div>
        <div className={css.time}>
          <TimePicker
            hour={this.state.hour}
            min={this.state.min}
            handleOnChange={this.handleTimeChange}
            disabled={timePickerDisabled}
          />
        </div>
      </div>
    )
  }
}

DateAndTimePicker.props = {
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date),
  date: PropTypes.object.isRequired,
  hour: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  datePickerDisabled: PropTypes.bool
}

DateAndTimePicker.defaultProps = {
  date: DEFAULT_DATE,
  hour: DEFAULT_HOUR,
  min: DEFAULT_MIN,
  dateFormat: 'DD-MMM HH:mm',
  datePickerDisabled: false
}

export default DateAndTimePicker
