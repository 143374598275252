import CONFIG from '../../config'
import { isNull } from 'lodash'
import HttpError from './httpError'
import { setLastTimeSaved } from 'core/product/actions'
import { logoutUser } from 'core/users/actions'
import { store } from 'common/store'
import { getTokenSilently, loginWithRedirect } from '../../auth'
import { isDirty, getFormNames } from 'redux-form'

export function getURL(url) {
  const baseUrl = CONFIG.api_url
  return `${baseUrl}/v${CONFIG.api_version}${
    url.startsWith('/') ? '' : '/'
  }${url}`
}

async function getHeaders() {
  const json = 'application/json'

  /**
   * If third-party cookies are disabled and the user's token expires or becomes
   * invalid the getTokenSilently() call will fail. If a user with third-party
   * cookies disabled tries to perform an action, this will catch the error and
   * force the user to re-authenticate and then will re-direct them back to
   * where they where in order for them to re-perform their action.
   *
   * If user's don't have third-party cookies disabled, the getTokenSilently()
   * call will silently re-authenticate user's with expired or invalid tokens.
   */
  let token
  try {
    token = await getTokenSilently()
  } catch {
    return loginWithRedirect({
      appState: { targetUrl: window.location.pathname }
    })
  }

  return new Headers({
    Accept: json,
    'Content-Type': json,
    Authorization: `Bearer ${token}`
  })
}

function getBody(body) {
  if (isNull(body)) {
    return null
  }

  if (body instanceof FormData) {
    return body
  }

  if (body instanceof File) {
    const formData = new FormData()
    formData.append('location', body)
    return formData
  }

  return JSON.stringify(body)
}

let pendingFetchCount = 0
async function makeRequest(fullUrl, method, body) {
  const options = {
    headers: await getHeaders(),
    method,
    // Intentionally passing undefined for Microsoft Edge support
    body: body ? getBody(body) : undefined
  }

  if (method !== 'GET') {
    pendingFetchCount++ //  Increment pending edit/create requests
  }

  if (body instanceof File || body instanceof FormData) {
    options.headers.delete('Content-Type')
  }

  return fetch(fullUrl, options)
    .then(async res => {
      //  Decrement pending edit/create requests
      if (method !== 'GET') {
        store.dispatch(setLastTimeSaved())
        pendingFetchCount--
      }

      if (res.ok) {
        return res.text()
      } else {
        const message = await res.text()
        throw new HttpError(res, message)
      }
    })
    .then(text => (text.length ? JSON.parse(text) : null))
    .catch(error => {
      // Unauthenticated
      if (error.status === 401) store.dispatch(logoutUser())

      //  Decrement pending edit/create requests
      if (method !== 'GET') pendingFetchCount--

      //  Only throw HttpErrors so cancelled requests won't alert
      if (error.name === 'HttpError') throw error
      return null
    })
}

//  Global listener that will alert user if there is any unsaved data when
//  leaving page
window.addEventListener('beforeunload', event => {
  const state = store.getState()

  //  Check if there are any dirty forms (unsaved form values)
  const hasDirtyForm = getFormNames()(state).some(form => isDirty(form)(state))
  if (hasDirtyForm || pendingFetchCount > 0) {
    event.preventDefault()
    event.returnValue = ''
  }
})

export function get(url) {
  return makeRequest(getURL(url), 'GET')
}
export function post(url, body) {
  return makeRequest(getURL(url), 'POST', body)
}
export function put(url, body) {
  return makeRequest(getURL(url), 'PUT', body)
}
export function del(url) {
  return makeRequest(getURL(url), 'DELETE')
}
