export const FETCH_AVALANCHE_PROBLEMS =
  'avalancheProblems/FETCH_AVALANCHE_PROBLEMS'
export const FAIL_FETCHING_AVALANCHE_PROBLEMS =
  'avalancheProblems/FAIL_FETCHING_AVALANCHE_PROBLEMS'
export const SET_AVALANCHE_PROBLEMS = 'avalancheProblems/SET_AVALANCHE_PROBLEMS'
export const DELETE_AVALANCHE_PROBLEM =
  'avalancheProblems/DELETE_AVALANCHE_PROBLEM'
export const UPDATE_AVALANCHE_PROBLEMS =
  'avalancheProblems/UPDATE_AVALANCHE_PROBLEMS'
export const SUBMIT_AVALANCHE_PROBLEMS =
  'avalancheProblems/SUBMIT_AVALANCHE_PROBLEMS'
