import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { shouldDisplayError } from '../utils'
import { classList } from 'common/utils'

import css from '../style.module.scss'

/*
  This input wrapper is used for components that are passing into
  redux-forms field components. Used to display errors in a consistent way from
  form input component to form input component
*/
const InputWrapper = props => {
  const { t } = useTranslation('validation')
  const displayError = shouldDisplayError(props)
  const classes = classList(css.inputWrapper, displayError && css.error)

  return (
    <div className={classes}>
      {props.children}
      <div className={css.errorText}>{displayError && t(props.error)}</div>
    </div>
  )
}

InputWrapper.propTypes = {
  /*
    The form component that this wrapper wraps
  */
  children: PropTypes.node,
  /*
    true if this field currently has focus. It will only work if you are passing onFocus to your input element.
  */
  active: PropTypes.bool,
  /*
    true if the field value has changed from its initialized value. Opposite of pristine.
  */
  dirty: PropTypes.bool,
  /*
The error for this field if its value is not passing validation. Both synchronous, asynchronous, and submit validation errors will be reported here.
  */
  error: PropTypes.string,
  /*
    true if the field has been touched. By default this will be set when the field is blurred.
  */
  touched: PropTypes.bool
}

export default InputWrapper
