import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import * as api from 'common/api'

import { setTerrainAndTravelAdvice } from './actions'
import { getCurrentProductKey } from 'core/product/selectors'

import {
  FETCH_TERRAIN_AND_TRAVEL_ADVICE,
  UPDATE_TERRAIN_AND_TRAVEL_ADVICE,
  DELETE_TERRAIN_AND_TRAVEL_ADVICE
} from './types'

function* onFetchTerrainAndTravelAdvice() {
  yield takeLatest(FETCH_TERRAIN_AND_TRAVEL_ADVICE, fetchTerrainAndTravelAdvice)
}

function* fetchTerrainAndTravelAdvice() {
  const reportKey = yield select(getCurrentProductKey)
  try {
    const terrainAndTravelAdvice = yield call(
      api.get,
      `/terrainandtraveladvice/${reportKey}`
    )
    yield put(setTerrainAndTravelAdvice(terrainAndTravelAdvice))
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

function* onUpdateTerrainAndTravelAdvice() {
  yield takeLatest(
    UPDATE_TERRAIN_AND_TRAVEL_ADVICE,
    updateTerrainAndTravelAdvice
  )
}

function* updateTerrainAndTravelAdvice({ payload }) {
  const reportKey = yield select(getCurrentProductKey)
  const { workspace, statements } = payload

  if (statements.length < 1) return

  try {
    yield call(api.put, `/terrainandtraveladvice/${reportKey}/${workspace}`, {
      reportKey,
      workspace,
      statements: statements.map(statement => ({
        statementId: statement.id,
        index: statement.index
      }))
    })
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

function* onDeleteTerrainAndTravelAdvice() {
  yield takeLatest(
    DELETE_TERRAIN_AND_TRAVEL_ADVICE,
    deleteTerrainAndTravelAdvice
  )
}

function* deleteTerrainAndTravelAdvice({ payload }) {
  const reportKey = yield select(getCurrentProductKey)
  const { workspace, index } = payload

  try {
    yield call(
      api.del,
      `/terrainandtraveladvice/${reportKey}/${workspace}/${index}`
    )
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

export default function* rootSaga() {
  yield all([
    onFetchTerrainAndTravelAdvice(),
    onUpdateTerrainAndTravelAdvice(),
    onDeleteTerrainAndTravelAdvice()
  ])
}
