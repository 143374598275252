import React from 'react'
import PropTypes from 'prop-types'

import classList from 'common/utils/classList'

import css from './style.module.scss'

const SidebarContainer = ({
  className,
  children,
  backgroundColor,
  noShadow,
  borderRight,
  narrow
}) => {
  const classes = classList(
    className,
    css.container,
    css[backgroundColor],
    noShadow && css.noShadow,
    borderRight && css.borderRight,
    narrow && css.narrow
  )

  return <div className={classes}>{children}</div>
}

SidebarContainer.defaultProps = {
  backgroundColor: 'white'
}

SidebarContainer.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.oneOf(['grey', 'white']),
  noShadow: PropTypes.bool,
  borderRight: PropTypes.bool,
  narrow: PropTypes.bool
}

export default SidebarContainer
