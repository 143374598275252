import i18n from 'i18next'
import XHR from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

async function loadLocales(url, options, callback, data) {
  try {
    const locale = await import('./locales/' + url + '.json')
    callback(locale, { status: '200' })
  } catch (e) {
    callback(null, { status: '404' })
  }
}

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    lng: 'english',
    load: 'languageOnly',
    fallbackLng: false,
    ns: ['common'],
    defaultNS: 'common',
    backend: {
      loadPath: '{{lng}}/{{ns}}',
      parse: data => data,
      ajax: loadLocales
    },
    react: {
      useSuspense: false
    }
  })

export const LANGUAGES = new Map([
  ['en', 'english'],
  ['fr', 'french']
])

export default i18n
