import { takeLatest, all, call, put, select } from 'redux-saga/effects'

import * as api from 'common/api'
import { FETCH_ADVISORIES, DELETE_ADVISORY, UPDATE_ADVISORIES } from './types'
import { setAdvisories } from './actions'
import { getCurrentProductKey } from 'core/product/selectors'

function* onFetchAdvisories() {
  yield takeLatest(FETCH_ADVISORIES, fetchAdvisories)
}

function* fetchAdvisories() {
  const reportKey = yield select(getCurrentProductKey)

  try {
    /**
     * Map advisories to make them easier to work with:
      {
        [workspaceNumber]: {
          [cautionLevel]: {
            ...advisory
          }
        }
      }
     */
    const advisories = yield call(
      api.get,
      `terrainandtraveladvisories/${reportKey}`
    )
    if (!advisories) return

    const reducedAdvisories = advisories.reduce(
      (advisories, advisory) => ({
        ...advisories,
        [advisory.workspace]: {
          ...advisories[advisory.workspace],
          [advisory.cautionLevel]: advisory
        }
      }),
      {}
    )

    yield put(setAdvisories(reducedAdvisories))
  } catch (err) {
    console.error(err)
    //window.alert(err.message)
  }
}

function* onDeleteAdvisory() {
  yield takeLatest(DELETE_ADVISORY, deleteAdvisory)
}

function* deleteAdvisory({ payload }) {
  const { workspace, cautionLevel, index } = payload
  const reportKey = yield select(getCurrentProductKey)

  try {
    yield call(
      api.del,
      `/terrainandtraveladvisories/${reportKey}/${workspace}/${cautionLevel}/${index}`
    )
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

function* onUpdateAdvisories() {
  yield takeLatest(UPDATE_ADVISORIES, updateAdvisories)
}

function* updateAdvisories({ payload }) {
  const { workspace, statements, cautionLevel } = payload
  const reportKey = yield select(getCurrentProductKey)

  try {
    yield call(
      api.put,
      `/terrainandtraveladvisories/${reportKey}/${workspace}/${cautionLevel}`,
      {
        reportKey,
        workspace,
        cautionLevel,
        statements
      }
    )
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

export default function* rootSaga() {
  yield all([onFetchAdvisories(), onDeleteAdvisory(), onUpdateAdvisories()])
}
