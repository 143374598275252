import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'common/components'
import { DayPickerInput } from 'common/components'
import isSameDay from 'date-fns/is_same_day'
import isTomorrow from 'date-fns/is_tomorrow'
import isYesterday from 'date-fns/is_yesterday'
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days'
import { QUICK_START_STATUS_FILTER } from 'core/products/components/productTable/constants'

import css from './style.module.scss'

const dateOptionsValues = [
  'today',
  'yesterday',
  'tomorrow',
  'thisWeek',
  'lastWeek'
]

export const DateFilterMethod = (filter, row) => {
  if (row.id === -1) return true
  if (!filter.value) return true
  const today = Date.now()
  if (filter.value === 'tomorrow') {
    return isTomorrow(row[filter.id])
  }
  if (filter.value === 'today') {
    return isSameDay(row[filter.id], today)
  }
  if (filter.value === 'yesterday') {
    return isYesterday(row[filter.id])
  }
  if (filter.value === 'thisWeek') {
    const diff = differenceInCalendarDays(row[filter.id], today)
    return diff >= 0 && diff < 7
  }
  if (filter.value === 'lastWeek') {
    const diff = differenceInCalendarDays(row[filter.id], today)
    return diff <= 0 && diff > -7
  }
  if (typeof filter.value === 'object') {
    // Date has been selected
    return isSameDay(row[filter.id], filter.value)
  }
  // filter.value === 'all' > just return everything
  // same with no filter
  return true
}

export const DropdownFilter = options => ({ filter, onChange }) => (
  <Dropdown
    onChange={event => onChange(event.target.value)}
    value={filter ? filter.value : 'all'}
    options={options}
  />
)

const DateFiltersDropdown = ({ dropdownValue, onChange }) => {
  const { t } = useTranslation(['products', 'common'])
  const dateOptions = dateOptionsValues.map(dateOptionsValue => ({
    label: t(`products:dateOptions.${dateOptionsValue}`),
    value: dateOptionsValue
  }))
  dateOptions.unshift({ value: '', label: t('common:all') })

  return (
    <Dropdown
      value={dropdownValue}
      onChange={event => onChange(event.target.value)}
      options={dateOptions}
    />
  )
}

export const DateFilters = ({ filter, onChange }) => {
  let dayPickerValue = new Date()
  let hideDayInputValue = true
  let dropdownValue = ''
  if (filter && typeof filter.value !== 'string') {
    dayPickerValue = filter.value
    dropdownValue = ''
    hideDayInputValue = false
  }
  if (filter && typeof filter.value !== 'object') {
    dropdownValue = filter.value
    hideDayInputValue = true
  }
  return (
    <div className={css.dateFiltersContainer}>
      <div className={css.dayPicker}>
        <DayPickerInput
          hide={hideDayInputValue}
          value={dayPickerValue}
          onDayChange={day => {
            onChange(day)
          }}
        />
      </div>
      <div className={css.dropdown}>
        <DateFiltersDropdown
          dropdownValue={dropdownValue}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export const filterDropdowns = type => (filter, row) => {
  if (row.id === -1) return true
  if (type === 'status' && filter.value === QUICK_START_STATUS_FILTER.id)
    return QUICK_START_STATUS_FILTER.statuses.includes(row[type])
  else return filter.value === row[type]
}

export const filterLocations = locations => (filter, row) => {
  if (row.id === -1) return true
  const locationId = row[filter.id]
  const locationName = locations[locationId]
  return locationName
    ? locationName.toLowerCase().search(filter.value.toLowerCase()) > -1
    : false
}

export const filterTextSearch = (filter, row) => {
  if (row.id === -1) return true
  return row[filter.id].toLowerCase().search(filter.value.toLowerCase()) > -1
}
