export default class Status {
  static pend() {
    return new Status(true, false, false)
  }
  static fulfill() {
    return new Status(false, true, false)
  }
  static reject() {
    return new Status(false, false, true)
  }
  constructor(isPending = false, isFulfilled = false, isRejected = false) {
    this.isPending = isPending
    this.isFulfilled = isFulfilled
    this.isRejected = isRejected
  }
  pend() {
    return Status.pend()
  }
  fulfill() {
    return Status.fulfill()
  }
  reject() {
    return Status.reject()
  }
  merge(status) {
    return new Status(
      this.isPending || status.isPending,
      this.isFulfilled && status.isFulfilled,
      this.isRejected || status.isRejected
    )
  }
}
