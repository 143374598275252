import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button } from 'common/components'
import classList from 'common/utils/classList'

import css from './style.module.scss'

const newProductClasses = classList(
  css.buttonContainer,
  css.buttonContainerNewProduct
)

const NewProductButton = ({ onClick }) => {
  const { t } = useTranslation('products')
  return (
    <div className={newProductClasses}>
      <Button context="new" onClick={onClick}>
        {t('newProduct')}
      </Button>
    </div>
  )
}

NewProductButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default NewProductButton
