import Immutable from 'seamless-immutable'

import { createReducer } from 'common/utils'
import * as types from './types'

const INITIAL_STATE = Immutable({
  isFetching: false,
  statementsByComponent: {}
})

const reducers = {
  [types.FETCH_STATEMENTS]: fetchStatements,
  [types.FAIL_FETCHING_STATEMENTS]: failFetchingStatements,
  [types.SET_STATEMENTS]: setStatements
}

function fetchStatements(state) {
  return Immutable.set(state, 'isFetching', true)
}

function failFetchingStatements(state) {
  return Immutable.set(state, 'isFetching', false)
}

function setStatements(state, { statements, component }) {
  return Immutable.merge(state, {
    isFetching: false,
    statementsByComponent: {
      ...state.statementsByComponent,
      [component]: statements
    }
  })
}

export default createReducer(INITIAL_STATE, reducers)
