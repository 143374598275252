export const GET_USER = 'GET_USER'
export const SET_USER = 'SET_USER'

export const SET_USERS = 'SET_USERS'

export const SUBMIT_EDIT_USER = 'SUBMIT_EDIT_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'

export const LOGOUT_USER = 'LOGOUT_USER'

export const FETCH_USERS = 'FETCH_USERS'
