import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { DateAndTimeCell } from 'common/components/table/cells'
import { DateAndTimePicker as DateAndTimePickerInput } from 'common/components/form'
import UntilFurtherNotice from './untilFurtherNotice'

import { onDateChange } from 'core/products/actions'
import { FIELDS_CHANGES } from 'core/products/components/productTable/constants'

let DateAndTimePicker = ({
  date,
  onDateAndTimeChangeHandler,
  datePickerDisabled
}) => (
  <DateAndTimePickerInput
    value={date}
    handleOnChange={onDateAndTimeChangeHandler}
    datePickerDisabled={datePickerDisabled}
  />
)

DateAndTimePicker.propTypes = {
  rowData: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  columnAccessor: PropTypes.string.isRequired,
  onDateAndTimeChangeHandler: PropTypes.func.isRequired,
  datePickerDisabled: PropTypes.bool
}

DateAndTimePicker.defaultProps = {
  datePickerDisabled: false
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDateAndTimeChangeHandler: newDateAndTime =>
    dispatch(
      onDateChange(ownProps.rowData, newDateAndTime, ownProps.columnAccessor)
    )
})

DateAndTimePicker = connect(
  null,
  mapDispatchToProps
)(DateAndTimePicker)

const DateCell = (columnAccessor, userId) => rowData => {
  const {
    row: { status, productType, user, id },
    value
  } = rowData
  if (columnAccessor === 'validTill' && productType === 'offseason')
    return <UntilFurtherNotice />

  // The DateCell should only be enabled if the product is assigned to the
  // current user or if the row is a "New Product" row (id === -1).
  if (
    FIELDS_CHANGES.edit[status].includes(columnAccessor) &&
    (id === -1 || userId === user)
  ) {
    const datePickerDisabled = status === 'draft' || status === 'published'

    return (
      <DateAndTimePicker
        date={new Date(value)}
        rowData={rowData}
        columnAccessor={columnAccessor}
        datePickerDisabled={datePickerDisabled}
      />
    )
  }

  return <DateAndTimeCell>{value}</DateAndTimeCell>
}

DateCell.propTypes = {
  columnAccessor: PropTypes.string.isRequired
}

export default DateCell
