import React, { Component } from 'react'
import PropTypes from 'prop-types'

import css from './style.module.scss'

/*
 * A customizable ribbon component; pass in one or more <Option /> components
 * (as children) to create a single-select ribbon component.
 *
 */
class OptionSet extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node // One or more <Option /> components
    ]).isRequired,
    selection: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onClick: PropTypes.func
  }

  render() {
    const { children, selection, ...rest } = this.props
    const childrenWithProps = React.Children.map(children, (child, index) => {
      /** the value prop on <Option /> is optional; if not given map it to child index */
      const value = child.props.value ? child.props.value : index

      /** calculates if the given child is the currently selected option */
      const selected = selection === value

      return React.cloneElement(child, { value, selected, ...rest })
    })

    return <div className={css.optionSet}>{childrenWithProps}</div>
  }
}

export default OptionSet
