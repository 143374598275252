import React from 'react'
import PropTypes from 'prop-types'

import { classList } from 'common/utils'

import css from './style.module.scss'

const Content = ({
  id,
  children,
  className,
  hasFooter,
  hasMainAndAside,
  noScroll
}) => {
  const classes = classList(
    className,
    css.content,
    hasFooter && css.hasFooter,
    hasMainAndAside && css.hasMainAndAside,
    noScroll && css.noScroll
  )

  return <div id={id} className={classes}>{children}</div>
}

Content.propTypes = {
  children: PropTypes.node.isRequired,
  hasFooter: PropTypes.bool,
  hasMainAndAside: PropTypes.bool,
  noScroll: PropTypes.bool
}

export default Content
