import React from 'react'
import Table from '../table'
import { useTranslation } from 'react-i18next'

const AvalancheProblemTypeInfoTable = ({ size }) => {
  const { t } = useTranslation('infoTip')

  const keys = [
    'dryloose',
    'wetloose',
    'stormslab',
    'windslab',
    'persistanceslab',
    'deeppersistenceslab',
    'wetslab',
    'dryslab',
    'cornice'
  ]

  const columns = [
    {
      title: t('avalancheProblemTypes.titles.name'),
      key: 'name'
    },
    {
      title: t('avalancheProblemTypes.titles.description'),
      key: 'description'
    },
    {
      title: t('avalancheProblemTypes.titles.formation'),
      key: 'formation'
    },
    {
      title: t('avalancheProblemTypes.titles.persistence'),
      key: 'persistence'
    }
  ]

  const data = keys.map(key => ({
    name: t(`avalancheProblemTypes.${key}.name`),
    description: t(`avalancheProblemTypes.${key}.description`),
    formation: t(`avalancheProblemTypes.${key}.formation`),
    persistence: t(`avalancheProblemTypes.${key}.persistence`)
  }))

  return (
    <Table
      data={data}
      columns={columns}
    />
  )
}

export default AvalancheProblemTypeInfoTable
