import React from 'react'
import PropTypes from 'prop-types'
import { map, capitalize } from 'lodash'
import { classList } from 'common/utils'
import css from './style.module.scss'

export default class Dropdown extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    /**
     * Current selected value
     */
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number
    ]),
    /**
     * Event to fire when checkbox changes, passes the dropdown's selected
     * value as an argument onChange={value => handleNewValue(value)}
     */
    onChange: PropTypes.func,
    /**
     * Array of `values` and `labels` for the select dropdown options
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          PropTypes.number
        ]),
        label: PropTypes.string
      })
    ),
    disabled: PropTypes.bool,
    style: PropTypes.object,
    containerStyle: PropTypes.object,
    narrow: PropTypes.bool,
    children: PropTypes.node
  }

  get options() {
    const { options } = this.props || {}

    return map(options, (option, index) => {
      return (
        <option
          key={`option-${index}`}
          value={option.value}
          disabled={option.disabled}
        >
          {option.label ? option.label : capitalize(option.value)}
        </option>
      )
    })
  }

  render() {
    const {
      name,
      autoFocus,
      className,
      value,
      onChange,
      onBlur,
      disabled,
      narrow,
      style,
      containerStyle,
      children
    } = this.props

    const classes = classList(
      css.select,
      disabled && css.disabled,
      narrow && css.narrow,
      className
    )

    return (
      <div className={css.selectContainer} style={containerStyle}>
        <select
          name={name}
          autoFocus={autoFocus}
          disabled={disabled}
          className={classes}
          style={style}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        >
          {children || this.options}
        </select>
      </div>
    )
  }
}
