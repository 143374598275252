import Immutable from 'seamless-immutable'
import { convertToRaw, ContentState } from 'draft-js'

import { createReducer } from 'common/utils'
import * as types from './types'

const INITIAL_STATE = Immutable({
  isFetching: false,
  whiteboardsByLocation: {}
})

const fetchWhiteboard = state => Immutable.set(state, 'isFetching', true)

const failFetchingWhiteboard = (state, payload) =>
  Immutable.set(state, 'isFetching', false)

const setWhiteboard = (state, { location, product, contents }) =>
  Immutable.merge(state, {
    isFetching: false,
    whiteboardsByLocation: {
      ...state.whiteboadsByLocation,
      [location]: {
        ...state.whiteboardsByLocation[location],
        /*  The content textbox should be initialized with an empty DraftJS
         *  ContentState object. This ensures accurate object comparisons when
         *  checking if the text has changed value.
         */
        [product]: contents || convertToRaw(ContentState.createFromText(''))
      }
    }
  })

const reducers = {
  [types.FETCH_WHITEBOARD]: fetchWhiteboard,
  [types.FAIL_FETCHING_WHITEBOARD]: failFetchingWhiteboard,
  [types.SET_WHITEBOARD]: setWhiteboard
}

export default createReducer(INITIAL_STATE, reducers)
