import React, { Component } from 'react'
import css from './style.module.scss'

const aspects = [
  {
    direction: 'n',
    d: 'M68.94,3.84a49.9,49.9,0,0,0-38.27,0L49.81,50Z'
  },
  {
    direction: 'ne',
    d: 'M96,30.9A49.87,49.87,0,0,0,68.94,3.84L49.81,50Z'
  },
  {
    direction: 'e',
    d: 'M96,69.17A49.81,49.81,0,0,0,96,30.9L49.81,50Z'
  },
  {
    direction: 'se',
    d: 'M68.94,96.23A49.8,49.8,0,0,0,96,69.17L49.81,50Z'
  },
  {
    direction: 's',
    d: 'M30.67,96.23a49.81,49.81,0,0,0,38.27,0L49.81,50Z'
  },
  {
    direction: 'sw',
    d: 'M3.61,69.17A49.84,49.84,0,0,0,30.67,96.23L49.81,50Z'
  },
  {
    direction: 'w',
    d: 'M3.61,30.9a49.9,49.9,0,0,0,0,38.27L49.81,50Z'
  },
  {
    direction: 'nw',
    d: 'M30.67,3.84A49.9,49.9,0,0,0,3.61,30.9L49.81,50Z'
  }
]

const AspectSlice = props => {
  const { d, isActive, onClick } = props
  return (
    <path
      d={d}
      onClick={onClick}
      className={css.aspect}
      stroke={isActive ? 'var(--color-white)' : 'var(--color-grey)'}
      strokeWidth="0.1"
      fill={isActive ? 'var(--color-blue)' : 'var(--color-light-medium-grey)'}
    />
  )
}

class Aspect extends Component {
  constructor(props) {
    super(props)

    const initialState = {}

    for (let i = 0; i < aspects.length; i += 1) {
      const aspect = aspects[i].direction
      initialState[aspect] = false
    }

    this.state = initialState
  }

  handleOnClick = aspect => {
    this.setState({ [aspect]: !this.state[aspect] })
  }

  render() {
    return (
      <div className={css.container}>
        <svg className={css.svg} viewBox="0 0 101 102">
          {aspects.map(aspect => {
            const { direction } = aspect
            return (
              <AspectSlice
                key={direction}
                {...aspect}
                isActive={this.state[direction]}
                onClick={() => this.handleOnClick(direction)}
              />
            )
          })}
        </svg>
        <div className={css.checkboxWrapper}>
          {aspects.map((aspect, i) => {
            const { direction } = aspect
            return (
              <input
                key={i}
                disabled
                type="checkbox"
                checked={this.state[direction]}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

export default Aspect
