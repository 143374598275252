import { all, call, put, takeLatest } from 'redux-saga/effects'

import * as api from 'common/api'

import { setStatements, failFetchingStatements } from './actions'

import { FETCH_STATEMENTS } from './types'

function* onFetchStatements() {
  yield takeLatest(FETCH_STATEMENTS, fetchStatements)
}

function* fetchStatements({ payload }) {
  try {
    const statements = yield call(api.get, `/statements/${payload}`)

    yield put(setStatements({ statements, component: payload }))
  } catch (err) {
    console.log(err)
    window.alert(err.message)
    yield put(failFetchingStatements(err))
  }
}

export default function* rootSaga() {
  yield all([onFetchStatements()])
}
