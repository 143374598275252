export default class HttpError extends Error {
  constructor(response, message) {
    super()
    const { status, url } = response

    this.name = 'HttpError'
    this.originalMessage = message
    this.message = `${message} for ${url}`
    this.status = status

    if (message.length > 0) return

    switch (status) {
      case 204:
        this.message = `File is empty`
        break
      case 400:
        this.message = `Body does not match`
        break
      case 404:
        this.message = `Unable to find the resource`
        break
      case 500:
        this.message = `Unable to process request`
        break
      default:
        this.message = status
    }

    this.message = `${this.message} for ${url}`
  }
}
