import { createSelector } from 'reselect'
import { isUndefined } from 'lodash'

const locationSelector = (state, props) => {
  if (!isUndefined(props.locationId)) {
    return state.locations.locationsById[props.locationId]
  }

  return null
}

export const getInitalValues = createSelector(
  locationSelector,
  location => location
)
