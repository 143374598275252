import React from 'react'
import PropTypes from 'prop-types'

import { DropdownCell, TextCell } from 'common/components/table/cells'

import { NEW_PRODUCT_STATUS } from '../constants'

const LocationCell = (
  locations,
  locationDropdownOptions,
  onDropdownChange
) => rowData => {
  const {
    row: { location: locationId, status }
  } = rowData

  if (status === NEW_PRODUCT_STATUS) {
    const options = [{ value: '', label: ' ' }].concat(locationDropdownOptions)
    return (
      <DropdownCell
        rowData={rowData}
        columnAccessor={'location'}
        options={options}
        onDropdownChange={onDropdownChange}
      />
    )
  }

  const locationName = locations[locationId]
    ? locations[locationId].feature.properties.name
    : ''

  return <TextCell>{locationName}</TextCell>
}

LocationCell.propTypes = {
  locations: PropTypes.array.isRequired,
  locationDropdownOptions: PropTypes.array.isRequired,
  onDropdownChange: PropTypes.func.isRequired
}

export default LocationCell
