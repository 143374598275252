import { call, put, all, takeLatest, takeEvery, select } from 'redux-saga/effects'
import { getFormValues } from 'redux-form'

import * as api from 'common/api'
import { getItem, KEYS } from 'common/helpers/storage'
import i18n, { LANGUAGES } from '../../i18n'

import { setUser, setUsers, updateCurrentUser, updateUser } from './actions'

import { GET_USER, SUBMIT_EDIT_USER, FETCH_USERS } from './types'

export function* onFetchUser() {
  try {
    yield takeEvery(GET_USER, fetchUser)
  } catch (error) {
    yield put({ type: 'LOGOUT_USER' })
  }
}

function* fetchUser({ payload }) {
  const userId = getItem(KEYS.userId)
  if (!userId) return

  try {
    const user = yield call(api.get, `/users/${userId}`)
    if (user) yield put(setUser(user))
    i18n.changeLanguage(LANGUAGES.get(user.language))
  } catch (error) {
    // Error other than "Not Found"
    if (error && !error.message.includes('No user with id')) {
      console.error(error)
      window.alert(error.message)
      return
    }

    if (payload && payload.redirect) {
      payload.redirect('/avid/profile')
    }
  }
}

function* onFetchUsers() {
  yield takeLatest(FETCH_USERS, fetchUsers)
}

function* fetchUsers() {
  const endpoint = `/users`

  try {
    const users = yield call(api.get, endpoint)
    yield put(setUsers(users))
  } catch (err) {
    console.log(err)
    window.alert(err.message)
  }
}

function* onEditUser() {
  yield takeLatest(SUBMIT_EDIT_USER, editUser)
}

function* editUser({ payload }) {
  const userId = getItem(KEYS.userId)
  let user = yield select(state => getFormValues('user')(state))
  if (!user || !user.name || !user.handle) {
    return
  }

  if (!user.Id) {
    user.id = userId
  }

  try {
    const updatedUser = yield call(api.put, `/users/${user.id}`, user)
    yield put(updateCurrentUser(updatedUser))
    yield put(updateUser(updatedUser))
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

export default function* rootSaga() {
  yield all([onFetchUser(), onEditUser(), onFetchUsers()])
}
