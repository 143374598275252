import React from 'react'
import PropTypes from 'prop-types'

import { Grid, GridItem } from 'common/components'
import { Dropdown } from 'common/components'

import css from './style.module.scss'

const HOUR_OPTIONS = []
const MIN_OPTIONS = []
const MIN_INCREMENT = 15
const COL_WIDTH = '49/100'

const appendZeroAndCoerce = value =>
  value.toString().length > 1 ? `${value}` : `0${value}`

for (let i = 0; i < 24; i += 1) {
  HOUR_OPTIONS.push({ value: i, label: appendZeroAndCoerce(i) })
}

for (let i = 0; i < 60; i += MIN_INCREMENT) {
  MIN_OPTIONS.push({ value: i, label: appendZeroAndCoerce(i) })
}

// TODO: Add check to find closest 1/4 increment in case of irregular minutes
const TimePicker = ({ hour, min, handleOnChange, disabled }) => {
  return (
    <Grid className={css.timePickerGrid}>
      <GridItem col={COL_WIDTH}>
        <div className={css.hour}>
          <Dropdown
            options={HOUR_OPTIONS}
            value={hour}
            narrow
            onChange={e => handleOnChange(e, 'hour')}
            disabled={disabled}
          />
        </div>
      </GridItem>
      <GridItem col={COL_WIDTH}>
        <div className={css.min}>
          <Dropdown
            options={MIN_OPTIONS}
            value={min}
            narrow
            onChange={e => handleOnChange(e, 'min')}
            disabled={disabled}
          />
        </div>
      </GridItem>
    </Grid>
  )
}

TimePicker.propTypes = {
  hour: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleOnChange: PropTypes.func.isRequired
}

export default TimePicker
