import React from 'react'
import Table from '../table'
import { useTranslation } from 'react-i18next'

const DestructiveSizeInfoTable = () => {
  const { t } = useTranslation('infoTip')

  const keys = ['1', '2', '3', '4', '5']

  const columns = [
    {
      title: t('destructiveSize.titles.size'),
      key: 'size'
    },
    {
      title: t('destructiveSize.titles.potential'),
      key: 'potential'
    },
    {
      title: t('destructiveSize.titles.mass'),
      key: 'mass'
    },
    {
      title: t('destructiveSize.titles.pressure'),
      key: 'pressure'
    },
    {
      title: t('destructiveSize.titles.length'),
      key: 'length'
    }
  ]

  const data = keys.map(key => ({
    size: t(`destructiveSize.${key}.size`),
    potential: t(`destructiveSize.${key}.potential`),
    mass: t(`destructiveSize.${key}.mass`),
    pressure: t(`destructiveSize.${key}.pressure`),
    length: t(`destructiveSize.${key}.length`)
  }))

  return (
    <Table
      data={data}
      columns={columns}
    />
  )
}

export default DestructiveSizeInfoTable
