import { takeLatest, takeEvery, all } from 'redux-saga/effects'

import {
  FETCH_SNOWPACK_SUMMARIES,
  SUBMIT_SNOWPACK_WORKSPACE_SUMMARY
} from './types'
import {
  failFetchingSnowpackSummaries,
  setSnowpackSummaries,
  updateSnowpackSummary
} from './actions'
import {
  fetchSummaries,
  submitWorkspaceSummary
} from 'core/summariesSagas'

const SNOWPACK_SUMMARY_TYPE = 'snowpack'

function* onFetchSnowpackSummaries() {
  yield takeLatest(FETCH_SNOWPACK_SUMMARIES, fetchSnowpackSummaries)
}

function* fetchSnowpackSummaries({ payload }) {
  yield fetchSummaries({
    summaryType: SNOWPACK_SUMMARY_TYPE,
    setSummaries: setSnowpackSummaries,
    failFetchingSummaries: failFetchingSnowpackSummaries,
    updateSummary: updateSnowpackSummary
  })
}

function* onSubmitSnowpackWorkspaceSummary() {
  yield takeEvery(
    SUBMIT_SNOWPACK_WORKSPACE_SUMMARY,
    submitSnowpackWorkspaceSummary
  )
}

function* submitSnowpackWorkspaceSummary({ payload }) {
  yield submitWorkspaceSummary({
    formName: payload.formName,
    summaryType: SNOWPACK_SUMMARY_TYPE,
    updateSummary: updateSnowpackSummary
  })
}

export default function* rootSaga() {
  yield all([
    onSubmitSnowpackWorkspaceSummary(),
    onFetchSnowpackSummaries()
  ])
}
