import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import { OptionSet, Option, InfoTip } from 'common/components/form'
import { ConfidenceRatingInfoTable } from 'common/components/form/infoTip/templates'
import css from './style.module.scss'

const ConfidenceOptionSet = props => {
  const { t } = useTranslation('confidence')
  return (
    <div className={css.wrapper}>
      <OptionSet {...props}>
        <Option value={'low'}>{t('LOW')}</Option>
        <Option value={'moderate'}>{t('MODERATE')}</Option>
        <Option value={'high'}>{t('HIGH')}</Option>
      </OptionSet>
      <InfoTip offset={50} defaultPos={{ height: 200 }}>
        <ConfidenceRatingInfoTable className={css.confidenceInfoTable} />
      </InfoTip>
    </div>
  )
}

ConfidenceOptionSet.propTypes = {
  onClick: PropTypes.func.isRequired,
  selection: PropTypes.string
}

ConfidenceOptionSet.defaultProps = {
  selection: 'low'
}

export default ConfidenceOptionSet
