import Immutable from 'seamless-immutable'
import { pick } from 'lodash'
import startOfToday from 'date-fns/start_of_today'
import setHours from 'date-fns/set_hours'
import addDays from 'date-fns/add_days'
import addHours from 'date-fns/add_hours'

import { createReducer } from 'common/utils'
import * as types from './types'

const FIVE_PM = 17
const DEFAULT_HOUR = process.env.REACT_APP_AVID_PUBLISH_TIME || FIVE_PM

const INITIAL_STATE = Immutable({
  isFetching: false,
  productsByKey: {},
  productForecasters: []
})

const reducers = {
  [types.ADD_NEW_REPORT]: addNewReport,
  [types.CANCEL_NEW_REPORT]: cancelNewReport,
  [types.CONFIRM_NEW_REPORT]: confirmNewReport,
  [types.ON_DROPDOWN_CHANGE]: onDropdownChange,
  [types.ON_DATE_CHANGE]: onDateChange,
  [types.FETCH_PRODUCTS]: fetchProducts,
  [types.FETCH_PRODUCTS_FAILED]: fetchProductsFailed,
  [types.SET_PRODUCTS]: setProducts,
  [types.UPDATE_PRODUCT]: updateProduct,
  [types.DELETE_PRODUCT]: deleteProduct,
  [types.REMOVE_PRODUCTS_WITH_DELETED_LOCATION]: removeProductsWithDeletedLocation,
  [types.SET_PRODUCT_FORECASTERS]: setProductForecasters
}

function addNewReport(state) {
  const todayDate = startOfToday()
  const newProduct = {
    key: -1,
    productType: '',
    location: '',
    date: {
      validFrom: setHours(todayDate, DEFAULT_HOUR).toISOString(),
      validTill: addDays(setHours(startOfToday(), DEFAULT_HOUR), 1).toISOString(),
      reviewBy: addHours(setHours(startOfToday(), DEFAULT_HOUR), 14).toISOString()
    },
    user: '',
    status: 'pending',
    version: 1
  }
  const products = {
    productsByKey: {
      [newProduct.key]: newProduct,
      ...state.productsByKey
    }
  }

  return Immutable.merge(state, products)
}

function cancelNewReport(state, { reportKey }) {
  const products = Object.keys(state.productsByKey).filter(
    key => parseInt(key, 10) !== reportKey
  )

  return Immutable.merge(state, {
    productsByKey: pick(state.productsByKey, products)
  })
}

function confirmNewReport(state, payload) {
  const { key, tempKey } = payload
  delete payload.tempKey

  const keysWithoutTempNewProduct = Object.keys(state.productsByKey).filter(
    key => parseInt(key, 10) !== tempKey
  )
  const productsWithoutTempNewProduct = Immutable.merge(state, {
    productsByKey: pick(state.productsByKey, keysWithoutTempNewProduct)
  })
  const products = {
    productsByKey: {
      [key]: payload,
      ...productsWithoutTempNewProduct.productsByKey
    }
  }

  return Immutable.merge(state, products)
}

function onDropdownChange(state, payload) {
  const {
    row: {
      row: { key }
    },
    value,
    accessor
  } = payload

  return Immutable.setIn(state, ['productsByKey', `${key}`, accessor], value)
}

function onDateChange(state, payload) {
  const {
    row: {
      row: { key }
    },
    value,
    accessor
  } = payload
  return Immutable.setIn(
    state,
    ['productsByKey', `${key}`, 'date', accessor],
    value
  )
}

function fetchProducts(state) {
  return Immutable.set(state, 'isFetching', true)
}

function fetchProductsFailed(state, action) {
  return Immutable.set(state, 'isFetching', false)
}

function setProducts(state, payload) {
  if (!payload) return state

  const productsByKey = payload.reduce((productsByKey, product) => {
    productsByKey[product.key] = {
      ...product
    }

    return productsByKey
  }, {})
  const products = {
    isFetching: false,
    productsByKey
  }

  return Immutable.merge(state, products)
}

function setProductForecasters(state, payload) {
  if (!payload) return state

  return Immutable.merge(state, { productForecasters: payload })
}

function updateProduct(state, payload) {
  const { key } = payload
  return Immutable.setIn(state, ['productsByKey', `${key}`], payload)
}

function deleteProduct(state, payload) {
  return Immutable.merge(state, {
    ...state,
    productsByKey: Immutable.without(state.productsByKey, payload)
  })
}

function removeProductsWithDeletedLocation(state, payload) {
  const { locationId } = payload
  const filteredProducts = Immutable.without(
    state.productsByKey,
    value => value.location === locationId
  )
  return Immutable.set(state, 'productsByKey', filteredProducts)
}

export default createReducer(INITIAL_STATE, reducers)
