import React from 'react'
import { useAuth, AUTH_STATE } from './auth'
import LoadingPage from 'common/components/layout/page/loading'
import Routes from 'core/routes'
import Login from 'core/login'

import './i18n'
import 'common/styles'

const App = () => {
  const { state } = useAuth()

  if (state === AUTH_STATE.authenticating) {
    return <LoadingPage />
  }

  if (state === AUTH_STATE.unauthenticated) {
    return <Login />
  }

  if (state === AUTH_STATE.authenticated) {
    return <Routes />
  }

  return null
}

export default App
