import React from 'react'

import css from './style.module.scss'

const HomeButton = () => (
  <a href={'/avid/products'} target="_blank" rel="noopener noreferrer">
    <img className={css.logo} src="/assets/logo.svg" alt="home" />
  </a>
)

export default HomeButton
