const storage = window.localStorage

class StorageAccessor {
  constructor(key) {
    this.key = key
    this.storage = storage
  }

  set(value) {
    try {
      this.storage.setItem(this.key)
    } catch (e) {
      console.log(e)
    }
  }

  get() {
    try {
      const item = this.storage.getItem(this.key)
      if (item === null) {
        return undefined
      }

      return item
    } catch (e) {
      console.log(e)
    }
  }
}

const accessTokenStorage = new StorageAccessor('accessToken')

export default accessTokenStorage
