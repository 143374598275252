import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Link } from 'common/components'
import { createProductTitle } from 'common/helpers/createProductTitle'
import { TextCell } from 'common/components/table/cells'
import { PUBLISHED_STATUS } from 'core/product/config/constants'

import css from './style.module.scss'

const ProductTitleCell = ({ locations, userId }) => rowData => {
  const {
    row: { key, user, productType, status, location: locationId, validFrom }
  } = rowData
  const locationName = get(locations[locationId], 'feature.properties.handle')
  const productTitle = createProductTitle(productType, locationName, validFrom)

  if (!key || (userId !== user && status !== PUBLISHED_STATUS))
    return <TextCell noColor>{productTitle}</TextCell>

  return (
    <Link
      to={`/avid/products/edit/${key}`}
      target="_blank"
      className={css.productTitleCell}
    >
      <TextCell noColor>{productTitle}</TextCell>
    </Link>
  )
}

ProductTitleCell.propTypes = {
  locations: PropTypes.array.isRequired,
  rowData: PropTypes.object.isRequired
}

export default ProductTitleCell
