import React from 'react'
import PropTypes from 'prop-types'
import { classList } from 'common/utils'
import InputWrapper from '../inputWrapper'
import css from './style.module.scss'

/**
 * Controlled input component. Is compatible with redux-form Field
 */
const TextField = props => {
  const classNames = classList(
    css.input,
    props.globalError ? css.globalError : null
  )

  const style = {}

  if (props.maxInputWidth) {
    style.maxWidth = props.maxInputWidth
  }
  return (
    <InputWrapper {...props.meta}>
      <input
        style={style}
        className={classNames}
        {...props.input}
        type={props.type}
        maxLength={props.maxLength}
        autoComplete={props.disableAutocomplete ? 'off' : 'on'}
        // for type='number'
        step={props.step}
        max={props.max}
        min={props.min}
        disabled={props.disabled}
        placeholder={props.placeholder}
      />
    </InputWrapper>
  )
}

TextField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  maxInputWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disableAutocomplete: PropTypes.bool,
  globalError: PropTypes.string
}

TextField.defaultProps = {
  type: 'text',
  disableAutoComplete: false,
  disabled: false,
  placeholder: null
}

export default TextField
