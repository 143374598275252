import Immutable from 'seamless-immutable'
import { convertToRaw, ContentState } from 'draft-js'

import { createReducer } from 'common/utils'
import * as types from './types'

const WORKSPACES = [1, 2, 3, 4]

const INITIAL_STATE = Immutable({
  isFetching: false,
  confidenceByDays: WORKSPACES.reduce((acc, i) => {
    acc[i] = {
      confidenceRating: 'notdefined',
      comments: [],
      statements: []
    }
    return acc
  }, {})
})

const reducers = {
  [types.FETCH_CONFIDENCE]: fetchConfidence,
  [types.FAIL_FETCHING_CONFIDENCE]: failFetchingConfidence,
  [types.SET_CONFIDENCE]: setConfidence,
  [types.SUBMIT_CONFIDENCE]: updateConfidence
}

function fetchConfidence(state) {
  return Immutable.set(state, 'isFetching', true)
}

function failFetchingConfidence(state) {
  return Immutable.set(state, 'isFetching', false)
}

function setConfidence(state, payload) {
  return Immutable.merge(state, {
    isFetching: false,
    confidenceByDays: payload.reduce((acc, data) => {
      acc[data.workspace] = {
        ...data,
        /*  The comments textboxes should be initialized with an empty DraftJS
         *  ContentState object. This ensures accurate object comparisons when
         *  checking if the text has changed value.
         */
        comments: data.comments.map(lang => lang[1]
          ? lang
          : [lang[0], convertToRaw(ContentState.createFromText(''))]
        )
      }
      return acc
    }, {})
  })
}

function updateConfidence(state, confidence) {
  return Immutable.setIn(
    state,
    ['confidenceByDays', `${confidence.workspace}`],
    confidence
  )
}

export default createReducer(INITIAL_STATE, reducers)
