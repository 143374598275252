import React from 'react'
import { useTranslation } from 'react-i18next'
import typography from 'common/components/typography/style.module.scss'
import classnames from 'classnames/bind'
import css from './style.module.scss'
const cx = classnames.bind(css)

const CharactersRemaining = ({ count, limit = 280, className }) => {
  const { t } = useTranslation('common')
  const remaining = limit - count

  return (
    <span
      className={cx(className, typography.Muted, { overLimit: remaining < 0 })}
    >
      {`${remaining} ${t('characters remaining')}`}
    </span>
  )
}

export default CharactersRemaining
