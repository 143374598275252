export const NEW_PRODUCT_STATUS = 'pending'

export const QUICK_START_STATUS_FILTER = {
  id: 'quickstart',
  display: 'notPublished',
  statuses: ['empty', 'initialized', 'draft']
}

export const FIELDS_CHANGES = {
  edit: {
    pending: ['product', 'validFrom', 'validTill', 'user'],
    empty: ['validFrom', 'validTill', 'user'],
    initialized: ['validFrom', 'validTill', 'user'],
    draft: ['validTill', 'user'],
    published: []
  },
  delete: {
    pending: true,
    empty: true,
    initialized: true,
    draft: true,
    published: false
  },
  carryForward: {
    pending: true,
    empty: true,
    initialized: false,
    draft: true,
    published: false
  }
}
