import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  getCurrentProductValidFromDate,
  getCurrentProductStatus,
  getCurrentProductUser
} from 'core/product/selectors'
import { createNewProductVersion } from 'core/product/actions'
import { getCurrentLocationName } from 'core/locations/selectors'
import { getUserLang } from 'core/users/selectors'
import { formatDate } from 'common/helpers/formatDate'
import { PUBLISHED_STATUS } from 'core/product/config/constants'

import { Button } from 'common/components'
import HeaderTitle from 'core/product/components/headerTitle'
import LastTimeSaved from 'core/product/components/lastTimeSaved'
import VisibleDays from 'core/product/components/visibleDays'

import ProductStatus from './productStatus'
import ChatLink from './chatLink'
import HomeButton from './homeButton'

import css from './style.module.scss'

const ProductHeader = ({
  location,
  publishDate,
  status,
  users,
  currentUser,
  lastTimeSaved,
  language,
  createNewVersion
}) => {
  const { t } = useTranslation('products')
  return (
    <div className={css.container}>
      <div className={css.home}>
        <HomeButton />
        {status !== PUBLISHED_STATUS ? (
          <LastTimeSaved date={lastTimeSaved} language={language} />
        ) : (
          <Button className={css.newVersion} onClick={createNewVersion}>
            {t('header.createNewProduct')}
          </Button>
        )}
      </div>
      <div className={css.visibleDays}>
        <HeaderTitle title="visibleDays" />
        <VisibleDays />
      </div>
      <div className={css.locationDate}>
        <HeaderTitle title="location">{location}</HeaderTitle>
        <HeaderTitle title="date">
          {formatDate(publishDate)}
        </HeaderTitle>
      </div>
      <div className={css.additionalInfo}>
        <HeaderTitle title="forecaster">
          {users ? users[currentUser] : ''}
        </HeaderTitle>
        <ProductStatus />
        <ChatLink />
      </div>
    </div>
  )
}

ProductHeader.propTypes = {
  location: PropTypes.string,
  publishDate: PropTypes.string,
  currentUser: PropTypes.string,
  users: PropTypes.object,
  language: PropTypes.string,
  lastTimeSaved: PropTypes.instanceOf(Date),
  createNewVersion: PropTypes.func
}

const mapStateToProps = state => ({
  location: getCurrentLocationName(state, {
    locationId: state.product.currentProduct.location
  }),
  publishDate: getCurrentProductValidFromDate(state),
  currentUser: getCurrentProductUser(state),
  users: state.users.usersById,
  language: getUserLang(state),
  lastTimeSaved: state.product.lastTimeSaved,
  status: getCurrentProductStatus(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  createNewVersion: () => dispatch(createNewProductVersion({
    component: ownProps.component
  }))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductHeader)
