import { createAction } from 'common/utils'
import {
  FETCH_WEATHER_SUMMARIES,
  FAIL_FETCHING_WEATHER_SUMMARIES,
  SET_WEATHER_SUMMARIES,
  SUBMIT_WEATHER_WORKSPACE_SUMMARY,
  ADD_NEW_WEATHER_SUMMARY,
  UPDATE_WEATHER_SUMMARY
} from './types'

export const fetchWeatherSummaries = payload =>
  createAction(FETCH_WEATHER_SUMMARIES, payload)
export const failFetchingWeatherSummaries = () =>
  createAction(FAIL_FETCHING_WEATHER_SUMMARIES)
export const setWeatherSummaries = payload =>
  createAction(SET_WEATHER_SUMMARIES, payload)
export const submitWeatherWorkspaceSummary = payload =>
  createAction(SUBMIT_WEATHER_WORKSPACE_SUMMARY, payload)
export const addNewWeatherSummary = payload =>
  createAction(ADD_NEW_WEATHER_SUMMARY, payload)
export const updateWeatherSummary = payload =>
  createAction(UPDATE_WEATHER_SUMMARY, payload)
