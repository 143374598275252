import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, find } from 'lodash'

import HeaderTitle from 'core/product/components/headerTitle'
import DefinedType from 'common/data/definedTypes'
import { getCurrentProductStatus } from 'core/product/selectors'

const ProductStatus = ({ productStatus }) => (
  <DefinedType type="reportstatuses">
    {({ data }) => {
      const status = get(find(data, { id: productStatus }), 'display')
      return <HeaderTitle title="status">{status}</HeaderTitle>
    }}
  </DefinedType>
)

ProductStatus.propTypes = {
  productStatus: PropTypes.string
}

const mapStateToProps = state => ({
  productStatus: getCurrentProductStatus(state)
})

export default connect(mapStateToProps)(ProductStatus)
