import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import css from './style.module.scss'

ProgressBar.propTypes = {
  /**
   * The value of the progress bar, 0 -> 100
   */
  value: PropTypes.number,
  children: PropTypes.node,
  /**
   * Optional: add another className
   */
  className: PropTypes.string
}

export default function ProgressBar({
  value,
  children = `${value} %`,
  className
}) {
  value = Math.max(Math.min(value, 100), 0)

  return (
    <progress
      max="100"
      min="0"
      value={value}
      className={classnames(className, css.ProgressBar)}
    >
      {children || value}
    </progress>
  )
}
