import React from 'react'
import Table from '../table'
import { useTranslation } from 'react-i18next'
import css from './style.module.scss'

const DangerRatingInfoTable = ({ parentId }) => {
  const { t } = useTranslation('infoTip')

  const keys = {
    0: 'extreme',
    1: 'high',
    2: 'considerable',
    3: 'moderate',
    4: 'low',
    5: 'norating'
  }
  const values = Object.values(keys)

  const columns = [
    {
      title: t('dangerRating.titles.dangerLevel'),
      key: 'dangerLevel'
    },
    {
      key: 'svg',
      title: ''
    },
    {
      title: t('dangerRating.titles.travelAdvice'),
      key: 'travelAdvice'
    },
    {
      title: t('dangerRating.titles.likelihood'),
      key: 'likelihood'
    },
    {
      title: t('dangerRating.titles.sizeDistribution'),
      key: 'sizeDistribution'
    }
  ]

  const data = values.map(key => ({
    label: t(`dangerRating.${key}.dangerLevel`),
    svg: (
      <div
        className={css.dangerRating}
        style={{
          backgroundImage: `url("/assets/danger-ratings/${key}.svg")`
        }}
      />
    ),
    travelAdvice: t(`dangerRating.${key}.travelAdvice`),
    likelihood: t(`dangerRating.${key}.likelihood`),
    sizeDistribution: t(`dangerRating.${key}.sizeDistribution`)
  }))

  return <Table data={data} columns={columns} />
}

export default DangerRatingInfoTable
