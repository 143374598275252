import React, { useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import css from './style.module.scss'
const cx = classnames.bind(css)

const ContextMenu = ({
  closeMenu,
  openMenu,
  menuRef,
  children,
  visible,
  parentDOMId
}) => {
  const handleClick = useCallback(closeMenu, [])
  const handleEsc = useCallback(event => {
    if (event.keyCode === 27) {
      closeMenu()
    }
  }, [closeMenu])

  useEffect(() => {
    document.addEventListener('contextmenu', openMenu)
    document.addEventListener('click', handleClick)
    document.addEventListener('keyup', handleEsc)

    return () => {
      document.removeEventListener('contextmenu', openMenu)
      document.addEventListener('click', handleClick)
      document.addEventListener('keyup', handleEsc)
    }
  }, [handleClick, handleEsc, openMenu])

  const renderContextMenu = () => {
    return (
      <div className={cx(css.contextMenu, { hidden: !visible })} ref={menuRef}>
        {children}
      </div>
    )
  }

  if (!parentDOMId)
    return renderContextMenu()

  const parent = document.getElementById(parentDOMId)

  if(!parent)
    return renderContextMenu()

  return (
    ReactDOM.createPortal(renderContextMenu(), parent)
  )
}

ContextMenu.propTypes = {
  /* A click handler for the "contextmenu" (right) click action.
   * Eg. a function to open the ContextMenu
   */
  openMenu: PropTypes.func.isRequired,
  /* A click handler for a "regular" (left) click action and an ESC key press.
   * Eg. a function to close the ContextMenu
   */
  closeMenu: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  /* ref set for the element in parent component */
  menuRef: PropTypes.func.isRequired,
  /* The ContextMenu can be set to render as the child of a different DOM
   * element than it's parent component. In order to do that, an ID for that
   * DOM element needs to be passed to the ContextMenu.
   */
  parentDOMId: PropTypes.string,
  children: PropTypes.array
}

export default ContextMenu
