import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Nav } from 'common/components'

import CreateLocation from './create'
import EditLocation from './edit'
import ListLocations from './list'

/**
 * Note: if you want anything to be common to all pages import here (global messaging etc)
 */
const Locations = () => (
  <div>
    <Nav />
    <main>
      <Switch>
        <Route exact path="/avid/locations" component={ListLocations} />
        <Route path="/avid/locations/edit/:id" component={EditLocation} />
        <Route path="/avid/locations/new" component={CreateLocation} />
      </Switch>
    </main>
  </div>
)

export default Locations
