import { createActions, handleActions } from 'redux-actions'
import { put, takeLatest, select, call, delay } from 'redux-saga/effects'
import * as utils from 'dot-prop-immutable'
import { convertToRaw, ContentState } from 'draft-js'
import isEqual from 'lodash/isEqual'
import * as api from 'common/api'
import Status from 'common/helpers/Status'
import {
  FETCH_ACTIONS,
  delegateReducerForFetch,
  statusReducerFactory
} from 'common/helpers/store'
import { getCurrentProductKey } from 'core/product/selectors'

// Defaults
export const DEFAULT = {
  data: {},
  received: {},
  statuses: {
    read: new Status(),
    save: new Status()
  }
}

// Types
const PREFIX = 'avid/communications/'
export const READ = PREFIX + 'READ'
const SAVE = PREFIX + 'SAVE'
const UPDATE = PREFIX + 'UPDATE'
const RECEIVE = PREFIX + 'RECEIVE'
const SUBMIT = PREFIX + 'SUBMIT'

// Actions
const actions = createActions({
  [READ]: FETCH_ACTIONS,
  [SAVE]: FETCH_ACTIONS,
  [UPDATE]: undefined,
  [RECEIVE]: undefined,
  [SUBMIT]: undefined
}).avid.communications

export const read = actions.read.requested
export const { update, submit } = actions

// Reducers
export default handleActions(
  new Map([
    [RECEIVE, delegateToReportReducer],
    [SUBMIT, delegateToReportReducer],
    [UPDATE, delegateToReportReducer],
    [READ, delegateReducerForFetch(delegateToReportReducer)],
    [SAVE, delegateReducerForFetch(delegateToReportReducer)]
  ]),
  {}
)
const reportReducer = handleActions(
  new Map([
    [READ, statusReducerFactory('statuses.read')],
    [SAVE, statusReducerFactory('statuses.save')],
    [
      UPDATE,
      (state, action) => {
        const { data } = action.payload
        return utils.set(state, `${data.reportKey}.data`, data)
      }
    ],
    [
      RECEIVE,
      (state, action) => {
        const { data } = action.payload
        const communications = {
          ...data,
          /*  The headline textboxes should be initialized with an empty DraftJS
           *  ContentState object. This ensures accurate object comparisons when
           *  checking if the text has changed value.
           */
          headline: data.headline.map(lang =>
            lang[1]
              ? lang
              : [lang[0], convertToRaw(ContentState.createFromText(''))]
          ),
          sms: data.sms.map(lang =>
            lang[1]
              ? lang
              : [lang[0], convertToRaw(ContentState.createFromText(''))]
          )
        }

        state = utils.set(state, `${data.reportKey}.data`, {
          ...communications
        })
        state = utils.set(state, `${data.reportKey}.received`, {
          ...communications
        })

        return state
      }
    ]
  ]),
  DEFAULT
)

// Getter
export function get(state, reportKey, defaultValue = DEFAULT) {
  return utils.get(state, `communications.${reportKey}`, defaultValue)
}

// Side effects
function* readCommunications() {
  const reportKey = yield select(getCurrentProductKey)
  const params = yield select(get, reportKey)
  if (!params) return

  const { statuses } = params
  if (statuses.read.isPending || statuses.read.isFulfilled) {
    return
  }

  try {
    yield put(actions.read.pending(reportKey))

    const data = yield call(api.get, `communications/${reportKey}`)

    yield put(actions.receive({ data }))
    yield put(actions.read.fulfilled(reportKey))
  } catch (error) {
    console.error(error)
    //window.alert(error.message)
    yield put(actions.read.rejected(reportKey))
  }
}

function* submitCommunications(action) {
  const { reset } = action.payload
  const reportKey = yield select(getCurrentProductKey)

  const { data, received } = yield select(get, reportKey)
  if (isEqual(data, received)) return

  try {
    yield put(actions.save.pending(reportKey))

    const updatedComumunications = yield call(
      api.put,
      `communications/${reportKey}`,
      data
    )

    if (updatedComumunications) {
      yield put(actions.receive({ data: updatedComumunications }))
      yield put(actions.save.fulfilled(reportKey))
    }
  } catch (error) {
    console.error(error)
    window.alert(error.message)

    yield put(actions.save.rejected(reportKey))

    /* Reset the form and then remove error message after 3s */
    yield call(delay, 3000)
    yield put(actions.receive({ data: received }))
    reset(received)
  }
}

export function* sagas() {
  yield takeLatest(read, readCommunications)
  yield takeLatest(submit, submitCommunications)
}

// Utils
function delegateToReportReducer(state, action) {
  if (!action.payload) return state

  const report = utils.get(state, action.payload)
  return utils.set(state, action.payload, reportReducer(report, action))
}
