import React from 'react'
import Table from '../table'
import { useTranslation } from 'react-i18next'

const AvalancheSensitivityInfoTable = () => {
  const { t } = useTranslation('infoTip')

  const keys = ['unreactive', 'stubborn', 'reactive', 'touchy', 'observation']

  const columns = [
    {
      title: t('sensitivity.titles.sensitivity'),
      key: 'sensitivity'
    },
    {
      title: t('sensitivity.titles.release'),
      key: 'release'
    },
    {
      title: t('sensitivity.titles.humanTriggers'),
      key: 'humanTriggers'
    },
    {
      title: t('sensitivity.titles.size'),
      key: 'size'
    },
    {
      title: t('sensitivity.titles.result'),
      key: 'result'
    },
    {
      title: t('sensitivity.titles.corniceTriggers'),
      key: 'corniceTriggers'
    }
  ]

  const data = keys.map(key => ({
    sensitivity: t(`sensitivity.${key}.sensitivity`),
    release: t(`sensitivity.${key}.release`),
    humanTriggers: t(`sensitivity.${key}.humanTriggers`),
    size: t(`sensitivity.${key}.explosiveTriggersSize`),
    result: t(`sensitivity.${key}.explosiveTriggersResult`),
    corniceTriggers: t(`sensitivity.${key}.corniceTriggers`)
  }))

  return <Table data={data} columns={columns} />
}

export default AvalancheSensitivityInfoTable
