import React from 'react'
import classnames from 'classnames/bind'
import css from './style.module.scss'
const cx = classnames.bind(css)

const Table = ({ size, columns, data, className }) => (
  <table className={cx('infoTable', className)}>
    <thead>
      <tr>
        {columns.map(column => (
          <th key={column.key}>{column.title}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((row, index) => (
        <tr key={index}>
          {Object.keys(row).map((key, i) =>
            i === 0 ? (
              <th key={key}>{row[key]}</th>
            ) : (
              <td key={key}>{row[key]}</td>
            )
          )}
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
