import React from 'react'
import PropTypes from 'prop-types'

import classList from 'common/utils/classList'

import css from './style.module.scss'

/**
 * SidebarPageContainer
 * A presentational component that allows us to force page
 * content to fill entire height of window, and leverages
 * a flex layout to ensure child components take up 100% height
 * (via justify-content: stretch).
 */

const SidebarPageContainer = ({ children, sidebarAlignment, headerSize }) => {
  const classes = classList(
    css.container,
    css[sidebarAlignment],
    css[headerSize]
  )

  return <div className={classes}>{children}</div>
}

SidebarPageContainer.defaultProps = {
  sidebarAlignment: 'left',
  headerSize: 'regular'
}

SidebarPageContainer.propTypes = {
  children: PropTypes.node,
  sidebarAlignment: PropTypes.oneOf(['right', 'left']),
  headerSize: PropTypes.oneOf(['regular', 'tall']),
  backgroundColor: PropTypes.oneOf(['white', 'grey'])
}

export default SidebarPageContainer
