import { put, call, all, select, takeLatest } from 'redux-saga/effects'

import * as api from 'common/api'

import { setCriticalFactors } from './actions'
import { getUserLang } from 'core/users/selectors'

import {
  FETCH_CRITICAL_FACTORS,
  SUBMIT_CRITICAL_FACTORS,
  UPDATE_CRITICAL_FACTORS,
  RESET_CRITICAL_FACTOR,
  CARRY_FORWARD_CRITICAL_FACTOR
} from './types'

import { getFormValues, isDirty } from 'redux-form'
import { CRITICAL_FACTORS_FORM_NAME } from 'core/product/config/constants'
import { getCurrentProductKey } from 'core/product/selectors'
import {
  initialFactors,
  factorsReducer
} from 'core/dangerRatingCriticalFactors/components/criticalFactors/reducer'

function* onFetchCriticalFactors() {
  yield takeLatest(FETCH_CRITICAL_FACTORS, fetchCriticalFactors)
}

function* fetchCriticalFactors() {
  try {
    const reportKey = yield select(getCurrentProductKey)
    const data = yield call(api.get, `/criticalfactors/${reportKey}`)
    if (!data) return

    yield put(setCriticalFactors(data))
  } catch (err) {
    console.error(err)
    //window.alert(err.message)
  }
}

function* putCriticalFactors(url, data) {
  try {
    const updatedData = yield call(api.put, url, data)
    if (!updatedData) return

    return yield put({
      type: UPDATE_CRITICAL_FACTORS,
      payload: {
        ...updatedData,
        factors: updatedData.factors.reduce(factorsReducer, {})
      }
    })
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

function* onSubmitCriticalFactors() {
  yield takeLatest(SUBMIT_CRITICAL_FACTORS, submitCriticalFactors)
}

function* submitCriticalFactors({ payload }) {
  const { workspace } = payload
  const reportKey = yield select(getCurrentProductKey)
  const formName = `${CRITICAL_FACTORS_FORM_NAME}_day${workspace}`

  const dirty = yield select(state => isDirty(formName)(state))
  if (!dirty) return

  const url = `criticalfactors/${reportKey}/${workspace}`
  const values = yield select(state => getFormValues(formName)(state))
  if (!values) return

  yield putCriticalFactors(url, {
    reportKey,
    workspace,
    dangerRating: values.dangerRating,
    factors: Object.keys(values.factors).map(factor => [
      factor,
      values.factors[factor]
    ]),
    comments: values.comments
  })
}

function* onResetCriticalFactor() {
  yield takeLatest(RESET_CRITICAL_FACTOR, resetCriticalFactor)
}

function* resetCriticalFactor({ payload }) {
  const { workspace } = payload
  const reportKey = yield select(getCurrentProductKey)
  const url = `criticalfactors/${reportKey}/${workspace}`
  const language = yield select(state => getUserLang(state))

  yield putCriticalFactors(url, {
    reportKey,
    workspace,
    dangerRating: 'norating',
    factors: initialFactors,
    comments: [[language, {}]]
  })
}

function* onCarryForwardCriticalFactor() {
  yield takeLatest(CARRY_FORWARD_CRITICAL_FACTOR, carryForwardCriticalFactor)
}

function* carryForwardCriticalFactor({ payload }) {
  const { workspace } = payload
  const reportKey = yield select(getCurrentProductKey)
  const formName = `${CRITICAL_FACTORS_FORM_NAME}_day${workspace}`
  const url = `criticalfactors/${reportKey}/${workspace + 1}`
  const values = yield select(state => getFormValues(formName)(state))
  if (!values) return

  yield putCriticalFactors(url, {
    reportKey,
    workspace: workspace + 1,
    dangerRating: values.dangerRating,
    factors: Object.keys(values.factors).map(factor => [
      factor,
      values.factors[factor]
    ]),
    comments: values.comments
  })
}

export default function* rootSaga() {
  yield all([
    onFetchCriticalFactors(),
    onSubmitCriticalFactors(),
    onResetCriticalFactor(),
    onCarryForwardCriticalFactor()
  ])
}
