import {
  AVALANCHE,
  AVALANCHE_PROBLEMS,
  SNOWPACK,
  WEAK_LAYERS,
  WEATHER,
  DANGER_RATINGS,
  REVIEW_MESSAGING,
  CONFIDENCE,
  COMMUNICATIONS,
  PUBLISH
} from './components'

export const FORECAST_COMPONENTS = [
  WEATHER,
  SNOWPACK,
  AVALANCHE,
  WEAK_LAYERS,
  AVALANCHE_PROBLEMS,
  DANGER_RATINGS,
  CONFIDENCE,
  COMMUNICATIONS,
  REVIEW_MESSAGING,
  PUBLISH
]

export const FORECAST_NAVIGATION = FORECAST_COMPONENTS.map(
  component => component.route
)
