import Immutable from 'seamless-immutable'

import { createReducer } from 'common/utils'
import { deleteItem } from 'common/helpers/orderedList'
import * as types from './types'

const WORKSPACES = [1, 2, 3, 4]
const CAUTION_LEVELS = ['normalcaution', 'extracaution', 'notrecommended']

const INITIAL_STATE = Immutable({
  isFetching: false,
  advisories: WORKSPACES.reduce(
    (workspaces, i) => ({
      ...workspaces,
      [i]: CAUTION_LEVELS.reduce(
        (levels, level) => ({
          ...levels,
          [level]: {}
        }),
        {}
      )
    }),
    {}
  )
})

const fetchAdvisories = state => {
  return Immutable.set(state, 'isFetching', true)
}

const failFetchAdvisories = state => {
  return Immutable.set(state, 'isFetching', false)
}

const setAdvisories = (state, advisories) => {
  return Immutable.merge(state, {
    isFetching: false,
    advisories
  })
}

const deleteAdvisory = (state, { workspace, index, cautionLevel }) => {
  const statements = deleteItem(
    state.advisories[workspace][cautionLevel].statements,
    index
  )

  return Immutable.setIn(
    state,
    ['advisories', workspace, cautionLevel, 'statements'],
    statements
  )
}

const updateAdvisories = (state, { workspace, statements, cautionLevel }) => {
  return Immutable.setIn(
    state,
    ['advisories', workspace, cautionLevel, 'statements'],
    statements
  )
}

const reducers = {
  [types.FETCH_ADVISORIES]: fetchAdvisories,
  [types.FAIL_FETCH_ADVISORIES]: failFetchAdvisories,
  [types.SET_ADVISORIES]: setAdvisories,
  [types.DELETE_ADVISORY]: deleteAdvisory,
  [types.UPDATE_ADVISORIES]: updateAdvisories
}

export default createReducer(INITIAL_STATE, reducers)
