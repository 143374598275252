import { createSelector } from 'reselect'

export const allCompleted = createSelector(
  root,
  components => {
    return Object.values(components)
      .filter(component => typeof component.completed === 'boolean')
      .every(component => component.completed)
  }
)

export function get(state, { type }) {
  return root(state)[type]
}

// Utils
function root(state) {
  return state.components
}
