import React from 'react'
import PropTypes from 'prop-types'
import { get, find } from 'lodash'

import { DropdownCell, TextCell } from 'common/components/table/cells'

import { NEW_PRODUCT_STATUS } from '../constants'

const ProductTypeCell = (products, onDropdownChange) => rowData => {
  const {
    row: { productType, status }
  } = rowData

  if (status === NEW_PRODUCT_STATUS) {
    const options = [{ value: '', label: ' ' }].concat(products)
    return (
     <div id="HACK_TO_REMOVE_ADVISORY">
      <DropdownCell
        rowData={rowData}
        columnAccessor={'productType'}
        options={options}
        onDropdownChange={onDropdownChange}
      />
      </div>
    )
  }

  return (
    <TextCell>
      {get(find(products, { value: productType }), 'label', '')}
    </TextCell>
  )
}

ProductTypeCell.propTypes = {
  options: PropTypes.object.isRequired,
  onDropdownChange: PropTypes.func.isRequired
}

export default ProductTypeCell
