import { createAction } from 'common/utils'
import {
  ADD_NEW_REPORT,
  CANCEL_NEW_REPORT,
  SUBMIT_NEW_REPORT,
  CONFIRM_NEW_REPORT,
  CARRY_FORWARD_REPORT_DATA,
  DELETE_REPORT,
  ON_DROPDOWN_CHANGE,
  ON_DATE_CHANGE,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_FAILED,
  SET_PRODUCTS,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  SET_PRODUCT_FORECASTERS,
  REMOVE_PRODUCTS_WITH_DELETED_LOCATION
} from './types'

export const addNewReport = payload => createAction(ADD_NEW_REPORT, payload)

// todo: this will fire off a watcher saga once api is ready
// keep the entire row object around for now
export const submitNewReport = payload =>
  createAction(SUBMIT_NEW_REPORT, payload)
export const confirmNewReport = payload =>
  createAction(CONFIRM_NEW_REPORT, payload)
export const cancelNewReport = payload =>
  createAction(CANCEL_NEW_REPORT, payload)
export const deleteReport = payload => createAction(DELETE_REPORT, payload)
export const carryForwardReport = payload =>
  createAction(CARRY_FORWARD_REPORT_DATA, payload)

export const onDropdownChangeAction = (row, e, accessor) => {
  return createAction(ON_DROPDOWN_CHANGE, {
    row,
    value: e.currentTarget.value,
    accessor
  })
}
export const onDateChange = (row, newDay, accessor) =>
  createAction(ON_DATE_CHANGE, { row, value: newDay, accessor })

export const fetchProducts = payload => createAction(FETCH_PRODUCTS, payload)
export const fetchProductsFailed = payload =>
  createAction(FETCH_PRODUCTS_FAILED, payload)

export const setProducts = payload => createAction(SET_PRODUCTS, payload)

export const updateProduct = payload => createAction(UPDATE_PRODUCT, payload)
export const deleteProduct = payload => createAction(DELETE_PRODUCT, payload)
export const removeProductsWithDeletedLocation = payload =>
  createAction(REMOVE_PRODUCTS_WITH_DELETED_LOCATION, payload)

export const setProductForecasters = payload =>
  createAction(SET_PRODUCT_FORECASTERS, payload)

export const resetCurrentWorkspace = payload => createAction()
