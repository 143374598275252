// TODO: This should ideally be location handle, hence no need for camel casing
import camelCase from 'lodash/camelCase'

import { formatDate } from './formatDate'

const shortCodes = {
  forecast: 'fx',
  advisory: 'ad',
  offseason: 'off'
}

/**
 * Accepts a product type, locationHandle and date, to return a
 * formatted product title.
 *
 * createProductTitle('forecast', 'kelowna', Date obj)
 * > fx_kelowna_04-04
 *
 */
export const createProductTitle = (
  productTypeValue,
  locationHandle,
  publishDate
) => {
  let productTitle = ''
  if (productTypeValue.length > 0)
    productTitle = `${shortCodes[productTypeValue]}_`
  if (locationHandle) productTitle += `${camelCase(locationHandle)}_`
  if (publishDate) productTitle += `${formatDate(publishDate)}`

  return productTitle
}
