import React from 'react'
import Container from './Container'

export default Container

export function Elevations(props) {
  return <Container {...props} type="elevations" />
}
export function DangerRatings(props) {
  return <Container {...props} type="dangerratings" />
}
