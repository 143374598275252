import React from 'react'
import { Select } from 'common/components/form'

const SelectWithBlankOption = ({ options, disableBlankOption, ...rest }) => {
  const optionsWithBlank = [
    {
      value: 0,
      label: ' ',
      disabled: disableBlankOption
    }
  ].concat(options)
  return <Select {...rest} options={optionsWithBlank} />
}

export default SelectWithBlankOption
