import { takeLatest, all, call, put, select } from 'redux-saga/effects'

import * as api from 'common/api'
import { FETCH_SEASON, SUBMIT_SEASON } from './types'
import { setSeason, failFetchSeason } from './actions'
import { getOffSeason } from './selectors'
import { getCurrentProductKey } from 'core/product/selectors'

function* onFetchSeason() {
  yield takeLatest(FETCH_SEASON, fetchSeason)
}

function* fetchSeason() {
  const reportKey = yield select(getCurrentProductKey)
  try {
    const { season, comments } = yield call(
      api.get,
      `offseasonmessage/${reportKey}`
    )

    yield put(setSeason({ season, comments }))
  } catch (err) {
    yield put(failFetchSeason(err))
    console.error(err)
    //window.alert(err.message)
  }
}

function* onSubmitSeason() {
  yield takeLatest(SUBMIT_SEASON, submitSeason)
}

function* submitSeason({ payload }) {
  const reportKey = yield select(getCurrentProductKey)
  const offseason = yield select(state => getOffSeason(state))
  if (!offseason) return

  try {
    yield call(api.put, `offseasonmessage/${reportKey}`, {
      ...offseason,
      reportKey
    })
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

export default function* rootSaga() {
  yield all([onFetchSeason(), onSubmitSeason()])
}
