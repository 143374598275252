import {
  WEATHER,
  OBSERVATION,
  DANGER_RATING_CRITICAL_FACTORS,
  TERRAIN_AND_TRAVEL_ADVISORY,
  CONFIDENCE,
  COMMUNICATIONS,
  REVIEW_MESSAGING,
  PUBLISH
} from './components'

export const ADVISORY_COMPONENTS = [
  WEATHER,
  OBSERVATION,
  DANGER_RATING_CRITICAL_FACTORS,
  TERRAIN_AND_TRAVEL_ADVISORY,
  CONFIDENCE,
  COMMUNICATIONS,
  REVIEW_MESSAGING,
  PUBLISH
]

export const ADVISORY_NAVIGATION = ADVISORY_COMPONENTS.map(
  component => component.route
)
