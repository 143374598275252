import React, { useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { Rnd } from 'react-rnd'
import HelpCircleIcon from 'mdi-react/HelpCircleIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import css from './style.module.scss'
const cx = classnames.bind(css)

const InfoTip = ({ children, className, defaultPos, offset = 16, label }) => {
  /**
   *  Position the portal under the root element so that it appears above
   *  everything else.
   */
  const root = document.getElementById('root')
  const [showTooltip, setTooltipVisibility] = useState(false)
  const tooltipRef = useRef()
  const [pos, setPos] = useState()

  React.useEffect(() => {
    /**
     *  A function to ensure that the portal shrinks if the right edge of it
     *  hits the right edge of the window if the window is being resized.
     */
    function resize(event) {
      setPos(prevPos => {
        const diff = prevPos.width - event.currentTarget.innerWidth
        if (diff > 0) {
          return {
            ...prevPos,
            width: event.currentTarget.innerWidth - 40
          }
        } else {
          return prevPos
        }
      })
    }

    /**
     *  Sets the portal's default size and position.
     */
    if (tooltipRef.current) {
      const bounds = tooltipRef.current.getBoundingClientRect()
      setPos({
        y: bounds.y + offset,
        x: 20,
        width: window.innerWidth - 40,
        height: window.innerHeight - bounds.y - offset - 20,
        ...defaultPos
      })
    }

    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [tooltipRef, defaultPos, offset])

  function handleClick() {
    setTooltipVisibility(!showTooltip)
  }

  return (
    <div className={css.infoTip}>
      <span ref={tooltipRef}>
        <HelpCircleIcon size={16} onClick={handleClick} />
      </span>
      {label && <span className={css.label}>{label}</span>}
      {showTooltip &&
        ReactDOM.createPortal(
          <Rnd
            className={cx('resize', className)}
            default={pos}
            size={{ width: pos.width, height: pos.height }}
            onResizeStop={(e, direction, ref, delta, position) => {
              setPos({
                width: ref.style.width,
                height: ref.style.height,
                ...position
              })
            }}
          >
            <>
              {children}
              <CloseIcon
                className={css.close}
                size={16}
                onClick={handleClick}
              />
            </>
          </Rnd>,
          root
        )}
    </div>
  )
}

InfoTip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultPos: PropTypes.shape({
    /**
     *  How far from the left the portal should initially appear.
     *  If unset, will appear 20px off of the left edge of the window.
     */
    x: PropTypes.number,
    /**
     *  How far from the top the portal should initially appear.
     *  If unset, will appear immediately below the question mark icon.
     */
    y: PropTypes.number,
    /**
     *  Portals starting width.
     *  If unset, will take the full width of the window -40px (as margin).
     */
    width: PropTypes.number,
    /**
     *  Portals starting height.
     *  If unset, will take the remainder of the available height from it's y
     *  position to the bottom of the window.
     */
    height: PropTypes.number
  }),
  /* How far below the question mark icon the portal should initially render */
  offset: PropTypes.number,
  /* String to appear to the right of the question mark icon.*/
  label: PropTypes.string
}

export default InfoTip
