import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const Header = ({ name }) => {
  const { t } = useTranslation('common')
  return (
    <div>
      {t(name)} <span />
    </div>
  )
}

Header.propTypes = {
  name: PropTypes.string.isRequired
}

export default Header
