import { all, call, put, takeLatest } from 'redux-saga/effects'

import * as api from 'common/api'

import { setTags, failFetchingTags } from './actions'

import { FETCH_TAGS } from './types'

function* onFetchTags() {
  yield takeLatest(FETCH_TAGS, fetchTags)
}

function* fetchTags({ payload }) {
  try {
    const tags = yield call(api.get, `/tags/${payload}`)

    yield put(setTags({ tags, component: payload }))
  } catch (err) {
    console.log(err)
    window.alert(err.message)
    yield put(failFetchingTags(err))
  }
}

export default function* rootSaga() {
  yield all([onFetchTags()])
}
