import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { classList } from 'common/utils'

import Header from './header'
import Notes from 'core/product/components/notes'

import css from './style.module.scss'

class Workspace extends Component {
  state = {
    showNotes: false
  }

  enableNotes = () => {
    this.setState({ showNotes: !this.state.showNotes })
  }

  renderNotes() {
    const { day, type, notes } = this.props

    return (
      <Notes workspace={day} type={type} notes={notes} />
    )
  }

  render() {
    const {
      id,
      type,
      element,
      day,
      hideDay,
      date,
      hideDate,
      fullWidth,
      yesterdaysId,
      handleOnReset,
      handleOnCopy,
      handleOnFetchContent,
      hasNotes,
      hideButtons,
      children
    } = this.props
    const { showNotes } = this.state
    const classes = classList(css.container, fullWidth && css.fullWidth)

    return (
      <div className={classes}>
        <div className={css.workspace} id={id}>
          <Header
            day={day}
            type={type}
            element={element}
            hideDay={hideDay}
            date={date}
            hideDate={hideDate}
            yesterdaysId={yesterdaysId}
            handleOnReset={handleOnReset}
            handleOnCopy={handleOnCopy}
            handleOnFetchContent={handleOnFetchContent}
            enableNotes={this.enableNotes}
            hasNotes={hasNotes}
            hideButtons={hideButtons}
          />
          {showNotes ? this.renderNotes() : children}
        </div>
      </div>
    )
  }
}

Workspace.propTypes = {
  /* Refers to type of component. i.e., 'weather' */
  type: PropTypes.string.isRequired,
  /* Refers to which SideNav element it belongs to, i.e. 'offseasonWeather' */
  element: PropTypes.string,
  /* Refers to "day" of workspace. i.e., 1 */
  day: PropTypes.number,
  /* Refers to Date relating workspace. */
  date: PropTypes.string,
  fullWidth: PropTypes.bool,
  notes: PropTypes.array,
  hasNotes: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node.isRequired,
  /* Passed to <Header /> */
  hideButtons: PropTypes.bool,
  yesterdaysId: PropTypes.number,
  handleOnReset: PropTypes.func,
  handleOnCopy: PropTypes.func,
  handleOnFetchContent: PropTypes.func
}

Workspace.defaultProps = {
  fullWidth: false,
  hasNotes: true,
  hideButtons: false
}

export default Workspace
