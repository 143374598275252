import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { get, find } from 'lodash'

import { TextCell } from 'common/components/table/cells'

const StatusCell = props => {
  const { t } = useTranslation('products')
  const { value, statusTypes } = props
  let status = ''

  if (value === 'pending') {
    status = t('pending')
  } else {
    status = get(find(statusTypes, { id: value }), 'display')
  }

  return <TextCell>{status}</TextCell>
}

StatusCell.propTypes = {
  rowData: PropTypes.object
}

export default StatusCell
