import React from 'react'
import PropTypes from 'prop-types'
import { classList } from 'common/utils'
import css from './style.module.scss'

const Page = props => {
  const { children, center, className } = props
  const contentClasses = classList(css.content, center && css.center, className)

  return (
    <div className={css.page}>
      <div className={contentClasses}>{children}</div>
    </div>
  )
}

Page.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
}

Page.defaultProps = {
  center: false
}

export default Page
