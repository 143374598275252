import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, NavLink } from 'react-router-dom'
import Container from './containers'
import { withTranslation } from 'react-i18next'
import { Checkbox } from 'common/components/form'
import {
  FORECAST_NAVIGATION,
  OFFSEASON_NAVIGATION,
  ADVISORY_NAVIGATION
} from 'core/product/config'
import styles from './style.module.scss'

class SidebarItem extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  /* /workspaces/{day}/avalancheProblems/{index} does not create a url match
   * therefore we need to do the match check ourselves in order to keep the
   * sidenav tab highlighted when navigating to the Avalanche Problem Form.
   */
  isActive = (match, location, url) => {
    const urlPath = url.split('/')
    const locationPath = location.pathname.split('/')
    if (
      urlPath.includes('avalancheProblems') &&
      locationPath.includes('avalancheProblems')
    )
      return true

    return match
  }

  renderContent({ completed, toggleCompleted, hasNotes }) {
    const { type, match, t } = this.props
    const url = `${match.url}/${type}`

    return (
      <div className={styles.itemContainer}>
        <div className={styles.check}>
          <Route path={url}>
            {({ location }) => (
              <Checkbox
                id={type}
                disabled={type !== location.pathname.split('/').pop()}
                noLabel
                onChange={toggleCompleted}
                value={completed}
              />
            )}
          </Route>
        </div>
        <NavLink
          to={url}
          className={styles.item}
          activeClassName={styles.active}
          isActive={(match, location) => this.isActive(match, location, url)}
        >
          <div className={styles.labelContainer}>{t(`navigation.${type}`)}</div>
          {hasNotes ? <span className={styles.notificationDot} /> : null}
        </NavLink>
      </div>
    )
  }
  render() {
    return (
      <Container type={this.props.type}>
        {props => this.renderContent(props)}
      </Container>
    )
  }
}

SidebarItem = withTranslation('products')(SidebarItem)
/**
 * Future product navigation can be configured in ./config/:productName.js
 */
const navigationByProductType = {
  forecast: FORECAST_NAVIGATION,
  offseason: OFFSEASON_NAVIGATION,
  advisory: ADVISORY_NAVIGATION
}

export default class SidebarNavigation extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    productType: PropTypes.oneOf(Object.keys(navigationByProductType))
      .isRequired
  }
  get components() {
    return navigationByProductType[this.props.productType]
  }
  renderSidebarItem(component) {
    const { match } = this.props

    return <SidebarItem type={component} key={component} match={match} />
  }
  render() {
    return (
      <div className={styles.container}>
        {this.components.map(this.renderSidebarItem, this)}
        <div className={styles.rightBorder} />
      </div>
    )
  }
}
