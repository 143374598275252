import React from 'react'
import PropTypes from 'prop-types'

import { classList } from 'common/utils'

import css from './style.module.scss'

const Main = ({ children, hasSiblingAside, className }) => {
  const classes = classList(
    className,
    css.main,
    hasSiblingAside && css.hasSiblingAside
  )

  return <main className={classes}>{children}</main>
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
  hasSiblingAside: PropTypes.bool
}

export default Main
