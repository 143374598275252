import { combineReducers } from 'redux'

/* External Dependency Reducers */
import { reducer as formReducer } from 'redux-form'

/* Avid Route Reducers */
import { default as locationsReducer } from 'core/locations/reducer'
import { default as productsReducer } from 'core/products/reducer'
import { default as usersReducer } from 'core/users/reducer'

/* Single Product View Reducers */
import { default as productReducer } from 'core/product/reducer'
import { default as avalancheProblemsReducer } from 'core/avalancheProblems/reducer'
import { default as avalancheSummariesReducer } from 'core/avalanche/reducer'
import { default as notesReducer } from 'core/product/components/notes/reducer'
import { default as snowpackSummariesReducer } from 'core/snowpack/reducer'
import { default as weakLayersReducer } from 'core/weakLayers/reducer'
import { default as weatherSummariesReducer } from 'core/weather/reducer'
import { default as visibleDaysReducer } from 'core/product/components/visibleDays/reducer'
import { default as reviewMessagingReducer } from 'core/reviewMessaging/reducer'
import { default as whiteboardReducer } from 'core/reviewMessaging/components/reviewMessagingWhiteboard/reducer'
import { default as travelAdvisoryReducer } from 'core/terrainAndTravelAdvisory/reducer'
import { default as offseasonMessageReducer } from 'core/offseasonMessage/reducer'
import dangerRatings from 'core/dangerRatings/store'
import { default as confidence } from 'core/confidence/reducer'
import { default as observationSummariesReducer } from 'core/observation/reducer'
import communications from 'core/communications/store'
import media from 'core/media/store'
import components from 'core/product/components/sidebarNavigation/store'
import { default as criticalFactorsReducer } from 'core/dangerRatingCriticalFactors/components/criticalFactors/reducer'

/* Miscellaneous Reducers */
import {
  statementsReducer,
  tagsReducer,
  terrainAndTravelAdviceReducer
} from 'common/data'
import definedTypes from 'common/data/definedTypes/store'
import managedTypes from 'common/data/managedTypes/store'

export default combineReducers({
  /* External Dependency Reducers */
  form: formReducer,
  /* Avid Route Reducers */
  locations: locationsReducer,
  products: productsReducer,
  users: usersReducer,
  /* Single Product View Reducers */
  product: productReducer,
  avalancheProblems: avalancheProblemsReducer,
  reviewMessaging: reviewMessagingReducer,
  whiteboards: whiteboardReducer,
  statements: statementsReducer,
  tags: tagsReducer,
  terrainAndTravelAdvice: terrainAndTravelAdviceReducer,
  avalancheSummaries: avalancheSummariesReducer,
  notes: notesReducer,
  snowpackSummaries: snowpackSummariesReducer,
  weakLayers: weakLayersReducer,
  weatherSummaries: weatherSummariesReducer,
  visibleDays: visibleDaysReducer,
  travelAdvisory: travelAdvisoryReducer,
  offseasonMessage: offseasonMessageReducer,
  components,
  dangerRatings,
  confidence,
  communications,
  media,
  observationSummaries: observationSummariesReducer,
  criticalFactors: criticalFactorsReducer,
  /* Miscellaneous Reducers */
  definedTypes,
  managedTypes
})
