import { put, call, all, takeLatest, select } from 'redux-saga/effects'
import { reset } from 'redux-form'

import * as api from 'common/api'
import {
  FETCH_NOTES,
  SUBMIT_NEW_NOTE,
  UPDATE_NOTE,
  FETCH_ALL_REVIEW_NOTES
} from './types'
import { failFetchingNotes, setNotes, addNewNote, setAllNotes } from './actions'
import { getCurrentProductId } from 'core/product/selectors'
import { NOTE_FORM_NAME } from 'core/product/config/constants'

const COMPONENT_TO_TYPES = new Map([
  ['weather', 'weathersummary'],
  ['snowpack', 'snowpacksummary'],
  ['avalanche', 'avalanchesummary'],
  ['observation', 'observationsummary'],
  ['weakLayers', 'weaklayer'],
  ['avalancheProblems', 'avalancheproblem'],
  ['communications', 'communication'],
  ['dangerRatings', 'dangerrating'],
  ['confidence', 'confidence'],
  ['offseasonMessage', 'offseasonmessage'],
  ['dangerRatingCriticalFactors', 'criticalfactors'],
  ['terrainAndTravelAdvisory', 'terrainandtraveladvisory']
])

const TYPES_TO_COMPONENTS = new Map(
  [...COMPONENT_TO_TYPES].map(a => a.reverse())
)

function* onFetchNotes() {
  yield takeLatest(FETCH_NOTES, fetchNotes)
}

/*
  GET /notes/{reportId}

  [
    {
      "author": "59ea6d0b3978f00820b624ad",
      "component": "avalanchesummary",
      "workspace": 1,
      "content": "Voluptas voluptas laudantium hic sint ut nisi voluptatem ad at.",
      "date": "2018-05-14T09:25:14.283Z",
      "id": 93,
      "requiresValidation": true,
      "reportId": 18,
      "validated": false
    }
  ]
*/
function* fetchNotes({ payload: reportId }) {
  try {
    // Fetch the notes for a given `reportId` and map the `component` enum to the types
    // used by the frontend
    const notes = yield call(api.get, `notes/${reportId}`)
    if (!notes) return

    const mappedNotes = notes.map(note => ({
      ...note,
      component: TYPES_TO_COMPONENTS.get(note.component)
    }))
    yield put(setNotes(mappedNotes))
  } catch (err) {
    console.error(err)
    window.alert(err.message)
    yield put(failFetchingNotes())
  }
}

function* onFetchAllReviewNotes() {
  yield takeLatest(FETCH_ALL_REVIEW_NOTES, fetchAllReviewNotes)
}

function* fetchAllReviewNotes() {
  try {
    const notes = yield call(api.get, 'notes/flagged')
    if (!notes) return

    const mappedNotes = notes.map(note => ({
      ...note,
      component: TYPES_TO_COMPONENTS.get(note.component)
    }))
    yield put(setAllNotes(mappedNotes))
  } catch (err) {
    console.error(err)
    window.alert(err.message)
    yield put(failFetchingNotes())
  }
}

function* onSubmitNewNote() {
  yield takeLatest(SUBMIT_NEW_NOTE, submitNewNote)
}

/*
  POST /notes

  [
    {
      "author": "59ea6d0b3978f00820b624ad",
      "component": "avalancheSummary",
      "workspace": 1,
      "content": "Voluptas voluptas laudantium hic sint ut nisi voluptatem ad at.",
      "requiresValidation": true,
      "reportId": 18
    }
  ]
*/
function* submitNewNote({ payload }) {
  const { note } = payload
  try {
    const reportId = yield select(getCurrentProductId)

    const newNote = yield call(api.post, `notes`, {
      ...note,
      reportId,
      component: COMPONENT_TO_TYPES.get(note.component),
      date: new Date().toISOString()
    })
    yield put(
      addNewNote({
        ...newNote,
        date: new Date(newNote.date),
        component: TYPES_TO_COMPONENTS.get(newNote.component)
      })
    )
    yield put(reset(`${NOTE_FORM_NAME}_workspace${note.workspace}`))
  } catch (err) {
    window.alert(err.message)
    console.error(err)
  }
}

function* onUpdateNote() {
  yield takeLatest(UPDATE_NOTE, updateNote)
}

function* updateNote({ payload }) {
  try {
    yield call(api.put, `notes/${payload.id}`, {
      ...payload,
      component: COMPONENT_TO_TYPES.get(payload.component)
    })
  } catch (err) {
    window.alert(err.message)
    console.error(err)
  }
}

export default function* rootSaga() {
  yield all([
    onFetchNotes(),
    onSubmitNewNote(),
    onUpdateNote(),
    onFetchAllReviewNotes()
  ])
}
