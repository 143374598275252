import React, { Component, Suspense } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import { getUserLang, getUserId } from 'core/users/selectors'

import { fetchLocations } from 'core/locations/actions'
import { fetchNotes } from './components/notes/actions'
import { fetchUser, fetchUsers } from 'core/users/actions'
import { getCurrentProduct } from './actions'

import {
  SidebarPageContainer,
  SidebarContainer as Sidebar
} from 'common/components'
import AvalancheProblems from 'core/avalancheProblems'

import {
  FORECAST_COMPONENTS,
  ADVISORY_COMPONENTS,
  OFFSEASON_COMPONENTS
} from './config'
import { PUBLISHED_STATUS } from './config/constants'
import {
  getCurrentProductType,
  getCurrentProductUser,
  getCurrentProductStatus,
  getCurrentProductLocation,
  getCurrentProductLocationHandle,
  getCurrentProductValidFromDate
} from './selectors'
import { createProductTitle } from 'common/helpers/createProductTitle'

import ProductHeader from './components/productHeader'
import SidebarNavigation from './components/sidebarNavigation'

import css from './style.module.scss'

/**
 * Future product components can be configured in ./config/:productName.js
 */
const componentsByProductType = {
  forecast: FORECAST_COMPONENTS,
  advisory: ADVISORY_COMPONENTS,
  offseason: OFFSEASON_COMPONENTS
}

class Product extends Component {
  constructor(props) {
    super(props)
    const {
      getCurrentProduct,
      fetchLocations,
      fetchNotes,
      fetchUsers,
      fetchUser,
      match: {
        params: { id }
      }
    } = props

    getCurrentProduct(id)
    fetchLocations()
    fetchNotes(id)
    fetchUsers()
    fetchUser({ redirect: props.history.push })
  }

  componentDidUpdate(prevProps) {
    const {
      productType,
      publishDate,
      currentProductLocationHandle,
      getCurrentProduct,
      match
    } = this.props

    if (prevProps.match.params.id !== match.params.id)
      getCurrentProduct(match.params.id)

    if (productType && publishDate) {
      const title = createProductTitle(
        productType,
        currentProductLocationHandle,
        publishDate
      )
      if (title !== document.title) document.title = title
    }
  }

  render() {
    const {
      history,
      match,
      productType,
      productStatus,
      productUser,
      userId,
      t
    } = this.props

    if (
      productUser &&
      userId &&
      productStatus &&
      productUser !== userId &&
      productStatus !== PUBLISHED_STATUS
    ) {
      window.alert(t('unassignedUser'))
      return <Redirect push to={'/avid/products'} />
    }

    if (!productType) return null

    const currentComponent = history.location.pathname.split('/').pop()
    const currentProduct = componentsByProductType[productType]
    const defaultComponent = currentProduct[0].route

    return (
      <div className={css.sidebar}>
        <ProductHeader component={currentComponent} />
        <SidebarPageContainer headerSize="tall" backgroundColor="white">
          <Sidebar backgroundColor="grey" noShadow narrow>
            <SidebarNavigation productType={productType} match={match} />
          </Sidebar>
          <div className={css.formContainer}>
            <Suspense fallback={<div/>}>
              <Switch>
                {currentProduct.map(
                  (
                    { route, component, workspaces, defaultDays, element },
                    index
                  ) => (
                    <Route
                      path={`${match.path}/${route}`}
                      key={route}
                      render={props =>
                        React.createElement(component, {
                          ...props,
                          workspaces,
                          defaultDays,
                          element
                        })
                      }
                    />
                  )
                )}
                <Route
                  path={`${match.path}/workspaces`}
                  component={AvalancheProblems}
                  key={'workspaces/avalancheProblems'}
                />
                <Route
                  path={`${match.path}`}
                  render={({ match }) => (
                    <Redirect to={`${match.url}/${defaultComponent}`} />
                  )}
                />
              </Switch>
            </Suspense>
          </div>
        </SidebarPageContainer>
      </div>
    )
  }
}

Product.propTypes = {
  getCurrentProduct: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired,
  fetchNotes: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  productType: PropTypes.string
}

const mapStateToProps = state => ({
  productType: getCurrentProductType(state),
  productUser: getCurrentProductUser(state),
  productStatus: getCurrentProductStatus(state),
  currentProductLocation: getCurrentProductLocation(state),
  currentProductLocationHandle: getCurrentProductLocationHandle(state),
  publishDate: getCurrentProductValidFromDate(state),
  language: getUserLang(state),
  userId: getUserId(state)
})

const mapDispatchToProps = {
  getCurrentProduct,
  fetchLocations,
  fetchNotes,
  fetchUsers,
  fetchUser
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation('common')
)(Product)
