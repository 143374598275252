import format from 'date-fns/format'

const STANDARD_FORMAT = 'YYYY-MM-DD'
const NOTE_FORMAT = 'HH:mm, YYYY-MM-DD'
const TABLE_FORMAT_DATE = 'YYYY/MM/DD'
const TABLE_FORMAT_HOUR = 'HH'
const TABLE_FORMAT_MIN = 'mm'
const TABLE_FORMAT_TIME = `${TABLE_FORMAT_HOUR}:${TABLE_FORMAT_MIN}`
const TABLE_FORMAT = `${TABLE_FORMAT_DATE} ${TABLE_FORMAT_TIME}`

export const formatDate = (date, dateFormat = STANDARD_FORMAT) =>
  format(date, dateFormat)

export const formatNoteDate = date => formatDate(date, NOTE_FORMAT)

export const formatTableDate = date => formatDate(date, TABLE_FORMAT_DATE)

export const formatTableHour = date => formatDate(date, TABLE_FORMAT_HOUR)

export const formatTableMin = date => formatDate(date, TABLE_FORMAT_MIN)

export const formatTableTime = date => formatDate(date, TABLE_FORMAT_TIME)

export const formatTableDateAndTime = date => formatDate(date, TABLE_FORMAT)

export const getUTCDateAndTime = date => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}
