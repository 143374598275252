import { createAction } from 'common/utils'

import {
  SUBMIT_REVIEW,
  FETCH_REVIEW,
  FETCH_ALL_REVIEWS,
  SET_REVIEW,
  SET_ALL_REVIEWS
} from './types'

export const submitReview = payload => createAction(SUBMIT_REVIEW, payload)
export const fetchReview = payload => createAction(FETCH_REVIEW, payload)
export const fetchAllReviews = payload =>
  createAction(FETCH_ALL_REVIEWS, payload)
export const setReview = payload => createAction(SET_REVIEW, payload)
export const setAllReviews = payload => createAction(SET_ALL_REVIEWS, payload)
