import { createAction } from 'common/utils'
import {
  FETCH_OBSERVATION_SUMMARIES,
  FAIL_FETCHING_OBSERVATION_SUMMARIES,
  SET_OBSERVATION_SUMMARIES,
  SUBMIT_OBSERVATION_WORKSPACE_SUMMARY,
  ADD_NEW_OBSERVATION_SUMMARY,
  UPDATE_OBSERVATION_SUMMARY
} from './types'

export const fetchObservationSummaries = payload =>
  createAction(FETCH_OBSERVATION_SUMMARIES, payload)
export const failFetchingObservationSummaries = () =>
  createAction(FAIL_FETCHING_OBSERVATION_SUMMARIES)
export const setObservationSummaries = payload =>
  createAction(SET_OBSERVATION_SUMMARIES, payload)
export const submitObservationWorkspaceSummary = payload =>
  createAction(SUBMIT_OBSERVATION_WORKSPACE_SUMMARY, payload)
export const addNewObservationSummary = payload =>
  createAction(ADD_NEW_OBSERVATION_SUMMARY, payload)
export const updateObservationSummary = payload =>
  createAction(UPDATE_OBSERVATION_SUMMARY, payload)
