import { takeLatest, select, all, call, put } from 'redux-saga/effects'
import { isPristine, getFormValues } from 'redux-form'

import * as api from 'common/api'
import {
  FETCH_WHITEBOARD,
  SUBMIT_WHITEBOARD
} from './types'
import { setWhiteboard, failFetchingWhiteboard } from './actions'

import { WHITEBOARD_FORM_NAME } from 'core/product/config/constants'


function* onFetchWhiteboard() {
  yield takeLatest(FETCH_WHITEBOARD, fetchWhiteboard)
}

function* fetchWhiteboard({ payload }) {
  const { locationId, productType } = payload

  try {
    const whiteboard = yield call(
      api.get,
      `whiteboards/${locationId}/${productType}`
    )
    yield put(setWhiteboard(whiteboard))
  } catch (err) {
    yield put(failFetchingWhiteboard())
    console.error(err)
    window.alert(err.message)
  }
}

function* onSubmitWhiteboard() {
  yield takeLatest(SUBMIT_WHITEBOARD, submitWhiteboard)
}

function* submitWhiteboard({ payload }) {
  const { locationId, productType } = payload

  const clean = yield select(state => isPristine(WHITEBOARD_FORM_NAME)(state))
  if (clean) return

  const values = yield select(state =>
    getFormValues(WHITEBOARD_FORM_NAME)(state)
  )
  if (!values) return

  try {
    const whitebaord = {
      location: locationId,
      product: productType,
      contents: values.whiteboard
    }
    yield call(api.put, `whiteboards/${locationId}/${productType}`, whitebaord)
    yield put(setWhiteboard(whitebaord))
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

export default function* rootSaga() {
  yield all([
    onFetchWhiteboard(),
    onSubmitWhiteboard()
  ])
}
