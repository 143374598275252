import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import { withTranslation } from 'react-i18next'
import 'react-table/react-table.css'

import { classList } from 'common/utils/'
import css from './style.module.scss'

/**
 * This is the basic table component. Shared table styles should
 * go in the style.module.scss in this folder.
 */
class Table extends Component {
  render() {
    const { t, data, columns, ...rest } = this.props
    const classes = classList(css.table, this.props.className || '')

    return (
      <div>
        <ReactTable
          data={data}
          className={classes}
          columns={columns}
          previousText={t('previous')}
          nextText={t('next')}
          loadingText={t('loading')}
          noDataText={t('noData')}
          pageText={t('page')}
          ofText={t('of')}
          rowsText={t('rows')}
          {...rest}
        />
      </div>
    )
  }
}

/**
 * Note: if you go to https://react-table.js.org/#/story/readme
 * You will notice that there are a ton of other props available to this
 * React-Table component. Add as necessary
 */
Table.propTypes = {
  data: PropTypes.array,
  sortable: PropTypes.bool,
  resizable: PropTypes.bool,
  filterable: PropTypes.bool,
  filtered: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool
    })
  ),
  className: PropTypes.string,
  onSortedChange: PropTypes.func,
  sorted: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool
    })
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Cell: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.element
      ]),
      Header: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.element
      ]),
      Footer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.element
      ]),
      Filter: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.element
      ]),
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      id: PropTypes.string,
      sortable: PropTypes.boolean,
      sortMethod: PropTypes.func,
      filterable: PropTypes.boolean,
      width: PropTypes.number,
      minWidth: PropTypes.number,
      maxWidth: PropTypes.number,
      className: PropTypes.string,
      headerClassName: PropTypes.string
    })
  )
}

export default withTranslation('common')(Table)
