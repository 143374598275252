import { createSelector } from 'reselect'
import { get } from 'lodash'

const terrainAndTravelAdviceSelector = state => state.terrainAndTravelAdvice

const terrainAndTravelAdviceByDaySelector = (state, workspace) =>
  state.terrainAndTravelAdvice.terrainAndTravelAdviceByDays[workspace]

export const getTerrainAndTravelAdviceForWorkspace = createSelector(
  terrainAndTravelAdviceByDaySelector,
  terrainAndTravelAdvice => terrainAndTravelAdvice
)

export const terrainAndTravelAdviceStatus = createSelector(
  terrainAndTravelAdviceSelector,
  terrainAndTravelAdvice => get(terrainAndTravelAdvice, 'status')
)
