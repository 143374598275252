import React from 'react'
import PropTypes from 'prop-types'

import classList from 'common/utils/classList'

import css from './style.module.scss'

const Label = ({ children, required, htmlFor }) => {
  const classes = classList(css.label, required && css.required)

  return (
    <label htmlFor={htmlFor} className={classes}>
      {children}
    </label>
  )
}

Label.propTypes = {
  children: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  required: PropTypes.bool
}

export default Label
