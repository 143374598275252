import React from 'react'
import Table from '../table'
import { useTranslation } from 'react-i18next'

const ConfidenceRatingInfoTable = ({ className }) => {
  const { t } = useTranslation('infoTip')

  const keys = ['high', 'moderate', 'low']

  const columns = [
    {
      title: t('confidence.titles.rating'),
      key: 'rating'
    },
    {
      title: t('confidence.titles.definition'),
      key: 'definition'
    }
  ]

  const data = keys.map(key => ({
    rating: t(`confidence.${key}.rating`),
    definition: t(`confidence.${key}.definition`)
  }))

  return (
    <Table
      className={className}
      data={data}
      columns={columns}
    />
  )
}

export default ConfidenceRatingInfoTable
