import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { deleteReport } from 'core/products/actions'
import { DeleteButton } from 'common/components'

import css from './style.module.scss'

const DeleteReportButton = ({ isDeleted, onDeleteReport }) => (
  <DeleteButton
    className={css.delete}
    disabled={isDeleted}
    onClick={onDeleteReport}
  />
)

DeleteReportButton.propTypes = {
  reportKey: PropTypes.string.isRequired,
  isDeleted: PropTypes.bool.isRequired,
  onDeleteReport: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDeleteReport() {
    if (window.confirm(ownProps.t('confirmDelete')) === true) {
      dispatch(deleteReport({ reportKey: ownProps.reportKey }))
    }
  }
})

export default compose(
  withTranslation('products'),
  connect(
    null,
    mapDispatchToProps
  )
)(DeleteReportButton)
