import React from 'react'
import { connect } from 'react-redux'
import { getFormValues, submit } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Button } from 'common/components'
import css from './style.module.scss'

const CreateAccount = ({ user, onClick }) => {
  const { t } = useTranslation('user')

  function handleClick() {
    if (!user.name || !user.handle) {
      return window.alert(t('nullUser'))
    }

    onClick()
  }

  return (
    <Button className={css.createAccount} onClick={handleClick}>
      {t('createAccount')}
    </Button>
  )
}

const mapStateToProps = state => ({
  user: getFormValues('user')(state)
})

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(submit('user'))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount)
