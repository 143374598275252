import { createAction } from 'common/utils'

import {
  FETCH_CRITICAL_FACTORS,
  FAIL_FETCHING_CRITICAL_FACTORS,
  SET_CRITICAL_FACTORS,
  UPDATE_CRITICAL_FACTORS,
  SUBMIT_CRITICAL_FACTORS,
  RESET_CRITICAL_FACTOR,
  CARRY_FORWARD_CRITICAL_FACTOR
} from './types'

export const fetchCriticalFactors = payload =>
  createAction(FETCH_CRITICAL_FACTORS, payload)
export const failFetchingcriticalFactors = payload =>
  createAction(FAIL_FETCHING_CRITICAL_FACTORS, payload)
export const setCriticalFactors = payload =>
  createAction(SET_CRITICAL_FACTORS, payload)
export const updateCriticalFactors = payload =>
  createAction(UPDATE_CRITICAL_FACTORS, payload)
export const submitCriticalFactors = payload =>
  createAction(SUBMIT_CRITICAL_FACTORS, payload)
export const resetCriticalFactor = payload =>
  createAction(RESET_CRITICAL_FACTOR, payload)
export const carryForwardCriticalFactor = payload =>
  createAction(CARRY_FORWARD_CRITICAL_FACTOR, payload)
