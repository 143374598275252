import React from 'react'
import { classList } from 'common/utils/'
import Styles from './style.module.scss'

/**
 * The <Grid /> component will most often be the first
 * child of a larger layout parent, and is responsible for holding
 * and properly templating all the <GridItem /> contents,
 * which it expects as children.
 */
const Grid = props => {
  const classes = classList(
    Styles.grid,
    props.noGutter ? Styles.noGutter : '',
    props.wrap ? Styles.wrap : '',
    props.className || ''
  )

  return <div className={classes}>{props.children}</div>
}

export default Grid
