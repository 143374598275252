import { createSelector } from 'reselect'
import { values } from 'lodash'

const visibleDaysSelector = state => state.visibleDays.days

export const getVisibleDaysArray = createSelector(
  visibleDaysSelector,
  visibleDays =>
    values(visibleDays).map((visibleDay, index) => ({
      day: index + 1,
      ...visibleDay
    }))
)

export const getShownVisibleDaysArray = createSelector(
  getVisibleDaysArray,
  visibleDays =>
    values(visibleDays).filter(({ isInteractable }) => isInteractable === true)
)

export const getActiveVisibleDaysArray = createSelector(
  getVisibleDaysArray,
  visibleDays => values(visibleDays).filter(({ isActive }) => isActive === true)
)
