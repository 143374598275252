import { all, call, put, takeLatest, select } from 'redux-saga/effects'

import * as api from 'common/api'

import {
  setAvalancheProblems,
  updateAvalancheProblems,
  failFetchingAvalancheProblems
} from './actions'
import { getCurrentProductKey } from 'core/product/selectors'

import {
  FETCH_AVALANCHE_PROBLEMS,
  DELETE_AVALANCHE_PROBLEM,
  SUBMIT_AVALANCHE_PROBLEMS
} from './types'
import { read as READ_DANGER_RATINGS } from '../dangerRatings/store'

function* onFetchAvalancheProblems() {
  yield takeLatest(
    [FETCH_AVALANCHE_PROBLEMS, READ_DANGER_RATINGS],
    fetchAvalancheProblems
  )
}

/*
  GET /avalancheproblems/:reportKey/:workspace

  {
    "reportKey": 18,
    "workspace": 2,
    "problemList" : [
      {
        "problemTypeId": 1,
        "distribution": "specific",
        "sensitivity": "reactive",
        "weakLayerId": 6,
        "public": false,
        "index": 0,
        "aspectElevation": [
          {
            aspect: "n",
            elevation: "btl"
          },
          {
            aspect: "n",
            elevation: "tln"
          },
          {
            aspect: "n",
            elevation: "alp"
          }
        ],
        "hazard": {
          "size": {
              "from": 1,
              "to": 2
          },
          "likelihood": {
              "from": "unlikely",
              "to": "possible"
          },
          "centroid": {
              "size": 1.5,
              "likelihood": "possible_unlikely"
          }
        }
      }
    ]
  }
*/
function* fetchAvalancheProblems() {
  const reportKey = yield select(getCurrentProductKey)
  try {
    const avalancheProblems = yield call(
      api.get,
      `/avalancheproblems/${reportKey}`
    )
    yield put(setAvalancheProblems(avalancheProblems))
  } catch (err) {
    console.error(err)
    //window.alert(err.message)
    yield put(failFetchingAvalancheProblems(err))
  }
}

function* onDeleteAvalancheProblem() {
  yield takeLatest(DELETE_AVALANCHE_PROBLEM, deleteAvalancheProblem)
}

function* deleteAvalancheProblem({ payload }) {
  const reportKey = yield select(getCurrentProductKey)
  const { workspace, index } = payload

  try {
    yield call(api.del, `/avalancheproblems/${reportKey}/${workspace}/${index}`)
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

function* onSubmitAvalancheProblems() {
  yield takeLatest(SUBMIT_AVALANCHE_PROBLEMS, submitAvalancheProblems)
}

function* submitAvalancheProblems({ payload }) {
  const reportKey = yield select(getCurrentProductKey)
  const { workspace, problemList, isEditing, redirect } = payload

  try {
    yield call(api.put, `/avalancheproblems/${reportKey}/${workspace}`, {
      reportKey,
      workspace,
      problemList
    })

    yield put(
      updateAvalancheProblems({
        workspace,
        updatedAvalancheProblems: problemList
      })
    )

    if (!isEditing && redirect)
      redirect(
        `/avid/products/edit/${reportKey}/avalancheProblems#workspace_${workspace}`
      )
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

export default function* rootSaga() {
  yield all([
    onFetchAvalancheProblems(),
    onSubmitAvalancheProblems(),
    onDeleteAvalancheProblem()
  ])
}
