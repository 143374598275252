import { createAction } from 'common/utils'
import {
  FETCH_AVALANCHE_SUMMARIES,
  FAIL_FETCHING_AVALANCHE_SUMMARIES,
  SET_AVALANCHE_SUMMARIES,
  SUBMIT_AVALANCHE_WORKSPACE_SUMMARY,
  ADD_NEW_AVALANCHE_SUMMARY,
  UPDATE_AVALANCHE_SUMMARY
} from './types'

export const fetchAvalancheSummaries = payload =>
  createAction(FETCH_AVALANCHE_SUMMARIES, payload)
export const failFetchingAvalancheSummaries = () =>
  createAction(FAIL_FETCHING_AVALANCHE_SUMMARIES)
export const setAvalancheSummaries = payload =>
  createAction(SET_AVALANCHE_SUMMARIES, payload)
export const submitAvalancheWorkspaceSummary = payload =>
  createAction(SUBMIT_AVALANCHE_WORKSPACE_SUMMARY, payload)
export const addNewAvalancheSummary = payload =>
  createAction(ADD_NEW_AVALANCHE_SUMMARY, payload)
export const updateAvalancheSummary = payload =>
  createAction(UPDATE_AVALANCHE_SUMMARY, payload)
