// TODO: Clean up these types -- they should be named module/ACTION_NAME
export const SUBMIT_REPORT_DETAILS = 'SUBMIT_REPORT_DETAILS'

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const FETCH_PRODUCTS_FAILED = 'FETCH_PRODUCTS_FAILED'
export const SET_PRODUCTS = 'SET_PRODUCTS'

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'

// TODO: New types -- merge these w/ above
export const GET_CURRENT_PRODUCT = 'GET_CURRENT_PRODUCT'
export const SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT'
export const SET_CURRENT_PRODUCT_STATUS = 'SET_CURRENT_PRODUCT_STATUS'

export const GET_YESTERDAYS_PRODUCT = 'GET_YESTERDAYS_PRODUCT'
export const SET_YESTERDAYS_PRODUCT = 'SET_YESTERDAYS_PRODUCT'
export const GET_YESTERDAYS_PRODUCT_FAIL = 'GET_YESTERDAYS_PRODUCT_FAIL'

export const GET_WORKSPACE_CONTENT = 'GET_WORKSPACE_CONTENT'
export const SET_WORKSPACE_CONTENT = 'SET_WORKSPACE_CONTENT'

export const SUBMIT_ALL_FORMS = 'product/SUBMIT_ALL_FORMS'
export const SET_LAST_TIME_SAVED = 'product/SET_LAST_TIME_SAVED'

export const PUBLISH_PRODUCT = 'PUBLISH_PRODUCT'
export const PUBLISH_READY = 'PUBLISH_READY'

export const CREATE_NEW_PRODUCT_VERSION = 'CREATE_NEW_PRODUCT_VERSION'
