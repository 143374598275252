export const ADD_NEW_REPORT = 'ADD_NEW_REPORT'
export const SUBMIT_NEW_REPORT = 'SUBMIT_NEW_REPORT'
export const CONFIRM_NEW_REPORT = 'CONFIRM_NEW_REPORT'
export const CANCEL_NEW_REPORT = 'CANCEL_NEW_REPORT'
export const DELETE_REPORT = 'DELETE_REPORT'
export const CARRY_FORWARD_REPORT_DATA = 'CARRY_FORWARD_REPORT_DATA'

export const ON_CHANGE_PRODUCT_DROPDOWN = 'ON_CHANGE_PRODUCT_DROPDOWN'
export const ON_DROPDOWN_CHANGE = 'ON_DROPDOWN_CHANGE'
export const ON_DATE_CHANGE = 'ON_DATE_CHANGE'

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const FETCH_PRODUCTS_FAILED = 'FETCH_PRODUCTS_FAILED'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_PRODUCT_FORECASTERS = 'SET_PRODUCT_FORECASTERS'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const REMOVE_PRODUCTS_WITH_DELETED_LOCATION =
  'REMOVE_PRODUCTS_WITH_DELETED_LOCATION'
