import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { fetchLocations } from 'core/locations/actions'
import { Nav } from 'common/components'
import { get as getDefinedType, DEFAULT } from 'common/data/definedTypes/store'

import { fetchProducts } from './actions'
import { fetchAllReviewNotes } from 'core/product/components/notes/actions'
import { fetchAllReviews } from 'core/reviewMessaging/actions'
import { fetchUser } from 'core/users/actions'
import {
  getProductsArray,
  getLocationNames,
  getDropdownsOptions
} from './selectors'
import { read as readDefinedType } from 'common/data/definedTypes/store'
import { getLocations } from 'core/locations/selectors'
import { getAllReviewNotes } from 'core/product/components/notes/selectors'
import { getAllReviews } from 'core/reviewMessaging/selectors'
import { getUserId } from 'core/users/selectors'
import ProductsTable from './components/productTable'

/**
 * Note: if you want anything to be common to all pages import here (global
 * messaging etc)
 */
class ProductsDashboard extends Component {
  constructor(props) {
    super(props)
    const {
      fetchUser,
      fetchLocations,
      fetchProducts,
      fetchAllReviewNotes,
      fetchAllReviews
    } = props

    fetchUser({ redirect: props.history.push })
    fetchLocations()
    fetchProducts()
    fetchAllReviewNotes()
    fetchAllReviews()
  }

  componentDidMount() {
    document.title = `AvID - ${this.props.t('dashboard')}`
  }
  componentDidUpdate(prevProps) {
    document.title = `AvID - ${this.props.t('dashboard')}`

    /**
     * Load after user object returns in order to fetch these based on user's
     * chosen language
     */
    if (!prevProps.userId && this.props.userId) {
      this.props.readDefinedType({ type: 'producttypes' })
      this.props.readDefinedType({ type: 'reportstatuses' })
    }
  }

  render() {
    const {
      products,
      locations,
      locationNames,
      productTypes,
      reportStatuses,
      dropdownsOptions,
      userId,
      notes,
      reviews
    } = this.props

    return (
      <div>
        <Nav />
        <main>
          <ProductsTable
            products={products}
            locations={locations}
            locationNames={locationNames}
            notes={notes}
            reviews={reviews}
            productTypes={productTypes.data}
            reportStatuses={reportStatuses.data}
            dropdownsOptions={dropdownsOptions}
            userId={userId}
          />
        </main>
      </div>
    )
  }
}

ProductsDashboard.propTypes = {
  products: PropTypes.array.isRequired,
  locations: PropTypes.object.isRequired,
  dropdownsOptions: PropTypes.shape({
    locationsOptions: PropTypes.array.isRequired,
    forecasterOptions: PropTypes.array.isRequired
  }).isRequired,
  fetchProducts: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  products: getProductsArray(state),
  locations: getLocations(state),
  locationNames: getLocationNames(state),
  dropdownsOptions: getDropdownsOptions(state),
  userId: getUserId(state),
  notes: getAllReviewNotes(state),
  reviews: getAllReviews(state),
  productTypes: getDefinedType(state, 'producttypes', DEFAULT),
  reportStatuses: getDefinedType(state, 'reportstatuses', DEFAULT)
})

const mapDispatchToProps = {
  fetchUser,
  fetchLocations,
  fetchProducts,
  fetchAllReviewNotes,
  fetchAllReviews,
  readDefinedType
}

export default compose(
  withTranslation('common'),
  connect(mapStateToProps, mapDispatchToProps)
)(ProductsDashboard)
