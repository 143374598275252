import React from 'react'
import PropTypes from 'prop-types'

import { classList } from 'common/utils'

import css from './style.module.scss'

const Aside = ({ children, className }) => {
  const classes = classList(className, css.aside)
  return <aside className={classes}>{children}</aside>
}

Aside.propTypes = {
  children: PropTypes.node.isRequired
}

export default Aside
