import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { AuthProvider } from './auth'
import { store } from './common/store'
import App from './App'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://099ab7c5a2b74ac18988bfb8d3608843@sentry.io/1502178'
  })
}

ReactDOM.render(
  <AuthProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </AuthProvider>,
  document.getElementById('root')
)
