import React from 'react'
import PropTypes from 'prop-types'
import addDays from 'date-fns/add_days'

import { formatDate } from 'common/helpers/formatDate'

import Buttons from '../buttons'

import css from './style.module.scss'

const Header = ({
  day,
  type,
  element,
  hideDay,
  date,
  hideDate,
  enableNotes,
  yesterdaysId,
  handleOnReset,
  handleOnCopy,
  handleOnFetchContent,
  hasNotes,
  hideButtons
}) => {
  return (
    <div className={css.header}>
      <div className={css.left}>
        {!hideDay && <div className={css.number}>{day}</div>}
        {!hideDate && (
          <div className={css.title}>
            {formatDate(addDays(date, day - 1))}
          </div>
        )}
      </div>
      <Buttons
        day={day}
        type={type}
        element={element}
        yesterdaysId={yesterdaysId}
        handleOnReset={handleOnReset}
        handleOnCopy={handleOnCopy}
        handleOnFetchContent={handleOnFetchContent}
        enableNotes={enableNotes}
        hasNotes={hasNotes}
        hideButtons={hideButtons}
      />
    </div>
  )
}

Header.propTypes = {
  type: PropTypes.string.isRequired,
  element: PropTypes.string,
  day: PropTypes.number,
  hideDay: PropTypes.bool,
  date: PropTypes.string,
  hideDate: PropTypes.bool,
  enableNotes: PropTypes.func.isRequired,
  /* Passed to <Buttons /> */
  hideButtons: PropTypes.bool,
  yesterdaysId: PropTypes.number,
  handleOnReset: PropTypes.func,
  handleOnCopy: PropTypes.func,
  handleOnFetchContent: PropTypes.func,
  hasNotes: PropTypes.bool
}

Header.defaultProps = {
  hasNotes: true,
  hideButtons: false
}

export default Header
