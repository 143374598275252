import React, { Component } from 'react'
import PropTypes from 'prop-types'

import css from './style.module.scss'
import classNames from 'classnames/bind'
const cx = classNames.bind(css)

/**
 * The result of a search for tagged statements.
 *
 * It includes the tags listed for the statement
 */
class StatementResult extends Component {
  static propTypes = {
    /** Tags for the statement */
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ),

    /** Statement itself is passed in as children */
    children: PropTypes.node
  }

  static defaultProps = {
    tags: []
  }

  render() {
    const { children, tags, disabled } = this.props
    return (
      <div className={cx('result', { disabled })}>
        <ul className={css.labels}>
          {tags.map(tag => (
            <li key={tag.id}>{tag.display}</li>
          ))}
        </ul>
        {children}
      </div>
    )
  }
}

export default StatementResult
