import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button } from 'common/components'

const SubmitWorkspaceButton = ({ isSubmitting, handleSubmit, text }) => {
  const { t } = useTranslation('common')
  return (
    <Button
      size="auto"
      context="workspace"
      disabled={isSubmitting}
      onClick={handleSubmit}
    >
      {t(`${text || 'submit'}`)}
    </Button>
  )
}

SubmitWorkspaceButton.propTypes = {
  text: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

export default SubmitWorkspaceButton
