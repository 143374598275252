import React, { Component } from 'react'

import css from './style.module.scss'

const elevations = [
  {
    height: 'below',
    cx: 50,
    cy: 50,
    rx: 50,
    ry: 50
  },
  {
    height: 'treeline',
    cx: 50,
    cy: 50,
    rx: 33,
    ry: 33
  },
  {
    height: 'alpine',
    cx: 50,
    cy: 50,
    rx: 16.5,
    ry: 16.5
  }
]

const ElevationLayer = props => {
  const { isActive, onClick, cx, cy, rx, ry } = props
  return (
    <ellipse
      cx={cx}
      cy={cy}
      rx={rx}
      ry={ry}
      onClick={onClick}
      className={css.elevation}
      stroke={isActive ? 'var(--color-white)' : 'var(--color-grey)'}
      strokeWidth="0.1"
      fill={isActive ? 'var(--color-blue)' : 'var(--color-light-medium-grey)'}
    />
  )
}

class Elevation extends Component {
  constructor(props) {
    super(props)

    const initialState = {}

    for (let i = 0; i < elevations.length; i += 1) {
      const elevation = elevations[i].height
      initialState[elevation] = false
    }

    this.state = initialState
  }

  handleOnClick = elevation => {
    this.setState({ [elevation]: !this.state[elevation] })
  }

  render() {
    return (
      <div className={css.container}>
        <svg className={css.svg} viewBox="0 0 101 102">
          {elevations.map(elevation => {
            const { height } = elevation
            return (
              <ElevationLayer
                key={height}
                {...elevation}
                isActive={this.state[height]}
                onClick={() => this.handleOnClick(height)}
              />
            )
          })}
        </svg>
        <div className={css.checkboxWrapper}>
          {elevations.map((elevation, i) => {
            const { height } = elevation
            return (
              <input
                key={i}
                disabled
                type="checkbox"
                checked={this.state[height]}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

export default Elevation
