import React from 'react'
import PropTypes from 'prop-types'

import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon'

import css from './style.module.scss'

const DeleteButton = ({ className, disabled, onClick }) => {
  const buttonClasses = [
    className,
    css.deleteButton,
    disabled && css.disabledButton
  ]
  const iconClasses = [css.icon, disabled && css.disabledIcon]
  const DeleteIconComponent = (
    <DeleteOutlineIcon className={iconClasses.join(' ')} />
  )

  if (disabled) {
    return <div className={buttonClasses.join(' ')}>{DeleteIconComponent}</div>
  }

  return (
    <div className={buttonClasses.join(' ')} onClick={!disabled && onClick}>
      {DeleteIconComponent}
    </div>
  )
}

DeleteButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

DeleteButton.defaultProps = {
  disabled: false
}

export default DeleteButton
