import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button, Link as StyledLink } from 'common/components'
import { classList } from 'common/utils'
import { formatDate } from 'common/helpers/formatDate'

import css from './style.module.scss'

const TextCell = ({ children, className = null }) => (
  <div className={classList(css.textCell, className)}>{children}</div>
)

export const HandleColumnCell = ({ value: { id, handle } }) => (
  <StyledLink
    to={`/avid/locations/edit/${id}`}
    className={css.locationHandleTitleCell}
  >
    <TextCell className={css.locationHandle}>{handle}</TextCell>
  </StyledLink>
)

export const NameColumnCell = ({ value }) => <TextCell>{value}</TextCell>

export const CreatedOnColumnCell = ({ value }) => (
  <TextCell>{formatDate(value)}</TextCell>
)

export const CreatedByColumnCell = ({ value, userOptions }) => (
  <TextCell>{userOptions[value] || value}</TextCell>
)

const EditButton = () => {
  const { t } = useTranslation('common')
  return (
    <Button size="secondary" context="open">
      {t('edit')}
    </Button>
  )
}

export const EditColumnCell = ({ value: id }) => (
  <div className={css.editLocation}>
    <Link to={`/avid/locations/edit/${id}`}>
      <EditButton />
    </Link>
  </div>
)
