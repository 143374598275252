import { put, call, all, select } from 'redux-saga/effects'
import {
  getFormValues,
  isDirty,
  reset,
  startSubmit,
  stopSubmit
} from 'redux-form'
import { convertToRaw, ContentState } from 'draft-js'

import * as api from 'common/api'
import { getCurrentProductKey } from 'core/product/selectors'

const WORKSPACES = [1, 2, 3, 4]

/*
  GET /${summaryType}/${reportKey}

  [
    {
      "reportKey": 18,
      "workspace": 0,
      "text": [
        ["en": "English text string"],
        ["fr", "Texte en Français"]
      ]
    },
    ...
  ]
*/
export function* fetchSummaries(action) {
  const {
    summaryType,
    setSummaries,
    failFetchingSummaries,
    updateSummary
  } = action

  try {
    const reportKey = yield select(getCurrentProductKey)
    const summaries = yield call(api.get, `${summaryType}summaries/${reportKey}`)

    if (summaries && summaries.length > 0) {
      yield setContent(summaries, summaryType, setSummaries)
    } else {
      yield setReportKey(reportKey, summaryType, updateSummary)
    }
  } catch (err) {
    console.error(err)
    //window.alert(err.message)
    yield put(failFetchingSummaries())
  }
}

function* setContent(summaries, summaryType, setSummaries) {
  const { reportKey } = summaries[0]

  const summariesByDays = WORKSPACES.reduce((summariesByDays, workspace) => {
    const summary = summaries.find(summary => summary.workspace === workspace)

    if (summary) {
      /*  The summary textboxes should be initialized with an empty DraftJS
       *  ContentState object. This ensures accurate object comparisons when
       *  checking if the text has changed value.
       */
      summariesByDays[summary.workspace] = {
        ...summary,
        text: summary.text.map(lang => lang[1]
          ? lang
          : [lang[0], convertToRaw(ContentState.createFromText(''))]
        )
      }
    } else {
      summariesByDays[workspace] = createEmptySummary(
        workspace,
        reportKey,
        summaryType
      )
    }

    return summariesByDays
  }, {})

  yield put(setSummaries(summariesByDays))
}

function createEmptySummary(workspace, reportKey, type) {
  return {
    workspace,
    reportKey,
    text: []
  }
}

function* setReportKey(reportKey, summaryType, updateSummary) {
  const actions = WORKSPACES.map(day =>
    put(updateSummary(createEmptySummary(day, reportKey, summaryType)))
  )
  yield all(actions)
}

/* Submit a summary for a single workspace */
export function* submitWorkspaceSummary(action) {
  const { formName, summaryType, updateSummary } = action

  const reportKey = yield select(getCurrentProductKey)
  const dirty = yield select(state => isDirty(formName)(state))
  if (!dirty) return

  yield put(startSubmit(formName))

  const currentValues = yield select(state => getFormValues(formName)(state))

  try {
    const updatedSummaries = yield sendSummaries(
      [currentValues],
      summaryType,
      reportKey
    )
    yield setSummaries(updatedSummaries, updateSummary)
  } catch (err) {
    yield put(reset(formName))
    console.error(err)
    window.alert(err.message)
  }

  yield put(stopSubmit(formName))
}

/*
  PUT /${type}summaries/${reportKey}/${workspace}

  {
    "reportKey": 18,
    "workspace": 0,
    "text": [
      ["en": "English text string"],
      ["fr", "Texte en Français"]
    ]
  }
*/
function* sendSummaries(summaries, summaryType, reportKey) {
  const putRequests = summaries.map(summary =>
    call(
      api.put,
      `${summaryType}summaries/${reportKey}/${summary.workspace}`,
      summary
    )
  )
  return yield all(putRequests)
}

function* setSummaries(summaries, updateSummary) {
  const mapSummaries = () =>
    summaries.map(summary => put(updateSummary(summary)))
  yield all(mapSummaries(summaries.putRequests))
}
