import { createSelector } from 'reselect'
import { values, get } from 'lodash'

import { getLocations } from 'core/locations/selectors'

export const productsSelector = state => state.products
export const currentProductSelector = state => state.product.currentProduct
export const isReadyToPublish = state => state.product.readyToPublish
const getDay = (state, props) => props.day
const avalancheSummariesSelector = state =>
  state.avalancheSummaries.summariesByDays
const snowpackSummariesSelector = state =>
  state.snowpackSummaries.summariesByDays
const weatherSummariesSelector = state => state.weatherSummaries.summariesByDays
const observationSummariesSelector = state =>
  state.observationSummaries.summariesByDays

export const getCurrentProduct = createSelector(
  currentProductSelector,
  currentProduct => currentProduct
)

export const getCurrentProductId = createSelector(
  currentProductSelector,
  currentProduct => get(currentProduct, 'id')
)

export const getCurrentProductKey = createSelector(
  currentProductSelector,
  currentProduct => get(currentProduct, 'key')
)

export const getCurrentProductType = createSelector(
  currentProductSelector,
  currentProduct => get(currentProduct, 'productType')
)

export const getCurrentProductUser = createSelector(
  currentProductSelector,
  currentProduct => get(currentProduct, 'user')
)

export const getCurrentProductLocation = createSelector(
  currentProductSelector,
  currentProduct => get(currentProduct, 'location')
)

export const getCurrentProductLocationHandle = createSelector(
  currentProductSelector,
  getLocations,
  (product, locations) =>
    get(locations[product.location], 'feature.properties.handle')
)

export const getCurrentProductValidFromDate = createSelector(
  currentProductSelector,
  currentProduct => get(currentProduct, 'date.validFrom')
)

export const getCurrentProductStatus = createSelector(
  currentProductSelector,
  currentProduct => get(currentProduct, 'status')
)

export const getLocationsArray = createSelector(
  getLocations,
  locations =>
    values(locations).map(
      ({
        geoJson: {
          properties: { name }
        }
      }) => name
    )
)

export const getInitialValuesForAvalancheSummaries = createSelector(
  [getDay, avalancheSummariesSelector],
  (day, avalancheSummaries) => avalancheSummaries[day]
)

export const getInitialValuesForSnowpackSummaries = createSelector(
  [getDay, snowpackSummariesSelector],
  (day, snowpackSummaries) => snowpackSummaries[day]
)

export const getInitialValuesForWeatherSummaries = createSelector(
  [getDay, weatherSummariesSelector],
  (day, weatherSummaries) => weatherSummaries[day]
)

export const getInitialValuesForObservationSummaries = createSelector(
  [getDay, observationSummariesSelector],
  (day, observationSummaries) => observationSummaries[day]
)

const productSelector = state => state.product
export const yesterdaysProductSelector = createSelector(
  productSelector,
  product => product.yesterdaysProduct
)
