import setHours from 'date-fns/set_hours'
import setMinutes from 'date-fns/set_minutes'
import setSeconds from 'date-fns/set_seconds'

/**
 * Given a Date object, an hour value, and a minute value,
 * returns new Date object with hours + minutes applied
 */
export const frankensteinDateAndTime = ({ date, hour, min }) => {
  let dateAndTime = new Date(date.getTime())

  dateAndTime = setHours(dateAndTime, hour)
  dateAndTime = setMinutes(dateAndTime, min)
  dateAndTime = setSeconds(dateAndTime, '00')

  return dateAndTime
}
