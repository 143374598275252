import React from 'react'
import PropTypes from 'prop-types'

import { classList } from 'common/utils'

import css from './style.module.scss'

const TextCell = ({ children, noPadding, noColor }) => {
  const classes = classList(
    css.textCell,
    noPadding && css.noPadding,
    noColor && css.noColor
  )

  return <div className={classes}>{children}</div>
}

TextCell.propTypes = {
  children: PropTypes.string,
  noPadding: PropTypes.bool
}

export default TextCell
