import React from 'react'
import PropTypes from 'prop-types'
import { find, get } from 'lodash'

import { FIELDS_CHANGES } from 'core/products/components/productTable/constants'
import { DropdownCell, TextCell } from 'common/components/table/cells'

const ForecasterCell = (forecasters, onDropdownChange) => rowData => {
  const {
    row: { status },
    value
  } = rowData

  if (FIELDS_CHANGES.edit[status].includes('user')) {
    const options = [{ value: '', label: ' ' }].concat(forecasters)
    return (
      <DropdownCell
        rowData={rowData}
        columnAccessor={'user'}
        options={options}
        onDropdownChange={onDropdownChange}
      />
    )
  }

  const forecasterName = get(
    find(forecasters, forecaster => forecaster.value === value),
    'label',
    ''
  )

  return <TextCell>{forecasterName}</TextCell>
}

ForecasterCell.propTypes = {
  forecasters: PropTypes.array.isRequired,
  onDropdownChange: PropTypes.func.isRequired
}

export default ForecasterCell
