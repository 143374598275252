import React from 'react'
import PropTypes from 'prop-types'
import Immutable from 'seamless-immutable'
import {
  Editor,
  EditorState,
  RichUtils,
  CompositeDecorator,
  Modifier,
  convertFromRaw,
  convertToRaw,
  getDefaultKeyBinding
} from 'draft-js'
import {
  registerCopySource,
  handleDraftEditorPastedText
} from 'draftjs-conductor'
import classnames from 'classnames/bind'
import { withTranslation } from 'react-i18next'
import * as yup from 'yup'

import { Button } from 'common/components'
import RichTextButtons from './richTextButtons'

import 'draft-js/dist/Draft.css'
import css from './style.module.scss'
const cx = classnames.bind(css)

const schema = yup
  .string()
  .url()
  .required()

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity()
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    )
  }, callback)
}

const Link = props => {
  const { url } = props.contentState.getEntity(props.entityKey).getData()
  return (
    <a
      className={cx('link')}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  )
}

const decorator = new CompositeDecorator([
  { strategy: findLinkEntities, component: Link }
])

export const getEditorStateFromRaw = value => {
  if (!value) return EditorState.createEmpty(decorator)

  const raw = Immutable.asMutable(value, { deep: true })
  try {
    const contentState = convertFromRaw(raw)
    return EditorState.createWithContent(contentState, decorator)
  } catch (err) {
    return EditorState.createEmpty(decorator)
  }
}

class RichTextEditor extends React.Component {
  static propTypes = {
    value: PropTypes.object,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    characterLimit: PropTypes.number,
    allowPreview: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }

  constructor(props) {
    super(props)
    this.state = {
      isReadOnly: props.disabled,
      editorState: getEditorStateFromRaw(props.value, decorator),
      showLinkInput: false,
      linkText: ''
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ editorState: getEditorStateFromRaw(this.props.value) })
    }

    if (this.props.disabled !== prevProps.disabled) {
      this.setState({ isReadOnly: this.props.disabled })
    }
  }

  componentDidMount() {
    this.copySource = registerCopySource(this.editorRef)
  }

  componentWillUnmount() {
    if (this.copySource) this.copySource.unregister()

    const content = this.state.editorState.getCurrentContent()
    const raw = convertToRaw(content)
    this.props.onBlur(raw)

    if (this.props.onChange) this.props.onChange(raw)
  }

  onLinkClick = () => {
    const { editorState, showLinkInput } = this.state

    const selection = editorState.getSelection()
    const startKey = selection.getStartKey()
    const startOffset = selection.getStartOffset()
    const contentState = editorState.getCurrentContent()
    const curentContentBlock = contentState.getBlockForKey(startKey)
    const entityKey = curentContentBlock.getEntityAt(startOffset)

    /*  Confirm at least one character has been selected for which the URL will
     *  be assigned
     */
    const endOffset = selection.getEndOffset()
    const selectedText = curentContentBlock
      .getText()
      .slice(startOffset, endOffset)
    if (selectedText === '' && !showLinkInput) {
      return window.alert(this.props.t('linkTextWarning'))
    }

    let data = {}
    let linkText = this.state.linkText
    if (entityKey) {
      data = contentState.getEntity(entityKey).getData()
      linkText = data.url
    }

    this.setState({
      showLinkInput: !this.state.showLinkInput,
      linkText: showLinkInput
        ? /* When closing the input keep only unconfirmed text in state */
          data.url
          ? ''
          : this.state.linkText
        : linkText
    })
    this.url.focus()
  }

  focusAndSubmit = editorState => {
    this.editorRef.focus()
    const selection = this.state.editorState.getSelection()
    const editorStateWithSelction = EditorState.forceSelection(
      editorState,
      selection
    )
    this.handleChange(editorStateWithSelction)
    this.handleBlur(null, editorStateWithSelction)
    this.setState({ showLinkInput: false, linkText: '' })
  }

  onConfirmLinkClick = () => {
    /* Confirm URL is valid */
    if (!schema.isValidSync(this.state.linkText)) {
      return window.alert(this.props.t('invalidURL'))
    }

    const { editorState } = this.state

    const selection = editorState.getSelection()
    const startKey = selection.getStartKey()
    const startOffset = selection.getStartOffset()
    const contentState = editorState.getCurrentContent()
    const blockWithLink = contentState.getBlockForKey(startKey)
    const entityKey = blockWithLink.getEntityAt(startOffset)

    let contentStateWithLink
    if (!entityKey) {
      /* Create new link */
      const contentStateWithEntity = contentState.createEntity(
        'LINK',
        'MUTABLE',
        { url: this.state.linkText }
      )
      const newEntityKey = contentStateWithEntity.getLastCreatedEntityKey()
      contentStateWithLink = Modifier.applyEntity(
        contentStateWithEntity,
        selection,
        newEntityKey
      )
    } else {
      /* Update link */
      contentStateWithLink = contentState.replaceEntityData(entityKey, {
        url: this.state.linkText
      })
    }

    const newEditorState = EditorState.push(
      editorState,
      contentStateWithLink,
      'apply-entity'
    )

    this.focusAndSubmit(newEditorState)
  }

  onRemoveLinkClick = () => {
    const { editorState } = this.state
    const selection = editorState.getSelection()
    const newEditorState = RichUtils.toggleLink(editorState, selection, null)
    this.focusAndSubmit(newEditorState)
  }

  onInlineStyleClick = inlineStyle => {
    const editorState = RichUtils.toggleInlineStyle(
      this.state.editorState,
      inlineStyle
    )
    this.handleChange(editorState)
  }

  onBlockTypeClick = blockType => {
    const editorState = RichUtils.toggleBlockType(
      this.state.editorState,
      blockType
    )
    this.handleChange(editorState)
  }

  onPreviewClick = () => {
    this.setState({ isReadOnly: !this.state.isReadOnly })
  }

  mapKeyToEditorCommand = e => {
    /* TAB */
    if (e.keyCode === 9)
      this.handleChange(RichUtils.onTab(e, this.state.editorState, 4))

    return getDefaultKeyBinding(e)
  }

  handleKeyCommand = (command, editorState) => {
    const newEditorState = RichUtils.handleKeyCommand(editorState, command)
    if (newEditorState) {
      this.handleChange(newEditorState)
      return true
    }
    return false
  }

  handlePastedText = (text, html, editorState) => {
    const newEditorState = handleDraftEditorPastedText(html, editorState)
    if (newEditorState) {
      this.handleChange(newEditorState)
      return true
    }

    return false
  }

  handleChange = editorState => {
    this.setState({ editorState })

    if (this.props.onChange) {
      const raw = convertToRaw(editorState.getCurrentContent())
      this.props.onChange(raw)
    }
  }

  handleBlur = (event, editorState) => {
    const state = editorState ? editorState : this.state.editorState

    if (
      event &&
      event.relatedTarget &&
      event.relatedTarget.id === 'linkInput'
    ) {
      return
    }

    const raw = convertToRaw(state.getCurrentContent())
    this.props.onBlur(raw)
  }

  render() {
    return (
      <div>
        <input
          id="linkInput"
          ref={ref => {
            this.url = ref
          }}
          className={cx('linkInput')}
          type={this.state.showLinkInput ? 'text' : 'hidden'}
          placeholder={this.props.t('Insert URL')}
          onChange={event => this.setState({ linkText: event.target.value })}
          value={this.state.linkText}
        />
        <div className={cx('linkButtons')}>
          <Button
            size={'secondary'}
            className={cx('confirmLink', { hidden: !this.state.showLinkInput })}
            onClick={this.onConfirmLinkClick}
          >
            {this.props.t('confirm')}
          </Button>
          <Button
            type={'button'}
            context={'stop'}
            size={'secondary'}
            className={cx('removeLink', { hidden: !this.state.showLinkInput })}
            onClick={this.onRemoveLinkClick}
          >
            {this.props.t('remove')}
          </Button>
        </div>
        <div
          className={cx('editor', { readOnly: this.state.isReadOnly })}
          onClick={() => {
            this.editorRef.focus()
          }}
        >
          <RichTextButtons
            allowPreview={this.props.allowPreview}
            disableButtons={this.state.isReadOnly}
            onBoldClick={() => this.onInlineStyleClick('BOLD')}
            onItalicClick={() => this.onInlineStyleClick('ITALIC')}
            onUnderlineClick={() => this.onInlineStyleClick('UNDERLINE')}
            onBulletClick={() => this.onBlockTypeClick('unordered-list-item')}
            onLinkClick={this.onLinkClick}
            onPreviewClick={this.onPreviewClick}
          />
          <Editor
            ref={ref => {
              this.editorRef = ref
            }}
            spellCheck={true}
            readOnly={this.state.isReadOnly}
            placeholder={this.props.placeholder}
            editorState={this.state.editorState}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            handleKeyCommand={this.handleKeyCommand}
            keyBindingFn={this.mapKeyToEditorCommand}
            handlePastedText={this.handlePastedText}
          />
        </div>
      </div>
    )
  }
}

export default withTranslation('common')(RichTextEditor)
