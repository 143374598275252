import { createSelector } from 'reselect'
import { get, isEmpty } from 'lodash'
import GJV from 'geojson-validation'

export const getLocations = state => state.locations.locationsById

const currentLocationSelector = (state, props) =>
  state.locations.locationsById[props.locationId]

export const uploadedGeoJSON = state => state.locations.currentUploadedGeoJSON

export const getLocationsArray = createSelector(
  getLocations,
  locations =>
    Object.values(locations)
      .filter(({ feature }) => feature.properties.status !== 'deleted')
      .map(location => ({
        id: location.feature.id,
        ...location.feature.properties
      }))
)

export const getCurrentLocationName = createSelector(
  currentLocationSelector,
  location => get(location, 'feature.properties.name', '')
)

export const getCurrentLocationLink = createSelector(
  currentLocationSelector,
  location => get(location, 'feature.properties.link', '')
)

export const getCurrentLocationGeoJSON = createSelector(
  [currentLocationSelector, uploadedGeoJSON],
  (location, uploadedGeoJSON) => {
    const geoJson = get(location, 'feature.geometry', {})
    if (!GJV.valid(geoJson)) {
      return uploadedGeoJSON
    }

    return geoJson
  }
)

export const hasUploadedGeoJSON = createSelector(
  uploadedGeoJSON,
  uploadedGeoJSON => !isEmpty(uploadedGeoJSON)
)

export const hasGeoJSON = createSelector(
  currentLocationSelector,
  location => {
    const geoJson = get(location, 'feature.geometry', {})
    return !isEmpty(geoJson)
  }
)
