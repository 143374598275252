import Immutable from 'seamless-immutable'

import { createReducer } from 'common/utils'
import * as types from './types'

const INITIAL_STATE = Immutable({
  isFetching: false,
  review: null,
  allReviews: []
})

const setReview = (state, payload) =>
  Immutable.merge(state, {
    isFetching: false,
    review: payload
  })

const setAllReviews = (state, payload) =>
  Immutable.merge(state, {
    isFetching: false,
    allReviews: payload
  })

const fetchReview = state => Immutable.set(state, 'isFetching', true)

const fetchAllReviews = state => Immutable.set(state, 'isFetching', true)

const failFetchReview = (state, payload) =>
  Immutable.set(state, 'isFetching', false)

const reducers = {
  [types.SET_REVIEW]: setReview,
  [types.FETCH_REVIEW]: fetchReview,
  [types.FETCH_ALL_REVIEWS]: fetchAllReviews,
  [types.FAIL_FETCH_REVIEW]: failFetchReview,
  [types.SET_ALL_REVIEWS]: setAllReviews
}

export default createReducer(INITIAL_STATE, reducers)
