import { all } from 'redux-saga/effects'

/* Avid Route Sagas */
import locationsSagas from 'core/locations/sagas'
import productsSagas from 'core/products/sagas'
import userSagas from 'core/users/sagas'

/* Single Product View Sagas */
import productSagas from 'core/product/sagas'
import avalancheProblemsSagas from 'core/avalancheProblems/sagas'
import avalancheSummariesSagas from 'core/avalanche/sagas'
import snowpackSummariesSagas from 'core/snowpack/sagas'
import notesSagas from 'core/product/components/notes/sagas'
import weakLayersSagas from 'core/weakLayers/sagas'
import weatherSummariesSagas from 'core/weather/sagas'
import reviewMessagingSagas from 'core/reviewMessaging/sagas'
import whiteboardSagas from 'core/reviewMessaging/components/reviewMessagingWhiteboard/sagas'
import confidenceSagas from 'core/confidence/sagas'
import travelAdvisorySagas from 'core/terrainAndTravelAdvisory/sagas'
import offseasonMessageSagas from 'core/offseasonMessage/sagas'
import observationSummariesSagas from 'core/observation/sagas'
import criticalFactorsSagas from 'core/dangerRatingCriticalFactors/components/criticalFactors/sagas'

/* Miscellaneous Sagas */
import {
  statementsSagas,
  tagsSagas,
  terrainAndTravelAdviceSagas
} from 'common/data'
import * as definedTypes from 'common/data/definedTypes/store'
import * as managedTypes from 'common/data/managedTypes/store'
import * as dangerRatings from 'core/dangerRatings/store'
import * as communications from 'core/communications/store'
import * as media from 'core/media/store'

export default function* rootSaga() {
  yield all([
    /* Avid Route Sagas */
    locationsSagas(),
    productsSagas(),
    userSagas(),
    /* Single Product View Sagas */
    productSagas(),
    avalancheProblemsSagas(),
    statementsSagas(),
    tagsSagas(),
    terrainAndTravelAdviceSagas(),
    avalancheSummariesSagas(),
    notesSagas(),
    snowpackSummariesSagas(),
    weakLayersSagas(),
    weatherSummariesSagas(),
    travelAdvisorySagas(),
    dangerRatings.sagas(),
    confidenceSagas(),
    communications.sagas(),
    media.sagas(),
    reviewMessagingSagas(),
    whiteboardSagas(),
    offseasonMessageSagas(),
    observationSummariesSagas(),
    criticalFactorsSagas(),
    /* Miscellaneous Sagas */
    definedTypes.sagas(),
    managedTypes.sagas()
  ])
}
