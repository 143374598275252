import { createActions, handleActions } from 'redux-actions'
import { put, takeLatest, takeEvery, select, call } from 'redux-saga/effects'
import * as utils from 'dot-prop-immutable'
import isEqual from 'lodash/isEqual'
import * as api from 'common/api'
import Status from 'common/helpers/Status'
import {
  FETCH_ACTIONS,
  delegateReducerForFetch,
  statusReducerFactory
} from 'common/helpers/store'
import { getCurrentProductKey } from 'core/product/selectors'

// Defaults
export const DEFAULT = {
  items: [],
  received: [],
  statuses: {
    read: new Status(),
    save: new Status()
  }
}

// Types
const PREFIX = 'avid/media/'
const RECEIVE = PREFIX + 'RECEIVE'
const UPDATE = PREFIX + 'UPDATE'
const READ = PREFIX + 'READ'
const SAVE = PREFIX + 'SAVE'
const SUBMIT = PREFIX + 'SUBMIT'

// Reducers
export default handleActions(
  new Map([
    [READ, delegateReducerForFetch(delegateToBucketReducer)],
    [SAVE, delegateReducerForFetch(delegateToBucketReducer)],
    [UPDATE, delegateToBucketReducer],
    [RECEIVE, delegateToBucketReducer]
  ]),
  {}
)
const bucketReducer = handleActions(
  new Map([
    [READ, statusReducerFactory('statuses.read')],
    [SAVE, statusReducerFactory('statuses.save')],
    [UPDATE, (state, action) => utils.set(state, 'items', action.payload.data)],
    [
      RECEIVE,
      (state, action) => {
        const data = action.payload.data.sort(sorter)

        state = utils.set(state, 'received', data)
        state = utils.set(state, 'items', data)

        return state
      }
    ]
  ]),
  DEFAULT
)

// Actions
const actions = createActions({
  [READ]: FETCH_ACTIONS,
  [SAVE]: FETCH_ACTIONS,
  [UPDATE]: undefined,
  [RECEIVE]: undefined,
  [SUBMIT]: undefined
}).avid.media
export const read = actions.read.requested
export const { update, submit } = actions

// Getters
export function get(state, params, defaultValue) {
  const reportKey = state.product.currentProduct.key
  const path = createPath({ reportKey, ...params })

  return utils.get(state, `media.${path}`, defaultValue) || defaultValue
}
function getReport(state, reportKey) {
  return utils.get(state, `media.${reportKey}`)
}

// Side effets
function* readMedia({ payload }) {
  const reportKey = yield select(getCurrentProductKey)
  const { workspace, component } = payload

  try {
    yield put(actions.read.pending({ reportKey, workspace, component }))

    const data = yield call(
      api.get,
      `media/${reportKey}?workspace=${workspace}&component=${component}`
    )

    yield put(actions.receive({ reportKey, component, workspace, data }))
    yield put(actions.read.fulfilled(payload))
  } catch (error) {
    yield put(actions.read.rejected({ ...payload, error }))
  }
}
function* submitMedia() {
  const reportKey = yield select(getCurrentProductKey)
  const report = yield select(getReport, reportKey)

  if (!report) {
    return
  }

  for (let component of Object.keys(report)) {
    for (let workspace of Object.keys(report[component])) {
      const params = { reportKey, component, workspace: Number(workspace) }

      try {
        const { items, received } = yield select(get, params)

        if (isEqual(items, received)) {
          continue
        }

        yield put(actions.save.pending(params))

        // DELETE unused media
        for (let i = received.length - 1; i > items.length - 1; i--) {
          yield call(
            api.del,
            `media/${reportKey}/${component}/${workspace}/${i}`
          )
        }

        // PUT all media objects
        let data = items.map((item, index) => ({ ...item, ...params, index }))

        if (data.length > 0) {
          data = yield call(api.put, `media/${reportKey}`, data)
        }

        yield put(actions.receive({ ...params, data }))
        yield put(actions.save.fulfilled(params))
      } catch (error) {
        yield put(actions.save.rejected({ ...params, error }))
      }
    }
  }
}

export function* sagas() {
  yield takeEvery(read, readMedia)
  yield takeLatest(submit, submitMedia)
}

// Utils
function createPath({ reportKey, workspace, component }, ...extra) {
  return [reportKey, component, workspace, ...extra].join('.')
}
function delegateToBucketReducer(state, action) {
  if (!action.payload) return state

  const path = createPath(action.payload)
  const bucket = utils.get(state, path)

  return utils.set(state, path, bucketReducer(bucket, action))
}
function sorter(a, b) {
  return a.index - b.index
}
