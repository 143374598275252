import React from 'react'
import Table from '../table'
import { useTranslation } from 'react-i18next'

const AvalancheLikelihoodInfoTable = () => {
  const { t } = useTranslation('infoTip')

  const keys = ['widespread', 'specific', 'isolated']

  const columns = [
    {
      title: t('likelihood.titles.distribution'),
      key: 'distribution'
    },
    {
      title: t('likelihood.titles.unreactive'),
      key: 'unreactive'
    },
    {
      title: t('likelihood.titles.stubborn'),
      key: 'stubborn'
    },
    {
      title: t('likelihood.titles.reactive'),
      key: 'reactive'
    },
    {
      title: t('likelihood.titles.touchy'),
      key: 'touchy'
    }
  ]

  const data = keys.map(key => ({
    distribution: t(`likelihood.${key}.distribution`),
    unreactive: t(`likelihood.${key}.unreactive`),
    stubborn: t(`likelihood.${key}.stubborn`),
    reactive: t(`likelihood.${key}.reactive`),
    touchy: t(`likelihood.${key}.touchy`)
  }))

  return (
    <Table
      data={data}
      columns={columns}
    />
  )
}

export default AvalancheLikelihoodInfoTable
