import React from 'react'
import PropTypes from 'prop-types'
import { classList } from 'common/utils'
import InputWrapper from '../inputWrapper'
import css from './style.module.scss'

/**
 * Controlled textarea component. Is compatible with redux-form Field
 */
const TextArea = props => {
  const classNames = classList(
    css.textarea,
    props.globalError ? css.globalError : null,
    props.small ? css.small : null
  )

  const style = {}

  if (props.maxTextAreaWidth) {
    style.maxWidth = props.maxTextAreaWidth
  }

  if (props.height) {
    style.height = props.height
  }

  if (props.width) {
    style.width = props.width
  }

  return (
    <InputWrapper {...props.meta}>
      <textarea
        style={style}
        className={classNames}
        {...props.input}
        maxLength={props.maxLength}
        disabled={props.disabled}
        placeholder={props.placeholder}
      />
    </InputWrapper>
  )
}

TextArea.propTypes = {
  textarea: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxLength: PropTypes.number,
  maxInputWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  globalError: PropTypes.string,
  small: PropTypes.bool
}

TextArea.defaultProps = {
  disabled: false,
  placeholder: null,
  small: false
}

export default TextArea
