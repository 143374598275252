import { createSelector } from 'reselect'
import { values, findIndex, flatten } from 'lodash'
import compareDesc from 'date-fns/compare_desc'

import { getUserId } from 'core/users/selectors'

const allReviewNotesSelector = state => state.notes.allReviewNotes
const notesSelector = state => state.notes.notesById
const getReportId = (state, props) => props.reportId
const getType = (state, props) => props.type
const getWorkspace = (state, props) => props.workspace

const COMPONENTS = [
  'weather',
  'snowpack',
  'avalanche',
  'observation',
  'weakLayers',
  'avalancheProblems',
  'dangerRatings',
  'confidence',
  'communications',
  'offseasonMessage',
  'dangerRatingCriticalFactors',
  'terrainAndTravelAdvisory'
]

// Returns an object whose keys are report ids and whose values are the
// unvalidated notes associated with that ID that require validation
export const getAllReviewNotes = createSelector(
  allReviewNotesSelector,
  notes =>
    notes
      .filter(note => note.requiresValidation && !note.validated)
      .reduce(
        (notes, note) => ({
          ...notes,
          [note.reportId]: [...(notes[note.reportId] || []), note]
        }),
        {}
      )
)

export const getNotesById = createSelector(
  notesSelector,
  note => note
)

export const getNotes = createSelector(
  notesSelector,
  notes => {
    const notesByComponent = COMPONENTS.reduce(
      (notesByComponent, component) => {
        notesByComponent[component] = []
        return notesByComponent
      },
      {}
    )

    const sortedNotes = values(notes).sort((noteA, noteB) => {
      if (noteB.requiresValidation === noteA.requiresValidation) {
        return compareDesc(noteA.date, noteB.date)
      }

      return noteB.requiresValidation - noteA.requiresValidation
    })

    return sortedNotes.reduce((notesByComponent, note, index) => {
      const componentNotes = notesByComponent[note.component]
      const workspaceNotes = componentNotes[note.workspace]

      if (Array.isArray(workspaceNotes)) {
        workspaceNotes.push(note)
      } else {
        componentNotes[note.workspace] = [note]
      }

      return notesByComponent
    }, notesByComponent)
  }
)

export const getComponentsWithNotes = createSelector(
  getNotes,
  notesByComponent =>
    Object.keys(notesByComponent).filter(
      component => notesByComponent[component].length > 0
    )
)

export const getUnvalidatedNotes = createSelector(
  getNotes,
  getComponentsWithNotes,
  (notesByComponent, componentsWithNotes) =>
    componentsWithNotes.filter(
      component =>
        findIndex(
          flatten(notesByComponent[component]).filter(Boolean),
          note => note.requiresValidation && !note.validated
        ) > -1
    )
)

export const getInitialValues = createSelector(
  [getReportId, getUserId, getType, getWorkspace],
  (reportId, author, type, workspace) => ({
    reportId,
    component: type,
    workspace,
    author,
    content: '',
    requiresValidation: false
  })
)
