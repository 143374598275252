/**
 * Simple function testing whether a path is
 * relative or absolute.
 */

export default path => {
  const pattern = /^(http|https|http:|https:|\/\/)/

  if (path.match(pattern)) {
    return 'absolute'
  } else {
    return 'relative'
  }
}
