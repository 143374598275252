import { createAction } from 'common/utils'
import {
  FETCH_NOTES,
  FETCH_ALL_REVIEW_NOTES,
  FAIL_FETCHING_NOTES,
  SET_NOTES,
  SET_ALL_REVIEW_NOTES,
  SUBMIT_NEW_NOTE,
  ADD_NEW_NOTE,
  UPDATE_NOTE
} from './types'

export const fetchNotes = payload => createAction(FETCH_NOTES, payload)
export const fetchAllReviewNotes = payload =>
  createAction(FETCH_ALL_REVIEW_NOTES, payload)
export const failFetchingNotes = () => createAction(FAIL_FETCHING_NOTES)
export const setNotes = payload => createAction(SET_NOTES, payload)
export const setAllNotes = payload =>
  createAction(SET_ALL_REVIEW_NOTES, payload)
export const submitNewNote = payload => createAction(SUBMIT_NEW_NOTE, payload)
export const addNewNote = payload => createAction(ADD_NEW_NOTE, payload)
export const updateNote = payload => createAction(UPDATE_NOTE, payload)
