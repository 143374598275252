import { createAction } from 'common/utils'
import {
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_FAILED,
  SET_PRODUCTS,
  UPDATE_PRODUCT,
  GET_CURRENT_PRODUCT,
  SET_CURRENT_PRODUCT,
  SET_CURRENT_PRODUCT_STATUS,
  GET_YESTERDAYS_PRODUCT,
  SET_YESTERDAYS_PRODUCT,
  GET_YESTERDAYS_PRODUCT_FAIL,
  GET_WORKSPACE_CONTENT,
  SET_WORKSPACE_CONTENT,
  SUBMIT_ALL_FORMS,
  SET_LAST_TIME_SAVED,
  PUBLISH_PRODUCT,
  CREATE_NEW_PRODUCT_VERSION,
  PUBLISH_READY
} from './types'

export const fetchProducts = payload => createAction(FETCH_PRODUCTS, payload)
export const fetchProductsFailed = payload =>
  createAction(FETCH_PRODUCTS_FAILED, payload)

export const setProducts = payload => createAction(SET_PRODUCTS, payload)

export const publishProduct = () => createAction(PUBLISH_PRODUCT)

export const updateProduct = payload => createAction(UPDATE_PRODUCT, payload)

export const createNewProductVersion = payload =>
  createAction(CREATE_NEW_PRODUCT_VERSION, payload)

export const getCurrentProduct = payload =>
  createAction(GET_CURRENT_PRODUCT, payload)
export const setCurrentProduct = payload =>
  createAction(SET_CURRENT_PRODUCT, payload)
export const setCurrentProductStatus = payload =>
  createAction(SET_CURRENT_PRODUCT_STATUS, payload)

export const getYesterdaysProduct = payload =>
  createAction(GET_YESTERDAYS_PRODUCT, payload)
export const setYesterdaysProduct = payload =>
  createAction(SET_YESTERDAYS_PRODUCT, payload)
export const getYesterdaysProductFail = () =>
  createAction(GET_YESTERDAYS_PRODUCT_FAIL)

export const getWorkspaceContent = payload =>
  createAction(GET_WORKSPACE_CONTENT, payload)
export const setWorkspaceContent = payload =>
  createAction(SET_WORKSPACE_CONTENT, payload)

export const submitAllForms = payload => createAction(SUBMIT_ALL_FORMS, payload)
export const setLastTimeSaved = () => createAction(SET_LAST_TIME_SAVED)

export const publishReady = payload => createAction(PUBLISH_READY, payload)
