import { createAction } from 'common/utils'

import {
  FETCH_CONFIDENCE,
  FAIL_FETCHING_CONFIDENCE,
  SET_CONFIDENCE,
  SUBMIT_CONFIDENCE,
  SUBMIT_ALL_CONFIDENCE_WORKSPACES
} from './types'

export const fetchConfidence = payload =>
  createAction(FETCH_CONFIDENCE, payload)
export const failFetchingConfidence = payload =>
  createAction(FAIL_FETCHING_CONFIDENCE, payload)
export const setConfidence = payload => createAction(SET_CONFIDENCE, payload)
export const submitConfidence = payload =>
  createAction(SUBMIT_CONFIDENCE, payload)
export const submitAllConfidenceWorkspaces = payload =>
  createAction(SUBMIT_ALL_CONFIDENCE_WORKSPACES, payload)
