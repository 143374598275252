import React from 'react'

import { TextCell } from 'common/components/table/cells'
import { useTranslation } from 'react-i18next'

const UntilFurtherNotice = () => {
  const { t } = useTranslation('common')
  return <TextCell>{t('untilFurtherNotice')}</TextCell>
}

export default UntilFurtherNotice
