import { createAction } from 'common/utils'

import {
  FETCH_WEAK_LAYERS,
  FAIL_FETCHING_WEAK_LAYERS,
  SET_WEAK_LAYERS,
  TOGGLE_WEAK_LAYER_VISIBILITY,
  CREATE_WEAK_LAYER,
  SUBMIT_WEAK_LAYER,
  ADD_WEAK_LAYER,
  UPDATE_WEAK_LAYER,
  DELETE_WEAK_LAYER,
  SUBMIT_WEAK_LAYER_COMMENT,
  ADD_WEAK_LAYER_COMMENT
} from './types'

export const fetchWeakLayers = payload =>
  createAction(FETCH_WEAK_LAYERS, payload)
export const deleteWeakLayer = payload =>
  createAction(DELETE_WEAK_LAYER, payload)

export const failFetchingWeakLayers = payload =>
  createAction(FAIL_FETCHING_WEAK_LAYERS, payload)
export const setWeakLayers = payload => createAction(SET_WEAK_LAYERS, payload)
export const toggleWeakLayersVisibility = () =>
  createAction(TOGGLE_WEAK_LAYER_VISIBILITY)
export const addWeakLayer = payload => createAction(ADD_WEAK_LAYER, payload)
export const updateWeakLayer = payload =>
  createAction(UPDATE_WEAK_LAYER, payload)

export const createWeakLayer = payload =>
  createAction(CREATE_WEAK_LAYER, payload)
export const submitWeakLayer = payload =>
  createAction(SUBMIT_WEAK_LAYER, payload)

export const submitWeakLayerComment = payload =>
  createAction(SUBMIT_WEAK_LAYER_COMMENT, payload)
export const addWeakLayerComment = payload =>
  createAction(ADD_WEAK_LAYER_COMMENT, payload)
