import { createSelector } from 'reselect'

const statementsByLanguageAndComponentSelector = (
  state,
  { language, component }
) => {
  if (!component || !state.statements.statementsByComponent[component])
    return []

  if (!language) language = 'en'

  return state.statements.statementsByComponent[component].map(statement => {
    const [statementForLanguage] = statement.values.filter(value =>
      value.includes(language)
    )
    return {
      id: statement.id,
      tags: statement.tags,
      display: statementForLanguage[1]
    }
  })
}

// createSelector is for meomization
export const filterStatements = createSelector(
  statementsByLanguageAndComponentSelector,
  statements => statements
)
