import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { reduxForm, Field, getFormSyncErrors } from 'redux-form'
import { convertFromRaw } from 'draft-js'

import { NOTE_FORM_NAME } from 'core/product/config/constants'
import { submitNewNote } from './actions'
import { getInitialValues } from './selectors'
import { RichTextEditor } from 'common/components/form'
import { Button } from 'common/components'
import { richTextRequired } from 'common/helpers/validation'
import Note from './note'

import css from './style.module.scss'

const RichTextEditorWrapper = ({ input }) => (
  <RichTextEditor
    value={input.value}
    onBlur={input.onChange}
    allowPreview={false}
  />
)

let Notes = ({
  notes,
  submitting,
  invalid,
  dirty,
  errors,
  handleSubmit,
  disabled
}) => {
  const { t } = useTranslation(['common', 'products', 'validation'])

  return (
    <div className={css.content}>
      <form className={css.newNote} onSubmit={handleSubmit}>
        <Field name="author" component="input" hidden />
        <Field name="component" component="input" hidden />
        <Field name="workspace" component="input" hidden />
        <Field
          name="content"
          component={RichTextEditorWrapper}
          placeholder={t('products:note')}
          height="100%"
          small
          validate={richTextRequired}
        />
        {dirty && Object.keys(errors).length > 0 && (
          <div className={css.error}>{t(`validation:${errors.content}`)}</div>
        )}
        <div className={css.newNoteFooter}>
          <div>
            <label htmlFor="requiresValidation" className={css.pinnedField}>
              <Field
                name="requiresValidation"
                component="input"
                type="checkbox"
              />
              <span>{t('products:createReviewNote')}</span>
            </label>
          </div>
          <Button
            className={css.submit}
            context="go"
            type="submit"
            disabled={submitting || invalid}
          >
            {t('common:submit')}
          </Button>
        </div>
      </form>
      <div className={css.notesContainer}>
        {notes.map(
          ({ date, author, content, requiresValidation, validated }, index) => (
            <Note
              key={`note_${index}-${date}_${author}`}
              dateTime={date}
              forecaster={author}
              content={content}
              requiresValidation={requiresValidation}
              validated={validated}
            />
          )
        )}
      </div>
    </div>
  )
}

Notes.propTypes = {
  type: PropTypes.string.isRequired,
  notes: PropTypes.array,
  workspace: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSubmit: PropTypes.func.isRequired
}

Notes.defaultProps = {
  notes: []
}

Notes = reduxForm()(Notes)

const mapStateToProps = (state, props) => ({
  form: `${NOTE_FORM_NAME}_workspace${props.workspace}`,
  initialValues: getInitialValues(state, props),
  errors: getFormSyncErrors(`${NOTE_FORM_NAME}_workspace${props.workspace}`)(
    state
  )
})

const mapDispatchToProps = dispatch => ({
  onSubmit: values => {
    const contentState = convertFromRaw(values.content)
    if (contentState.getPlainText().length < 1) return

    dispatch(
      submitNewNote({
        note: values
      })
    )
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notes)
