import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Formik } from 'formik'
import isEqual from 'lodash/isEqual'

import { Workspace, WorkspaceContent, WorkspaceFooter } from 'common/components'
import { getNotes } from 'core/product/components/notes/selectors'
import { fetchStatements } from 'common/data/statements/actions'
import { fetchTags } from 'common/data/tags/actions'
import {
  fetchAvalancheProblems,
  submitAvalancheProblems
} from 'core/avalancheProblems/actions'
import { setWorkspaces } from 'core/product/components/visibleDays/actions'
import { fetchWeakLayers } from 'core/weakLayers/actions'
import { fetchTerrainAndTravelAdvice } from 'common/data/terrainAndTravelAdvice/actions'
import { terrainAndTravelAdviceStatus } from 'common/data/terrainAndTravelAdvice/selectors'
import {
  getInitialFormValues,
  getPublicSumForWorkspace,
  getWeakLayerOptionsByProductLocation
} from 'core/avalancheProblems/selectors'
import { putItem } from 'common/helpers/orderedList'

import Form from '../avalancheProblemsForm'
import TerrainAndTravelAdvice from '../avalancheProblemsTerrainTravel'
import SubmitWorkspaceButton from 'common/components/submitWorkspaceButton'

import css from './style.module.scss'

const AvalancheProblemsSingle = ({
  fetchData,
  workspace,
  index,
  isEditing,
  notes,
  problemList,
  relatedWeakLayers,
  terrainAndTravelAdviceStatus,
  initialValues,
  handleSubmit,
}) => {

  const fetchDataCallback = React.useCallback(() => {
    fetchData(terrainAndTravelAdviceStatus, relatedWeakLayers)
  }, [terrainAndTravelAdviceStatus, relatedWeakLayers, fetchData])

  React.useEffect(() => {
    fetchDataCallback()
  }, [fetchDataCallback])

  return (
    <Workspace
      className={css.workspace}
      type="avalancheProblems"
      day={workspace}
      notes={notes['avalancheProblems'][workspace]}
      fullWidth
      hideDate
      hideDay
      hideButtons
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          return new Promise(resolve =>
            setTimeout(() => {
              if (!isEqual(values, initialValues)) {
                handleSubmit(values)
                actions.setSubmitting(false)
              }
              resolve()
            }, 1000)
          )
        }}
      >
        {props => (
          <>
            <WorkspaceContent
              id={'avalancheSingle'}
              className={css.avalancheSingle}
              hasFooter={!isEditing}
              hasMainAndAside
            >
              <Form
                isEditing={isEditing}
                relatedWeakLayers={relatedWeakLayers}
              />
              <TerrainAndTravelAdvice workspace={workspace} />
            </WorkspaceContent>
            {!isEditing && (
              <WorkspaceFooter>
                <SubmitWorkspaceButton
                  text={'submitProblem'}
                  isSubmitting={props.isSubmitting}
                  handleSubmit={() => {
                    Object.keys(props.values).forEach(field =>
                      props.setFieldTouched(field, true)
                    )

                    props.submitForm()
                  }}
                />
              </WorkspaceFooter>
            )}
          </>
        )}
      </Formik>
    </Workspace>
  )
}

const mapStateToProps = (state, props) => {
  const index = parseInt(props.match.params.index, 10)
  const workspace = parseInt(props.match.params.workspace, 10)
  const isEditing = props.match.params.index !== 'new'

  return {
    index,
    workspace,
    isEditing,
    notes: getNotes(state),
    problemList:
      state.avalancheProblems.avalancheProblemsByDays[
        props.match.params.workspace
      ].problemList,
    publicSum: getPublicSumForWorkspace(state, workspace),
    initialValues: getInitialFormValues(state, isEditing, workspace, index),
    relatedWeakLayers: getWeakLayerOptionsByProductLocation(state),
    terrainAndTravelAdviceStatus: terrainAndTravelAdviceStatus(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchData(terrainAndTravelAdviceStatus, relatedWeakLayers) {
    dispatch(fetchAvalancheProblems())
    dispatch(fetchStatements('terrainandtraveladvice'))
    dispatch(fetchTags('terrainandtraveladvice'))
    dispatch(setWorkspaces({ toShow: 0, defaultDays: [] }))

    /* If the Weak Layers have not yet been fetched, fetch them now for the
     * Related Weak Layer dropdown component. These are only the Weak Layers
     * associated with the Location that this product is assigned to.
     */
    if (relatedWeakLayers && relatedWeakLayers.length === 0) {
      dispatch(fetchWeakLayers())
    }

    if (
      terrainAndTravelAdviceStatus !== 'FULFILLED' &&
      terrainAndTravelAdviceStatus !== 'PENDING'
    ) {
      dispatch(fetchTerrainAndTravelAdvice())
    }
  },
  submit(values) {
    dispatch(submitAvalancheProblems(values))
  }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  ...dispatchProps,
  handleSubmit(values) {
    const { workspace, isEditing, publicSum, problemList } = stateProps
    let updatedAvalancheProblems = [...problemList]

    if (values) {
      let avalancheProblemForm = {
        ...values,
        aspectElevation: JSON.parse(values.aspectElevation),
        hazard: JSON.parse(values.hazard),
        comment: JSON.parse(values.comment)
      }
      if (!isEditing) {
        avalancheProblemForm.index = problemList.length
        avalancheProblemForm.public = publicSum < 3
      }

      updatedAvalancheProblems = putItem(
        problemList,
        avalancheProblemForm.index,
        avalancheProblemForm
      )
    }

    dispatchProps.submit({
      workspace,
      isEditing,
      problemList: updatedAvalancheProblems,
      redirect: ownProps.history.push
    })
  }
})

AvalancheProblemsSingle.propTypes = {
  workspace: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  isEditing: PropTypes.bool.isRequired,
  notes: PropTypes.object.isRequired,
  problemList: PropTypes.array.isRequired,
  relatedWeakLayers: PropTypes.array.isRequired,
  terrainAndTravelAdviceStatus: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withTranslation('common')
)(AvalancheProblemsSingle)
