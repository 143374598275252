import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../../auth'

import { Button } from 'common/components'

const Logout = ({ onLogout }) => {
  const { t } = useTranslation('user')
  const { logout } = useAuth()

  return (
    <Button context="stop" onClick={logout}>
      {t('user:logout')}
    </Button>
  )
}

export default Logout
