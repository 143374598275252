import { createSelector } from 'reselect'
import { get } from 'lodash'

export const usersSelector = state => state.users

export const allUsersSelector = createSelector(usersSelector, users =>
  get(users, 'usersById')
)

export const currentUserSelector = createSelector(usersSelector, users =>
  get(users, 'currentUser')
)

export const getUserLang = createSelector(currentUserSelector, user =>
  get(user, 'language', 'en')
)

export const getUserId = createSelector(currentUserSelector, user => user.id)

export const getInitialUserFormValues = createSelector(
  currentUserSelector,
  user => ({
    language: 'en',
    ...user
  })
)
