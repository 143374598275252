import Immutable from 'seamless-immutable'
import { convertToRaw, ContentState } from 'draft-js'

import { createReducer } from 'common/utils'
import * as types from './types'

const INITIAL_STATE = Immutable({
  isFetching: false,
  offSeason: {
    season: null,
    comments: []
  }
})

const setSeason = (state, offSeason) =>
  Immutable.merge(state, {
    isFetching: false,
    offSeason: {
      ...state.offSeason,
      ...offSeason,
      /*  The comments textboxes should be initialized with an empty DraftJS
       *  ContentState object. This ensures accurate object comparisons when
       *  checking if the text has changed value.
       */
      comments: offSeason.comments.map(lang => lang[1]
          ? lang
          : [lang[0], convertToRaw(ContentState.createFromText(''))]
        )
    }
  })

const fetchSeason = state => Immutable.set(state, 'isFetching', true)

const failFetchSeason = state => Immutable.set(state, 'isFetching', false)

const reducers = {
  [types.SET_SEASON]: setSeason,
  [types.FETCH_SEASON]: fetchSeason,
  [types.FAIL_FETCH_SEASON]: failFetchSeason
}

export default createReducer(INITIAL_STATE, reducers)
