import { createSelector } from 'reselect'

import { getCurrentProductLocation } from 'core/product/selectors'
import { getUserLang } from 'core/users/selectors'
import { getNonPersistentWeakLayerId } from 'core/weakLayers/selectors'
import { STICKY_WEAK_LAYER_NAME } from 'core/product/config/constants'

export const initialHazardValue = JSON.stringify({
  size: {
    from: 1,
    to: 2
  },
  likelihood: {
    from: 'unlikely',
    to: 'possible'
  },
  centroid: {
    size: 1.5,
    likelihood: 'possible_unlikely'
  }
})

export const avalancheProblemsStateSelector = state => state.avalancheProblems
const getParams = (state, isEditing, workspace, index) => ({
  isEditing,
  workspace,
  index
})
const getActiveWeakLayers = state => state.weakLayers.weakLayersById

export const avalancheProblemsSelector = createSelector(
  avalancheProblemsStateSelector,
  avalancheProblemsState => avalancheProblemsState.avalancheProblemsByDays
)

export const getPublicSumForWorkspace = createSelector(
  (state, workspace) => workspace,
  avalancheProblemsSelector,
  (day, avalancheProblemsByDays) => {
    const workspace = avalancheProblemsByDays[day]
    return workspace ? workspace.publicSum : null
  }
)

export const getInitialFormValues = createSelector(
  getParams,
  getUserLang,
  getNonPersistentWeakLayerId,
  avalancheProblemsSelector,
  (params, language, weakLayerId, avalancheProblemsByDays) => {
    const { isEditing, workspace, index } = params

    if (
      isEditing &&
      avalancheProblemsByDays[workspace].problemList.length > 0
    ) {
      const problem = avalancheProblemsByDays[workspace].problemList[index]
      return {
        ...problem,
        aspectElevation: JSON.stringify(problem.aspectElevation),
        hazard: JSON.stringify(problem.hazard),
        comment: JSON.stringify(problem.comment)
      }
    }

    return {
      problemTypeId: '0',
      distribution: '0',
      sensitivity: '0',
      typicalSize: '0',
      weakLayerId,
      comment: JSON.stringify([[language, {}]]),
      public: false,
      status: 'active',
      aspectElevation: JSON.stringify([]),
      hazard: initialHazardValue,
      typicalDepth: {
        from: null,
        to: null
      }
    }
  }
)

export const getWeakLayerOptionsByProductLocation = createSelector(
  getCurrentProductLocation,
  getActiveWeakLayers,
  (currentProductLocation, weakLayers) => {
    const options = Object.keys(weakLayers).map(id => {
      if (
        weakLayers[id].location !== currentProductLocation &&
        weakLayers[id].name !== STICKY_WEAK_LAYER_NAME
      )
        return null

      return {
        label:
          weakLayers[id].name.charAt(0).toUpperCase() +
          weakLayers[id].name.slice(1),
        value: weakLayers[id].id
      }
    })

    return options.filter(Boolean)
  }
)
