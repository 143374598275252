import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getUserLang } from 'core/users/selectors'
import { PageHeader } from 'common/components'
import LastTimeSaved from 'core/product/components/lastTimeSaved'

import css from './style.module.scss'

const LastTimeSavedHeader = ({ language, lastTimeSaved }) => {
  return (
    <PageHeader>
      <div className={css.lastTimeSaveContainer}>
        {lastTimeSaved && (
          <LastTimeSaved date={lastTimeSaved} language={language} />
        )}
      </div>
    </PageHeader>
  )
}

LastTimeSavedHeader.propTypes = {
  language: PropTypes.string.isRequired,
  lastTimeSaved: PropTypes.instanceOf(Date)
}

const mapStateToProps = state => ({
  language: getUserLang(state),
  lastTimeSaved: state.product.lastTimeSaved
})

export default connect(mapStateToProps)(LastTimeSavedHeader)
