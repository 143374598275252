/* Copy Forward / Reset Buttons */
export const FULL_COPY_FUNCTIONALITY = ['0', '1', 'R', 'C', 'N']
export const PARTIAL_COPY_FUNCTIONALITY = FULL_COPY_FUNCTIONALITY.slice(2, 5)
export const JUST_COPY_FUNCTIONALITY = FULL_COPY_FUNCTIONALITY.slice(3, 5)
export const NO_COPY_FUNCTIONALITY = FULL_COPY_FUNCTIONALITY.slice(4, 5)
export const RESET_NOTES_FUNCTIONALITY = [
  FULL_COPY_FUNCTIONALITY[2],
  FULL_COPY_FUNCTIONALITY[4]
]

/* Form Names */
export const SUMMARY_FORM_NAME = 'Summary'
export const NOTE_FORM_NAME = 'note'
export const WEAK_LAYER_FORM_NAME = 'weakLayer'
export const WEAK_LAYER_COMMENT_FORM_NAME = 'weakLayerComment'
export const AVALANCHE_LAYER_FORM_NAME = 'avalancheProblem'
export const CRITICAL_FACTORS_FORM_NAME = 'criticalFactors'
export const MESSAGING_ITEM_FORM_NAME = 'reviewMessagingItem'
export const WHITEBOARD_FORM_NAME = 'whiteboard'

/* Product Statuses */
export const ASSIGNED_STATUS = 'assigned'
export const PUBLISHED_STATUS = 'published'
export const DRAFT_STATUS = 'draft'

/* Product Types */
export const OFF_SEASON = 'offseason'

/* Weak Layer names are not translated, however, the Forecaster would like the
 * "Non-Persistent" layer name to be display in both French and English. In
 * order to avoid translating just that layer name frontend it has been
 * hard-coded in both languages backend.
 */
export const STICKY_WEAK_LAYER_NAME = 'Non-Persistent/Non-Persistente'
