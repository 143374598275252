import React from 'react'
import PropTypes from 'prop-types'

import { classList } from 'common/utils'

import css from './style.module.scss'

/**
 * The <Combo /> element is a templating element that wraps
 * two pieces of content that need to leverage contextual alignment
 * between themselves -- for example, an avatar image and user name
 * vertically aligned. <Combo /> expects two children -- <ComboFirst />
 * and <ComboLast /> to function properly.
 *
 * When used normally, <ComboFirst /> will take up a determined amount
 * of width, and <ComboLast /> will take up the remainder. When reverse
 * is applied to <Combo />, that order switches.
 */
const Combo = props => {
  const classes = classList(
    css.combo,
    props.reverse ? css.reverse : null,
    props.middle ? css.middle : null,
    props.bottom ? css.bottom : null,
    props.wide ? css.wide : null,
    props.mobileReset ? css.mobileReset : null
  )

  return <div className={classes}>{props.children}</div>
}

Combo.propTypes = {
  /* Expects `<ComboFirst />` and `<ComboLast />` */
  children: PropTypes.array.isRequired,
  /* If true, reverses the width order of `<ComboFirst />` + `<ComboLast />` */
  reverse: PropTypes.bool,
  /* If true, vertically aligns the content to middle */
  middle: PropTypes.bool,
  /* If true, vertically aligns content to bottom / baseline */
  bottom: PropTypes.bool
}

export default Combo
