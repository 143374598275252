import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import MenuUpOutlineIcon from 'mdi-react/MenuUpOutlineIcon'
import MenuDownOutlineIcon from 'mdi-react/MenuDownOutlineIcon'

import css from './style.module.scss'

const cx = classNames.bind(css)

class MoveKeys extends React.Component {
  static propTypes = {
    /** disable the arrows */
    disabled: PropTypes.bool,

    /** set to true when at the start of the list, disables up arrow */
    start: PropTypes.bool,

    /** set to true when at the end of the list, disables down arrow */
    end: PropTypes.bool,

    /** called when user clicks on an arrow with -1 (up) or 1 (down) */
    onClick: PropTypes.func.isRequired
  }

  static defaultProps = {
    disabled: false,
    start: false,
    end: false
  }

  /**
   * Check if the up or down arrow should be disabled based on start or end
   * props
   */
  isDisabled(check) {
    const { start, end, disabled } = this.props
    return check || (start && end) || disabled
  }

  handleClickUp = () => {
    if (!this.isDisabled(this.props.start)) this.props.onClick(-1)
  }

  handleClickDown = () => {
    if (!this.isDisabled(this.props.end)) this.props.onClick(1)
  }

  render() {
    const { start, end } = this.props
    const iconStartClasses = cx('icon', { disabled: this.isDisabled(start) })
    const iconEndClasses = cx('icon', { disabled: this.isDisabled(end) })

    return (
      <div className={css.moveKeys}>
        <MenuUpOutlineIcon
          className={iconStartClasses}
          onClick={this.handleClickUp}
        />
        <MenuDownOutlineIcon
          className={iconEndClasses}
          onClick={this.handleClickDown}
        />
      </div>
    )
  }
}

export default MoveKeys
