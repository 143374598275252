import { takeLatest, takeEvery, all } from 'redux-saga/effects'

import {
  FETCH_WEATHER_SUMMARIES,
  SUBMIT_WEATHER_WORKSPACE_SUMMARY
} from './types'
import {
  failFetchingWeatherSummaries,
  setWeatherSummaries,
  updateWeatherSummary
} from './actions'
import {
  fetchSummaries,
  submitWorkspaceSummary
} from 'core/summariesSagas'

const WEATHER_SUMMARY_TYPE = 'weather'

function* onFetchWeatherSummaries() {
  yield takeLatest(FETCH_WEATHER_SUMMARIES, fetchWeatherSummaries)
}

function* fetchWeatherSummaries({ payload }) {
  yield fetchSummaries({
    summaryType: WEATHER_SUMMARY_TYPE,
    setSummaries: setWeatherSummaries,
    failFetchingSummaries: failFetchingWeatherSummaries,
    updateSummary: updateWeatherSummary
  })
}

function* onSubmitWeatherWorkspaceSummary() {
  yield takeEvery(
    SUBMIT_WEATHER_WORKSPACE_SUMMARY,
    submitWeatherWorkspaceSummary
  )
}

function* submitWeatherWorkspaceSummary({ payload }) {
  yield submitWorkspaceSummary({
    formName: payload.formName,
    summaryType: WEATHER_SUMMARY_TYPE,
    updateSummary: updateWeatherSummary
  })
}

export default function* rootSaga() {
  yield all([
    onSubmitWeatherWorkspaceSummary(),
    onFetchWeatherSummaries()
  ])
}
