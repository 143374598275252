import Immutable from 'seamless-immutable'
import { createReducer } from 'common/utils'
import * as types from './types'

const INITIAL_STATE = Immutable({
  usersById: {},
  currentUser: {}
})

const reducers = {
  [types.SET_USER]: hydrateUser,
  [types.SET_USERS]: setUsers,
  [types.UPDATE_USER]: updateUser,
  [types.UPDATE_CURRENT_USER]: updateCurrentUser,
  [types.LOGOUT_USER]: logoutUser
}

function hydrateUser(state, payload) {
  return Immutable.set(state, 'currentUser', payload)
}

function setUsers(state, payload) {
  if (!payload) return state

  const usersById = payload.reduce((users, user) => {
    const { id, name } = user
    users[id] = name
    return users
  }, {})

  return Immutable.set(state, 'usersById', usersById)
}

function updateUser(state, payload) {
  const { id, name } = payload
  const updatedUsers = {
    ...state.usersById,
    [id]: name
  }
  return Immutable.set(state, 'usersById', updatedUsers)
}

function updateCurrentUser(state, payload) {
  return Immutable.set(state, 'currentUser', payload)
}

function logoutUser(state) {
  return INITIAL_STATE
}

export default createReducer(INITIAL_STATE, reducers)
