import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Table } from 'common/components'
import {
  NewLocationHeaderCell,
  LocationHeaderCell,
  CreatedOnHeaderCell,
  CreatedByHeaderCell
} from './headerCells'
import {
  HandleColumnCell,
  NameColumnCell,
  CreatedOnColumnCell,
  CreatedByColumnCell
} from './columnCells'
import { filterTextSearch } from 'common/components/table/filters'

const NEW_LOCATION_WIDTH = 140

const LocationsTable = ({ locations, users }) => (
  <div>
    <Table
      filterable
      sortable
      data={locations}
      showPageJump={false}
      columns={[
        {
          id: 'handle',
          accessor: location => ({
            id: location.id,
            handle: get(location, 'handle') || get(location, 'name')
          }),
          filterable: true,
          Filter: NewLocationHeaderCell,
          Cell: HandleColumnCell,
          minWidth: NEW_LOCATION_WIDTH
        },
        {
          id: 'name',
          accessor: location => get(location, 'name'),
          Header: LocationHeaderCell,
          Cell: NameColumnCell,
          filterMethod: filterTextSearch
        },
        {
          id: 'created',
          accessor: location => get(location, 'created'),
          Header: CreatedOnHeaderCell,
          Cell: CreatedOnColumnCell,
          filterable: false
        },
        {
          id: 'owner',
          accessor: location => get(location, 'owner'),
          Header: CreatedByHeaderCell,
          Cell: ({ value }) => (
            <CreatedByColumnCell userOptions={users} value={value} />
          ),
          filterable: false
        }
      ]}
    />
  </div>
)

LocationsTable.propTypes = {
  locations: PropTypes.array.isRequired,
  users: PropTypes.object.isRequired
}

export default LocationsTable
