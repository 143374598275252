import { createAction } from 'common/utils'
import {
  FETCH_SNOWPACK_SUMMARIES,
  FAIL_FETCHING_SNOWPACK_SUMMARIES,
  SET_SNOWPACK_SUMMARIES,
  SUBMIT_SNOWPACK_WORKSPACE_SUMMARY,
  ADD_NEW_SNOWPACK_SUMMARY,
  UPDATE_SNOWPACK_SUMMARY
} from './types'

export const fetchSnowpackSummaries = payload =>
  createAction(FETCH_SNOWPACK_SUMMARIES, payload)
export const failFetchingSnowpackSummaries = () =>
  createAction(FAIL_FETCHING_SNOWPACK_SUMMARIES)
export const setSnowpackSummaries = payload =>
  createAction(SET_SNOWPACK_SUMMARIES, payload)
export const submitSnowpackWorkspaceSummary = payload =>
  createAction(SUBMIT_SNOWPACK_WORKSPACE_SUMMARY, payload)
export const addNewSnowpackSummary = payload =>
  createAction(ADD_NEW_SNOWPACK_SUMMARY, payload)
export const updateSnowpackSummary = payload =>
  createAction(UPDATE_SNOWPACK_SUMMARY, payload)
