import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import MenuIcon from 'mdi-react/MenuIcon'
import css from './style.module.scss'

const VIEWS = [
  {
    to: 'products',
    display: 'dashboard'
  },
  {
    to: 'locations'
  },
  {
    to: 'profile'
  }
]

let NavItem = ({ t, view }) => (
  <NavLink
    to={`/avid/${view.to}`}
    className={css.navItem}
    activeClassName={css.active}
  >
    {view.display ? t(`common:${view.display}`) : t(`common:${view.to}`)}
  </NavLink>
)

NavItem.propTypes = {
  t: PropTypes.func.isRequired,
  view: PropTypes.object.isRequired
}

NavItem = withTranslation('common')(NavItem)

const NavDropdown = ({ onClick }) => {
  return (
    <div className={css.dropdown} onClick={onClick}>
      {VIEWS.map((view, i) => (
        <NavItem key={i} view={view} />
      ))}
    </div>
  )
}

NavDropdown.propTypes = {
  onClick: PropTypes.func.isRequired
}

class Nav extends Component {
  state = {
    isOpen: false
  }

  handleOnClick = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return (
      <div className={css.container}>
        {this.state.isOpen && (
          <div className={css.invisibleOverlay} onClick={this.handleOnClick} />
        )}
        <MenuIcon className={css.menu} onClick={this.handleOnClick} />
        {this.state.isOpen && <NavDropdown onClick={this.handleOnClick} />}
      </div>
    )
  }
}

Nav.propTypes = {
  t: PropTypes.func.isRequired
}

export default withTranslation('common')(Nav)
