import { createSelector } from 'reselect'
import compareDesc from 'date-fns/compare_desc'
import isEmpty from 'lodash/isEmpty'
import format from 'date-fns/format'
import { getUserLang } from 'core/users/selectors'

import { FORMAT } from 'common/helpers/reactDayPicker'
import { STICKY_WEAK_LAYER_NAME } from 'core/product/config/constants'

export const weakLayersStateSelector = state => state.weakLayers
const getWeakLayerId = (state, props) => props.weakLayerId
export const getWeakLayerComments = (state, { weakLayerId }) => {
  const weakLayer = state.weakLayers.weakLayersById[weakLayerId]

  if (!weakLayer) return []
  return weakLayer.comments
}
export const getNonPersistentWeakLayerId = state =>
  Object.keys(state.weakLayers.weakLayersById).find(
    id => state.weakLayers.weakLayersById[id].name === STICKY_WEAK_LAYER_NAME
  )

export const weakLayersSelector = createSelector(
  weakLayersStateSelector,
  stateKey => {
    const { weakLayersById, hideInactiveWeakLayers } = stateKey
    let weakLayerIdsArray = Object.keys(weakLayersById)

    if (hideInactiveWeakLayers) {
      weakLayerIdsArray = weakLayerIdsArray.filter(
        weakLayerId => weakLayersById[weakLayerId].status !== 'inactive'
      )
    }

    weakLayerIdsArray.sort((a, b) => {
      return compareDesc(
        weakLayersById[a].burialDate,
        weakLayersById[b].burialDate
      )
    })

    /* Keep "Non-Persistent" layer at the top of the list */
    const nonPersistentIndex = weakLayerIdsArray.findIndex(
      id => weakLayersById[id].name === STICKY_WEAK_LAYER_NAME
    )
    if (nonPersistentIndex > -1) {
      const nonPersistentId = weakLayerIdsArray[nonPersistentIndex]
      weakLayerIdsArray.splice(nonPersistentIndex, 1)
      weakLayerIdsArray.splice(0, 0, nonPersistentId)
    }

    return weakLayerIdsArray
      .reduce((acc, weakLayerId) => {
        acc.push(weakLayersById[weakLayerId])
        return acc
      }, [])
      .filter(Boolean)
  }
)

export const getWeakLayerInitialValues = createSelector(
  [getWeakLayerId, weakLayersStateSelector, getUserLang],
  (weakLayerId, { weakLayersById }, language) => {
    if (!weakLayerId) return {}

    if (!isEmpty(weakLayersById)) {
      const burialDate = format(
        weakLayersById[weakLayerId].burialDate,
        FORMAT,
        { locale: language }
      )

      const initialValues = {
        depth: { to: '', from: '' },
        thickness: { to: '', from: '' },
        ...weakLayersById[weakLayerId],
        burialDate
      }

      delete initialValues.comments
      return initialValues
    }

    return {}
  }
)
