export const FETCH_WEAK_LAYERS = 'weakLayers/FETCH_WEAK_LAYERS'
export const DELETE_WEAK_LAYER = 'weakLayers/DELETE_WEAK_LAYER'

export const FAIL_FETCHING_WEAK_LAYERS = 'weakLayers/FAIL_FETCHING_WEAK_LAYERS'
export const SET_WEAK_LAYERS = 'weakLayers/SET_WEAK_LAYERS'
export const TOGGLE_WEAK_LAYER_VISIBILITY =
  'weakLayers/TOGGLE_WEAK_LAYER_VISIBILITY'
export const ADD_WEAK_LAYER = 'weakLayers/ADD_WEAK_LAYER'
export const UPDATE_WEAK_LAYER = 'weakLayers/UPDATE_WEAK_LAYER'

export const CREATE_WEAK_LAYER = 'weakLayers/CREATE_WEAK_LAYER'
export const SUBMIT_WEAK_LAYER = 'weakLayers/SUBMIT_WEAK_LAYER'

export const SUBMIT_WEAK_LAYER_COMMENT = 'weakLayers/SUBMIT_WEAK_LAYER_COMMENT'
export const ADD_WEAK_LAYER_COMMENT = 'weakLayers/ADD_WEAK_LAYER_COMMENT'
