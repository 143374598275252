import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Editor } from 'draft-js'

import { formatNoteDate } from 'common/helpers/formatDate'
import { getEditorStateFromRaw } from 'common/components/form/richTextEditor'

import css from './style.module.scss'

const Note = ({ requiresValidation, validated, dateTime, author, content }) => {
  const { t } = useTranslation('products')
  return (
    <div className={css.noteContainer}>
      <div className={css.noteHeader}>
        <div className={css.pinned}>
          {requiresValidation && (
            <span
              className={css[validated ? 'validated' : 'requires-validation']}
            >
              {t(validated ? 'validated' : 'requiresValidation')}
            </span>
          )}
        </div>
        <div className={css.dateAndAuthor}>
          {formatNoteDate(dateTime)} by{' '}
          <span className={css.author}>{author}</span>
        </div>
      </div>
      <div className={css.note}>
        <Editor readOnly={true} editorState={getEditorStateFromRaw(content)} />
      </div>
    </div>
  )
}

Note.propTypes = {
  requiresValidation: PropTypes.bool.isRequired,
  validated: PropTypes.string,
  dateTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
    .isRequired,
  author: PropTypes.string.isRequired,
  forecaster: PropTypes.string.isRequired,
  content: PropTypes.object
}

const mapStateToProps = ({ users }, { forecaster }) => ({
  author: users.usersById[forecaster]
})

export default connect(mapStateToProps)(Note)
