import React from 'react'
import PropTypes from 'prop-types'

import StatementResult from 'core/statements/statementResult'
import lunr from 'lunr'
import memoize from 'lodash/memoize'
import isEqual from 'lodash/isEqual'
import { withTranslation } from 'react-i18next'

import css from './style.module.scss'

function Statement(props) {
  // pull off the everything but the tagged data
  const { display, tags, selected } = props.statement
  return (
    <StatementResult tags={tags} disabled={selected}>
      {display}
    </StatementResult>
  )
}

class QueryStatements extends React.Component {
  static defaultProps = {
    initialFilter: '',
    statements: []
  }

  constructor(props) {
    super(props)
    let results = []
    if (props.initialFilter) {
      try {
        const index = this.getIndex(props.statements)
        results = this.getResults(index, props.initialFilter)
      } catch (e) {
        results = []
      }
    }

    this.state = {
      filterText: props.initialFilter,
      results
    }
  }

  handleChange = event => {
    const filterText = event.target.value
    this.setState({ filterText })

    // Return results only if the search is error free. Because we are updating
    // the results as the user types, it's possible to submit a filterText that
    // throws an error ( e.g. '+' will throw an error ). We want to keep the
    // previous result set if we get into an error state as we wait for the
    // user to finish typing
    try {
      const index = this.getIndex(this.props.statements)
      let results = this.getResults(index, filterText)

      if (filterText === '' && results.length === this.props.statements.length)
        results = []

      this.setState({ results })
    } catch (e) {
      if (e.name !== 'QueryParseError') throw e
    }
  }

  clear = () => this.handleChange({ target: { value: '' } })

  getIndex = memoize(this._createIndex)

  _createIndex(statements) {
    const index = lunr(function() {
      this.field('display')
      this.field('tags')
      this.ref('id')
      statements.forEach(s => {
        const tags = s.tags
          .map(tag => tag.display.trim().replace(/\s/g, ''))
          .filter(value => value !== '')
        const display = s.display
        const id = s.id
        const docToAdd = { tags, display, id }
        this.add(docToAdd)
      })
    })
    return index
  }

  getResults(index, filterText) {
    const results = index.search(filterText).map(({ ref, score }) => ({
      ref,
      score,
      // results only includes a ref id to the matched document, find this
      // document and include it so we can easily display it in render()
      item: this.props.statements.find(s => s.id.toString() === ref)
    }))
    return results
  }

  componentDidUpdate(prevProps) {
    // regenerate result list with updated "selected" statuses
    if (!isEqual(prevProps.statements, this.props.statements)) {
      this.handleChange({ target: { value: this.state.filterText } })
    }
  }

  render() {
    const { t, onSelectStatement } = this.props
    const displayClearButton = this.state.filterText.length > 0
    return (
      <div className={css.queryStatements}>
        {this.props.children}
        <div className={css.queryWrapper}>
          <input
            placeholder={t('statementBuilderPlaceholder')}
            className={css.queryInput}
            onChange={this.handleChange}
            value={this.state.filterText}
          />
          <button
            className={css.clearQueryInput}
            hidden={!displayClearButton}
            type="reset"
            onClick={this.clear}
          >
            x
          </button>
        </div>
        <div className={css.queryScroll}>
          {this.state.results.map(result => (
            <div
              key={result.ref}
              onClick={() => onSelectStatement(result.item)}
            >
              <Statement key={result.ref} statement={result.item} />
              <br />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

QueryStatements = withTranslation('statements')(QueryStatements)

QueryStatements.propTypes = {
  /** an array of statements */
  statements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      display: PropTypes.string.isRequired,
      tags: PropTypes.array.isRequired
    })
  ),
  /** called with the statement object when a statement is clicked */
  onSelectStatement: PropTypes.func.isRequired,
  /** Initial filter to set when rendering the component */
  initialFilter: PropTypes.string
}

export default QueryStatements
