import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, change, Field } from 'redux-form'
import { useTranslation } from 'react-i18next'
import i18n, { LANGUAGES } from '../../../../i18n'

import { Label, TextField } from 'common/components/form'
import { required } from 'common/helpers/validation'

import LanguageDropdown from '../languageDropdown'
import { getInitialUserFormValues } from '../../selectors'

let UserForm = ({ initialValues, handleChange, onSubmit }) => {
  const { t } = useTranslation('user')

  function handleBlur() {
    if (initialValues.id) {
      onSubmit()
    }
  }

  return (
    <form>
      <Label htmlFor="name" required>
        {t('name')}
      </Label>
      <Field
        name="name"
        component={TextField}
        validate={required}
        onBlur={handleBlur}
      />

      <Label htmlFor="handle">{t('handle')}</Label>
      <Field
        name="handle"
        validate={required}
        component={TextField}
        onBlur={handleBlur}
      />

      <Label htmlFor="language" required>
        {t('language')}
      </Label>
      <Field
        name="language"
        component={LanguageDropdown}
        onChange={handleChange}
      />
    </form>
  )
}

UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

UserForm = reduxForm({
  enableReinitialize: true,
  form: 'user'
})(UserForm)

const mapStateToProps = state => ({
  initialValues: getInitialUserFormValues(state)
})

const mapDispatchToProps = (dispatch, props) => ({
  handleChange: (e, value) => {
    dispatch(change('user', 'language', value))
    i18n.changeLanguage(LANGUAGES.get(value))
    props.onSubmit()
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)
