import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'common/components'
import { classList } from 'common/utils'

import css from './style.module.scss'

const MessageCount = ({ count, id, priority }) => {
  const showCount = count || count > 0
  const classes = classList(
    css.count,
    !showCount && css.hide,
    showCount && css[`priority-${priority}`]
  )

  return (
    <Link
      className={classes}
      to={`/avid/products/edit/${id}/reviewMessaging`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {showCount && <span>{count}</span>}
    </Link>
  )
}

MessageCount.propTypes = {
  count: PropTypes.number,
  id: PropTypes.string,
  priority: PropTypes.oneOf(['low', 'moderate', 'high'])
}

export default MessageCount
