import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'

import rootReducer from './rootReducer'
import rootSaga from './rootSaga'

const dev = process.env.NODE_ENV === 'development'
const middlewares = []

if (dev) {
  middlewares.push(
    createLogger({
      collapsed: true
    })
  )
}

const sagaMiddleware = createSagaMiddleware()
middlewares.push(sagaMiddleware)

function devTools() {
  if (dev && window.__REDUX_DEVTOOLS_EXTENSION__) {
    return window.__REDUX_DEVTOOLS_EXTENSION__()
  }
  return f => f
}

const history = createBrowserHistory()

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middlewares),
    devTools()
  )
)

if (module.hot) {
  module.hot.accept(rootReducer, () => {
    store.replaceReducer(rootReducer)
  })
}

sagaMiddleware.run(rootSaga)

export { history, store }
