import { put, call, takeLatest, takeEvery, all } from 'redux-saga/effects'

import * as api from 'common/api'
import {
  FETCH_LOCATIONS,
  SUBMIT_NEW_LOCATION,
  SUBMIT_EDIT_LOCATION,
  DELETE_LOCATION,
  SET_LOCATION_GEOJSON
} from './types'
import {
  setLocations,
  fetchLocationsFailed,
  addNewLocation,
  updateLocation,
  setCurrentUploadedGeoJSON
} from './actions'
import { removeProductsWithDeletedLocation } from 'core/products/actions'

function* onFetchLocations() {
  yield takeLatest(FETCH_LOCATIONS, fetchLocations)
}

/*
  GET /locations

  {
    "feature": {
      "id": 1,
      "type": "Polygon",
      "properties": {
        "name": "Local-1",
        "handle": "loc1",
        "link": "https://link.org",
        "created": "",
        "owner": "1234",
        "status": "active"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -116.2078857421875,
              49.439556958940855
            ],
            [
              -116.15844726562501,
              49.933544070506144
            ],
            [
              -116.6802978515625,
              50.60764573009061
            ],
            [
              -117.8338623046875,
              50.7295015014743
            ]
          ]
        ]
      },
      "centroid": {
        "type": "Point",
        "coordinates": [1,1]
      }
    }
  }
*/
function* fetchLocations() {
  try {
    let locations = yield call(api.get, 'locations')
    if (!locations || !locations.length) locations = []

    yield put(setLocations(locations))
  } catch (err) {
    console.error(err)
    window.alert(err.message)
    yield put(fetchLocationsFailed(err))
  }
}

export function* onAddNewLocation() {
  yield takeLatest(SUBMIT_NEW_LOCATION, addLocation)
}

/*
  POST /locations

  same as schema above
*/
function* addLocation({ payload: { location, redirect } }) {
  try {
    const newLocation = yield call(api.post, 'locations', location)
    yield put(addNewLocation(newLocation))
    redirect('/avid/locations')
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

function* onEditLocation() {
  yield takeLatest(SUBMIT_EDIT_LOCATION, editLocation)
}

/*
  PUT /locations/:locationId

  same as schema above
*/
function* editLocation({ payload }) {
  const {
    feature: { id }
  } = payload

  try {
    const updatedLocation = yield call(api.put, `locations/${id}`, payload)
    yield put(updateLocation(updatedLocation))
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

function* onDeleteLocation() {
  yield takeLatest(DELETE_LOCATION, deleteLocation)
}

/*
  DELETE /locations/:locationsId
*/
function* deleteLocation({ payload: { id, redirect } }) {
  try {
    yield call(api.del, `locations/${id}`)
    yield put(removeProductsWithDeletedLocation({ locationId: id }))
    redirect('/avid/locations')
  } catch (err) {
    console.error(err)
    window.alert(err.message)
  }
}

function* onSetGeoJSON() {
  yield takeEvery(SET_LOCATION_GEOJSON, setGeoJSON)
}

function* setGeoJSON({ payload }) {
  const geometry = payload.length > 0 ? yield call(readFile, payload[0]) : null

  yield put(setCurrentUploadedGeoJSON(geometry))
}

function readFile(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(JSON.parse(reader.result))
    reader.onerror = () => reject(reader.error)
    reader.readAsText(blob)
  })
}

export default function* rootSaga() {
  yield all([
    onFetchLocations(),
    onAddNewLocation(),
    onEditLocation(),
    onDeleteLocation(),
    onSetGeoJSON()
  ])
}
