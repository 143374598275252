import React from 'react'
import PropTypes from 'prop-types'

import { classList } from 'common/utils'

import css from './style.module.scss'

const ComboLast = props => {
  const classes = classList(css.last, props.center ? css.center : '')

  return <div className={classes}>{props.children}</div>
}

ComboLast.propTypes = {
  children: PropTypes.any.isRequired,
  /**
   * In passing center, it allows us to center-align combo element,
   * as combolast naturally takes up 100%
   */
  center: PropTypes.bool
}

export default ComboLast
