import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Error, Muted } from 'common/components/typography'
import Status from 'common/helpers/Status'

class StatusComponent extends Component {
  static propTypes = {
    value: PropTypes.instanceOf(Status).isRequired,
    pending: PropTypes.string,
    error: PropTypes.string,
    children: PropTypes.node,
    t: PropTypes.func
  }
  static defaultProps = {
    pending: 'loading',
    error: 'error',
    children: null
  }
  render() {
    const { t, error, loading, value, children } = this.props
    const { isRejected, isPending } = value

    if (isRejected) {
      return <Error>{t(error)}</Error>
    }

    if (isPending) {
      return <Muted>{t(loading)}</Muted>
    }

    return children
  }
}

export default withTranslation()(StatusComponent)
