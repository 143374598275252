import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'seamless-immutable'
import { withTranslation } from 'react-i18next'
import { xorWith, isEqual } from 'lodash'
import { ContextMenu } from 'common/components/form'
import aspects from './svgs'
import css from './style.module.scss'

const AspectSlice = React.memo(props => {
  const { d, transform, isActive, onClick, direction, disabled } = props

  return (
    <path
      id={direction}
      d={d}
      transform={transform}
      onClick={() => onClick(direction)}
      className={!disabled ? css.aspect : ''}
      stroke={isActive ? 'var(--color-white)' : 'var(--color-grey)'}
      strokeWidth="0.5"
      fill={isActive ? 'var(--color-blue)' : 'var(--color-light-medium-grey)'}
    />
  )
})

class AspectElevation extends PureComponent {
  static propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    parentDOMId: PropTypes.string
  }

  static defaultProps = {
    value: [],
    disabled: false
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.mergeAspectValues(props),
      contextMenu: {
        show: false
      }
    }

    this.id = 'aspectElevation'
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState(this.mergeAspectValues(this.props))
    }
  }

  mergeAspectValues = props => {
    const values = props.value.map(
      value => `${value.aspect}-${value.elevation}`
    )
    const arrOfValues = aspects.map(({ direction }) => ({
      [direction]: values.indexOf(direction) !== -1
    }))
    return Object.assign({}, ...arrOfValues)
  }

  getSelectedAspectElevationValues = values => {
    const selected = []
    Object.keys(values).forEach(key => {
      if (values[key]) {
        const aspectElevationSplit = key.split('-')
        selected.push({
          aspect: aspectElevationSplit[0],
          elevation: aspectElevationSplit[1]
        })
      }
    })
    return selected
  }

  handleAllAspects = (event, select) => {
    event.preventDefault()
    const { elevation } = this.state.contextMenu
    const selections = Object.keys(this.state).filter(key =>
      key.includes(elevation)
    )
    const newState = { ...this.state }
    selections.forEach(key => (newState[key] = select))

    this.setState({ ...newState, contextMenu: { show: false } })

    delete newState.contextMenu
    const selectedAspectElevations = this.getSelectedAspectElevationValues(
      newState
    )
    this.props.onChange(selectedAspectElevations)
  }

  handleAllElevations = (event, select) => {
    event.preventDefault()
    const { aspect } = this.state.contextMenu
    const selections = Object.keys(this.state).filter(key => {
      const aspectElevationSplit = key.split('-')
      if (aspectElevationSplit[0] === aspect) return true
      return false
    })
    const newState = { ...this.state }
    selections.forEach(key => (newState[key] = select))
    this.setState({ ...newState, contextMenu: { show: false } })

    delete newState.contextMenu
    const selectedAspectElevations = this.getSelectedAspectElevationValues(
      newState
    )
    this.props.onChange(selectedAspectElevations)
  }

  handleAllAspectAndElevations = (event, select) => {
    event.preventDefault()
    const newState = { ...this.state }
    const keys = Object.keys(this.state)
    keys.forEach(key => (newState[key] = select))
    this.setState({ ...newState, contextMenu: { show: false } })

    delete newState.contextMenu
    const selectedAspectElevations = this.getSelectedAspectElevationValues(
      newState
    )
    this.props.onChange(selectedAspectElevations)
  }

  handleContextMenuClick = event => {
    const self = document.getElementById(this.id)

    if (this.props.disabled || !self.contains(event.target)) {
      return
    }

    event.preventDefault()

    const aspectElevationItem = event.target.id
    const aspectElevationSplit = aspectElevationItem.split('-')
    this.setState({
      contextMenu: {
        show: true,
        aspect: aspectElevationSplit[0],
        elevation: aspectElevationSplit[1]
      }
    })

    /* Display ContextMenu at mouse click position
     */
    let parent
    if (this.props.parentDOMId) {
      parent = document.getElementById(this.props.parentDOMId)
    } else {
      parent = self.parentElement
    }
    const parentRect = parent.getBoundingClientRect()
    const scrollTop = parent.scrollTop
    const clickX = event.clientX
    const clickY = event.clientY
    const screenW = window.innerWidth
    const screenH = window.innerHeight
    const menuW = this.contextMenuRef.offsetWidth
    const menuH = this.contextMenuRef.offsetHeight

    const right = clickX + menuW < screenW
    const left = !right
    const top = clickY + menuH < screenH
    const bottom = !top

    if (right) {
      this.contextMenuRef.style.left =
        `${clickX - parentRect.left + 5}px`
    }
    if (left) {
      this.contextMenuRef.style.left =
        `${clickX - parentRect.left - menuW - 5}px`
    }
    if (top) {
      this.contextMenuRef.style.top =
        `${clickY - parentRect.top + scrollTop + 5}px`
    }
    if (bottom) {
      this.contextMenuRef.style.top =
        `${clickY - parentRect.top - menuH - 5}px`
    }
  }

  handleOnClick = (aspectElevationItem) => {
    if (this.props.disabled) return

    if (!aspectElevationItem || this.state.contextMenu.show) {
      return this.setState({ contextMenu: { show: false } })
    }

    this.setState({
      [aspectElevationItem]: !this.state[aspectElevationItem]
    })

    const aspectElevationSplit = aspectElevationItem.split('-')
    const updatedAspectElevations = xorWith(
      this.props.value,
      [{ aspect: aspectElevationSplit[0], elevation: aspectElevationSplit[1] }],
      isEqual
    )

    this.props.onChange(Immutable(updatedAspectElevations))
  }

  render() {
    const { t, disabled, parentDOMId } = this.props
    return (
      <>
        <div id={this.id} className={css.container}>
          <svg className={css.svg} viewBox="57 26 722 722">
            {aspects.map(aspect => {
              const { direction } = aspect
              return (
                <AspectSlice
                  disabled={disabled}
                  key={direction}
                  {...aspect}
                  isActive={this.state[direction]}
                  onClick={this.handleOnClick}
                />
              )
            })}
          </svg>
        </div>
        <ContextMenu
          parentDOMId={parentDOMId || this.id}
          menuRef={ref => (this.contextMenuRef = ref)}
          visible={this.state.contextMenu.show}
          closeMenu={() => this.handleOnClick(null)}
          openMenu={this.handleContextMenuClick}
        >
          <button onClick={event => this.handleAllAspects(event, true)}>
            {t('contextMenu.allAspectsForElevation')}
          </button>
          <button onClick={event => this.handleAllAspects(event, false)}>
            {t('contextMenu.clearAspectsForElevation')}
          </button>
          <button onClick={event => this.handleAllElevations(event, true)}>
            {t('contextMenu.allElevationsForAspect')}
          </button>
          <button onClick={event => this.handleAllElevations(event, false)}>
            {t('contextMenu.clearElevationsForAspect')}
          </button>
          <button onClick={event => this.handleAllAspectAndElevations(event, true)}>
            {t('contextMenu.selectAll')}
          </button>
          <button onClick={event => this.handleAllAspectAndElevations(event, false)}>
            {t('contextMenu.clearAll')}
          </button>
        </ContextMenu>
      </>
    )
  }
}

export default withTranslation('avalancheProblems')(AspectElevation)
