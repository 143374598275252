import * as utils from 'dot-prop-immutable'

export const FETCH_ACTIONS = {
  REQUESTED: undefined,
  PENDING: undefined,
  FULFILLED: undefined,
  REJECTED: undefined
}

export function delegateReducerForFetch(reducer) {
  return {
    REQUESTED: reducer,
    PENDING: reducer,
    FULFILLED: reducer,
    REJECTED: reducer
  }
}

export function statusReducerFactory(path = 'status') {
  return {
    PENDING(state) {
      return utils.set(state, path, status => status.pend())
    },
    FULFILLED(state) {
      return utils.set(state, path, status => status.fulfill())
    },
    REJECTED(state) {
      return utils.set(state, path, status => status.reject())
    }
  }
}
