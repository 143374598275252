import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import { OptionSet, Option } from 'common/components/form'

import css from './style.module.scss'

const StatusOptionSet = props => {
  const { t } = useTranslation('common')
  return (
    <OptionSet {...props}>
      <Option value="yes" selectedClassName={css['status--yes']}>
        {t('YES')}
      </Option>
      <Option value="unknown" selectedClassName={css['status--unknown']}>
        {t('UNKNOWN')}
      </Option>
      <Option value="no" selectedClassName={css['status--no']}>
        {t('NO')}
      </Option>
    </OptionSet>
  )
}

StatusOptionSet.propTypes = {
  onClick: PropTypes.func.isRequired,
  selection: PropTypes.string
}

StatusOptionSet.defaultProps = {
  selection: 'no'
}

export default StatusOptionSet
