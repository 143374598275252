import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import FileUndoIcon from 'mdi-react/FileUndoIcon'
import FileMoveIcon from 'mdi-react/FileMoveIcon'
import FilePlusIcon from 'mdi-react/FilePlusIcon'
import CloseIcon from 'mdi-react/CloseIcon'

import css from './style.module.scss'

const getIcon = {
  R: <FileUndoIcon />,
  C: <FileMoveIcon />,
  N: <FilePlusIcon />,
  close: <CloseIcon />
}

const getIconName = {
  R: 'Reset',
  C: 'Copy Forward',
  N: 'Notes'
}

const Button = ({ label, isActive, onClick, disabled, toggleLabel }) => {
  const { t } = useTranslation('common')
  const classes = [
    css.button,
    label === 'N' ? css.messageButton : null,
    isActive ? css.buttonActive : null,
    disabled ? css.disabled : null,
    isActive && toggleLabel ? css.close : null
  ]

  return (
    <button
      type="button"
      disabled={disabled}
      className={classes.join(' ')}
      onClick={onClick}
      title={t(`${toggleLabel && isActive ? '' : getIconName[label]}`)}
    >
      {toggleLabel && isActive ? getIcon['close'] : getIcon[label]}
    </button>
  )
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  toggleLabel: PropTypes.bool
}

Button.defaultProps = {
  isActive: false,
  toggleLabel: false
}

export default Button
