import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Field,
  reduxForm,
  clearFields,
  isSubmitting,
  getFormSyncErrors,
  getFormValues,
  touch
} from 'redux-form'
import { withTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import { hasGeoJSON, hasUploadedGeoJSON } from 'core/locations/selectors'
import { setGeoJSON, deleteGeoJSON } from 'core/locations/actions'
import { required, hrefPattern } from 'common/helpers/validation'
import { Button, SubmitWorkspaceButton } from 'common/components'
import { FileInput, TextField, Label } from 'common/components/form'
import DownloadGeoJsonButton from '../downloadGeoJsonButton'

import { getInitalValues } from './selectors'

import css from './style.module.scss'

class LocationForm extends Component {
  componentWillUnmount() {
    this.props.onDeleteGeoJSON()
  }

  render() {
    const {
      t,
      change,
      hasUploadedGeoJSON,
      hasGeoJSON,
      locationId,
      handleChange,
      onDelete,
      onSetGeoJSON,
      onDeleteGeoJSON,
      isSubmitting
    } = this.props

    const showDownloadGeoJSONButton =
      locationId && hasGeoJSON && !hasUploadedGeoJSON

    return (
      <form>
        <div>
          <Label htmlFor="name" required>
            {t('locations:locationName')}
          </Label>
          <Field
            name="feature.properties.name"
            component={TextField}
            placeholder={t('locations:locationName')}
            validate={required}
            onBlur={async (e, value) => {
              await change('feature.properties.name', value)
              if (locationId) handleChange()
            }}
          />
        </div>
        <div>
          <Label htmlFor="handle" required>
            {t('locations:locationHandle')}
          </Label>
          <Field
            name="feature.properties.handle"
            component={TextField}
            placeholder={t('locations:locationHandle')}
            validate={required}
            onBlur={async (e, value) => {
              await change('feature.properties.handle', value)
              if (locationId) handleChange()
            }}
          />
        </div>
        <div>
          <Label htmlFor="link">{t('locations:link')}</Label>
          <Field
            name="feature.properties.link"
            component={TextField}
            placeholder={t('locations:link')}
            validate={hrefPattern}
            onBlur={async (e, value) => {
              await change('feature.properties.link', value)
              if (locationId) handleChange()
            }}
          />
        </div>
        <div>
          {!hasGeoJSON && (
            <Field
              name="feature.geometry"
              disabled={hasGeoJSON}
              label={t('locations:uploadGeojson')}
              onChange={onSetGeoJSON}
              component={FileInput}
              validate={required}
            />
          )}
          {hasUploadedGeoJSON && (
            <Button context="stop" onClick={onDeleteGeoJSON}>
              {t('locations:deleteGeojson')}
            </Button>
          )}
          {showDownloadGeoJSONButton && (
            <DownloadGeoJsonButton locationId={locationId} />
          )}
        </div>
        <div className={css.formButtons}>
          {/*  It was decided that the Location deletion function should be
            *  temporarily disabled.
          {onDelete ? (
            <Button context="stop" onClick={onDelete}>
              {t('locations:deleteLocation')}
            </Button>
          ) : (
          */}
          {!onDelete && (
            <SubmitWorkspaceButton
              text={'submitLocation'}
              isSubmitting={isSubmitting}
              handleSubmit={handleChange}
            />
          )}
        </div>
      </form>
    )
  }
}

LocationForm.propTypes = {
  locationId: PropTypes.string,
  hasUploadedGeoJSON: PropTypes.bool.isRequired,
  hasGeoJSON: PropTypes.bool.isRequired,
  onSetGeoJSON: PropTypes.func.isRequired,
  onDeleteGeoJSON: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  handleChange: PropTypes.func.isRequired
}

const fields = [
  'feature.properties.name',
  'feature.properties.handle',
  'feature.properties.link',
  'feature.geometry',
]

LocationForm = reduxForm({
  enableReinitialize: true,
  form: 'location',
  fields
})(LocationForm)

const mapStateToProps = (state, ownProps) => ({
  initialValues: getInitalValues(state, ownProps),
  hasUploadedGeoJSON: hasUploadedGeoJSON(state),
  hasGeoJSON: hasGeoJSON(state, ownProps),
  isSubmitting: isSubmitting('location')(state),
  getFormErrors: () => getFormSyncErrors('location')(state),
  getFormValues: () => getFormValues('location')(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSetGeoJSON(event, newValue, previousValue, name) {
    dispatch(setGeoJSON(newValue))
  },
  onDeleteGeoJSON() {
    dispatch(clearFields('location', false, false, 'feature.geometry'))
    dispatch(deleteGeoJSON())
  },
  isValid(getFormErrors) {
    const erroredFields = getFormErrors()
    const isValid = isEmpty(erroredFields)
    if (isValid) return true

    fields.forEach(field => {
      const error = get(erroredFields, field)
      if (!error) return

      dispatch(touch('location', field))
    })
    return false
  }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  handleChange() {
    const isValid = dispatchProps.isValid(stateProps.getFormErrors)
    if (!isValid) return

    const locationFormValues = stateProps.getFormValues()
    ownProps.onSubmit(locationFormValues)
  }
})

LocationForm = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(LocationForm)

export default withTranslation('common, locations')(LocationForm)
