import React from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouterLink } from 'react-router-dom'

import { pathType, classList } from 'common/utils'

import css from './style.module.scss'

const Link = props => {
  /* Intentionally popping off noUnderline as it cause DOM complaints w/ attrs */
  const { noUnderline, ...propsToPass } = props
  const { to, children, className } = props

  const classes = classList(css.link, className, noUnderline && css.noUnderline)

  if (pathType(to) === 'absolute') {
    return (
      <a
        className={classes}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  } else {
    return (
      <RouterLink {...propsToPass} className={classes} to={to}>
        {children}
      </RouterLink>
    )
  }
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  noUnderline: PropTypes.bool
}

export default Link
