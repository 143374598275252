import Immutable from 'seamless-immutable'

import { createReducer } from 'common/utils'
import * as types from './types'

const INITIAL_STATE = Immutable({
  isFetching: false,
  hideInactiveWeakLayers: false,
  weakLayersById: {}
})

const reducers = {
  [types.FETCH_WEAK_LAYERS]: startFetching,
  [types.FAIL_FETCHING_WEAK_LAYERS]: doneFetching,
  [types.SET_WEAK_LAYERS]: setWeakLayers,
  [types.TOGGLE_WEAK_LAYER_VISIBILITY]: toggleWeakLayersVisibility,
  [types.ADD_WEAK_LAYER]: addWeakLayer,
  [types.UPDATE_WEAK_LAYER]: updateWeakLayer,
  [types.DELETE_WEAK_LAYER]: deleteWeakLayer,
  [types.ADD_WEAK_LAYER_COMMENT]: addWeakLayerComment
}

function startFetching(state) {
  return Immutable.set(state, 'isFetching', true)
}

function doneFetching(state) {
  return Immutable.set(state, 'isFetching', false)
}

function setWeakLayers(state, payload) {
  const weakLayersById = payload.reduce((acc, weakLayer) => {
    acc[weakLayer.id] = {
      ...weakLayer
    }
    return acc
  }, {})

  const weakLayers = {
    isFetching: false,
    weakLayersById
  }

  return Immutable.merge(state, weakLayers)
}

function toggleWeakLayersVisibility(state) {
  return Immutable.set(
    state,
    'hideInactiveWeakLayers',
    !state.hideInactiveWeakLayers
  )
}

function addWeakLayer(state, payload) {
  const { id } = payload

  return Immutable.merge(state, {
    ...state,
    weakLayersById: {
      ...state.weakLayersById,
      [`${id}`]: { ...payload, new: true }
    }
  })
}

function updateWeakLayer(state, payload) {
  const { weakLayerUpdated } = payload

  return Immutable.setIn(
    state,
    ['weakLayersById', `${weakLayerUpdated.id}`],
    weakLayerUpdated
  )
}

function deleteWeakLayer(state, payload) {
  return Immutable.merge(state, {
    ...state,
    weakLayersById: Immutable.without(state.weakLayersById, payload)
  })
}

function addWeakLayerComment(state, { weakLayerId, newWeakLayerComment }) {
  const existingComments = state.weakLayersById[weakLayerId]
    ? state.weakLayersById[weakLayerId].comments
    : []

  return Immutable.setIn(
    state,
    ['weakLayersById', `${weakLayerId}`, 'comments'],
    [newWeakLayerComment, ...existingComments]
  )
}

export default createReducer(INITIAL_STATE, reducers)
