import React from 'react'
import PropTypes from 'prop-types'
import css from './style.module.scss'
import { classList } from 'common/utils'

import AddIcon from 'mdi-react/AddIcon'

const Button = props => {
  const {
    children,
    type,
    size,
    context,
    onClick,
    disabled,
    className,
    progress
  } = props

  const classes = classList(
    css.button,
    css[context],
    css[size],
    disabled && css.disabled,
    className
  )

  let icon

  if (context === 'new') {
    icon = AddIcon
  }

  const style = {}

  if (context === 'progress' && progress) {
    style.width = `${(progress.done / progress.total) * 100}%`
  }

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={classes}
    >
      <span className={css.buttonLabel}>{children}</span>
      {icon && <AddIcon className={css.icon} />}
      {progress && (
        <div className={css.progress}>
          <div className={css.progressBar} style={style} />
          <span className={css.progressCount}>
            {progress.done}/{progress.total}
          </span>
        </div>
      )}
    </button>
  )
}

Button.defaultProps = {
  context: 'go',
  type: 'button',
  disabled: false
}

Button.propTypes = {
  type: PropTypes.string,
  size: PropTypes.oneOf(['primary', 'secondary', 'auto']),
  context: PropTypes.oneOf([
    'go',
    'review',
    'stop',
    'edit',
    'open',
    'new',
    'quick-start',
    'upload',
    'progress',
    'workspace'
  ]),
  progress: PropTypes.shape({
    total: PropTypes.number.isRequired,
    done: PropTypes.number.isRequired
  }),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string
}

export default Button
