import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import TimeAgo from 'react-timeago'
import englishStrings from 'react-timeago/lib/language-strings/en'
import frenchStrings from 'react-timeago/lib/language-strings/fr'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import classnames from 'classnames/bind'

import css from './style.module.scss'
const cx = classnames.bind(css)

const FORMATTERS = {
  en: englishStrings,
  fr: frenchStrings
}

class LastTimeSaved extends Component {
  static propTypes = {
    date: PropTypes.object,
    language: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
  }
  static defaultProps = {
    language: 'en'
  }
  formatter = (value, unit, suffix, date, defaultFormatter) => {
    const { t, language } = this.props
    const formatter = FORMATTERS[language]

    return `${t('header.lastTimeSaved')} ${buildFormatter(formatter)(
      value,
      unit,
      suffix,
      date,
      defaultFormatter,
      Date.now
    )}`
  }
  render() {
    return (
      <div
        className={cx(css.header, css.lastSave)}
      >
        {this.props.date && (
          <TimeAgo
            date={this.props.date}
            minPeriod={10}
            maxPeriod={10}
            formatter={this.formatter}
          />
        )}
      </div>
    )
  }
}

export default withTranslation('products')(LastTimeSaved)
