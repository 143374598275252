import React from 'react'
import { useTranslation } from 'react-i18next'

import { Dropdown } from 'common/components'

const LanguageDropdown = ({ input }) => {
  const { t } = useTranslation('user')
  const languages = [
    {
      value: 'en',
      label: t('user:english')
    },
    {
      value: 'fr',
      label: t('user:french')
    }
  ]

  return <Dropdown {...input} options={languages} />
}

export default LanguageDropdown
