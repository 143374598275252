import { convertFromRaw } from 'draft-js'

/**
 * Regex Source: https://code.tutsplus.com/tutorials/8-regular-expressions-you-should-know--net-6149
 * Updated protocol to be non-optional.
 */
const HREF_PATTERN = /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/

/** Matches an integer or a number with a single decimal point.
 * Does not match an empty string.
 */
const SINGLE_DECIMAL = /^\d+(\.?\d?)?$/

export const hrefPattern = value => {
  if (value && value.length > 0) {
    return value.match(HREF_PATTERN) ? undefined : 'href_pattern'
  }
}

export const required = value => (value ? undefined : 'required')
export const requiredAndNotBlank = value =>
  value === '0' ? 'required' : required(value)

export const alphaNumeric = value =>
  value && /[a-zA-Z0-9 ]/i.test(value)
    ? undefined
    : 'onlyAlphanumericCharacters'

export const number = value =>
  value && Number(value) ? undefined : 'mustBeANumber'

export const integer = value =>
  Number.isInteger(parseFloat(value)) ? undefined : 'mustBeAnInteger'

export const singleDecimal = value => {
  const number = value || value === 0 ? value.toString() : ''
  return number.match(SINGLE_DECIMAL) ? undefined : 'singleDecimalNumber'
}

export const richTextRequired = value => {
  if (!value || value === '') return 'required'

  try {
    const contentState = convertFromRaw(value)
    return contentState.getPlainText().length > 0 ? undefined : 'required'
  } catch (error) {
    return 'required'
  }
}

export const lessThan = (value, values, field) => {
  if (!value || !values[field]) return undefined
  return parseInt(value, 10) > parseInt(values[field].to, 10)
    ? 'mustBeLessThan'
    : undefined
}

export const greaterThan = (value, values, field) => {
  if (!value || !values[field]) return undefined
  return parseInt(value, 10) < parseInt(values[field].from, 10)
    ? 'mustBeGreaterThan'
    : undefined
}

export const lessThan1000 = value =>
  value && value <= 1000 ? undefined : 'mustBeLessThan1000'

export const lessThan500 = value =>
  value && value <= 500 ? undefined : 'mustBeLessThan500'
