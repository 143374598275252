import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Immutable from 'seamless-immutable'

import { submitNewReport, cancelNewReport } from 'core/products/actions'
import { Button } from 'common/components'

import css from './style.module.scss'

class CancelConfirmButtons extends Component {
  static propTypes = {
    values: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    submitNewReport: PropTypes.func.isRequired,
    cancelNewReport: PropTypes.func.isRequired,
    restoreSorted: PropTypes.func.isRequired
  }

  validateData = () => {
    const { productType, location, user } = this.props.values
    if (productType.length < 1 || location.length < 1 || user < 1) return false

    return true
  }

  onConfirmClicked = () => {
    const { submitNewReport, values, restoreSorted, t } = this.props

    if (!this.validateData()) return window.alert(t('incompleteReport'))

    const product = {
      ...Immutable.asMutable(values, { deep: true }),
      location: values.location,
      status: 'empty'
    }

    if (product.productType === 'offseason') delete product.date.validTill

    submitNewReport({ product })
    restoreSorted()
  }

  onCancelClicked = () => {
    const {
      cancelNewReport,
      values: { key },
      restoreSorted
    } = this.props
    cancelNewReport({ reportKey: key })
    restoreSorted()
  }

  render() {
    const { t } = this.props

    return (
      <div className={css.buttonGroup}>
        <div className={css.newProductButton}>
          <Button context="stop" onClick={this.onCancelClicked}>
            {t('cancel')}
          </Button>
        </div>
        <div className={css.newProductButton}>
          <Button context="go" onClick={this.onConfirmClicked}>
            {t('confirm')}
          </Button>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  submitNewReport,
  cancelNewReport
}

CancelConfirmButtons = connect(
  null,
  mapDispatchToProps
)(CancelConfirmButtons)

CancelConfirmButtons = withTranslation('common')(CancelConfirmButtons)

export default CancelConfirmButtons
