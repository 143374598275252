import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import DefinedType from 'common/data/definedTypes'
import Dropdown from 'common/components/dropdown'

import css from './style.module.scss'

class DangerRating extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
  }

  render() {
    const { t, value, onChange } = this.props
    const style = value
      ? {
          backgroundImage: `url("/assets/danger-ratings/${value}.svg")`
        }
      : {
          paddingLeft: '0.25em'
        }

    return (
      <DefinedType type="dangerratings">
        {({ data }) => (
          <Dropdown
            className={css.dangerRating}
            value={value || ''}
            onChange={event => onChange(event.target.value)}
            style={style}
          >
            <option value="" disabled>
              {t('Select a danger rating')}
            </option>
            {data.map(({ id, display }) => (
              <option key={id} value={id}>
                {display}
              </option>
            ))}
          </Dropdown>
        )}
      </DefinedType>
    )
  }
}

export default withTranslation('dangerRatings')(DangerRating)
