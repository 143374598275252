import Immutable from 'seamless-immutable'

const WORKSPACES = [1, 2, 3, 4]

const summary = (workspace, type) => ({
  workspace,
  text: [],
  type
})

export function createInitialState(summaryType) {
  return Immutable({
    isFetching: false,
    summariesByDays: WORKSPACES.reduce((summariesByDays, workspace) => {
      summariesByDays[workspace] = summary(workspace, summaryType)
      return summariesByDays
    }, {})
  })
}

export const reducers = (summaryType, types) => {
  const summaryTypeUppercase = summaryType.toUpperCase()

  return {
    [types[`FETCH_${summaryTypeUppercase}_SUMMARIES`]]: fetchSummaries,
    [types[
      `FAIL_FETCHING_${summaryTypeUppercase}_SUMMARIES`
    ]]: failFetchingSummaries,
    [types[`SET_${summaryTypeUppercase}_SUMMARIES`]]: setSummaries,
    [types[`UPDATE_${summaryTypeUppercase}_SUMMARY`]]: updateSummary
  }
}

function fetchSummaries(state) {
  return Immutable.set(state, 'isFetching', true)
}

function failFetchingSummaries(state, action) {
  return Immutable.set(state, 'isFetching', false)
}

function setSummaries(state, payload) {
  const summaries = {
    isFetching: false,
    summariesByDays: payload
  }

  return Immutable.merge(state, summaries)
}

function updateSummary(state, payload) {
  if (!payload) return state

  const { workspace } = payload
  return Immutable.setIn(state, ['summariesByDays', `${workspace}`], payload)
}
