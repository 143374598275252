import React from 'react'
import { Router, Switch, Redirect, Route } from 'react-router-dom'
import { history } from 'common/store'

import ScrollToTop from './scrollToTop'
import { Page } from 'common/components'
import Locations from 'core/locations'
import Products from 'core/products'
import User from 'core/users'
/**
 * Note: if you want anything to be common to all pages import here
 * (global messaging etc)
 *
 */

const Routes = () => (
  <Router history={history}>
    <ScrollToTop>
      <Page>
        <Switch>
          <Route
            key={'/products'}
            path={'/avid/products'}
            component={Products}
          />
          <Route
            key={'/locations'}
            path={'/avid/locations'}
            component={Locations}
          />
          <Route key={'/profile'} path={'/avid/profile'} component={User} />
          <Route component={() => <Redirect to={'/avid/products'} />} />
        </Switch>
      </Page>
    </ScrollToTop>
  </Router>
)

export default Routes
