import React from 'react'

import {
  PARTIAL_COPY_FUNCTIONALITY,
  JUST_COPY_FUNCTIONALITY,
  NO_COPY_FUNCTIONALITY,
  RESET_NOTES_FUNCTIONALITY
} from './constants'

const Avalanche = React.lazy(() => import('core/avalanche'))
const AvalancheProblems = React.lazy(() => import('core/avalancheProblems'))
const WeakLayers = React.lazy(() => import('core/weakLayers'))
const Weather = React.lazy(() => import('core/weather'))
const Snowpack = React.lazy(() => import('core/snowpack'))
const DangerRatings = React.lazy(() => import('core/dangerRatings'))
const DangerRatingCriticalFactors = React.lazy(() => import('core/dangerRatingCriticalFactors'))
const Communications = React.lazy(() => import('core/communications'))
const ReviewMessaging = React.lazy(() => import('core/reviewMessaging'))
const Confidence = React.lazy(() => import('core/confidence'))
const TerrainAndTravelAdvisory = React.lazy(() => import('core/terrainAndTravelAdvisory'))
const Observation = React.lazy(() => import('core/observation'))
const OffSeasonMessage = React.lazy(() => import('core/offseasonMessage'))
const Publish = React.lazy(() => import('core/publish'))

export const COMPONENT_NAMES = {
  avalanche: 'avalanche',
  avalancheProblems: 'avalancheProblems',
  snowpack: 'snowpack',
  weakLayers: 'weakLayers',
  weather: 'weather',
  dangerRatings: 'dangerRatings',
  dangerRatingCriticalFactors: 'dangerRatingCriticalFactors',
  reviewMessaging: 'reviewMessaging',
  confidence: 'confidence',
  communications: 'communications',
  observation: 'observation',
  terrainAndTravelAdvisory: 'terrainAndTravelAdvisory',
  offseasonMessage: 'offseasonMessage',
  offseasonWeather: 'offseasonWeather',
  publish: 'publish'
}

export const COMPONENT_WORKSPACES = {
  forecast: {
    weather: {
      total: 4,
      defaultDays: [1, 2]
    },
    snowpack: {
      total: 4,
      defaultDays: [1, 2]
    },
    avalanche: {
      total: 4,
      defaultDays: [1, 2]
    },
    weakLayers: {
      total: 0,
      defaultDays: []
    },
    avalancheProblems: {
      total: 4,
      defaultDays: [1, 2]
    },
    dangerRatings: {
      total: 4,
      defaultDays: [1, 2, 3, 4]
    },
    confidence: {
      total: 4,
      defaultDays: [2]
    },
    communications: {
      total: 0,
      defaultDays: []
    },
    reviewMessaging: {
      total: 0,
      defaultDays: []
    },
    publish: {
      total: 0,
      defaultDays: []
    }
  },
  advisory: {
    weather: {
      total: 4,
      defaultDays: [1, 2]
    },
    observation: {
      total: 4,
      defaultDays: [1, 2]
    },
    dangerRatingCriticalFactors: {
      total: 4,
      defaultDays: [1, 2, 3, 4]
    },
    terrainAndTravelAdvisory: {
      total: 4,
      defaultDays: [1, 2, 3, 4]
    },
    confidence: {
      total: 4,
      defaultDays: [2]
    },
    communications: {
      total: 0,
      defaultDays: []
    },
    reviewMessaging: {
      total: 0,
      defaultDays: []
    },
    publish: {
      total: 0,
      defaultDays: []
    }
  },
  offseason: {
    weather: {
      total: 1,
      defaultDays: [1]
    },
    offseasonMessage: {
      total: 0,
      defaultDays: []
    },
    communications: {
      total: 0,
      defaultDays: []
    },
    reviewMessaging: {
      total: 0,
      defaultDays: []
    },
    publish: {
      total: 0,
      defaultDays: []
    }
  }
}

export const AVALANCHE = {
  route: COMPONENT_NAMES.avalanche,
  element: COMPONENT_NAMES.avalanche,
  component: Avalanche,
  workspaces: COMPONENT_WORKSPACES.forecast.avalanche.total,
  defaultDays: COMPONENT_WORKSPACES.forecast.avalanche.defaultDays,
  carryForwardConfig: PARTIAL_COPY_FUNCTIONALITY
}

export const AVALANCHE_PROBLEMS = {
  route: COMPONENT_NAMES.avalancheProblems,
  element: COMPONENT_NAMES.avalancheProblems,
  component: AvalancheProblems,
  workspaces: COMPONENT_WORKSPACES.forecast.avalancheProblems.total,
  defaultDays: COMPONENT_WORKSPACES.forecast.avalancheProblems.defaultDays,
  carryForwardConfig: JUST_COPY_FUNCTIONALITY
}

export const SNOWPACK = {
  route: COMPONENT_NAMES.snowpack,
  element: COMPONENT_NAMES.snowpack,
  component: Snowpack,
  workspaces: COMPONENT_WORKSPACES.forecast.snowpack.total,
  defaultDays: COMPONENT_WORKSPACES.forecast.snowpack.defaultDays,
  carryForwardConfig: PARTIAL_COPY_FUNCTIONALITY
}

export const WEAK_LAYERS = {
  route: COMPONENT_NAMES.weakLayers,
  element: COMPONENT_NAMES.weakLayers,
  component: WeakLayers,
  workspaces: COMPONENT_WORKSPACES.forecast.weakLayers.total,
  defaultDays: COMPONENT_WORKSPACES.forecast.weakLayers.defaultDays,
  carryForwardConfig: NO_COPY_FUNCTIONALITY
}

export const WEATHER = {
  route: COMPONENT_NAMES.weather,
  element: COMPONENT_NAMES.weather,
  component: Weather,
  workspaces: COMPONENT_WORKSPACES.forecast.weather.total,
  defaultDays: COMPONENT_WORKSPACES.forecast.weather.defaultDays,
  carryForwardConfig: PARTIAL_COPY_FUNCTIONALITY
}

export const OFFSEASON_WEATHER = {
  route: COMPONENT_NAMES.weather,
  element: COMPONENT_NAMES.offseasonWeather,
  component: Weather,
  workspaces: COMPONENT_WORKSPACES.offseason.weather.total,
  defaultDays: COMPONENT_WORKSPACES.offseason.weather.defaultDays,
  carryForwardConfig: RESET_NOTES_FUNCTIONALITY
}

export const DANGER_RATINGS = {
  route: COMPONENT_NAMES.dangerRatings,
  element: COMPONENT_NAMES.dangerRatings,
  component: DangerRatings,
  workspaces: COMPONENT_WORKSPACES.forecast.dangerRatings.total,
  defaultDays: COMPONENT_WORKSPACES.forecast.dangerRatings.defaultDays,
  carryForwardConfig: JUST_COPY_FUNCTIONALITY
}

export const DANGER_RATING_CRITICAL_FACTORS = {
  route: COMPONENT_NAMES.dangerRatingCriticalFactors,
  component: DangerRatingCriticalFactors,
  workspaces: COMPONENT_WORKSPACES.advisory.dangerRatingCriticalFactors.total,
  defaultDays:
    COMPONENT_WORKSPACES.advisory.dangerRatingCriticalFactors.defaultDays,
  carryForwardConfig: PARTIAL_COPY_FUNCTIONALITY
}

export const CONFIDENCE = {
  route: COMPONENT_NAMES.confidence,
  element: COMPONENT_NAMES.confidence,
  component: Confidence,
  workspaces: COMPONENT_WORKSPACES.forecast.confidence.total,
  defaultDays: COMPONENT_WORKSPACES.forecast.confidence.defaultDays,
  carryForwardConfig: NO_COPY_FUNCTIONALITY
}

export const COMMUNICATIONS = {
  route: COMPONENT_NAMES.communications,
  element: COMPONENT_NAMES.communications,
  component: Communications,
  workspaces: COMPONENT_WORKSPACES.forecast.communications.total,
  defaultDays: COMPONENT_WORKSPACES.forecast.communications.defaultDays,
  carryForwardConfig: NO_COPY_FUNCTIONALITY
}

export const REVIEW_MESSAGING = {
  route: COMPONENT_NAMES.reviewMessaging,
  element: COMPONENT_NAMES.reviewMessaging,
  component: ReviewMessaging,
  workspaces: COMPONENT_WORKSPACES.forecast.reviewMessaging.total,
  defaultDays: COMPONENT_WORKSPACES.forecast.reviewMessaging.defaultDays,
  carryForwardConfig: NO_COPY_FUNCTIONALITY
}

export const PUBLISH = {
  route: COMPONENT_NAMES.publish,
  component: Publish,
  workspaces: COMPONENT_WORKSPACES.forecast.publish.total,
  defaultDays: COMPONENT_WORKSPACES.forecast.publish.defaultDays,
  carryForwardConfig: NO_COPY_FUNCTIONALITY
}

export const OBSERVATION = {
  route: COMPONENT_NAMES.observation,
  component: Observation,
  workspaces: COMPONENT_WORKSPACES.advisory.observation.total,
  defaultDays: COMPONENT_WORKSPACES.advisory.observation.defaultDays,
  carryForwardConfig: PARTIAL_COPY_FUNCTIONALITY
}

export const TERRAIN_AND_TRAVEL_ADVISORY = {
  route: COMPONENT_NAMES.terrainAndTravelAdvisory,
  component: TerrainAndTravelAdvisory,
  workspaces: COMPONENT_WORKSPACES.advisory.terrainAndTravelAdvisory.total,
  defaultDays:
    COMPONENT_WORKSPACES.advisory.terrainAndTravelAdvisory.defaultDays,
  carryForwardConfig: PARTIAL_COPY_FUNCTIONALITY
}

export const OFFSEASON_MESSAGE = {
  route: COMPONENT_NAMES.offseasonMessage,
  element: COMPONENT_NAMES.offseasonMessage,
  component: OffSeasonMessage,
  workspaces: COMPONENT_WORKSPACES.offseason.offseasonMessage.total,
  defaultDays: COMPONENT_WORKSPACES.offseason.offseasonMessage.defaultDays,
  carryForwardConfig: NO_COPY_FUNCTIONALITY
}

export const ALL_COMPONENTS = {
  avalanche: AVALANCHE,
  avalancheProblems: AVALANCHE_PROBLEMS,
  snowpack: SNOWPACK,
  weakLayers: WEAK_LAYERS,
  weather: WEATHER,
  dangerRatings: DANGER_RATINGS,
  dangerRatingCriticalFactors: DANGER_RATING_CRITICAL_FACTORS,
  reviewMessaging: REVIEW_MESSAGING,
  confidence: CONFIDENCE,
  communications: COMMUNICATIONS,
  terrainAndTravelAdvisory: TERRAIN_AND_TRAVEL_ADVISORY,
  offseasonMessage: OFFSEASON_MESSAGE,
  offseasonWeather: OFFSEASON_WEATHER,
  observation: OBSERVATION,
  publish: PUBLISH
}
