import { createAction } from 'common/utils'

import {
  FETCH_TERRAIN_AND_TRAVEL_ADVICE,
  FAIL_FETCHING_TERRAIN_AND_TRAVEL_ADVICE,
  SET_TERRAIN_AND_TRAVEL_ADVICE,
  UPDATE_TERRAIN_AND_TRAVEL_ADVICE,
  DELETE_TERRAIN_AND_TRAVEL_ADVICE
} from './types'

export const fetchTerrainAndTravelAdvice = payload =>
  createAction(FETCH_TERRAIN_AND_TRAVEL_ADVICE, payload)
export const failFetchingTerrainAndTravelAdvice = payload =>
  createAction(FAIL_FETCHING_TERRAIN_AND_TRAVEL_ADVICE, payload)
export const setTerrainAndTravelAdvice = payload =>
  createAction(SET_TERRAIN_AND_TRAVEL_ADVICE, payload)
export const updateTerrainAndTravelAdvice = payload =>
  createAction(UPDATE_TERRAIN_AND_TRAVEL_ADVICE, payload)
export const deleteTerrainAndTravelAdvice = payload =>
  createAction(DELETE_TERRAIN_AND_TRAVEL_ADVICE, payload)
