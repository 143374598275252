import React from 'react'
import Table from '../table'
import { useTranslation } from 'react-i18next'

const AvalancheDistributionInfoTable = () => {
  const { t } = useTranslation('infoTip')

  const keys = ['isolated', 'specific', 'widespread', 'comment']

  const columns = [
    {
      title: t('distribution.titles.distribution'),
      key: 'distribution'
    },
    {
      title: t('distribution.titles.spatialDensity'),
      key: 'spatialDensity'
    },
    {
      title: t('distribution.titles.evidence'),
      key: 'evidence'
    }
  ]

  const data = keys.map(key => ({
    distribution: t(`distribution.${key}.distribution`),
    spatialDensity: t(`distribution.${key}.spatialDensity`),
    evidence: t(`distribution.${key}.evidence`)
  }))

  return (
    <Table
      data={data}
      columns={columns}
    />
  )
}

export default AvalancheDistributionInfoTable
