import React from 'react'
import PropTypes from 'prop-types'

import css from './style.module.scss'

const ComboFirst = props => <div className={css.first}>{props.children}</div>

ComboFirst.propTypes = {
  children: PropTypes.any.isRequired
}

export default ComboFirst
