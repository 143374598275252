import { takeLatest, takeEvery, all } from 'redux-saga/effects'

import {
  FETCH_AVALANCHE_SUMMARIES,
  SUBMIT_AVALANCHE_WORKSPACE_SUMMARY
} from './types'
import {
  failFetchingAvalancheSummaries,
  setAvalancheSummaries,
  updateAvalancheSummary
} from './actions'
import {
  fetchSummaries,
  submitWorkspaceSummary
} from 'core/summariesSagas'

const AVALANCHE_SUMMARY_TYPE = 'avalanche'

function* onFetchAvalancheSummaries() {
  yield takeLatest(FETCH_AVALANCHE_SUMMARIES, fetchAvalancheSummaries)
}

function* fetchAvalancheSummaries({ payload }) {
  yield fetchSummaries({
    summaryType: AVALANCHE_SUMMARY_TYPE,
    setSummaries: setAvalancheSummaries,
    failFetchingSummaries: failFetchingAvalancheSummaries,
    updateSummary: updateAvalancheSummary
  })
}

function* onSubmitAvalancheWorkspaceSummary() {
  yield takeEvery(
    SUBMIT_AVALANCHE_WORKSPACE_SUMMARY,
    submitAvalancheWorkspaceSummary
  )
}

function* submitAvalancheWorkspaceSummary({ payload }) {
  yield submitWorkspaceSummary({
    formName: payload.formName,
    summaryType: AVALANCHE_SUMMARY_TYPE,
    updateSummary: updateAvalancheSummary
  })
}

export default function* rootSaga() {
  yield all([
    onSubmitAvalancheWorkspaceSummary(),
    onFetchAvalancheSummaries()
  ])
}
