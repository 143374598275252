import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { fetchLocations } from 'core/locations/actions'
import { getCurrentLocationGeoJSON } from 'core/locations/selectors'

import {
  Map,
  SidebarPageContainer as SidebarPage,
  SidebarContainer as Sidebar,
  LastTimeSavedHeader
} from 'common/components'
import LocationForm from '../locationForm'

class Location extends React.Component {
  static propTypes = {
    locationId: PropTypes.string,
    geoJSON: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func
  }

  componentDidMount() {
    document.title = `AvID - ${this.props.t('common:locations')}`

    if (this.props.locationId && !this.props.geoJSON)
      this.props.fetchLocations()
  }

  render() {
    const { locationId, geoJSON, onSubmit, onDelete } = this.props

    return (
      <div>
        <LastTimeSavedHeader />
        <SidebarPage>
          <Sidebar narrow backgroundColor="grey">
            <LocationForm
              locationId={locationId}
              onSubmit={onSubmit}
              onDelete={onDelete}
            />
          </Sidebar>
          {(!locationId || geoJSON) && <Map data={geoJSON} fullSize />}
        </SidebarPage>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  geoJSON: getCurrentLocationGeoJSON(state, ownProps)
})

const mapDispatchToProps = { fetchLocations }

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation(['locations', 'common'])
)(Location)
