import React from 'react'
import PropTypes from 'prop-types'

import {
  formatTableDate,
  formatTableHour,
  formatTableMin
} from 'common/helpers/formatDate'

import css from './style.module.scss'

const DateAndTimeCell = ({ children }) => {
  return (
    <div className={css.dateAndTime}>
      <span className={css.date}>{formatTableDate(children)}</span>
      <span className={css.time}>
        <span className={css.hour}>{formatTableHour(children)}</span>
        <div className={css.colon}>:</div>
        <span className={css.min}>{formatTableMin(children)}</span>
      </span>
    </div>
  )
}

DateAndTimeCell.propTypes = {
  children: PropTypes.string.isRequired
}

export default DateAndTimeCell
