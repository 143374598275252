import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import centroid from '@turf/centroid'
import merge from 'lodash/merge'

import { submitNewLocation } from './actions'
import { getCurrentLocationGeoJSON } from './selectors'
import { getUserId } from 'core/users/selectors'
import Location from './components/location'

const CreateLocation = ({ onSubmit, ...rest }) => (
  <Location onSubmit={onSubmit} locationId={undefined} {...rest} />
)

CreateLocation.propTypes = {
  user: PropTypes.string,
  onCreateLocationSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: getUserId(state),
  currentUploadedGeoJSON: getCurrentLocationGeoJSON(state, { locationId: null })
})

const mapDispatchToProps = dispatch => ({
  onCreateLocationSubmit: values => dispatch(submitNewLocation(values))
})

const mergeProps = (mapStateToProps, mapDispatchToProps, ownProps) => ({
  ...mapStateToProps,
  ...mapDispatchToProps,
  ...ownProps,
  onSubmit: values => {
    const today = new Date()
    const { currentUploadedGeoJSON, user } = mapStateToProps
    delete values.feature.geometry

    const location = merge({}, values, {
      feature: {
        type: 'Feature',
        geometry: mapStateToProps.currentUploadedGeoJSON,
        centroid: centroid(currentUploadedGeoJSON).geometry,
        properties: {
          created: today.toISOString(),
          owner: user,
          status: 'active'
        }
      }
    })

    return mapDispatchToProps.onCreateLocationSubmit({
      location,
      redirect: ownProps.history.push
    })
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CreateLocation)
