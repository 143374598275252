import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { allUsersSelector } from 'core/users/selectors'

import { fetchLocations } from './actions'
import { fetchUsers, fetchUser } from '../users/actions'
import { getLocationsArray } from './selectors'
import LocationsTable from './components/locationsTable'

/**
 * Note: if you want anything to be common to all pages import here (global
 * messaging etc)
 */
class ListLocations extends Component {
  componentDidMount() {
    this.props.fetchLocations()
    this.props.fetchUsers()
    this.props.fetchUser({ redirect: this.props.history.push })
    document.title = `AvID - ${this.props.t('locations')}`
  }

  render() {
    return (
      <LocationsTable
        locations={this.props.locations}
        users={this.props.users}
      />
    )
  }
}

ListLocations.propTypes = {
  locations: PropTypes.array.isRequired,
  users: PropTypes.object.isRequired,
  fetchLocations: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  locations: getLocationsArray(state),
  users: allUsersSelector(state)
})

const mapDispatchToProps = {
  fetchLocations,
  fetchUsers,
  fetchUser
}

export default compose(
  withTranslation('common'),
  connect(mapStateToProps, mapDispatchToProps)
)(ListLocations)
