import React from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'

import { OrderedStatements, QueryStatements } from 'core/statements'

import css from './style.module.scss'

class StatementBuilder extends React.Component {
  static propTypes = {
    orderedStatements: QueryStatements.propTypes.statements,
    queryStatements: QueryStatements.propTypes.statements,
    onMove: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    limit: PropTypes.number
  }

  static defaultProps = {
    orderedStatements: [],
    queryStatements: []
  }

  addSelectedTag = item => ({
    ...item,
    selected:
      this.props.orderedStatements.findIndex(
        statement => statement.id === item.id
      ) > -1
  })

  itemsWithTags = item => item.tags.length > 0

  constructor(props) {
    super(props)
    this.state = {
      queryStatements: props.queryStatements.map(this.addSelectedTag)
    }
  }

  handleStatements = (statement, action, next) => {
    if (this.props.orderedStatements.length < this.props.limit) {
      const index = this.state.queryStatements.findIndex(
        item => item.id === statement.id
      )

      if (index > -1) {
        const queryStatements = cloneDeep(this.state.queryStatements)
        queryStatements[index].selected = action
        this.setState({ queryStatements })
      }
    }
    next(statement)
  }

  componentDidUpdate(prevProps) {
    if (
      // orderedStatements were previously empty
      prevProps.orderedStatements.length !==
        this.props.orderedStatements.length ||
      // queryStatements were previously empty
      prevProps.queryStatements.length !== this.props.queryStatements.length ||
      // the tags on the queryStatements were previsouly not attached
      (prevProps.queryStatements.filter(this.itemsWithTags).length === 0 &&
        this.props.queryStatements.filter(this.itemsWithTags).length > 0)
    ) {
      this.setState({
        queryStatements: this.props.queryStatements.map(this.addSelectedTag)
      })
    }
  }

  render() {
    const { onMove, onDelete, onSelect, orderedStatements } = this.props

    return (
      <QueryStatements
        statements={this.state.queryStatements}
        onSelectStatement={statement =>
          this.handleStatements(statement, true, onSelect)
        }
      >
        <OrderedStatements
          className={css.scrollOrdered}
          statements={orderedStatements}
          onMove={onMove}
          onDelete={statement =>
            this.handleStatements(statement, false, onDelete)
          }
        />
      </QueryStatements>
    )
  }
}

export default StatementBuilder
