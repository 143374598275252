import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { destroy } from 'redux-form'
import { AVALANCHE_LAYER_FORM_NAME } from 'core/product/config/constants'

import {
  Button,
  Workspace,
  WorkspaceContent,
  WorkspaceMain,
  WorkspaceFooter
} from 'common/components'

import { getCurrentProductValidFromDate } from 'core/product/selectors'
import { getActiveVisibleDaysArray } from 'core/product/components/visibleDays/selectors'
import { setWorkspaces } from 'core/product/components/visibleDays/actions'

import {
  fetchAvalancheProblems,
  submitAvalancheProblems
} from 'core/avalancheProblems/actions'
import { avalancheProblemsSelector } from 'core/avalancheProblems/selectors'
import {
  fetchTerrainAndTravelAdvice,
  updateTerrainAndTravelAdvice
} from 'common/data/terrainAndTravelAdvice/actions'
import { terrainAndTravelAdviceStatus } from 'common/data/terrainAndTravelAdvice/selectors'
import { getNotes } from 'core/product/components/notes/selectors'

import AvalacheProblemListItem from '../avalancheProblemsListItem'

import css from './style.module.scss'

class AvalancheProblemsList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    workspaces: PropTypes.number.isRequired,
    defaultDays: PropTypes.array.isRequired,
    date: PropTypes.string.isRequired,
    reportKey: PropTypes.string.isRequired,
    visibleDays: PropTypes.array.isRequired,
    avalancheProblems: PropTypes.object.isRequired,
    terrainAndTravelAdvice: PropTypes.object.isRequired,
    terrainAndTravelAdviceStatus: PropTypes.string,
    notes: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    submitAvalancheProblems: PropTypes.func.isRequired,
    updateTerrainAndTravelAdvice: PropTypes.func.isRequired,
    onCopy: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.props.initialize()
  }

  componentDidUpdate() {
    const { hash } = this.props.location
    if (hash) {
      const element = window.document.getElementById(hash.replace('#', ''))
      if (element) element.scrollIntoView()
    }
  }

  handleOnCopy = day => {
    const { t, onCopy, showCopyForwardWarnings } = this.props

    if (!showCopyForwardWarnings[day - 1]) return onCopy(day)

    if (window.confirm(t('confirmCopy')) === true) onCopy(day)
  }

  render() {
    const {
      t,
      date,
      notes,
      reportKey,
      visibleDays,
      avalancheProblems
    } = this.props

    return (
      <div className={css.container}>
        {visibleDays.map(({ day }) => {
          const workspaceAvalancheProblems = avalancheProblems[day]
          const problemList = workspaceAvalancheProblems.problemList
          const url = `/avid/products/edit/${reportKey}/workspaces/${day}/avalancheProblems`
          return (
            <Workspace
              id={`workspace_${day}`}
              key={`workspace_avalancheProblem_${day}`}
              type="avalancheProblems"
              name={day}
              day={day}
              date={date}
              notes={notes['avalancheProblems'][day]}
              handleOnCopy={() => this.handleOnCopy(day)}
            >
              <WorkspaceContent hasFooter>
                <WorkspaceMain>
                  {problemList.length === 0 && (
                    <div className={css.noData}>{t('noData')}</div>
                  )}
                  {problemList.map((problem, ref) => (
                    <AvalacheProblemListItem
                      key={ref}
                      problem={problem}
                      workspace={day}
                      workspaceAvalancheProblems={workspaceAvalancheProblems}
                      path={url}
                    />
                  ))}
                </WorkspaceMain>
              </WorkspaceContent>
              <WorkspaceFooter>
                <Link to={`${url}/new`}>
                  <Button size="auto" context="workspace">
                    {t('addNew')}
                  </Button>
                </Link>
              </WorkspaceFooter>
            </Workspace>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const avalancheProblems = avalancheProblemsSelector(state)
  const terrainAndTravelAdvice =
    state.terrainAndTravelAdvice.terrainAndTravelAdviceByDays

  const showCopyForwardWarnings = [1, 2, 3].map(day => {
    const isNextDayTaTEmpty = terrainAndTravelAdvice[day + 1].length === 0
    const isNextDayAvProblemEmpty =
      avalancheProblems[day + 1].problemList.length === 0
    return !isNextDayTaTEmpty || !isNextDayAvProblemEmpty
  })

  return {
    reportKey: ownProps.match.params.id,
    date: getCurrentProductValidFromDate(state),
    visibleDays: getActiveVisibleDaysArray(state),
    notes: getNotes(state),
    avalancheProblems,
    terrainAndTravelAdvice,
    terrainAndTravelAdviceStatus: terrainAndTravelAdviceStatus(state),
    showCopyForwardWarnings
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchInitialize(terrainAndTravelAdviceStatus) {
    dispatch(fetchAvalancheProblems())
    dispatch(destroy(AVALANCHE_LAYER_FORM_NAME))
    if (
      terrainAndTravelAdviceStatus !== 'FULFILLED' &&
      terrainAndTravelAdviceStatus !== 'PENDING'
    )
      dispatch(fetchTerrainAndTravelAdvice())

    dispatch(
      setWorkspaces({
        toShow: ownProps.workspaces,
        defaultDays: ownProps.defaultDays
      })
    )
  },
  submitAvalancheProblems(payload) {
    dispatch(submitAvalancheProblems(payload))
  },
  updateTerrainAndTravelAdvice(payload) {
    dispatch(updateTerrainAndTravelAdvice(payload))
  }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onCopy(day) {
    dispatchProps.submitAvalancheProblems({
      listPageSubmit: true,
      workspace: day + 1,
      isEditing: false,
      problemList: stateProps.avalancheProblems[day].problemList
    })

    dispatchProps.updateTerrainAndTravelAdvice({
      workspace: day + 1,
      statements: stateProps.terrainAndTravelAdvice[day].map(statement => ({
        id: statement.statementId,
        index: statement.index
      }))
    })
  },
  initialize() {
    dispatchProps.dispatchInitialize(stateProps.terrainAndTravelAdviceStatus)
  }
})

AvalancheProblemsList = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AvalancheProblemsList)
AvalancheProblemsList = withTranslation('avalancheProblems')(
  AvalancheProblemsList
)

export default AvalancheProblemsList
