import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import noop from 'lodash/noop'

import { classList } from 'common/utils'

import { getVisibleDaysArray } from './selectors'
import { toggleDay } from './actions'

import css from './style.module.scss'

let VisibleDay = ({ label, active, disabled, onVisibleDayClicked }) => {
  const classes = classList(
    css.visibleDayButton,
    active && css.visibleDayButtonActive,
    disabled && css.disabled
  )

  return (
    <div className={classes} onClick={disabled ? noop : onVisibleDayClicked}>
      {label}
    </div>
  )
}

VisibleDay.propTypes = {
  label: PropTypes.number.isRequired,
  onVisibleDayClicked: PropTypes.func.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool
}

VisibleDay.defaultProps = {
  active: false
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onVisibleDayClicked: () => {
    dispatch(toggleDay(ownProps.label))
  }
})

VisibleDay = connect(
  null,
  mapDispatchToProps
)(VisibleDay)

const VisibleDays = ({ visibleDays }) => (
  <div className={css.visibleDays}>
    {visibleDays.map(({ day, isActive, isInteractable }) => (
      <VisibleDay
        label={day}
        active={isActive}
        disabled={!isInteractable}
        key={day}
      />
    ))}
  </div>
)

VisibleDays.propTypes = {
  visibleDays: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  visibleDays: getVisibleDaysArray(state)
})

export default connect(mapStateToProps)(VisibleDays)
