import Immutable from 'seamless-immutable'

import { createReducer } from 'common/utils'
import * as types from './types'

const INITIAL_STATE = Immutable({
  isFetching: false,
  tagsByComponent: {}
})

const reducers = {
  [types.FETCH_TAGS]: fetchTags,
  [types.FAIL_FETCHING_TAGS]: failFetchingTags,
  [types.SET_TAGS]: setTags
}

function fetchTags(state) {
  return Immutable.set(state, 'isFetching', true)
}

function failFetchingTags(state) {
  return Immutable.set(state, 'isFetching', false)
}

function setTags(state, { tags, component }) {
  return Immutable.merge(state, {
    isFetching: false,
    tagsByComponent: {
      ...state.tagsByComponent,
      [component]: tags
    }
  })
}

export default createReducer(INITIAL_STATE, reducers)
