import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw'
import { isEmpty } from 'lodash'

import { classList } from 'common/utils'
import css from './style.module.scss'

const ACCESS_TOKEN =
  'pk.eyJ1IjoicmVkY29uc2VydmF0b3J5IiwiYSI6ImNqOGJ4c2MzMDAzYXQzM21zb2I0b3d4cXoifQ.t7YdRp6TC5rtmS_uR0UYNQ'
mapboxgl.accessToken = ACCESS_TOKEN

// TODO: Greatly simplify this component
const UserInput = ({ id, index, coordinate, value, onBlur, onChange }) => (
  <input
    type="text"
    value={value}
    onBlur={onBlur}
    onChange={onChange}
    key={`${id}-${index}-${coordinate}`}
  />
)

class Map extends Component {
  state = {
    polygons: [] // list of the drawn polygons
  }

  componentDidMount() {
    const { zoom, center, data } = this.props
    /*
     * options: https://www.mapbox.com/mapbox-gl-js/api/
     */
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v9',
      zoom: zoom,
      center: center
    })

    this.setUpDraw()

    // setup layer
    this.map.on('load', () => {
      /**
       * Add data and layers
       * todo: move this data into redux state,
       * then we can provide tools to edit
       */

      if (!isEmpty(data)) {
        this.initGeoJson(data)
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.updateGeoJson(this.props.data)
    }
  }

  initGeoJson = data => {
    this.map.addSource('polygons', {
      type: 'geojson',
      data
    })

    this.map.addLayer(
      {
        id: 'polygons',
        type: 'fill',
        source: 'polygons'
      },
      'country-label-lg'
    )
    this.map.setPaintProperty('polygons', 'fill-opacity', 0.5)

    this.setPolygonsFill()
  }

  updateGeoJson = data => {
    let mapSource = this.map.getSource('polygons')
    if (!mapSource) {
      this.initGeoJson(data)
      mapSource = this.map.getSource('polygons')
    }

    if (!data) {
      this.map.removeLayer(mapSource.id)
      this.map.removeSource(mapSource.id)
    } else {
      mapSource.setData(data)
    }
  }

  setUpDraw = () => {
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true
      }
    })

    this.map.addControl(this.draw)

    // set up draw listeners
    this.map.on('draw.create', this.updateArea)
    this.map.on('draw.delete', this.updateArea)
    this.map.on('draw.update', this.updateArea)
  }

  /**
   *
   * @param {event} event has features which are the polygons
   * This event is fired every time you create, delete and double
   * click on a polygon
   */
  updateArea = e => {
    const { action, type } = e
    let data
    if (action === 'change_coordinates') {
      data = this.draw.getAll()
      this.setState({
        polygons: data.features
      })
      return
    }
    if (type === 'draw.create') {
      data = this.draw.getAll()
      this.setState({
        polygons: data.features
      })
    }

    if (type === 'draw.delete') {
      data = this.draw.getAll()
      this.setState({
        polygons: []
      })
    }
  }

  /**
   * This kind of styling can be called in
   * componentDidUpdate with state or props later
   */
  setPolygonsFill = () => {
    this.map.setPaintProperty('polygons', 'fill-color', {
      property: 'example_property',
      stops: [[0, '#f8d5cc'], [1, '#f8d5cc']]
    })
  }

  componentWillUnmount() {
    this.map.remove()
  }

  handleInputBlur = e => {
    const data = this.draw.getAll() // is this a copy?
    data.features = this.state.polygons
    this.draw.set(data)
  }

  handleInputChange = (e, id, whichPolygon, whichLatLng, latLngIndex) => {
    const { currentTarget } = e
    const newPolygons = cloneDeep(this.state.polygons)
    // if it's the first or last latLng, update both
    // the polygon needs to close so the first and last latlng needs to be identical
    const length = newPolygons[whichPolygon].geometry.coordinates[0].length
    if (whichLatLng === 0) {
      newPolygons[whichPolygon].geometry.coordinates[0][length - 1][
        latLngIndex
      ] = Number(currentTarget.value)
    }
    if (whichLatLng === length - 1) {
      newPolygons[whichPolygon].geometry.coordinates[0][0][
        latLngIndex
      ] = Number(currentTarget.value)
    }
    newPolygons[whichPolygon].geometry.coordinates[0][whichLatLng][
      latLngIndex
    ] = Number(currentTarget.value)
    this.setState({
      polygons: newPolygons
    })
  }

  renderFeatureInputs = () => {
    if (this.state.polygons.length) {
      return this.state.polygons.map((feature, index) =>
        feature.geometry.coordinates.map(polygon =>
          polygon.map((coordinate, coordinateIndex) => (
            <div
              className={css.set}
              key={`${index}-${coordinateIndex}-${feature.id}`}
            >
              <UserInput
                id={feature.id}
                index={index}
                coordinate={0}
                value={coordinate[0]}
                onBlur={this.handleInputBlur}
                onChange={e =>
                  this.handleInputChange(
                    e,
                    feature.id,
                    index,
                    coordinateIndex,
                    0
                  )
                }
                key={`${index}-${coordinateIndex}-0-${feature.id}`}
              />
              <UserInput
                id={feature.id}
                index={index}
                coordinate={1}
                value={coordinate[1]}
                onBlur={this.handleInputBlur}
                onChange={e =>
                  this.handleInputChange(
                    e,
                    feature.id,
                    index,
                    coordinateIndex,
                    1
                  )
                }
                key={`${index}-${coordinateIndex}-1-${feature.id}`}
              />
            </div>
          ))
        )
      ) // end map
    }
  }

  render() {
    const classes = classList(css.left, this.props.fullSize && css.fullSize)

    return (
      <div className={css.container}>
        <div className={classes} ref={el => (this.mapContainer = el)} />
        <div className={css.right}>{this.renderFeatureInputs()}</div>
      </div>
    )
  }
}
Map.propTypes = {
  center: PropTypes.array,
  zoom: PropTypes.number,
  data: PropTypes.object,
  fullSize: PropTypes.bool
}

Map.defaultProps = {
  center: [-118.1955909, 50.9981698],
  zoom: 6,
  data: {}
}

export default Map
