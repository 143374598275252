import React from 'react'
import PropTypes from 'prop-types'

import css from './style.module.scss'

const PageHeader = ({ children }) => (
  <div className={css.pageHeader}>{children}</div>
)

PageHeader.propTypes = {
  children: PropTypes.node
}

export default PageHeader
