import Immutable from 'seamless-immutable'

import { createReducer } from 'common/utils'
import { deleteItem } from 'common/helpers/orderedList'
import * as types from './types'

const WORKSPACES = [1, 2, 3, 4]

const INITIAL_STATE = Immutable({
  isFetching: false,
  isFormActive: false,
  avalancheProblemsByDays: WORKSPACES.reduce((acc, i) => {
    acc[i] = {
      problemList: [],
      publicSum: 0
    }
    return acc
  }, {})
})

const reducers = {
  [types.FETCH_AVALANCHE_PROBLEMS]: fetchAvalancheProblems,
  [types.FAIL_FETCHING_AVALANCHE_PROBLEMS]: failFetchingAvalancheProblems,
  [types.SET_AVALANCHE_PROBLEMS]: setAvalancheProblems,
  [types.DELETE_AVALANCHE_PROBLEM]: deleteAvalancheProblem,
  [types.UPDATE_AVALANCHE_PROBLEMS]: updateAvalancheProblems
}

function fetchAvalancheProblems(state) {
  return Immutable.set(state, 'isFetching', true)
}

function failFetchingAvalancheProblems(state) {
  return Immutable.set(state, 'isFetching', false)
}

function setAvalancheProblems(state, payload) {
  if (!payload) return state

  return Immutable.merge(state, {
    isFetching: false,
    avalancheProblemsByDays: payload.reduce((acc, data) => {
      acc[data.workspace] = {
        problemList: data.problemList,
        publicSum: data.problemList.filter(problem => problem.public).length
      }
      return acc
    }, {})
  }, { deep: true })
}

function deleteAvalancheProblem(state, { workspace, index }) {
  const updatedAvalancheProblems = deleteItem(
    state.avalancheProblemsByDays[workspace].problemList,
    index
  )

  return Immutable.setIn(state, ['avalancheProblemsByDays', `${workspace}`], {
    problemList: updatedAvalancheProblems,
    publicSum: updatedAvalancheProblems.filter(problem => problem.public).length
  })
}

function updateAvalancheProblems(
  state,
  { workspace, updatedAvalancheProblems }
) {
  return Immutable.setIn(state, ['avalancheProblemsByDays', `${workspace}`], {
    problemList: updatedAvalancheProblems,
    publicSum: updatedAvalancheProblems.filter(problem => problem.public).length
  })
}

export default createReducer(INITIAL_STATE, reducers)
