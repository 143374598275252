import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button } from 'common/components'

import css from './style.module.scss'

const NewLocationButton = () => {
  const { t } = useTranslation('locations')
  return <Button context="new">{t('newLocation')}</Button>
}

export const NewLocationHeaderCell = () => (
  <div className={css.buttonContainer}>
    <Link to="/avid/locations/new">
      <NewLocationButton />
    </Link>
  </div>
)

export const LocationHeaderCell = () => {
  const { t } = useTranslation('common')
  return (
    <div>
      {t('location')} <span />
    </div>
  )
}

export const CreatedOnHeaderCell = () => {
  const { t } = useTranslation('common')
  return (
    <div>
      {t('createdOn')} <span />
    </div>
  )
}

export const CreatedByHeaderCell = () => {
  const { t } = useTranslation('common')
  return (
    <div>
      {t('createdBy')} <span />
    </div>
  )
}
