import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { ALL_COMPONENTS as COMPONENT_CONFIG } from 'core/product/config'

import Button from '../button'

import css from './style.module.scss'

const LAST_DAY = 4

class Buttons extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeButton: ''
    }

    this.buttons = [
      {
        label: '0',
        disabled: props.yesterdaysId === null,
        onClick: this.onDayZeroClicked
      },
      {
        label: '1',
        disabled: props.yesterdaysId === null,
        onClick: this.onDayOneClicked
      },
      {
        label: 'R',
        onClick: this.onResetClicked
      },
      {
        label: 'C',
        onClick: this.onCopyClicked
      },
      {
        label: 'N',
        onClick: this.onNotesClicked
      }
    ]
  }

  buttonsToRender = config => {
    return this.buttons.filter(button => {
      return config.indexOf(button.label) > -1
    })
  }

  setButtonActive = activeButton => {
    if (this.state.activeButton !== activeButton) {
      this.setState({ activeButton })
    } else {
      this.setState({ activeButton: '' })
    }
  }

  onDayClicked = workspaceRequested => {
    if (this.props.handleOnFetchContent) {
      const { type, day } = this.props
      this.props.handleOnFetchContent(type, day, workspaceRequested)
    }
  }

  onDayZeroClicked = () => {
    this.onDayClicked(0)
  }

  onDayOneClicked = () => {
    this.onDayClicked(1)
  }

  onResetClicked = () => {
    if (this.props.handleOnReset) {
      const { type, day } = this.props
      this.props.handleOnReset(type, day)
    }
  }

  onCopyClicked = () => {
    if (this.props.handleOnCopy) {
      const { type, day } = this.props
      this.props.handleOnCopy(type, day)
    }
  }

  onNotesClicked = () => {
    this.props.enableNotes()
    this.setButtonActive('N')
  }

  render() {
    const { activeButton } = this.state
    const { type, element, day, hasNotes, hideButtons } = this.props

    const carryForwardConfig =
      COMPONENT_CONFIG[element || type].carryForwardConfig
    let buttons = this.buttonsToRender(carryForwardConfig)

    // Store the Notes button in a variable to render separately
    const notesButton = buttons[buttons.length - 1]
    // Remove the notes button [N] from array of mapped buttons
    buttons = buttons.slice(0, buttons.length - 1)

    // Remove the Carry button for the last day
    if (day === LAST_DAY) {
      buttons = buttons.slice(0, buttons.length - 1)
    }

    const notesActive = activeButton === notesButton.label

    if (hideButtons) buttons = []

    return (
      <div className={css.right}>
        {!notesActive &&
          buttons.map(({ label, onClick, disabled }) => (
            <Button
              label={label}
              disabled={disabled}
              isActive={activeButton === label}
              onClick={onClick}
              key={label}
            />
          ))}
        <div className={css.buttonSeparator} />
        {hasNotes && (
          <Button
            toggleLabel
            label={notesButton.label}
            isActive={notesActive}
            onClick={notesButton.onClick}
          />
        )}
      </div>
    )
  }
}

Buttons.propTypes = {
  type: PropTypes.string.isRequired,
  element: PropTypes.string,
  enableNotes: PropTypes.func.isRequired,
  day: PropTypes.number,
  yesterdaysId: PropTypes.number,
  handleOnReset: PropTypes.func,
  handleOnCopy: PropTypes.func,
  handleOnFetchContent: PropTypes.func,
  hasNotes: PropTypes.bool,
  /* Flag does not include the notes button */
  hideButtons: PropTypes.bool
}

Buttons.defaultProps = {
  showExtraButtons: false,
  hasNotes: true,
  hideButtons: false
}

export default Buttons
