import React from 'react'
import { useFormikContext } from 'formik'
import debounce from 'lodash/debounce'

const AutoSave = ({ debounceMs }) => {
  const formik = useFormikContext()
  const debouncedSubmit = React.useCallback(
    debounce(() => {
      formik.submitForm()
    }, debounceMs),
    [debounceMs, formik.submitForm]
  )

  React.useEffect(() => {
    if (formik.dirty) {
      debouncedSubmit()
    }
  }, [debouncedSubmit, formik.values, formik.dirty])

  return null
}

export default AutoSave
