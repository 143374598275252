import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import config from './authConfig'
import { history } from 'common/store'
import {
  setItem,
  destroyItem,
  formatProfile,
  KEYS
} from 'common/helpers/storage'

const PROTOCOL = window.location.protocol
const HOSTNAME = window.location.hostname
const PORT = window.location.port
const CALLBACK_URL = `${PROTOCOL}//${HOSTNAME}${PORT ? `:${PORT}` : ''}`

export const AUTH_STATE = {
  authenticating: 'AUTHENTICATING',
  authenticated: 'AUTHENTICATED',
  unauthenticated: 'UNAUTHENTICATED'
}

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

const getAuthClient = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const client = await createAuth0Client({
        cacheLocation: 'localstorage',
        audience: config.audience,
        domain: config.domain,
        client_id: config.clientId,
        redirect_uri: CALLBACK_URL,
        onRedirectCallback
      })
      resolve(client)
    } catch (error) {
      reject(error)
    }
  })
}

export const getTokenSilently = async (...p) => {
  const client = await getAuthClient()
  return await client.getTokenSilently(...p)
}

export const loginWithRedirect = async (...p) => {
  const client = await getAuthClient()
  return await client.loginWithRedirect(...p)
}

export const AuthContext = React.createContext()
export const useAuth = () => useContext(AuthContext)
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState()
  const [client, setClient] = useState()
  const [state, setState] = useState(AUTH_STATE.authenticating)

  useEffect(() => {
    const initAuth = async () => {
      const auth = await getAuthClient()
      setClient(auth)

      if (window.location.search.includes('code=')) {
        const { appState } = await auth.handleRedirectCallback()
        history.push(
          appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
        )
      }

      const isAuthenticated = await auth.isAuthenticated()

      if (isAuthenticated) {
        const user = await auth.getUser()
        setUser(user)
        setItem(KEYS.userId, formatProfile(user))
        setState(AUTH_STATE.authenticated)
      } else {
        setState(AUTH_STATE.unauthenticated)
      }
    }

    initAuth()
    // eslint-disable-next-line
  }, [])

  const logout = async params => {
    try {
      client.logout({
        client_id: client.options.client_id,
        returnTo: `${CALLBACK_URL}/login`
      })
      setState(AUTH_STATE.unauthenticated)
      destroyItem(KEYS.userId)
    } catch (error) {
      console.error(error)
    }
  }

  const handleRedirectCallback = async () => {
    await client.handleRedirectCallback()

    const user = await client.getUser()
    setState(AUTH_STATE.authenticated)
    setUser(user)
  }

  return (
    <AuthContext.Provider
      value={{
        state,
        user,
        logout,
        handleRedirectCallback,
        loginWithRedirect: (...p) => client.loginWithRedirect(...p)
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
