import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { submitEditLocation, deleteLocation } from './actions'
import Location from './components/location'

const EditLocation = props => {
  const { locationId, onSubmit, onDeleteClicked } = props

  return (
    <Location
      locationId={locationId}
      onSubmit={onSubmit}
      onDelete={onDeleteClicked}
    />
  )
}

EditLocation.propTypes = {
  locationId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onEditLocationSubmit: PropTypes.func.isRequired,
  onDeleteClicked: PropTypes.func.isRequired
}

const mapStateToProps = (state, props) => ({
  locationId: props.match.params.id
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onEditLocationSubmit: values => dispatch(submitEditLocation(values)),
  onDeleteClicked: () => {
    if (window.confirm(ownProps.t('confirmDeleteLocation')) === true) {
      dispatch(
        deleteLocation({
          id: ownProps.match.params.id,
          redirect: ownProps.history.push
        })
      )
    }
  }
})

const mergeProps = (mapStateToProps, mapDispatchToProps, ownProps) => ({
  ...mapStateToProps,
  ...mapDispatchToProps,
  ...ownProps,
  onSubmit: values => {
    return mapDispatchToProps.onEditLocationSubmit(values)
  }
})

export default compose(
  withTranslation('locations'),
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )
)(EditLocation)
