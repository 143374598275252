import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Dropdown } from 'common/components'

class DropdownCell extends Component {
  onChanged = e => {
    const { onDropdownChange, rowData, columnAccessor } = this.props
    return onDropdownChange(rowData, e, columnAccessor)
  }

  render() {
    const { rowData, options } = this.props

    return (
      <Dropdown
        value={rowData.value ? rowData.value : false}
        onChange={this.onChanged}
        options={options}
      />
    )
  }
}

DropdownCell.propTypes = {
  rowData: PropTypes.object.isRequired,
  columnAccessor: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onDropdownChange: PropTypes.func.isRequired
}

export default DropdownCell
