import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button } from 'common/components'
import classList from 'common/utils/classList'

import css from './style.module.scss'

const quickStartClasses = classList(
  css.buttonContainer,
  css.buttonContainerQuickStart
)

const QuickStartAndReviewButtons = ({
  onQuickStartClick,
  onReviewFilterClick
}) => {
  const { t } = useTranslation('products')
  return (
    <div className={quickStartClasses}>
      <div className={css.buttonHalf}>
        <Button context="quick-start" onClick={onQuickStartClick}>
          {t('quickStart')}
        </Button>
      </div>
      <div className={css.buttonHalf}>
        <Button context="review" onClick={onReviewFilterClick}>
          {t('review')}
        </Button>
      </div>
    </div>
  )
}

QuickStartAndReviewButtons.propTypes = {
  onQuickStartClick: PropTypes.func.isRequired,
  onReviewFilterClick: PropTypes.func.isRequired
}

export default QuickStartAndReviewButtons
