import { useEffect } from 'react'
import { useAuth, AUTH_STATE } from '../../auth'

const Login = () => {
  const { state, loginWithRedirect } = useAuth()

  useEffect(() => {
    if (
      state === AUTH_STATE.authenticating ||
      state === AUTH_STATE.authenticated
    ) {
      return
    }

    async function login() {
      await loginWithRedirect()
    }

    login()
  }, [state, loginWithRedirect])

  return null
}

export default Login
