import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getCurrentProductLocation } from 'core/product/selectors'
import { getCurrentLocationLink } from 'core/locations/selectors'

import HeaderTitle from 'core/product/components/headerTitle'

import css from './style.module.scss'

const ChatLink = ({ link }) => {
  const { t } = useTranslation('products')
  return (
    <HeaderTitle title="chat">
      {link ? (
        <a
          className={css.chat}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {link}
        </a>
      ) : (
        <span>{t('header.noLinkAvailable')}</span>
      )}
    </HeaderTitle>
  )
}

ChatLink.propTypes = {
  link: PropTypes.string
}

const mapStateToProps = state => {
  const locationId = getCurrentProductLocation(state)
  const link = getCurrentLocationLink(state, { locationId })
  return { link }
}

export default connect(mapStateToProps)(ChatLink)
