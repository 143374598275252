import { createAction } from 'common/utils'

import {
  FETCH_STATEMENTS,
  FAIL_FETCHING_STATEMENTS,
  SET_STATEMENTS
} from './types'

export const fetchStatements = payload =>
  createAction(FETCH_STATEMENTS, payload)
export const failFetchingStatements = payload =>
  createAction(FAIL_FETCHING_STATEMENTS, payload)
export const setStatements = payload => createAction(SET_STATEMENTS, payload)
