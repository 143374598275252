import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import snakeCase from 'lodash/snakeCase'

import { Button } from 'common/components'
import {
  getCurrentLocationGeoJSON,
  getCurrentLocationName
} from 'core/locations/selectors'

const DownloadGeoJsonButton = ({ geoJsonUrl, filename }) => {
  const { t } = useTranslation('locations')
  return (
    <a href={geoJsonUrl} download={filename}>
      <Button context="go">{t('downloadGeojson')}</Button>
    </a>
  )
}

DownloadGeoJsonButton.propTypes = {
  locationId: PropTypes.string.isRequired,
  geoJsonUrl: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired
}

const mapStateToProps = (state, props) => {
  const locationName = getCurrentLocationName(state, props)
  const geoJson = getCurrentLocationGeoJSON(state, props)
  const json = JSON.stringify(geoJson)
  const blob = new Blob([json], { type: 'application/json' })

  return {
    geoJsonUrl: window.URL.createObjectURL(blob),
    filename: `${snakeCase(locationName)}.geojson`
  }
}

export default connect(mapStateToProps)(DownloadGeoJsonButton)
