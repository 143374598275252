import Immutable from 'seamless-immutable'

import { createReducer } from 'common/utils'
import * as types from './types'

const INITIAL_STATE = Immutable({
  isFetching: false,
  notesById: {},
  allReviewNotes: []
})

const reducers = {
  [types.FETCH_NOTES]: fetchNotes,
  [types.FETCH_ALL_REVIEW_NOTES]: fetchAllReviewNotes,
  [types.FAIL_FETCHING_NOTES]: failFetchingNotes,
  [types.SET_NOTES]: setNotes,
  [types.SET_ALL_REVIEW_NOTES]: setAllReviewNotes,
  [types.ADD_NEW_NOTE]: addNewNote,
  [types.UPDATE_NOTE]: updateNote
}

function fetchNotes(state) {
  return Immutable.set(state, 'isFetching', true)
}

function fetchAllReviewNotes(state) {
  return Immutable.set(state, 'isFetching', true)
}

function failFetchingNotes(state) {
  return Immutable.set(state, 'isFetching', false)
}

function setNotes(state, payload) {
  if (!payload) return state

  const notes = {
    isFetching: false,
    // Notes are returned by the server as an array and need to be mapped to an
    // object with keys that correspond to their IDs
    notesById: payload.reduce(
      (notes, note) => ({ ...notes, [note.id]: note }),
      {}
    )
  }

  return Immutable.merge(state, notes)
}

function setAllReviewNotes(state, payload) {
  return Immutable.merge(state, {
    isFetching: false,
    allReviewNotes: payload
  })
}

function addNewNote(state, payload) {
  const notes = {
    isFetching: false,
    notesById: {
      ...state.notesById,
      [`${payload.id}`]: payload
    }
  }

  return Immutable.merge(state, notes)
}

function updateNote(state, payload) {
  const { id } = payload
  return Immutable.setIn(state, ['notesById', `${id}`], payload)
}

export default createReducer(INITIAL_STATE, reducers)
