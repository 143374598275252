import React from 'react'

import Dropdown from '../../dropdown'
import InputWrapper from '../inputWrapper'

const Select = props => (
  <InputWrapper {...props.meta}>
    <Dropdown
      options={props.options}
      {...props.input}
      autoFocus={props.autoFocus}
      disabled={props.disabled}
    />
  </InputWrapper>
)

export default Select
