import { takeLatest, takeEvery, all } from 'redux-saga/effects'

import {
  FETCH_OBSERVATION_SUMMARIES,
  SUBMIT_OBSERVATION_WORKSPACE_SUMMARY
} from './types'
import {
  failFetchingObservationSummaries,
  setObservationSummaries,
  updateObservationSummary
} from './actions'
import {
  fetchSummaries,
  submitWorkspaceSummary
} from 'core/summariesSagas'

const OBSERVATION_SUMMARY_TYPE = 'observation'

function* onFetchObservationSummaries() {
  yield takeLatest(FETCH_OBSERVATION_SUMMARIES, fetchObservationSummaries)
}

function* fetchObservationSummaries({ payload }) {
  yield fetchSummaries({
    summaryType: OBSERVATION_SUMMARY_TYPE,
    setSummaries: setObservationSummaries,
    failFetchingSummaries: failFetchingObservationSummaries,
    updateSummary: updateObservationSummary
  })
}

function* onSubmitObservationWorkspaceSummary() {
  yield takeEvery(
    SUBMIT_OBSERVATION_WORKSPACE_SUMMARY,
    submitObservationWorkspaceSummary
  )
}

function* submitObservationWorkspaceSummary({ payload }) {
  yield submitWorkspaceSummary({
    formName: payload.formName,
    summaryType: OBSERVATION_SUMMARY_TYPE,
    updateSummary: updateObservationSummary
  })
}

export default function* rootSaga() {
  yield all([
    onSubmitObservationWorkspaceSummary(),
    onFetchObservationSummaries()
  ])
}
