import React, { Component, PureComponent } from 'react'
import PropTypes from 'prop-types'
import DeleteIcon from 'mdi-react/DeleteIcon'
import { withTranslation } from 'react-i18next'
import Immutable from 'seamless-immutable'
import { Dropdown } from 'common/components'
import { CharactersRemaining } from 'common/components/form'
import RichTextEditor from 'common/components/form/richTextEditor'
import { convertToRaw, convertFromRaw, ContentState } from 'draft-js'
import styles from './style.module.scss'

const LOCALES = ['en', 'fr']
const SUMMARIES = new Map([
  ['en', 'English'],
  ['fr', 'French']
])

class MultiLanguageInput extends PureComponent {
  static propTypes = {
    value: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      )
    ),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    characterLimit: PropTypes.number
  }
  static defaultProps = {
    value: []
  }
  handleLocaleChange = event => {
    this.addLocale(event.target.value)
  }
  addLocale(locale) {
    const index = this.props.value.length

    /*  The MultiLanguageInput textboxes should be initialized with an empty
     *  DraftJS ContentState object. This ensures accurate object comparisons
     *  when checking if the text has changed value.
     */
    const emptyRawContent = convertToRaw(ContentState.createFromText(''))

    this.props.onChange(
      Object.assign([...this.props.value], {
        [index]: [locale, emptyRawContent]
      })
    )
  }
  removeLocale = locale => {
    if (window.confirm(this.props.t('confirmDelete')) === true) {
      const value = this.props.value.filter(item => item[0] !== locale)
      this.props.onChange(value)
    }
  }
  handleValueChange = (locale, content) => {
    const index = this.props.value.findIndex(item => item[0] === locale)
    if (index < 0) return

    const updatedValue = Object.assign([...this.props.value[index]], {
      1: content
    })
    const values = Object.assign([...this.props.value], {
      [index]: updatedValue
    })
    this.props.onChange(values)
  }
  renderDropdown() {
    const { value, t } = this.props
    const locales = value.map(item => item[0])

    return (
      <Dropdown
        onChange={this.handleLocaleChange}
        value=""
        style={{ fontSize: '1em' }}
      >
        <option value="" disabled>
          {t('Add another language')}
        </option>
        {LOCALES.map(locale => (
          <option
            key={locale}
            value={locale}
            disabled={locales.includes(locale)}
          >
            {t(SUMMARIES.get(locale))}
          </option>
        ))}
      </Dropdown>
    )
  }
  renderPlaceholder(locale) {
    const { t } = this.props
    return `${t('Type some content in')} ${t(`${locale}InText`)}`
  }
  renderItem(item) {
    const [locale, value] = item

    return (
      <Item
        key={locale}
        locale={locale}
        value={value}
        placeholder={this.renderPlaceholder(locale)}
        disabled={this.props.disabled}
        onChange={this.handleValueChange}
        onRemove={this.removeLocale}
        characterLimit={this.props.characterLimit}
      />
    )
  }
  render() {
    return (
      <div>
        {this.props.value.map(this.renderItem, this)}
        {this.renderDropdown()}
      </div>
    )
  }
}

export default withTranslation()(MultiLanguageInput)

class Item extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(LOCALES).isRequired,
    value: PropTypes.object,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    characterLimit: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired
  }
  static defaultProps = {
    value: ''
  }
  state = {
    count: 0
  }

  componentDidMount() {
    this.setState({
      characterCount: this.updateCharacterCount(this.props.value)
    })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value)
      this.setState({
        characterCount: this.updateCharacterCount(this.props.value)
      })
  }

  updateCharacterCount = value => {
    if (!value) return

    const raw = Immutable.asMutable(value, { deep: true })
    try {
      const contentState = convertFromRaw(raw)
      const count = contentState.getPlainText().length
      this.setState({ count })
    } catch (err) {
      return
    }
  }
  handleRemove = event => {
    event.preventDefault()
    this.props.onRemove(this.props.locale)
  }
  handleChange = value => {
    this.props.onChange(this.props.locale, value)
  }

  render() {
    const {
      locale,
      characterLimit,
      value,
      placeholder,
      disabled,
      t
    } = this.props

    return (
      <details open className={styles.Item}>
        <summary>
          {t(SUMMARIES.get(locale))}
          {characterLimit && (
            <CharactersRemaining
              className={styles.FloatRight}
              count={this.state.count}
              limit={characterLimit}
            />
          )}
        </summary>
        <div className={styles.Content}>
          <RichTextEditor
            value={value}
            onChange={this.updateCharacterCount}
            onBlur={this.handleChange}
            characterLimit={characterLimit}
            placeholder={placeholder}
            disabled={disabled}
          />
          <button className={styles.deleteButton} onClick={this.handleRemove}>
            <DeleteIcon size={18} />
          </button>
        </div>
      </details>
    )
  }
}

Item = withTranslation()(Item)
