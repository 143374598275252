import React, { Component } from 'react'
import PropTypes from 'prop-types'

import css from './style.module.scss'
import classNames from 'classnames/bind'

import StatementResult from 'core/statements/statementResult'
import MoveKeys from 'common/components/moveKeys'
import DeleteVariantIcon from 'mdi-react/DeleteVariantIcon'

const cx = classNames.bind(css)

/**
 * The result of a search for tagged statements.
 *
 * It includes the tags listed for the statement
 */
class OrderedStatements extends Component {
  static propTypes = {
    /** A mutable array of statements - sort will be called on this */
    statements: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        index: PropTypes.number.isRequired,
        display: PropTypes.string.isRequired,
        tags: PropTypes.array.isRequired
      })
    ),

    /**
     * function to be called when a statement should be moved
     * will be called with the statement object, and the direction (-1, 1)
     */
    onMove: PropTypes.func.isRequired,

    /**
     * Function to be called when a statement should be removed
     * will be called with the statement object
     */
    onDelete: PropTypes.func.isRequired
  }

  static defaultProps = {
    statements: []
  }

  /**
   * Return statements ordered by index, with start/end mapped
   *
   * Returns something like:
   * [{
   *   statment: {id: 1, index: 1, display: 'the advice', tags: []},
   *   start: true,
   *   end: true
   * }]
   */
  getOrderedStatements() {
    return this.props.statements
      .sort((a, b) => a.index - b.index)
      .map((statement, i, a) => ({
        statement,
        start: i === 0,
        end: i === a.length - 1
      }))
  }

  render() {
    const { className } = this.props
    return (
      <div className={cx('orderedStatement', className)}>
        {this.getOrderedStatements().map(({ statement, start, end }) => (
          <div className={cx('grid')} key={statement.id}>
            <StatementResult tags={statement.tags}>
              <div>{statement.display}</div>
            </StatementResult>
            <div className={css.controlls}>
              <MoveKeys
                start={start}
                end={end}
                onClick={dir => this.props.onMove(statement, dir)}
              />
              <DeleteVariantIcon
                className={css.delete}
                onClick={() => this.props.onDelete(statement)}
              />
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default OrderedStatements
