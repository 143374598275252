import { createActions, handleActions } from 'redux-actions'
import { put, takeLatest, select, call } from 'redux-saga/effects'
import * as utils from 'dot-prop-immutable'
import * as api from 'common/api'
import Status from 'common/helpers/Status'
import {
  FETCH_ACTIONS,
  delegateReducerForFetch,
  statusReducerFactory
} from 'common/helpers/store'
import { getCurrentProductKey } from 'core/product/selectors'

// Types
const PREFIX = 'avid/danger-ratings/'
export const READ = PREFIX + 'READ'
const UPDATE = PREFIX + 'UPDATE'
const SET = PREFIX + 'SET'
const SUBMIT = PREFIX + 'SUBMIT'

// Actions
const actions = createActions({
  [READ]: FETCH_ACTIONS,
  [SUBMIT]: FETCH_ACTIONS,
  [SET]: undefined,
  [UPDATE]: undefined
}).avid.dangerRatings

export const read = actions.read.requested
export const submit = actions.submit.requested
export const { update } = actions

// Defaults
export const DEFAULT = {
  data: buildEmptyWorkspaces(),
  statuses: {
    read: new Status(),
    submit: new Status()
  }
}

// Reducers
export default handleActions(
  new Map([
    [SET, delegateToReportReducer],
    [UPDATE, delegateToReportReducer],
    [READ, delegateReducerForFetch(delegateToReportReducer)],
    [SUBMIT, delegateReducerForFetch(delegateToReportReducer)]
  ]),
  new Map()
)
const reportReducer = handleActions(
  new Map([
    [
      SET,
      (state, action) =>
        utils.set(state, 'data', deserialize(action.payload.data))
    ],
    [
      UPDATE,
      (state, action) => {
        const { workspace, elevation, rating } = action.payload

        // TODO: Could use utils.set here!

        return {
          ...state,
          data: new Map(
            state.data.set(
              workspace,
              new Map(state.data.get(workspace).set(elevation, rating))
            )
          )
        }
      }
    ],
    [READ, statusReducerFactory('statuses.read')],
    [SUBMIT, statusReducerFactory('statuses.submit')]
  ]),
  DEFAULT
)

// Getters
export function get(state, reportKey, defaultValue) {
  return state.dangerRatings.get(reportKey) || defaultValue
}
function has(state, reportKey) {
  return state.dangerRatings.has(reportKey)
}

// Side effects
function* readDangerRatings(action) {
  const { reportKey } = action.payload
  const { statuses } = yield select(get, reportKey, DEFAULT)
  const status = statuses.read

  if (status.isPending || status.isFulfilled) return

  try {
    yield put(actions.read.pending({ reportKey }))

    const data = yield call(api.get, `dangerratings/${reportKey}`)

    if (data) {
      yield put(actions.set({ reportKey, data }))
      yield put(actions.read.fulfilled({ reportKey }))
    }
  } catch (error) {
    console.error(error)
    //window.alert(error.message)
    yield put(actions.read.rejected({ reportKey, error }))
  }
}
function* submitDangerRatings() {
  const reportKey = yield select(getCurrentProductKey)
  const hasData = yield select(has, reportKey)

  if (!hasData) return

  try {
    yield put(actions.submit.pending({ reportKey }))

    const { data } = yield select(get, reportKey)
    const body = serialize(data, reportKey)

    yield call(api.put, `dangerratings/${reportKey}`, body)
    yield put(actions.submit.fulfilled({ reportKey }))
  } catch (error) {
    console.error(error)
    window.alert(error.message)
    yield put(actions.submit.rejected({ reportKey, error }))
  }
}
export function* sagas() {
  yield takeLatest(submit, submitDangerRatings)
  yield takeLatest(read, readDangerRatings)
}

// Utils
function serialize(data, reportKey) {
  const payload = []

  for (let [workspace, elevations] of data) {
    for (let [elevation, rating] of elevations) {
      if (rating) {
        payload.push({
          reportKey,
          workspace,
          elevation,
          rating
        })
      }
    }
  }

  return payload
}
function deserialize(payload) {
  return payload.reduce((data, { workspace, elevation, rating }) => {
    data.get(workspace).set(elevation, rating)

    return data
  }, buildEmptyWorkspaces())
}
function buildEmptyWorkspaces() {
  return new Map(
    Array(4)
      .fill(0)
      .map((workspace, index) => [index + 1, new Map()])
  )
}
function delegateToReportReducer(state, action) {
  if (!action.payload) return state
  const { reportKey } = action.payload

  state.set(reportKey, reportReducer(state.get(reportKey), action))

  return new Map(state)
}
