import { createElement } from 'react'
import styles from './style.module.scss'
import classnames from 'classnames'

export function Error({ component, children, className, ...props }) {
  return createElement(
    component || 'div',
    Object.assign(props, {
      className: classnames(className, styles.Error)
    }),
    children
  )
}

export function Muted({ component, children, className, ...props }) {
  return createElement(
    component || 'div',
    Object.assign(props, {
      className: classnames(className, styles.Muted)
    }),
    children
  )
}
