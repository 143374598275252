import Immutable from 'seamless-immutable'

import { createReducer } from 'common/utils'
import * as types from './types'

const INITIAL_STATE = Immutable({
  isFetching: false,
  locationsById: {},
  currentUploadedGeoJSON: null
})

const reducers = {
  [types.FETCH_LOCATIONS]: fetchLocations,
  [types.FETCH_LOCATIONS_FAILED]: fetchLocationsFailed,
  [types.SET_LOCATIONS]: setLocations,
  [types.ADD_NEW_LOCATION]: addNewLocation,
  [types.EDIT_LOCATION]: updateLocation,
  [types.DELETE_LOCATION]: deleteLocation,
  [types.SET_CURRENT_GEOJSON]: setCurrentUploadedGeoJSON,
  [types.DELETE_CURRENT_GEOJSON]: deleteCurrentUploadedGeoJSON
}

function fetchLocations(state) {
  return Immutable.set(state, 'isFetching', true)
}

function fetchLocationsFailed(state) {
  return Immutable.set(state, 'isFetching', false)
}

function setLocations(state, payload) {
  const locationsById = payload.reduce((locationsById, location) => {
    locationsById[location.feature.id] = {
      ...location
    }

    return locationsById
  }, {})
  const locations = {
    isFetching: false,
    locationsById
  }

  return Immutable.merge(state, locations)
}

function addNewLocation(state, payload) {
  const location = {
    locationsById: {
      ...state.locationsById,
      [payload.feature.id]: {
        ...payload
      }
    }
  }

  return Immutable.merge(state, location)
}

function updateLocation(state, payload) {
  return Immutable.setIn(
    state,
    ['locationsById', `${payload.feature.id}`],
    payload
  )
}

function deleteLocation(state, payload) {
  const locations = Immutable.asMutable(state.locationsById, { deep: true })
  if (locations[payload])
    locations[payload].feature.properties.status = 'deleted'

  return Immutable.set(state, 'locationsById', locations)
}

function setCurrentUploadedGeoJSON(state, payload) {
  let feat
  if (!payload) {
    return state
  } else if (payload.type === 'Feature') {
    feat = payload
  } else if (
    payload.type === 'FeatureCollection' &&
    payload.features.length > 0
  ) {
    feat = payload.features[0]
  } else {
    window.alert('ERROR: Invalid GeoJSON file')
    return state
  }

  if (!feat.geometry || feat.geometry.type !== 'Polygon') {
    window.alert(
      `ERROR: Incompatable GeoJSON type "${feat.geometry.type}"; Only "Polygon" is supported at this time`
    )
    return state
  }

  //TODO: a helpful message about what exactly is supported here?

  return Immutable.set(state, 'currentUploadedGeoJSON', feat.geometry)
}

function deleteCurrentUploadedGeoJSON(state) {
  return Immutable.set(state, 'currentUploadedGeoJSON', null)
}

export default createReducer(INITIAL_STATE, reducers)
