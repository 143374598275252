export function sortInAlphabeticalOrder(a, b) {
  const nameA = a.label.toLowerCase()
  const nameB = b.label.toLowerCase()

  if (nameA < nameB) {
    return -1
  }

  if (nameA > nameB) {
    return 1
  }

  return 0
}
