import { createSelector } from 'reselect'

const offseasonMessageSelector = state => state.offseasonMessage
export const getOffSeason = createSelector(
  offseasonMessageSelector,
  state => state.offSeason
)
export const getSeason = createSelector(
  offseasonMessageSelector,
  state => state.offSeason.season
)
export const getComments = createSelector(
  offseasonMessageSelector,
  state => state.offSeason.comments
)
