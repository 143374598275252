import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { classList } from 'common/utils/'

import css from './style.module.scss'

let HeaderTitle = ({ title, className, children }) => {
  const classes = classList(css.header, className)
  const { t } = useTranslation('products')

  return (
    <div>
      <span className={classes}>{`${t(`header.${title}`)}: `}</span>
      <span>{children}</span>
    </div>
  )
}

HeaderTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export default HeaderTitle
