export const storage = window.localStorage

export const KEYS = {
  userId: 'USERID'
}

export function setItem(key, value) {
  try {
    storage.setItem(key, value)
  } catch (e) {
    console.error(e)
  }
}

export function getItem(key) {
  try {
    return storage.getItem(key)
  } catch (e) {
    console.error(e)
  }
}

export function destroyItem(key) {
  try {
    storage.removeItem(key)
  } catch (e) {
    console.error(e)
  }
}

/**
 * Helper function that accepts Auth0 profile and returns
 * profile with referrer and ID as split.
 *
 * An example Auth0 profile looks something like:
 * sub: { "google-oauth2|117112289750966194829" }
 *
 * We only need the unique ID after the pipe.
 */
export function formatProfile( auth0Profile) {
  return auth0Profile['sub'].split('|')[1]
}
