import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './style.module.scss'

const LoadingPage = () => {
  const { t } = useTranslation('common')
  return (
    <div className={css.loading}>{t('loading')}</div>
  )
}

export default LoadingPage
